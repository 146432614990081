import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Repo from "../Utility/Repo";
import Select from "../Objects/Select";
import UploadFile from "../Objects/UploadFile";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import moment from "moment";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

var CodiceFiscale = require("codice-fiscale-js");

function Delega(props) {
  const [id, setId] = useState();
  const [validation, setValidation] = useState();
  const [validationEmail, setValidationEmail] = useState(true);
  const [validationCF, setValidationCF] = useState(true);
  const [validationGiuridica, setValidationGiuridica] = useState(true);
  const [validationImmobili, setValidationImmobili] = useState(true);
  const [token, setToken] = useState(props.token);
  const [listaProvince, setListaProvince] = useState([]);
  const [selectedProvincia, setSelectedProvincia] = useState();
  const [listaComuni, setListaComuni] = useState([]);
  const [selectedComune, setSelectedComune] = useState();
  const [uploadFronte, setUploadFronte] = useState();
  const [uploadRetro, setUploadRetro] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessage2, setErrorMessage2] = useState(false);
  const [isOpenDeletePlanimetria, toggleModalDeletePlanimetria] =
    useState(false);
  const [indexDelete, setIndexDelete] = useState();

  const cookies = new Cookies();
  const [apiToken] = useState(cookies.get("token_key"));

  const [count, setCount] = useState(1);
  const [overFive, setOverFive] = useState(false);
  const [onlyOne, setOnlyOne] = useState(false);

  const [data, setData] = useState({
    nominativo: "",
    luogoNascita: "",
    dataNascita: "",
    cittaResidenza: "",
    provinciaResidenza: "",
    indirizzoResidenza: "",
    codiceFiscale: null,
    numeroTelefono: "",
    pec: null,
    tipoDocumento: "",
    numeroDocumento: "",
    enteDocumento: "",
    dataDocumento: "",
    personaGiuridica: "",
    ragioneSociale: "",
    cittaSede: "",
    viaSede: "",
    cF_societa: "",
    //nominativoDelegato: "",
    //tipoDocumentoDelegato: "",
    //numeroDocumentoDelegato: "",
    //enteDocumentoDelegato: "",
    //dataDocumentoDelegato: "",
    sezione_0: "",
    sezione_1: "",
    sezione_2: "",
    sezione_3: "",
    sezione_4: "",
    // provincia_1: "",
    // sezione_1: "",
    // foglio_1: "",
    // particella_1: "",
    // suB_1: "",
    // provincia_2: "",
    // sezione_2: "",
    // foglio_2: "",
    // particella_2: "",
    // suB_2: "",
    // provincia_3: "",
    // sezione_3: "",
    // foglio_3: "",
    // particella_3: "",
    // suB_3: "",
    // provincia_4: "",
    // sezione_4: "",
    // foglio_4: "",
    // particella_4: "",
    // suB_4: "",
    luogoDelega: "",
    copiaVisura: true,
    //firmaDelega: "",
    fronteCarta: "",
    retroCarta: "",
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setOverFive(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, [overFive]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOnlyOne(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, [onlyOne]);

  useEffect(() => {
    if (apiToken) {
      var decoded = jwt_decode(apiToken);

      try {
        var cf = new CodiceFiscale(decoded.FiscalCode);
        let temp = data;

        // setData({
        //   ...data,
        //   pec: decoded.unique_name,
        //   nominativo: decoded.FullName,
        //   codiceFiscale: decoded.FiscalCode,
        //   luogoNascita: cf.birthplace.nome,
        //   dataNascita: moment(cf.birthday).format("YYYY-MM-DD"),
        // });
        temp.pec = decoded.unique_name;
        temp.nominativo = decoded.FullName;
        temp.codiceFiscale = decoded.FiscalCode;
        temp.luogoNascita = cf.birthplace.nome;
        temp.dataNascita = moment(cf.birthday).format("YYYY-MM-DD");

        setData(temp);
      } catch (error) {
        setData({
          ...data,
          pec: decoded.unique_name,
          nominativo: decoded.FullName,
          codiceFiscale: decoded.FiscalCode,
        });

        console.error("Codice fiscale non valido");
      }
    }
  }, []);

  useEffect(() => {
    const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (data.pec !== null) {
      if (!data.pec.match(regexEmail)) {
        setValidationEmail(false);
      } else {
        setValidationEmail(true);
      }
    }
  }, [data.pec]);

  useEffect(() => {
    const regexPW =
      /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/m;

    if (data.codiceFiscale !== null) {
      if (!data.codiceFiscale.match(regexPW)) {
        setValidationCF(false);
      } else {
        setValidationCF(true);
      }
    }
  }, [data.codiceFiscale]);

  useEffect(() => {
    //occorre portarsi dietro l'id nell'URL cambiando pagina
    const query = new URLSearchParams(window.location.search);
    setId(query.get("query"));

    Repo.getDatiImmobili(apiToken, query.get("query"))
      .then((response) => {
        if (Object.entries(response.data).length / 4 < 5) {
          setCount(Object.entries(response.data).length / 4);
        } else {
          //errore
        }

        let temp = data;
        Object.entries(response.data).map(([key, value]) => {
          temp[key] = value;
        });
        setData(temp);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    Repo.getProvince(token)
      .then((response) => {
        setListaProvince(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const changeProvincia = (event) => {
    setSelectedProvincia({
      value: event.value,
      label: event.label,
    });

    setData({ ...data, provinciaResidenza: event.value });

    Repo.getComuni(token, event.value)
      .then((response) => {
        setListaComuni(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeComune = (event) => {
    setData({ ...data, cittaResidenza: event.label });
    setSelectedComune({
      value: event.value,
      label: event.label,
    });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleClick = async () => {
    var passedValidation = true;
    setValidation(true);

    var tempData = data;

    if (uploadFronte) {
      var base64FileF = await getBase64(uploadFronte);
      var splitBase64F = base64FileF.split(",");
      // setData({ ...data, fronteCarta: splitBase64F[1] });
      tempData["fronteCarta"] = splitBase64F[1];
    }

    if (uploadRetro) {
      var base64FileR = await getBase64(uploadRetro);
      var splitBase64R = base64FileR.split(",");
      // setData({ ...data, retroCarta: splitBase64R[1] });
      tempData["retroCarta"] = splitBase64R[1];
    }

    Object.entries(tempData).map(([key, value]) => {
      if (
        key !== "personaGiuridica" &&
        key !== "ragioneSociale" &&
        key !== "cittaSede" &&
        key !== "viaSede" &&
        key !== "cF_societa" &&
        key !== "sezione_0" &&
        key !== "sezione_1" &&
        key !== "sezione_2" &&
        key !== "sezione_3" &&
        key !== "sezione_4"
      ) {
        if (value == "" || value == null) {
          passedValidation = false;
        }
      }
    });

    // controlli planimetria
    for (var i = 0; i < count; i++) {
      if (
        tempData["provincia_" + i.toString()] !== "" ||
        tempData["foglio_" + i.toString()] !== "" ||
        tempData["sezione_" + i.toString()] !== "" ||
        tempData["particella_" + i.toString()] !== "" ||
        tempData["suB_" + i.toString()] !== "" ||
        count == 1
      ) {
        if (
          tempData["provincia_" + i.toString()] == "" ||
          tempData["foglio_" + i.toString()] == "" ||
          tempData["particella_" + i.toString()] == "" ||
          tempData["suB_" + i.toString()] == ""
        ) {
          const element = document.getElementById("provincia_" + i.toString());
          element.scrollIntoView();
          setValidationImmobili(false);
          passedValidation = false;
          continue;
        } else {
          setValidationImmobili(true);
        }
      }
    }
    // Verifica campi se persona Giuridica è compilato
    setValidationGiuridica(true);
    if (
      tempData.ragioneSociale !== "" ||
      tempData.cittaSede !== "" ||
      tempData.viaSede !== "" ||
      tempData.cF_societa !== "" ||
      tempData.personaGiuridica !== ""
    ) {
      if (
        tempData.ragioneSociale == "" ||
        tempData.cittaSede == "" ||
        tempData.viaSede == "" ||
        tempData.cF_societa == "" ||
        tempData.personaGiuridica == ""
      ) {
        var element = document.getElementById("personaGiuridica");
        element.scrollIntoView();
        setValidationGiuridica(false);
        passedValidation = false;
      }
    }

    if (passedValidation) {
      if (id) {
        Repo.postDatiDelega(props.token, JSON.stringify(tempData), id)
          .then((response) => {
            if (response.status === 200) {
              window.location.href =
                props.subfolder + "/fatturazione?query=" + id;
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      } else {
        Repo.postUserDatiDelega(props.token, JSON.stringify(tempData))
          .then((response) => {
            if (response.status === 200) {
              window.location.href = props.subfolder + "/fatturazione";
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      }
    }
  };

  const addImmobile = async () => {
    if (count < 5) {
      setCount(count + 1);
      setOverFive(false);
      setData({
        ...data,
        ["provincia_" + count.toString()]: "",
        ["sezione_" + count.toString()]: "",
        ["foglio_" + count.toString()]: "",
        ["particella_" + count.toString()]: "",
        ["suB_" + count.toString()]: "",
      });
    } else {
      setOverFive(true);
    }
  };

  const deleteImmobile = () => {
    if (count == 1) {
      setOnlyOne(true);
    } else {
      let temp = data;
      for (var i = indexDelete; i < count - 1; i++) {
        temp["provincia_" + i.toString()] =
          temp["provincia_" + (i + 1).toString()];
        temp["sezione_" + i.toString()] = temp["sezione_" + (i + 1).toString()];
        temp["foglio_" + i.toString()] = temp["foglio_" + (i + 1).toString()];
        temp["particella_" + i.toString()] =
          temp["particella_" + (i + 1).toString()];
        temp["suB_" + i.toString()] = temp["suB_" + (i + 1).toString()];
      }
      delete temp["provincia_" + i.toString()];
      delete temp["sezione_" + i.toString()];
      delete temp["foglio_" + i.toString()];
      delete temp["particella_" + i.toString()];
      delete temp["suB_" + i.toString()];
      setData(temp);
      setCount(count - 1);
      setOnlyOne(false);
    }
  };

  return (
    <>
      <div className="card card-teaser rounded shadow mb-4">
        <div className="card-body">
          <h4 className="card-title mb-sm-4 mb-2">Delega</h4>
          <div className="form-group px-lg-5 py-lg-2">
            <div className="col-lg-12 mb-2 text-center">
              <h4>DELEGA PER L’ACCESSO ALLE PLANIMETRIE</h4>
            </div>
            <div className="col-lg-12 mb-2 text-center">
              <a>
                Con questo modello il Contribuente, titolare di diritti reali su
                un immobile, può delegare un’altra persona a richiedere la
                planimetria del bene di Sua proprietà presso gli uffici
                dell’Agenzia.
              </a>
            </div>
            {/*inizio parte prima  */}
            <div className="row mt-4">
              <div className="col-12 col-lg-6">
                <Input
                  id="nominativo"
                  value={data ? data.nominativo : ""}
                  name="nominativo"
                  label="Nome e cognome*"
                  onChange={(e) => {
                    setData({ ...data, nominativo: e.target.value });
                  }}
                  {...(validation &&
                    !data.nominativo && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
              </div>
              <div className="col-lg-3 col-12">
                <Input
                  id="dataNascita"
                  type="Date"
                  name="dataNascita"
                  value={data ? data.dataNascita : ""}
                  onChange={(e) =>
                    setData({ ...data, dataNascita: e.target.value })
                  }
                  {...(validation &&
                    !data.dataNascita && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
                <label htmlFor="dataNascita" className="active">
                  Data di nascita
                </label>
              </div>
              <div className="col-lg-3 col-12">
                <Input
                  id="luogoNascita"
                  type="text"
                  name="luogoNascita"
                  maxLength={20}
                  label="Luogo di nascita*"
                  value={data && data.luogoNascita ? data.luogoNascita : ""}
                  onChange={(e) =>
                    setData({ ...data, luogoNascita: e.target.value })
                  }
                  {...(validation &&
                    !data.luogoNascita && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className="bootstrap-select-wrapper col-12">
                  <Select
                    aria-label="Provincia di residenza*"
                    clearText="Annulla"
                    defaultMenuIsOpen={false}
                    value={selectedProvincia ? selectedProvincia : ""}
                    icon={false}
                    id="selectExampleClassic"
                    onChange={changeProvincia}
                    options={listaProvince}
                    isSearchable
                    placeholder="Provincia di residenza*"
                  />
                </div>
                {validation && !selectedProvincia ? (
                  <small
                    id="errorSelect"
                    className="form-text error_div text-danger"
                  >
                    Seleziona un campo
                  </small>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-lg-4 col-12">
                <div className="bootstrap-select-wrapper col-12">
                  <Select
                    aria-label="Città di residenza*"
                    clearText="Annulla"
                    defaultMenuIsOpen={false}
                    value={selectedComune ? selectedComune : ""}
                    icon={false}
                    id="selectExampleClassic"
                    onChange={changeComune}
                    options={listaComuni}
                    isSearchable
                    placeholder="Città di residenza*"
                  />
                </div>
                {validation && !selectedComune ? (
                  <small
                    id="errorSelect"
                    className="form-text error_div text-danger"
                  >
                    Seleziona un campo
                  </small>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-lg-4 col-12">
                <Input
                  id="indirizzoResidenza"
                  type="text"
                  name="indirizzoResidenza"
                  label="Indirizzo Residenza*"
                  onChange={(e) =>
                    setData({ ...data, indirizzoResidenza: e.target.value })
                  }
                  {...(validation &&
                    !data.indirizzoResidenza && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-4 col-12">
                <Input
                  id="pec"
                  value={data && data.pec ? data.pec : ""}
                  type="text"
                  name="pec"
                  label="Email / Pec*"
                  onChange={(e) => setData({ ...data, pec: e.target.value })}
                  {...(validation &&
                    !data.pec && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  {...(!validationEmail && {
                    infoText: "Il campo mail non è corretto!",
                    className: "text-danger",
                  })}
                />
              </div>
              <div className="col-lg-4 col-12">
                <Input
                  id="codiceFiscale"
                  value={data && data.codiceFiscale ? data.codiceFiscale : ""}
                  type="text"
                  name="codiceFiscale"
                  label="Codice Fiscale*"
                  onChange={(e) =>
                    setData({ ...data, codiceFiscale: e.target.value })
                  }
                  {...(validation &&
                    !data.codiceFiscale && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  {...(!validationCF && {
                    infoText: "Il campo Codice Fiscale non è corretto!",
                    className: "text-danger",
                  })}
                />
              </div>
              <div className="col-lg-4 col-12">
                <Input
                  id="numeroTelefono"
                  type="text"
                  name="numeroTelefono"
                  maxLength={13}
                  label="Numero di telefono*"
                  onChange={(e) =>
                    setData({ ...data, numeroTelefono: e.target.value })
                  }
                  {...(validation &&
                    !data.numeroTelefono && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
              </div>
            </div>
            <div className="row ">
              {/*fine parte prima  */}
              <div className="col-lg-12 mt-2">
                <b>Documento di identità:</b>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-6 col-12">
                <Input
                  id="tipoDocumento"
                  type="text"
                  name="tipoDocumento"
                  maxLength={25}
                  label="Tipo di documento*"
                  onChange={(e) =>
                    setData({ ...data, tipoDocumento: e.target.value })
                  }
                  {...(validation &&
                    !data.tipoDocumento && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
              </div>
              <div className="col-lg-6 col-12">
                <Input
                  id="numeroDocumento"
                  type="text"
                  name="numeroDocumento"
                  maxLength={9}
                  label="Numero di documento*"
                  onChange={(e) =>
                    setData({ ...data, numeroDocumento: e.target.value })
                  }
                  {...(validation &&
                    !data.numeroDocumento && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-9 col-12">
                <Input
                  id="enteDocumento"
                  type="text"
                  name="enteDocumento"
                  maxLength={25}
                  label="Ente di rilascio del documento*"
                  onChange={(e) =>
                    setData({ ...data, enteDocumento: e.target.value })
                  }
                  {...(validation &&
                    !data.enteDocumento && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
              </div>
              <div className="col-lg-3 col-12">
                <Input
                  id="dataDocumento"
                  type="date"
                  name="dataDocumento"
                  onChange={(e) =>
                    setData({ ...data, dataDocumento: e.target.value })
                  }
                  {...(validation &&
                    !data.dataDocumento && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
                <label htmlFor="dataDocumento" className="active">
                  Data di Rilascio Documento*
                </label>
              </div>
            </div>
            <div className="form-group px-5 py-2 borderCard">
              <div className="col-lg-12 mb-2 text-center">
                <b>
                  <i>Da compilare solo in caso di persona giuridica</i>
                </b>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6 col-12">
                  <Input
                    id="personaGiuridica"
                    type="text"
                    name="personaGiuridica"
                    maxLength={30}
                    label="Qualità di persona giuridica*"
                    onChange={(e) =>
                      setData({ ...data, personaGiuridica: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-12">
                  <Input
                    id="ragioneSociale"
                    type="text"
                    name="ragioneSociale"
                    maxLength={30}
                    label="Società (Ragione sociale)"
                    onChange={(e) =>
                      setData({ ...data, ragioneSociale: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-12">
                  <Input
                    id="cittaSede"
                    type="text"
                    name="cittaSede"
                    maxLength={30}
                    label="Città della sede"
                    onChange={(e) =>
                      setData({ ...data, cittaSede: e.target.value })
                    }
                  />
                </div>
                <div className="col-lg-6 col-12">
                  <Input
                    id="viaSede"
                    type="text"
                    name="viaSede"
                    maxLength={30}
                    label="Via della sede"
                    onChange={(e) =>
                      setData({ ...data, viaSede: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Input
                    id="cF_societa"
                    type="text"
                    name="cF_societa"
                    maxLength={16}
                    label="Codice fiscale della società"
                    onChange={(e) =>
                      setData({ ...data, cF_societa: e.target.value })
                    }
                  />
                </div>
              </div>
              {!validationGiuridica && (
                <div className="text-danger text-center">
                  Devi compilare tutti i campi in caso di persona giuridica
                </div>
              )}
            </div>
            {/* <div className="text-center">
              <b>delego</b>
            </div> */}
            {/* <div className="row mt-3">
              <div className="col">
                <Input
                  id="nominativoDelegato"
                  type="text"
                  name="nominativoDelegato"
                  label="Nome e Cognome del delegato*"
                  onChange={(e) =>
                    setData({ ...data, nominativoDelegato: e.target.value })
                  }
                  {...(validation &&
                    !data.nominativoDelegato && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <b>Documento di identità del delegato:</b>
                <Input
                  id="tipoDocumentoDelegato"
                  type="text"
                  name="tipoDocumentoDelegato"
                  label="Tipo di documento del Delegato*"
                  className="mt-3"
                  onChange={(e) =>
                    setData({ ...data, tipoDocumentoDelegato: e.target.value })
                  }
                  {...(validation &&
                    !data.tipoDocumentoDelegato && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
              </div>
              <div className="col align-self-end">
                <Input
                  id="numeroDocumentoDelegato"
                  type="text"
                  name="numeroDocumentoDelegato"
                  label="Numero di documento del Delegato*"
                  onChange={(e) =>
                    setData({
                      ...data,
                      numeroDocumentoDelegato: e.target.value,
                    })
                  }
                  {...(validation &&
                    !data.numeroDocumentoDelegato && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Input
                  id="enteDocumentoDelegato"
                  type="text"
                  name="enteDocumentoDelegato"
                  label="Ente di rilascio del documento del Delegato*"
                  onChange={(e) =>
                    setData({ ...data, enteDocumentoDelegato: e.target.value })
                  }
                  {...(validation &&
                    !data.enteDocumentoDelegato && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
              </div>
              <div className="col-3">
                <Input
                  id="dataDocumentoDelegato"
                  type="Date"
                  name="dataDocumentoDelegato"
                  onChange={(e) =>
                    setData({ ...data, dataDocumentoDelegato: e.target.value })
                  }
                  {...(validation &&
                    !data.dataDocumentoDelegato && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
                <label htmlFor="dataDocumentoDelegato" className="active">
                  Data di Rilascio Documento
                </label>
              </div>
            </div> */}
            <div className="row mt-2">
              <div className="col-6 ">
                <div
                  key={"confermato"}
                  className="form-check"
                  aria-describedby=""
                >
                  <input
                    id={"confermato"}
                    type="checkbox"
                    name="checkBox1"
                    checked={data.copiaVisura}
                    onChange={(e) =>
                      setData({
                        ...data,
                        copiaVisura: !data.copiaVisura,
                      })
                    }
                  />
                  <label htmlFor={"confermato"}>
                    <span>
                      <span>a effettuare la visura</span>
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-6 ">
                <div
                  key={"confermato"}
                  className="form-check"
                  aria-describedby=""
                >
                  <input
                    id={"confermato"}
                    type="checkbox"
                    name="checkBox1"
                    checked={!data.copiaVisura}
                    onChange={(e) =>
                      setData({
                        ...data,
                        copiaVisura: !data.copiaVisura,
                      })
                    }
                  />
                  <label htmlFor={"confermato"}>
                    <span>
                      <span>a richiedere e ricevere la copia</span>
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12 text-center">
                <p>
                  <b>
                    Della planimetria relativa alle seguenti unità immobiliari:
                  </b>
                </p>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 text-right">
                <button
                  onClick={() => addImmobile()}
                  className="btn btn-primary"
                >
                  Aggiungi
                  <svg
                    style={{ cursor: "pointer" }}
                    className="icon icon-white ml-2"
                  >
                    <use href={`${iconSprite}#it-plus-circle`}></use>
                  </svg>
                </button>
              </div>
            </div>

            {count &&
              [...Array(count)].map((x, index) => (
                <React.Fragment key={index + "_" + count}>
                  {/*pseudo-tabella */}
                  <div className="row justify-content-center mb-2 immobiliData">
                    <div className="col-12 col-lg-2">
                      <Input
                        id={"provincia_" + index}
                        type="text"
                        name={"provincia_" + index}
                        value={
                          data && data["provincia_" + index.toString()]
                            ? data["provincia_" + index.toString()]
                            : ""
                        }
                        label="Comune*"
                        onChange={(e) =>
                          setData({
                            ...data,
                            [e.target.id]: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-12 col-lg-2">
                      <Input
                        id={"sezione_" + index}
                        type="text"
                        name={"sezione_" + index}
                        value={
                          data && data["sezione_" + index.toString()]
                            ? data["sezione_" + index.toString()]
                            : ""
                        }
                        label="Sezione"
                        onChange={(e) =>
                          setData({ ...data, [e.target.id]: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-12 col-lg-2">
                      <Input
                        id={"foglio_" + index}
                        type="text"
                        name={"foglio_" + index}
                        value={
                          data && data["foglio_" + index.toString()]
                            ? data["foglio_" + index.toString()]
                            : ""
                        }
                        label="Foglio*"
                        onChange={(e) =>
                          setData({ ...data, [e.target.id]: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-12 col-lg-2">
                      <Input
                        id={"particella_" + index}
                        type="text"
                        name={"particella_" + index}
                        value={
                          data && data["particella_" + index.toString()]
                            ? data["particella_" + index.toString()]
                            : ""
                        }
                        label="Particella*"
                        onChange={(e) =>
                          setData({
                            ...data,
                            [e.target.id]: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-12 col-lg-2">
                      <Input
                        id={"suB_" + index}
                        type="text"
                        name={"suB_" + index}
                        value={
                          data && data["suB_" + index.toString()]
                            ? data["suB_" + index.toString()]
                            : ""
                        }
                        label="Subalterno*"
                        onChange={(e) =>
                          setData({
                            ...data,
                            [e.target.id]: e.target.value,
                            setUploadFronte,
                          })
                        }
                      />
                    </div>
                    <div className="col-12 col-lg-1">
                      <svg
                        className="icon icon-danger float-right"
                        aria-hidden="true"
                        onClick={() => (
                          toggleModalDeletePlanimetria(true),
                          setIndexDelete(index)
                        )}
                        style={{ cursor: "pointer" }}
                      >
                        <use href={`${iconSprite}#it-close-circle`}></use>
                      </svg>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            <div className="row text-center">
              {/* sezione Caricamento documenti */}
              {validation && !validationImmobili && (
                <div className="col-12 text-danger mb-3">
                  (*) Inserisci almeno un'unità immobiliare specificando tutti i
                  campi obbligatori
                </div>
              )}
            </div>
            <div className="form-group px-5 py-2 borderCard">
              <b>Caricamento documenti (in formato jpg, jpeg, png, pdf) :</b>
              <div className="row mt-2">
                <div className="col-12 mb-3 ">
                  Carica una copia del fronte del documento di identità:
                </div>
                <div className="col-12 mb-4">
                  <UploadFile
                    handleFile={setUploadFronte}
                    position={"text-left"}
                    testoInterno={"Carica"}
                    setErrorMessage={setErrorMessage}
                    typeFile={[
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                      "application/pdf",
                    ]}
                  ></UploadFile>
                </div>
                {errorMessage && (
                  <div className="text-danger">
                    Carica un file con un formato supportato
                  </div>
                )}
                {validation && !uploadFronte && (
                  <div className="col-12 text-danger">
                    Il campo non può essere vuoto!
                  </div>
                )}
              </div>
              <div className="row mt-2">
                <div className="col-12 mb-3">
                  Carica una copia del retro del documento di identità:
                </div>
                <div className="col-12 mb-4">
                  <UploadFile
                    handleFile={setUploadRetro}
                    position={"text-left"}
                    testoInterno={"Carica"}
                    setErrorMessage={setErrorMessage2}
                    typeFile={[
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                      "application/pdf",
                    ]}
                  ></UploadFile>
                </div>
                {errorMessage2 && (
                  <div className="text-danger">
                    Carica un file con un formato supportato
                  </div>
                )}
                {validation && !uploadRetro && (
                  <div className="col-12 text-danger">
                    Il campo non può essere vuoto!
                  </div>
                )}
              </div>
            </div>

            {/*sezione firma del delegante */}
            <div className="row">
              <div className="col-6">
                <Input
                  id="luogo"
                  type="text"
                  name="luogo"
                  maxLength={30}
                  label="Luogo"
                  onChange={(e) =>
                    setData({ ...data, luogoDelega: e.target.value })
                  }
                  {...(validation &&
                    !data.luogoDelega && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
              </div>
              {/* <div className="col">
                <Input
                  id="firmaDelegante"
                  type="text"
                  name="firmaDelegante"
                  label="Firma digitale / autografa del delegante"
                  onChange={(e) =>
                    setData({ ...data, firmaDelega: e.target.value })
                  }
                  {...(validation &&
                    !data.firmaDelega && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                />
              </div> */}
            </div>

            <div className="row mt-2">
              <div className="col-12 text-right">
                <button
                  className="btn btn-primary "
                  onClick={() => handleClick()}
                >
                  Invia
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {overFive && (
        <>
          <div
            className="alert alert-danger"
            role="alert"
            style={{
              position: "fixed",
              bottom: 0,
              right: "15px",
              backgroundColor: "#fff",
              width: "30%",
              zIndex: 1000,
            }}
          >
            <b>Non è possibile aggiungere più di 5 immobili!</b>
          </div>
        </>
      )}

      {onlyOne && (
        <>
          <div
            className="alert alert-danger"
            role="alert"
            style={{
              position: "fixed",
              bottom: 0,
              right: "15px",
              backgroundColor: "#fff",
              width: "30%",
              zIndex: 1000,
            }}
          >
            <b>Deve essere presente almeno una unità immobiliare!</b>
          </div>
        </>
      )}

      <Modal
        isOpen={isOpenDeletePlanimetria}
        toggle={() => toggleModalDeletePlanimetria(!isOpenDeletePlanimetria)}
        size={"lg"}
        labelledBy="Modale1"
      >
        <ModalHeader id="Modale1">Attenzione!</ModalHeader>
        <ModalBody>
          Sei sicuro di voler eliminare l'unità immobiliare selezionata?
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary text-white text-center"
            onClick={() => {
              toggleModalDeletePlanimetria(false), deleteImmobile();
            }}
          >
            Si
          </Button>
          <Button
            color="info text-white text-center"
            onClick={() => {
              toggleModalDeletePlanimetria(false);
            }}
          >
            NO
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Delega;
