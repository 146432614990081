import { useEffect, useState } from "react";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Link } from "react-router-dom";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
} from "design-react-kit";
import moment from "moment";
import Repo from "../Utility/Repo";
import SelectServizi from "./SelectServizi";

function ListaVisureCatastali(props) {
  const [token, setToken] = useState(props.token);
  const [isOpen, toggleModal] = useState(false);
  const [isOpen2, toggleModal2] = useState(false);
  const [idDel1, setIdDel1] = useState();
  const [idDel2, setIdDel2] = useState();
  const [idDel3, setIdDel3] = useState();

  const delRichiestaOrdine = (idRichiesta) => {
    Repo.delRichiestaFromOrdine(token, idRichiesta)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const delServizioRichiesta = (idServizio, idRichiesta) => {
    Repo.delServizioFromRichiesta(token, idServizio, idRichiesta)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          {props.riepilogo.richiesteServizi.map((x, index) => (
            <div key={index}>
              <div className="card card-teaser rounded shadow mb-3 col-12 mr-1">
                <div className="card-body">
                  <div className="row mb-1">
                    <h4 className="card-title mb-3">Richieste</h4>

                    <div className="col-lg-12 text-right">
                      {props.riepilogo &&
                        props.riepilogo.flagPagato === false &&
                        props.riepilogo.richiesteServizi.length &&
                        props.riepilogo.richiesteServizi.length > 1 && (
                          <a
                            className="list-item"
                            onClick={() => {
                              toggleModal2(!isOpen2);
                              setIdDel3(x.id);
                            }}
                          >
                            <svg
                              className="icon icon-danger mt-0"
                              style={{ cursor: "pointer" }}
                            >
                              <use href={`${iconSprite}#it-delete`} />{" "}
                            </svg>
                          </a>
                        )}
                    </div>
                    {x.ufficioConservatoria && (
                      <div
                        className="col-lg-6"
                        style={{ borderRight: "inset" }}
                      >
                        <div className="row mb-1">
                          <h6>
                            {" "}
                            Dati richiesta conservatoria{" "}
                            {(x.tipoNota || x.numeroRegistroGenerale) && (
                              <>per nota:</>
                            )}{" "}
                          </h6>
                          <div className="col-lg-12">
                            Ufficio: <b>{x.ufficioConservatoria}</b>
                          </div>
                          <div className="col-lg-6">
                            Registro:{" "}
                            <b>
                              {x.numeroRegistroParticolare ? (
                                <>Particolare</>
                              ) : (
                                <>Generale</>
                              )}
                            </b>
                          </div>
                          {x.tipoNota && (
                            <div className="col-lg-6">
                              Tipo Nota:{" "}
                              <b>
                                {x.tipoNota == "0" ? (
                                  <>Trascrizioni</>
                                ) : x.tipoNota == "" ? (
                                  <>Iscrizioni</>
                                ) : (
                                  <>Annotazioni</>
                                )}
                              </b>
                            </div>
                          )}
                          {x.numeroRegistroParticolare && (
                            <div className="col-lg-6">
                              N. Registro: <b>{x.numeroRegistroParticolare}</b>
                            </div>
                          )}
                          {x.numeroRegistroGenerale && (
                            <div className="col-lg-6">
                              N. Registro: <b>{x.numeroRegistroGenerale}</b>
                            </div>
                          )}
                          {x.annoRegistroGenerale && (
                            <div className="col-lg-6">
                              Anno Registro: <b>{x.annoRegistroGenerale}</b>
                            </div>
                          )}
                          {x.annoRegistroParticolare && (
                            <div className="col-lg-6">
                              Anno Registro: <b>{x.annoRegistroParticolare}</b>
                            </div>
                          )}
                          {x.motivazioneConservatoria && (
                            <div className="col-lg-12">
                              Motivazione: <b>{x.motivazioneConservatoria}</b>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {/* Dati dell'immobile */}
                    {!x.cf_piva && !x.cognome && (
                      <div
                        className="col-lg-6"
                        style={{ borderRight: "inset" }}
                      >
                        <div className="row mb-1 ">
                          <h6 className="mx-1"> Dati Immobile:</h6>

                          {x.tipoImmobile && (
                            <div className="col-lg-12">
                              Tipo Immobile:{" "}
                              <b>
                                {x.tipoImmobile && x.tipoImmobile.descrizione
                                  ? x.tipoImmobile.descrizione
                                  : "-"}
                              </b>
                            </div>
                          )}

                          {x.mappale && x.foglio && (
                            <div className="col-lg-12">
                              Mappale: <b>{x.mappale} </b> Foglio:{" "}
                              <b>{x.foglio}</b>
                              {x.subalterno && (
                                <>
                                  {" "}
                                  Subalterno: <b> {x.subalterno}</b>
                                </>
                              )}
                            </div>
                          )}
                          {x.indirizzo && (
                            <div className="col-lg-12">
                              Indirizzo (Piano):{" "}
                              <b>
                                {x.indirizzo}
                                {x.piano ? <>{" (" + x.piano + ")"}</> : <> </>}
                              </b>
                            </div>
                          )}
                          {x.comune && (
                            <div className="col-lg-12">
                              Comune:{" "}
                              <b>
                                {x.comune ? x.comune : "-"}
                                {x.provincia ? " (" + x.provincia + ")" : ""}
                              </b>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {x.tipoImmobile == null && (
                      <>
                        {/* Dati del Proprietario */}
                        <div className="col-lg-6 pl-2">
                          <div className="row">
                            <h6> Dati del Proprietario:</h6>
                          </div>{" "}
                          <div className="row">
                            {(x.nome || x.cognome) && (
                              <div className="col-lg-12">
                                Nominativo:{" "}
                                <b>
                                  {x.nome ? x.nome : " "}{" "}
                                  {x.cognome ? x.cognome : " "}
                                </b>
                              </div>
                            )}
                            {x.cf_piva && (
                              <div className="col-lg-12">
                                Codice Fiscale/PIva: <b>{x.cf_piva}</b>
                              </div>
                            )}
                            {x.luogoNascita && (
                              <div className="col-lg-6">
                                Luogo di Nascita: <b>{x.luogoNascita}</b>
                              </div>
                            )}
                            {x.dataNascita && x.cf_piva !== "" && (
                              <div className="col-lg-6">
                                Data di Nascita:{" "}
                                <b>
                                  {x.dataNascita &&
                                  x.dataNascita != "01/01/0001"
                                    ? x.dataNascita
                                    : "-"}
                                </b>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <hr></hr>
                  <h5 className="card-title mb-2 pl-1">Servizi</h5>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="myList">
                        <ul className="it-list">
                          {x.servizi && x.servizi.length > 0 ? (
                            x.servizi.map((y, idx) => (
                              <li className="myList" key={idx}>
                                <a
                                  style={{
                                    color: "#19191a",
                                  }}
                                >
                                  <div className="row it-right-zone">
                                    <div className="col-lg-8">
                                      - {y.nome}{" "}
                                      {props.riepilogo &&
                                        props.riepilogo.flagPagato === false &&
                                        x.servizi &&
                                        x.servizi.length > 1 && (
                                          <button
                                            className="btn mb-2"
                                            onClick={
                                              () => {
                                                toggleModal(!isOpen);

                                                setIdDel1(y.id);
                                                setIdDel2(x.id);
                                              }
                                              // delServizioRichiesta(y.id, x.id)
                                            }
                                          >
                                            <svg
                                              className="icon icon-danger"
                                              style={{ cursor: "pointer" }}
                                            >
                                              <use
                                                href={`${iconSprite}#it-close-big`}
                                              />{" "}
                                            </svg>
                                          </button>
                                        )}
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="row">
                                        <div className="col-lg-12">
                                          Imponibile:{" "}
                                          <b>{y.prezzoDTO.imponibile} €</b>
                                        </div>
                                        <div className="col-lg-12">
                                          Diritti:{" "}
                                          <b>
                                            {y.prezzoDTO.costoDirittiETributi} €
                                          </b>
                                        </div>
                                        <div className="col-lg-12">
                                          Iva: <b>{y.prezzoDTO.imposta} €</b>
                                        </div>
                                      </div>
                                    </div>
                                    {y.subServizio && (
                                      <div
                                        className="row col-lg-12 "
                                        style={{ paddingInline: "2%" }}
                                      >
                                        <div className="col-lg-12">
                                          <b>Servizi Aggiuntivi:</b>
                                        </div>
                                        <div className="col-lg-8">
                                          {y.subServizio.nome}
                                          {props.riepilogo &&
                                            props.riepilogo.flagPagato ===
                                              false && (
                                              <button
                                                className="btn mb-2"
                                                onClick={
                                                  () => {
                                                    toggleModal(!isOpen);
                                                    setIdDel1(y.subServizio.id);
                                                    setIdDel2(x.id);
                                                  }
                                                  // delServizioRichiesta(
                                                  //   y.subServizio.id,
                                                  //   x.id
                                                  // )
                                                }
                                              >
                                                <svg
                                                  className="icon icon-danger"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <use
                                                    href={`${iconSprite}#it-close-big`}
                                                  />{" "}
                                                </svg>
                                              </button>
                                            )}
                                        </div>

                                        <div className="col-lg-2">
                                          <div className="row">
                                            <div className="col-lg-12">
                                              Imponibile:{" "}
                                              <b>
                                                {
                                                  y.subServizio.prezzoDTO
                                                    .imponibile
                                                }{" "}
                                                €
                                              </b>
                                            </div>
                                            <div className="col-lg-12">
                                              Diritti:{" "}
                                              <b>
                                                {
                                                  y.subServizio.prezzoDTO
                                                    .costoDirittiETributi
                                                }{" "}
                                                €
                                              </b>
                                            </div>
                                            <div className="col-lg-12">
                                              Iva:{" "}
                                              <b>
                                                {
                                                  y.subServizio.prezzoDTO
                                                    .imposta
                                                }{" "}
                                                €
                                              </b>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </a>
                              </li>
                            ))
                          ) : (
                            <>
                              <p>Non sono presenti servizi</p>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div>
        <Modal
          isOpen={isOpen}
          toggle={() => toggleModal(!isOpen)}
          size={"lg"}
          labelledBy="Modale1"
        >
          <ModalHeader id="Modale1">ELIMINAZIONE SERVIZIO</ModalHeader>
          <ModalBody>
            {" "}
            Stai per eliminare un servizio. Sei sicuro di voler procedere ?
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger text-white text-center"
              onClick={() => delServizioRichiesta(idDel1, idDel2)}
            >
              Elimina
            </Button>
            <Button
              color="primary text-white text-center"
              onClick={() => toggleModal(!isOpen)}
            >
              Annulla
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        <Modal
          isOpen={isOpen2}
          toggle={() => toggleModal2(!isOpen2)}
          size={"lg"}
          labelledBy="Modale2"
        >
          <ModalHeader id="Modale2">ELIMINAZIONE RICHIESTA</ModalHeader>
          <ModalBody>
            {" "}
            Stai per eliminare una richiesta. Sei sicuro di voler procedere ?
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger text-white text-center"
              onClick={() => delRichiestaOrdine(idDel3)}
            >
              Elimina
            </Button>
            <Button
              color="primary text-white text-center"
              onClick={() => toggleModal2(!isOpen2)}
            >
              Annulla
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default ListaVisureCatastali;
