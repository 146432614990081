import { Switch, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

//Componenti
import useLocalStorage from "../Utility/useLocalStorage";
import Breadcrumb from "./Breadcrumb";
import Help from "../Page/Help";
import Faq from "../Page/Faq";
import PrivacyPolicy from "../Page/PrivacyPolicy";
import TerminiCondizioni from "../Page/TerminiCondizioni";
import ServiziCittadino from "../Page/ServiziCittadino";
import VisuraCatastale from "../Page/VisuraCatastale";
import AttivitaSvolte from "../Page/AttivitaSvolte";
import FatturazioneCatastale from "../Page/FatturazioneCatastale";
import Delega from "../Page/Delega";
import Riepilogo from "../Page/Riepilogo";
import RichiesteOperatore from "../Page/RichiesteOperatore";
import RichiesteOperatoreElaborate from "../Page/RichiesteOperatoreElaborate";
import HomeOperator from "../Page/HomeOperator";
import OrdiniSospeso from "../Page/OrdiniSospeso";
import SignedUp from "../Page/SignedUp";
import StoricoRichieste from "../Page/StoricoRichieste";
import StoricoRichiesteCompletate from "../Page/StoricoRichiesteCompletate";
import Hero from "../Objects/Hero";
import Profilo from "../Page/Profilo";
import Conservatoria from "../Page/Conservatoria";
import Vantaggi from "../Page/Vantaggi";
import Stats from "../Page/Stats";
import jwt_decode from "jwt-decode";
import RegistrationConfirm from "../Page/RegistrationConfirm";
import PasswordResetConfirm from "../Page/PasswordResetConfirm";
import Cookies from "universal-cookie";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import StatsIntermediari from "../Page/StatsIntermediari";
import RichiesteSuperDelegato from "../Page/RichiesteSuperDelegato";
import ValutazioneImmobile from "../ValutazioneImmobile/ValutazioneImmobile";
import DashboardProfessionista from "../Page/DashboardProfessionista";
import StatsInterne from "../Page/StatsInterne";
import DashboardProfessionisti from "../Page/DashboardProfessionisti";
import StatsIntermediariInt from "../Page/StatsIntermediariInt";

function Body(props) {
  const cookies = new Cookies();
  const [apiToken] = useState(cookies.get("token_key"));
  const [isDelegate, setIsDelegate] = useState(false);
  const [isOperator, setIsOperator] = useState(false);
  const [isStatsViewer, setIsStatsViewer] = useState(false);
  const [isSuperDelegate, setIsSuperDelegate] = useState(false);

  const [isOpen, toggleModal] = useState(false);
  const [needLogin, toggleNeedLogin] = useState(false);

  useEffect(() => {
    if (apiToken) {
      var decoded = jwt_decode(apiToken);

      if (decoded.role && typeof decoded.role === "object") {
        if (decoded.role.includes("STATSVIEWER")) {
          setIsStatsViewer(true);
        } else if (decoded.role.includes("OPERATOR")) {
          setIsOperator(true);
        } else if (decoded.role.includes("DELEGATE")) {
          setIsDelegate(true);
        } else if (decoded.role.includes("SUPER_DELEGATE")) {
          setIsSuperDelegate(true);
        }
      } else if (decoded.role && typeof decoded.role === "string") {
        if (decoded.role == "STATSVIEWER") {
          setIsStatsViewer(true);
        } else if (decoded.role == "OPERATOR") {
          setIsOperator(true);
        } else if (decoded.role == "DELEGATE") {
          setIsDelegate(true);
        } else if (decoded.role == "SUPER_DELEGATE") {
          setIsSuperDelegate(true);
        }
      }
    }

    if (!isSuperDelegate && window.location.pathname == "/richieste_delegato") {
      toggleNeedLogin(true);
    } else {
      toggleNeedLogin(false);
    }
  }, [cookies]);

  const deleteToken = () => {
    cookies.remove("token_key");
    window.location.href = props.subfolder + "/home";
  };

  const deleteCoockieExpired = () => {
    cookies.remove("token_key");
    toggleModal(!isOpen);
  };

  return (
    <main role="main" className="main-body">
      {!isOperator && !isStatsViewer && !isSuperDelegate && (
        <Switch>
          <Route exact path="/">
            <Hero></Hero>
          </Route>
          <Route exact path="/home">
            <Hero></Hero>
          </Route>
        </Switch>
      )}
      {apiToken ? (
        <>
          {/* Pagine Utente  */}
          {!isOperator && !isStatsViewer && !isSuperDelegate && (
            <div className="container mb-4 mt-3">
              <div>
                <Switch>
                  <Route exact path="/Valutazione_immobile">
                    <Breadcrumb></Breadcrumb>
                    <ValutazioneImmobile
                      deleteCoockieExpired={deleteCoockieExpired}
                      subfolder={props.subfolder}
                      token={apiToken}
                      pagato={false}
                    ></ValutazioneImmobile>
                  </Route>
                  <Route exact path="/Conservatoria">
                    <Breadcrumb></Breadcrumb>
                    <Conservatoria
                      deleteCoockieExpired={deleteCoockieExpired}
                      subfolder={props.subfolder}
                      token={apiToken}
                      pagato={false}
                    ></Conservatoria>
                  </Route>
                  <Route exact path="/catasto">
                    <Breadcrumb></Breadcrumb>
                    <VisuraCatastale
                      deleteCoockieExpired={deleteCoockieExpired}
                      subfolder={props.subfolder}
                      token={apiToken}
                      pagato={false}
                    ></VisuraCatastale>
                  </Route>
                  <Route exact path="/Delega">
                    <Breadcrumb></Breadcrumb>
                    <Delega
                      deleteCoockieExpired={deleteCoockieExpired}
                      subfolder={props.subfolder}
                      token={apiToken}
                      pagato={false}
                    ></Delega>
                  </Route>
                  <Route exact path="/Fatturazione">
                    <Breadcrumb></Breadcrumb>
                    <FatturazioneCatastale
                      deleteCoockieExpired={deleteCoockieExpired}
                      subfolder={props.subfolder}
                      token={apiToken}
                    ></FatturazioneCatastale>
                  </Route>
                  <Route exact path="/Riepilogo">
                    <Breadcrumb></Breadcrumb>
                    <Riepilogo
                      deleteCoockieExpired={deleteCoockieExpired}
                      subfolder={props.subfolder}
                      token={apiToken}
                    ></Riepilogo>
                  </Route>
                  <Route exact path="/ordini_sospeso">
                    <Breadcrumb></Breadcrumb>
                    <OrdiniSospeso
                      deleteCoockieExpired={deleteCoockieExpired}
                      subfolder={props.subfolder}
                      token={apiToken}
                      pagato={false}
                    ></OrdiniSospeso>
                  </Route>
                  <Route exact path="/ordini_completati">
                    <Breadcrumb></Breadcrumb>
                    <OrdiniSospeso
                      deleteCoockieExpired={deleteCoockieExpired}
                      subfolder={props.subfolder}
                      token={apiToken}
                      pagato={true}
                    ></OrdiniSospeso>
                  </Route>
                  {/* Statistiche Intermediari */}
                  {isDelegate && (
                    <Route exact path="/dashboard">
                      <div className="container my-4">
                        <Breadcrumb></Breadcrumb>
                        <StatsIntermediari
                          subfolder={props.subfolder}
                          token={apiToken}
                        ></StatsIntermediari>
                      </div>
                    </Route>
                  )}
                  <Route exact path="/Help">
                    <div className="container my-4">
                      <Breadcrumb></Breadcrumb>
                      <Help></Help>
                    </div>
                  </Route>
                  <Route exact path="/Faq">
                    <div className="container my-4">
                      <Breadcrumb></Breadcrumb>
                      <Faq></Faq>
                    </div>
                  </Route>
                  <Route exact path="/Vantaggi">
                    <div className="container my-4">
                      <Breadcrumb></Breadcrumb>
                      <Vantaggi></Vantaggi>
                    </div>
                  </Route>
                  <Route exact path="/termini_condizioni">
                    <TerminiCondizioni></TerminiCondizioni>
                  </Route>
                  <Route exact path="/privacy_policy">
                    <PrivacyPolicy></PrivacyPolicy>
                  </Route>
                  <Route exact path="/profilo">
                    <Breadcrumb></Breadcrumb>
                    <Profilo subfolder={props.subfolder}></Profilo>
                  </Route>
                  <Route path="/">
                    <ServiziCittadino
                      deleteCoockieExpired={deleteCoockieExpired}
                      subfolder={props.subfolder}
                      token={apiToken}
                    ></ServiziCittadino>
                  </Route>
                </Switch>
              </div>
            </div>
          )}
          {/* Pagine Operatore  */}
          {isOperator && !isStatsViewer && !isSuperDelegate && (
            <div className="container mb-4 mt-3">
              <div>
                <Switch>
                  <Route exact path="/richieste_visure">
                    <Breadcrumb></Breadcrumb>
                    <RichiesteOperatore
                      deleteCoockieExpired={deleteCoockieExpired}
                      subfolder={props.subfolder}
                      token={apiToken}
                    ></RichiesteOperatore>
                  </Route>
                  <Route exact path="/richieste_elaborate">
                    <Breadcrumb></Breadcrumb>
                    <RichiesteOperatoreElaborate
                      deleteCoockieExpired={deleteCoockieExpired}
                      subfolder={props.subfolder}
                      token={apiToken}
                      isSuperDelegate={isSuperDelegate}
                    ></RichiesteOperatoreElaborate>
                  </Route>
                  <Route exact path="/richieste_corso">
                    <Breadcrumb></Breadcrumb>
                    <StoricoRichieste
                      deleteCoockieExpired={deleteCoockieExpired}
                      subfolder={props.subfolder}
                      token={apiToken}
                      isSuperDelegate={isSuperDelegate}
                    ></StoricoRichieste>
                  </Route>
                  <Route exact path="/richieste_completate">
                    <Breadcrumb></Breadcrumb>
                    <StoricoRichiesteCompletate
                      deleteCoockieExpired={deleteCoockieExpired}
                      subfolder={props.subfolder}
                      token={apiToken}
                      status={"EVASO"}
                      isSuperDelegate={isSuperDelegate}
                    ></StoricoRichiesteCompletate>
                  </Route>
                  <Route exact path="/attivita_svolte">
                    <Breadcrumb></Breadcrumb>
                    <AttivitaSvolte
                      deleteCoockieExpired={deleteCoockieExpired}
                      subfolder={props.subfolder}
                      token={apiToken}
                    ></AttivitaSvolte>
                  </Route>
                  <Route exact path="/Help">
                    <div className="container my-4">
                      <Breadcrumb></Breadcrumb>
                      <Help></Help>
                    </div>
                  </Route>
                  <Route exact path="/Faq">
                    <div className="container my-4">
                      <Breadcrumb></Breadcrumb>
                      <Faq></Faq>
                    </div>
                  </Route>
                  <Route exact path="/profilo">
                    <Breadcrumb></Breadcrumb>
                    <Profilo subfolder={props.subfolder}></Profilo>
                  </Route>
                  <Route exact path="/termini_condizioni">
                    <TerminiCondizioni></TerminiCondizioni>
                  </Route>
                  <Route exact path="/privacy_policy">
                    <PrivacyPolicy></PrivacyPolicy>
                  </Route>
                  <Route path="/">
                    <HomeOperator
                      isSuperDelegate={isSuperDelegate}
                    ></HomeOperator>
                  </Route>
                </Switch>
              </div>
            </div>
          )}
          {/* Pagine super delegato  */}
          {isSuperDelegate && !isOperator && !isStatsViewer && (
            <>
              <div className="container mb-4 mt-3">
                <div>
                  <Switch>
                    <Route exact path="/richieste_delegato">
                      <Breadcrumb></Breadcrumb>
                      <RichiesteSuperDelegato
                        deleteCoockieExpired={deleteCoockieExpired}
                        subfolder={props.subfolder}
                        token={apiToken}
                      ></RichiesteSuperDelegato>
                    </Route>
                    <Route exact path="/dashboard_professionista">
                      <Breadcrumb></Breadcrumb>
                      <DashboardProfessionista
                        subfolder={props.subfolder}
                        token={apiToken}
                      ></DashboardProfessionista>
                    </Route>
                    <Route exact path="/richieste_corso">
                      <Breadcrumb></Breadcrumb>
                      <StoricoRichieste
                        deleteCoockieExpired={deleteCoockieExpired}
                        subfolder={props.subfolder}
                        token={apiToken}
                        isSuperDelegate={isSuperDelegate}
                      ></StoricoRichieste>
                    </Route>
                    <Route exact path="/richieste_completate">
                      <Breadcrumb></Breadcrumb>
                      <StoricoRichiesteCompletate
                        deleteCoockieExpired={deleteCoockieExpired}
                        subfolder={props.subfolder}
                        token={apiToken}
                        status={"EVASO"}
                        isSuperDelegate={isSuperDelegate}
                      ></StoricoRichiesteCompletate>
                    </Route>
                    <Route exact path="/richieste_elaborate">
                      <Breadcrumb></Breadcrumb>
                      <RichiesteOperatoreElaborate
                        deleteCoockieExpired={deleteCoockieExpired}
                        subfolder={props.subfolder}
                        token={apiToken}
                        isSuperDelegate={isSuperDelegate}
                      ></RichiesteOperatoreElaborate>
                    </Route>
                    {/* 
                      
                      
                      <Route exact path="/attivita_svolte">
                        <Breadcrumb></Breadcrumb>
                        <AttivitaSvolte
                          deleteCoockieExpired={deleteCoockieExpired}
                          subfolder={props.subfolder}
                          token={apiToken}
                        ></AttivitaSvolte>
                      </Route> */}
                    <Route exact path="/Help">
                      <div className="container my-4">
                        <Breadcrumb></Breadcrumb>
                        <Help></Help>
                      </div>
                    </Route>
                    <Route exact path="/Faq">
                      <div className="container my-4">
                        <Breadcrumb></Breadcrumb>
                        <Faq></Faq>
                      </div>
                    </Route>
                    <Route exact path="/profilo">
                      <Breadcrumb></Breadcrumb>
                      <Profilo subfolder={props.subfolder}></Profilo>
                    </Route>
                    <Route exact path="/termini_condizioni">
                      <TerminiCondizioni></TerminiCondizioni>
                    </Route>
                    <Route exact path="/privacy_policy">
                      <PrivacyPolicy></PrivacyPolicy>
                    </Route>
                    <Route path="/">
                      <HomeOperator
                        isSuperDelegate={isSuperDelegate}
                      ></HomeOperator>
                    </Route>
                  </Switch>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {/* Pagine Non Loggato  */}
          <div className="container mb-4 mt-3">
            <div>
              <Switch>
                <Route exact path="/Valutazione_immobile">
                  <Breadcrumb></Breadcrumb>
                  <ValutazioneImmobile
                    subfolder={props.subfolder}
                    token={apiToken}
                    pagato={false}
                  ></ValutazioneImmobile>
                </Route>
                <Route exact path="/Conservatoria">
                  <Breadcrumb></Breadcrumb>
                  <Conservatoria
                    subfolder={props.subfolder}
                    token={apiToken}
                    pagato={false}
                  ></Conservatoria>
                </Route>
                <Route exact path="/catasto">
                  <Breadcrumb></Breadcrumb>
                  <VisuraCatastale
                    subfolder={props.subfolder}
                    token={apiToken}
                    pagato={false}
                  ></VisuraCatastale>
                </Route>
                <Route exact path="/Delega">
                  <Breadcrumb></Breadcrumb>
                  <Delega
                    deleteCoockieExpired={deleteCoockieExpired}
                    subfolder={props.subfolder}
                    token={apiToken}
                    pagato={false}
                  ></Delega>
                </Route>
                <Route exact path="/Fatturazione">
                  <Breadcrumb></Breadcrumb>
                  <FatturazioneCatastale
                    subfolder={props.subfolder}
                    token={apiToken}
                  ></FatturazioneCatastale>
                </Route>
                <Route exact path="/Riepilogo">
                  <Breadcrumb></Breadcrumb>
                  <Riepilogo
                    subfolder={props.subfolder}
                    token={apiToken}
                  ></Riepilogo>
                </Route>
                <Route exact path="/Help">
                  <div className="container my-4">
                    <Breadcrumb></Breadcrumb>
                    <Help></Help>
                  </div>
                </Route>
                <Route exact path="/Faq">
                  <div className="container my-4">
                    <Breadcrumb></Breadcrumb>
                    <Faq></Faq>
                  </div>
                </Route>
                <Route exact path="/termini_condizioni">
                  <Breadcrumb></Breadcrumb>
                  <TerminiCondizioni></TerminiCondizioni>
                </Route>
                <Route exact path="/privacy_policy">
                  <Breadcrumb></Breadcrumb>
                  <PrivacyPolicy></PrivacyPolicy>
                </Route>
                <Route exact path="/login">
                  <SignedUp subfolder={props.subfolder}></SignedUp>
                </Route>
                <Route exact path="/RegistrationConfirm">
                  <RegistrationConfirm
                    subfolder={props.subfolder}
                    token={apiToken}
                  ></RegistrationConfirm>
                </Route>
                <Route exact path="/PasswordResetConfirm">
                  <PasswordResetConfirm
                    subfolder={props.subfolder}
                    token={apiToken}
                  ></PasswordResetConfirm>
                </Route>
                <Route path="/">
                  <ServiziCittadino
                    subfolder={props.subfolder}
                    token={apiToken}
                  ></ServiziCittadino>
                </Route>
              </Switch>
            </div>
          </div>
        </>
      )}
      {isStatsViewer && (
        <div className="container mb-4 mt-3">
          <div>
            <Switch>
              <Route exact path="/termini_condizioni">
                <Breadcrumb></Breadcrumb>
                <TerminiCondizioni></TerminiCondizioni>
              </Route>
              <Route exact path="/privacy_policy">
                <Breadcrumb></Breadcrumb>
                <PrivacyPolicy></PrivacyPolicy>
              </Route>
              <Route exact path="/Help">
                <Breadcrumb></Breadcrumb>
                <Help></Help>
              </Route>
              <Route path="/statistiche_professionisti">
                <DashboardProfessionisti
                  deleteCoockieExpired={deleteCoockieExpired}
                  subfolder={props.subfolder}
                  token={apiToken}
                  pagato={false}
                ></DashboardProfessionisti>
              </Route>
              <Route path="/statistiche_esercenti">
                <StatsIntermediariInt
                  deleteCoockieExpired={deleteCoockieExpired}
                  subfolder={props.subfolder}
                  token={apiToken}
                  pagato={false}
                ></StatsIntermediariInt>
              </Route>
              <Route path="/">
                <StatsInterne
                  deleteCoockieExpired={deleteCoockieExpired}
                  subfolder={props.subfolder}
                  token={apiToken}
                  pagato={false}
                ></StatsInterne>
              </Route>
            </Switch>
          </div>
        </div>
      )}
      {isOpen && (
        <Modal isOpen={isOpen} labelledBy="esempio1">
          <ModalHeader id="esempio1">SESSIONE SCADUTA</ModalHeader>
          <ModalBody>Esegui nuovamente il login</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() =>
                (window.location.href = props.subfolder + "/login")
              }
            >
              Login
            </Button>
            <Button
              color="primary"
              onClick={() => (window.location.href = props.subfolder + "/home")}
            >
              Home
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {needLogin && (
        <Modal isOpen={needLogin} labelledBy="needLogin">
          <ModalHeader id="needLogin">
            PER ACCEDERE ALLA PAGINA SI NECESSITA DEL LOGIN COME PROFESSIONISTA
          </ModalHeader>
          <ModalBody>Esegui nuovamente il login come professionista</ModalBody>
          <ModalFooter>
            {apiToken ? (
              <Button color="primary" onClick={() => deleteToken()}>
                Esci
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={() =>
                  (window.location.href = props.subfolder + "/login")
                }
              >
                Login
              </Button>
            )}
            <Button
              color="primary"
              onClick={() => (window.location.href = props.subfolder + "/home")}
            >
              Home
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </main>
  );
}

export default Body;
