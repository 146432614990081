import { useEffect, useState } from "react";
import Repo from "../Utility/Repo";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Loader from "../Utility/Loader";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";

function PasswordResetConfirm(props) {
  const [apiToken, setApiToken] = useState(props.apiToken);
  const queryP = new URLSearchParams(window.location.search);
  const [bool, setBool] = useState(true);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [userId, setUserId] = useState("");
  const [validatePasswordRegex, setValidatePasswordRegex] = useState();
  const [validatePasswordConfirmRegex, setValidatePasswordConfirmRegex] =
    useState();
  const [isOpen, toggleModal] = useState(false);

  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  useEffect(() => {
    setLoading(false);
    if (queryP.get("userid") && queryP.get("code")) {
      setCode(queryP.get("code"));
      setUserId(queryP.get("userid"));

      setLoading(true);
    }
  }, []);

  const validatePassword = (event) => {
    let value = event.value;

    if (event.name == "password") {
      setPassword(value.toString());
      validateInput(event.name, value.toString());
    } else {
      setConfirmPassword(value.toString(), value.toString());
      validateInput(event.name, value.toString());
    }
  };

  const validateInput = (name, value) => {
    if (name == "password") {
      const regexPW =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/m;

      if (!value.match(regexPW)) {
        setValidatePasswordRegex(false);
      } else {
        setValidatePasswordRegex(true);
      }
      if (confirmPassword == value) {
        setValidatePasswordConfirmRegex(true);
      } else {
        setValidatePasswordConfirmRegex(false);
      }
    } else {
      if (value == password) {
        setValidatePasswordConfirmRegex(true);
      } else {
        setValidatePasswordConfirmRegex(false);
      }
    }
  };

  const resetPasswordConfirm = (event) => {
    event.preventDefault();
    setLoading(false);
    const data = {
      password: password,
      confirmPassword: confirmPassword,
    };
    if (validatePasswordConfirmRegex && validatePasswordRegex) {
      if (confirmPassword == password) {
        setLoading(false);
        Repo.postResetPassword(userId, code, password)
          .then((response) => {
            if (response.status == 200) {
              setLoading(true);
              toggleModal(!isOpen);
            }
          })
          .catch((error) => {
            setLoading(true);
            console.log(error);
          });
      }
    }
  };

  return (
    <>
      {bool && (
        <>
          <div className="row">
            <div className="col-lg-7 mt-4">
              <h3>Reset password</h3>
              <br />
              <h5>Inserisci la nuova password nei due campi indicati</h5>
            </div>
            <div className="col-lg-5">
              <div className="col d-flex justify-content-center mt-4">
                <div
                  className="card card-teaser rounded shadow"
                  style={{ width: "25em" }}
                >
                  <div className="card-body">
                    <br />
                    <form onSubmit={resetPasswordConfirm} autoComplete="on">
                      <Input
                        type="password"
                        onChange={(e) => validatePassword(e.target)}
                        id="password"
                        label="Password"
                        name="password"
                        infoText="Inserisci almeno 8 caratteri, una lettera maiuscola e un simbolo"
                        required
                        {...(validatePasswordRegex == false && {
                          className: "text-danger",
                        })}
                        {...(validatePasswordRegex == false && {
                          infoText: "Inserisci una password valida!",
                        })}
                        {...(validatePasswordRegex && {
                          className: "text-success",
                        })}
                        {...(validatePasswordRegex && {
                          infoText: "Password Valida!",
                        })}
                      />
                      <br />
                      <Input
                        type="password"
                        onChange={(e) => validatePassword(e.target)}
                        id="confirmPassword"
                        label="Conferma Password"
                        name="confirmPassword"
                        infoText="Ripeti la password inserita nel campo in alto"
                        required
                        {...(validatePasswordConfirmRegex == false && {
                          className: "text-danger",
                        })}
                        {...(validatePasswordConfirmRegex == false && {
                          infoText: "La password non corrisponde!",
                        })}
                        {...(validatePasswordConfirmRegex && {
                          className: "text-success",
                        })}
                        {...(validatePasswordConfirmRegex && {
                          infoText: "La password corrisponde!",
                        })}
                      />
                      <div className="row mt-3">
                        <div className="col-lg-12  text-right">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block rounded"
                          >
                            Reimposta password
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!loading && <Loader />}

      <Modal
        isOpen={isOpen}
        toggle={() => (window.location.href = props.subfolder + "/login")}
        labelledBy="esempio1"
      >
        <ModalHeader id="esempio1">Password Reimpostata</ModalHeader>
        <ModalBody>La tua password è stata reimpostata correttamente</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => (window.location.href = props.subfolder + "/login")}
          >
            Login
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default PasswordResetConfirm;
