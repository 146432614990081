import { createRef, useEffect, useState } from "react";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import moment from "moment";

function SoggettoForm(props) {
  const [backgroundColorF, setBackgroundColorF] = useState("transparent");
  const [textColorF, setTextColorF] = useState("#435a70");
  const [backgroundColorG, setBackgroundColorG] = useState("transparent");
  const [textColorG, setTextColorG] = useState("#435a70");

  useEffect(() => {
    if (!props.checkPrivate) {
      setBackgroundColorF("#2e58a6");
      setTextColorF("white");
      setBackgroundColorG("transparent");
      setTextColorG("#435a70");
    } else {
      setBackgroundColorF("transparent");
      setTextColorF("#435a70");
      setBackgroundColorG("#2e58a6");
      setTextColorG("white");
    }

    if (!props.checkPrivate) {
      props.setData((prevState) => ({
        ...prevState,
        cf_piva: "",
        cognome: "",
        nome: "",
        luogoNascita: "",
        dataNascita: null,
      }));
    } else {
      props.setData((prevState) => ({
        ...prevState,
        cf_piva: "",
        cognome: "",
        indirizzoSede: "",
        luogoNascita: "",
        dataNascita: null,
      }));
    }

    props.setValidationFatturazione(false);
    props.setValidationStep(false);
  }, [props.checkPrivate]);

  const handleChange = (event, num) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case "provincia":
        props.setData((prevState) => ({
          ...prevState,
          provincia: value,
        }));

        break;

      case "comuneSede":
        props.setData((prevState) => ({
          ...prevState,
          comuneSede: value,
        }));

        break;

      case "comune":
        props.setData((prevState) => ({
          ...prevState,
          comune: value,
        }));

        break;

      case "indirizzo":
        props.setData((prevState) => ({
          ...prevState,
          indirizzo: value,
        }));
        break;

      case "indirizzoSede":
        props.setData((prevState) => ({
          ...prevState,
          indirizzoSede: value,
        }));
        break;

      case "cf_piva":
        props.setData((prevState) => ({
          ...prevState,
          cf_piva: num,
        }));
        break;

      case "cognome":
        props.setData((prevState) => ({
          ...prevState,
          cognome: value,
        }));
        break;

      case "nome":
        props.setData((prevState) => ({
          ...prevState,
          nome: value,
        }));
        break;

      case "dataNascita":
        props.setData((prevState) => ({
          ...prevState,
          dataNascita: value,
        }));
        break;

      case "luogoNascita":
        props.setData((prevState) => ({
          ...prevState,
          luogoNascita: value,
        }));
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div
        className="row form-group pl-5 px-5 pt-2 pb-2"
        style={{
          borderRadius: "5px",
          border: "2px solid rgb(46, 88, 166, 0.35)",
        }}
      >
        <div className="col-lg-12 mb-2 text-center">
          <a style={{ fontSize: "20px" }}>
            <b>Dati del titolare</b>
          </a>
        </div>
        <div
          className="row col-lg-4 border border-primary rounded mb-4 mt-3 text-center ml-1 p-0 align-items-center"
          style={{ height: "40px" }}
        >
          <div
            className="col-6 p-0 border-right border-primary rounded pt-1"
            style={{
              backgroundColor: backgroundColorF,
              color: textColorF,
              cursor: "pointer",
              height: "40px",
            }}
            onClick={() => props.setCheckPrivate(false)}
          >
            <a>
              <b>Privato</b>
            </a>
          </div>
          <div
            className="col-6 p-0 rounded pt-1"
            style={{
              backgroundColor: backgroundColorG,
              color: textColorG,
              cursor: "pointer",
              height: "40px",
            }}
            onClick={() => props.setCheckPrivate(true)}
          >
            <a>
              <b>Ditta</b>
            </a>
          </div>
        </div>

        {!props.checkPrivate ? (
          <>
            {" "}
            <div className="row form-group">
              <div className="col-lg-12 mt-2 mb-2 text-center">
                <b>
                  <u>Inserisci il Codice Fiscale oppure i dati restanti.</u>
                </b>
              </div>
              <div className="col-lg-12">
                <Input
                  id="cf_piva"
                  required
                  label="Codice Fiscale"
                  type="text"
                  name="cf_piva"
                  value={props.dataObj.cf_piva || ""}
                  {...(props.validationStep &&
                    !props.dataObj.cf_piva &&
                    !props.validationFatturazione && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  {...(props.validationStep &&
                    !props.validationCF && {
                      infoText: "Il codice fiscale deve avere 16 caratteri!",
                      className: "text-danger",
                    })}
                  onChange={(e) => handleChange(e, e.target.value)}
                />
              </div>
              <div className="col-lg-12">
                <Input
                  id="cognome"
                  required
                  label="Cognome"
                  type="text"
                  name="cognome"
                  value={props.dataObj.cognome || ""}
                  {...(props.validationStep &&
                    !props.dataObj.cognome &&
                    !props.validationFatturazione && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-12">
                <Input
                  id="nome"
                  required
                  label="Nome"
                  type="text"
                  name="nome"
                  value={props.dataObj.nome || ""}
                  {...(props.validationStep &&
                    !props.dataObj.nome &&
                    !props.validationFatturazione && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-12">
                <Input
                  id="dataNascita"
                  required
                  label="Data di Nascita"
                  type="date"
                  value={moment(props.dataObj.dataNascita).format("YYYY-MM-DD")}
                  name="dataNascita"
                  max={moment().subtract(16, "year").format("YYYY-MM-DD")}
                  placeholder="Seleziona una data"
                  {...(props.validationStep &&
                    !props.dataObj.dataNascita &&
                    !props.validationFatturazione && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-12">
                <Input
                  id="luogoNascita"
                  required
                  label="Luogo di nascita"
                  type="text"
                  name="luogoNascita"
                  value={props.dataObj.luogoNascita || ""}
                  {...(props.validationStep &&
                    !props.dataObj.luogoNascita &&
                    !props.validationFatturazione && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  onChange={handleChange}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row form-group">
              <div className="col-lg-12 mt-2 mb-2 text-center">
                <b>
                  <u>Inserisci la Partita IVA oppure i dati restanti.</u>
                </b>
              </div>
              <div className="col-lg-12">
                <Input
                  id="cf_piva"
                  required
                  label="Partita IVA"
                  type="text"
                  name="cf_piva"
                  value={props.dataObj.cf_piva || ""}
                  {...(props.validationStep &&
                    !props.dataObj.cf_piva &&
                    !props.validationFatturazione && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  {...(props.validationStep &&
                    !props.validationIVA && {
                      infoText: "La partita IVA deve avere 11 caratteri!",
                      className: "text-danger",
                    })}
                  onChange={(e) =>
                    handleChange(e, e.target.value.replace(/[^0-9.]/g, ""))
                  }
                />
              </div>
              <div className="col-lg-12">
                <Input
                  id="cognome"
                  required
                  label="Ragione Sociale"
                  type="text"
                  name="cognome"
                  value={props.dataObj.cognome || ""}
                  {...(props.validationStep &&
                    !props.dataObj.cognome &&
                    !props.validationFatturazione && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-12">
                <Input
                  id="indirizzoSede"
                  required
                  label="Indirizzo Sede Legale"
                  type="text"
                  name="indirizzoSede"
                  value={props.dataObj.indirizzoSede || ""}
                  {...(props.validationStep &&
                    !props.dataObj.indirizzoSede &&
                    !props.validationFatturazione && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-12">
                <Input
                  id="luogoNascita"
                  required
                  label="Comune Sede Legale"
                  type="text"
                  name="luogoNascita"
                  value={props.dataObj.luogoNascita || ""}
                  {...(props.validationStep &&
                    !props.dataObj.luogoNascita &&
                    !props.validationFatturazione && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  onChange={handleChange}
                />
              </div>

              {props.validationStep && !props.validationFatturazione && (
                <div className="col-lg-12 mb-2 text-center">
                  <a className="text-danger" style={{ fontSize: "20px" }}>
                    <b>
                      È necessario inserire la Partita Iva o compilare i
                      restanti campi
                    </b>
                  </a>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default SoggettoForm;
