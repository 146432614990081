import { useEffect, useState } from "react";
import Select from "../Objects/Select";

import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import useLocalStorage from "../Utility/useLocalStorage";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Repo from "../Utility/Repo";
import { Link } from "react-router-dom";
import moment from "moment";
import Cookies from "universal-cookie";
import Loader from "../Utility/Loader";

function SignedUp(props) {
  const [registrationPannel, setRegistrationPannel] = useState(false);
  const [resetPannel, setResetPannel] = useState(false);
  const [validation, setValidation] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [cf, setCf] = useState();
  const [cfValidation, setCfValidation] = useState(true);
  const [street, setStreet] = useState();
  const [houseNumber, setHouseNumber] = useState();
  const [city, setCity] = useState();
  const [cap, setCap] = useState();
  const [phone, setPhone] = useState();
  const [dataNascita, setDataNascita] = useState("");
  const [role, setRole] = useState("");
  const [privacyOne, setPrivacyOne] = useState(false);
  const [privacyTwo, setPrivacyTwo] = useState(false);
  const [isOpen, toggleModal] = useState(false);
  const [isOpenCredentials, toggleModalCredentials] = useState(false);
  const [isOpenCheckEmail, toggleModalCheckEmail] = useState(false);
  const [isOpenError, toggleModalError] = useState(false);
  const [errorLogin, setErrorLogin] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [errorApi, setErrorApi] = useState();
  const [operatorBool, setOperatorBool] = useState(false);
  const cookies = new Cookies();
  const reg = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(true);
  // Gestione validazione mail
  const [email, setEmail] = useState();
  const [emailReg, setEmailReg] = useState("");
  const [validationEmail, setValidationEmail] = useState(true);

  const [roleOperator, setRoleOperator] = useState([
    { label: "OPERATORE", value: "OPERATOR" },
    { label: "STATSVIEWER", value: "STATSVIEWER" },
    { label: "PROFESSIONISTA", value: "SUPER_DELEGATE" },
  ]);

  useEffect(() => {
    const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!emailReg.match(regexEmail)) {
      setValidationEmail(false);
    } else {
      setValidationEmail(true);
    }
  }, [emailReg]);

  useEffect(() => {
    setRole("");
  }, [operatorBool == false]);

  // Gestione validazione password
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [validatePasswordRegex, setValidatePasswordRegex] = useState();
  const [validatePasswordConfirmRegex, setValidatePasswordConfirmRegex] =
    useState();

  const validatePassword = (event) => {
    let value = event.value;

    if (event.name == "password") {
      setPassword(value.toString());
      validateInput(event.name, value.toString());
    } else {
      setConfirmPassword(value.toString(), value.toString());
      validateInput(event.name, value.toString());
    }
  };

  const validateInput = (name, value) => {
    if (name == "password") {
      const regexPW =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/m;

      if (!value.match(regexPW)) {
        setValidatePasswordRegex(false);
      } else {
        setValidatePasswordRegex(true);
      }
      if (confirmPassword == value) {
        setValidatePasswordConfirmRegex(true);
      } else {
        setValidatePasswordConfirmRegex(false);
      }
    } else {
      if (value == password) {
        setValidatePasswordConfirmRegex(true);
      } else {
        setValidatePasswordConfirmRegex(false);
      }
    }
  };

  useEffect(() => {
    if (reg.get("reg")) {
      setRegistrationPannel(reg.get("reg"));
    }
  }, []);

  const sendForm = (event) => {
    event.preventDefault();
    setLoading(false);
    setValidation(true);

    const regexCF =
      /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/gm;

    let passedValidation = true;

    if (!firstName) passedValidation = false;
    if (!lastName) passedValidation = false;
    if (!cf) passedValidation = false;
    else {
      if (!cf.match(regexCF)) {
        setCfValidation(false);
        passedValidation = false;
      } else setCfValidation(true);
    }
    // if (!street) passedValidation = false;
    // if (!houseNumber) passedValidation = false;
    // if (!city) passedValidation = false;
    // if (!cap) passedValidation = false;
    // if (!phone) passedValidation = false;

    if (!emailReg) passedValidation = false;
    if (!privacyOne) passedValidation = false;

    if (validatePasswordConfirmRegex && validatePasswordRegex) {
      if (confirmPassword == password) {
        if (passedValidation && validationEmail) {
          var request = {
            Password: password,
            ConfirmPassword: confirmPassword,
            Email: emailReg,
            LastName: lastName,
            FirstName: firstName,
            FiscalCode: cf,
            DocumentType: null,
            DocumentNumber: null,
            DocumentDate: null,
            Birthdate: moment(dataNascita).format("MM/DD/YYYY"),
            PrivacyConfirmed: privacyOne ? 1 : 0,
            PrivacyCommercialConfirmed: null,
            // telefono: phone,
            // via: street,
            // nCivico: houseNumber,
            // luogoResidenza: city,
            // cap: cap,
          };

          Repo.postRegistration(JSON.stringify(request))

            .then((response) => {
              setLoading(true);
              if (response.status == 200) {
                setDisableButton(true);
                toggleModal(!isOpen);
              }
            })
            .catch((error) => {
              setLoading(true);
              if (error.response.status == 400) {
                setErrorApi(error.response.data.Message);
                toggleModalError(!isOpenError);
              }
            });
        }
      }
    } else {
      setDisableButton(false);
    }
  };

  const loginForm = (event) => {
    event.preventDefault();

    setLoading(false);
    Repo.postLogin(email, password, role.value)
      .then((response) => {
        if (response.status == 200) {
          cookies.set("token_key", response.data.access_token);
          setLoading(true);
          window.location.href = props.subfolder + "/home";
        }
      })
      .catch((error) => {
        if (error.response.status == 400) {
          setErrorApi(error.response.data.Message);
          toggleModalError(!isOpenError);
        }
        setLoading(true);
      });

    // const obb = new URLSearchParams();
    // obb.append("grant_type", "password");
    // obb.append("client_id", "semplicita_catasto");
    // obb.append("username", email);
    // obb.append("password", password);

    // Repo.postLoginOld(obb)
    //   .then((response) => {
    //     if (response.status == 200) {
    //       cookies.set("token_key", response.data.access_token, { path: "/" });
    //       window.location.href = props.subfolder + "/home";
    //     }
    //   })

    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const postForgot = () => {
    if (emailReg && validationEmail) {
      setLoading(false);
      Repo.postForgotPassword(emailReg)
        .then((response) => {
          if (response.status == 200) {
            setLoading(true);
            toggleModalCredentials(false);
            toggleModalCheckEmail(true);
          }
        })
        .catch((error) => {
          setLoading(true);
          console.log(error);
        });
    }
  };

  return (
    <>
      {registrationPannel && !resetPannel ? (
        <>
          <div className="col d-flex justify-content-center mt-5">
            <div className="card card-teaser rounded shadow">
              <div className="card-body">
                <form className="needs-validation" onSubmit={sendForm}>
                  <h5 className="card-title">
                    <div className="row">
                      <div className="col-lg-8">
                        <h3>Registrati</h3>
                      </div>
                      <div className="col-lg-4 text-right">
                        <i>
                          oppure -{" "}
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => setRegistrationPannel(false)}
                          >
                            Accedi
                          </button>
                        </i>
                      </div>
                    </div>
                  </h5>
                  <br />

                  <div className="row">
                    <div className="col-12 mb-2">
                      <h6>Dati Anagrafici</h6>
                    </div>

                    <div className="col-lg-6">
                      <Input
                        onChange={(e) => setFirstName(e.target.value)}
                        id="firstName "
                        label="Nome"
                        name="firstName"
                        {...(validation &&
                          !firstName && {
                            infoText: "Il campo non può essere vuoto!",
                            className: "text-danger",
                          })}
                      />
                    </div>

                    <div className="col-lg-6">
                      <Input
                        label="Cognome"
                        id="lastName"
                        name="lastName"
                        {...(validation &&
                          !lastName && {
                            infoText: "Il campo non può essere vuoto!",
                            className: "text-danger",
                          })}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-6 mt-1">
                      <Input
                        label="Codice Fiscale"
                        id="cf"
                        name="cf"
                        {...(validation &&
                          !cf && {
                            infoText: "Il campo non può essere vuoto!",
                            className: "text-danger",
                          })}
                        {...(validation &&
                          !cfValidation && {
                            infoText: "Codice Fiscale non coretto",
                            className: "text-danger",
                          })}
                        onChange={(e) => setCf(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6 mt-1">
                      <Input
                        id="dataNascita"
                        required
                        label="Data di Nascita"
                        type="date"
                        value={moment(dataNascita).format("YYYY-MM-DD")}
                        name="dataNascita"
                        max={moment().subtract(16, "year").format("YYYY-MM-DD")}
                        placeholder="Seleziona una data"
                        onChange={(e) => setDataNascita(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12 mb-3">
                      <h6>Recapiti</h6>
                    </div>
                    <div className="col-lg-6">
                      <Input
                        onChange={(e) => setEmailReg(e.target.value)}
                        label="Email"
                        name="email"
                        id="email"
                        {...(validation &&
                          !emailReg && {
                            infoText: "Il campo non può essere vuoto!",
                            className: "text-danger",
                          })}
                        {...(emailReg &&
                          !validationEmail && {
                            infoText: "Il formato della mail non è corretto!",
                            className: "text-danger",
                          })}
                      />
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-12 mb-3">
                      <h6>Credenziali</h6>
                    </div>
                    <div className="col-lg-4">
                      <Input
                        type="password"
                        onChange={(e) => validatePassword(e.target)}
                        id="password"
                        label="Password"
                        name="password"
                        infoText="Inserisci almeno 8 caratteri, una lettera maiuscola e un simbolo"
                        required
                        {...(validatePasswordRegex == false && {
                          infoText: "Inserisci una password valida!",
                          className: "text-danger",
                        })}
                        {...(validatePasswordRegex && {
                          infoText: "Password Valida!",
                          className: "text-success",
                        })}
                      />
                    </div>

                    <div className="col-lg-4">
                      <Input
                        type="password"
                        onChange={(e) => validatePassword(e.target)}
                        id="confirmPassword"
                        label="Conferma Password"
                        name="confirmPassword"
                        infoText="Ripeti la password inserita nel campo in alto"
                        required
                        {...(validatePasswordConfirmRegex == false && {
                          infoText: "La password non corrisponde!",
                          className: "text-danger",
                        })}
                        {...(validatePasswordConfirmRegex &&
                          password !== "" && {
                            infoText: "La password corrisponde!",
                            className: "text-success",
                          })}
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-lg-10">
                      <FormGroup check style={{ marginTop: "0%" }}>
                        <Input
                          id="disabled-checkbox1"
                          type="checkbox"
                          name="privacyOne"
                          onChange={() => setPrivacyOne(!privacyOne)}
                        />
                        <Label
                          for="disabled-checkbox1"
                          check
                          style={{ lineHeight: "1.5" }}
                        >
                          <i style={{ fontSize: "0.8rem" }}>
                            Accetto i{" "}
                            <Link to="/termini_condizioni" target="_blank">
                              <b>termini e le condizioni</b>
                            </Link>{" "}
                            e dichiaro di aver preso visione della
                            <Link to="/privacy_policy" target="_blank">
                              {" "}
                              <b>Privacy Policy</b>
                            </Link>
                          </i>
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="col-lg-2 text-right ">
                      <button
                        type="submit"
                        disabled={disableButton}
                        className="btn btn-primary mt-4"
                      >
                        Registrati
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        !registrationPannel &&
        !resetPannel && (
          <div className="row">
            <div className="col-lg-7 mt-4">
              <h3>Accedi con le tue credenziali</h3>
              <br />
              <h5 className="mb-5">
                Accedi all'area riservata inserendo l'indirizzo e-mail e la
                password associati al tuo account.
              </h5>
              {!operatorBool ? (
                <>
                  <strong style={{ fontSize: "1.4em" }} className="ml-2">
                    Sei Operatore?
                  </strong>
                  <div className="col-lg-5 mt-2 text-center">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-block rounded"
                      onClick={() => setOperatorBool(true)}
                    >
                      Clicca Qui
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <strong style={{ fontSize: "1.4em" }} className="ml-2">
                    Sei Utente?{" "}
                  </strong>
                  <div className="col-lg-5 mt-2 text-center">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-block rounded"
                      onClick={() => setOperatorBool(false)}
                    >
                      Clicca Qui
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-5">
              <div className="col d-flex justify-content-center mt-4">
                <div
                  className="card card-teaser rounded shadow"
                  style={{ width: "25em" }}
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6">
                        {operatorBool ? (
                          <h5 className="card-title">Accedi come operatore</h5>
                        ) : (
                          <h5 className="card-title">Accedi al tuo account</h5>
                        )}
                      </div>
                    </div>

                    <br />
                    <form onSubmit={loginForm} autoComplete="on">
                      <Input
                        label="Email"
                        type="email"
                        name="email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <br />
                      <Input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        id="exampleInputPassword"
                        label="Password"
                        name="password"
                        required
                      />

                      <div className="col-lg-12 text-danger">{errorLogin}</div>

                      <div className="row mt-3">
                        {operatorBool && (
                          <>
                            <div className="bootstrap-select-wrapper col-12 mb-5 mt-4">
                              <Select
                                aria-label="Scegli ruolo"
                                clearText="Annulla"
                                defaultMenuIsOpen={false}
                                value={role ? role : ""}
                                icon={false}
                                id="ruolo"
                                onChange={(e) => setRole(e)}
                                options={roleOperator}
                                placeholder="Scegli ruolo"
                              />
                            </div>
                          </>
                        )}
                        <div className="col-lg-12  text-right">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block rounded"
                          >
                            Accedi
                          </button>
                        </div>
                        {!operatorBool && (
                          <>
                            <div className="col-lg-12 text-center mt-3">
                              <Link to="#" onClick={() => setResetPannel(true)}>
                                {" "}
                                Password dimenticata?
                              </Link>
                            </div>
                            <div className="col-12">
                              <hr />
                            </div>
                            <div className="col-lg-12 text-right">
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-block rounded"
                                onClick={() => setRegistrationPannel(true)}
                              >
                                Registrati
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}

      {resetPannel && (
        <>
          <div className="row">
            <div className="col-lg-7 mt-4">
              <h3>Recupera Password</h3>
              <br />
              <h5>Inserisci l'indirizzo e-mail associato al tuo account.</h5>
              <br />
            </div>
            <div className="col-lg-5">
              <div className="col d-flex justify-content-center mt-4">
                <div
                  className="card card-teaser rounded shadow"
                  style={{ width: "25em" }}
                >
                  <div className="card-body">
                    <div className="row mb-4">
                      <div className="col-12">
                        <h5 className="card-title">
                          Assistenza per la password
                        </h5>
                      </div>
                    </div>

                    <form onSubmit={postForgot} autoComplete="on">
                      <Input
                        onChange={(e) => setEmailReg(e.target.value)}
                        label="Email"
                        name="email"
                        id="email"
                        {...(validation &&
                          !emailReg && {
                            infoText: "Il campo non può essere vuoto!",
                            className: "text-danger",
                          })}
                        {...(emailReg &&
                          !validationEmail && {
                            infoText: "Il formato della mail non è corretto!",
                            className: "text-danger",
                          })}
                      />
                      <br />
                      <div className="row mt-3">
                        <div className="col-lg-12 text-right">
                          <button
                            type="button"
                            className="btn btn-primary btn-block rounded"
                            onClick={() => postForgot()}
                          >
                            Recupera Password
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="col-lg-12 text-center mt-3">
                      <Link to="#" onClick={() => setResetPannel(false)}>
                        {" "}
                        Accedi
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div>
        <Modal
          isOpen={isOpenCheckEmail}
          toggle={() => toggleModalCheckEmail(!isOpenCheckEmail)}
          labelledBy="esempio1"
        >
          <ModalHeader id="esempio1">Recupera le tue credenziali</ModalHeader>
          <ModalBody>
            Ti abbiamo inviato una mail nella casella di posta elettronica da te
            indicata!
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>

        <Modal
          isOpen={isOpen}
          toggle={() => toggleModal(!isOpen)}
          labelledBy="esempio1"
        >
          <ModalHeader id="esempio1">CONFERMA MAIL</ModalHeader>
          <ModalBody>
            Riceverai nella tua casella mail un link per confermare la
            registrazione
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() =>
                (window.location.href = props.subfolder + "/login")
              }
            >
              Avanti
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={isOpenError}
          toggle={() => toggleModalError(!isOpenError)}
          labelledBy="esempio1"
        >
          <ModalHeader id="esempio1">ERRORE</ModalHeader>
          <ModalBody>{errorApi}</ModalBody>
          <ModalFooter>
            <Button
              onClick={() => toggleModalError(!isOpenError)}
              color="primary"
            >
              Chiudi
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      {!loading && <Loader />}
    </>
  );
}

export default SignedUp;
