import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Link } from "react-router-dom";
import chatbot from "../../Img/chatbotlogo.png";

function Help(props) {
  return (
    <>
      {props.isOperator ? (
        <>
          <div className="bd-example-tabs">
            <div className="row">
              <div className="col-4">
                <div
                  className="nav nav-tabs nav-tabs-vertical"
                  id="nav-vertical-tab-ico"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className="nav-link active"
                    id="nav-vertical-tab-ico1-tab"
                    data-toggle="tab"
                    href="#nav-vertical-tab-ico1"
                    role="tab"
                    aria-controls="nav-vertical-tab-ico1"
                    aria-selected="true"
                  >
                    Sevizi demografici:{" "}
                    <svg className="icon icon-primary">
                      <use href={`${iconSprite}#it-link`} />{" "}
                    </svg>
                  </a>
                  <a
                    className="nav-link"
                    id="nav-vertical-tab-ico2-tab"
                    data-toggle="tab"
                    href="#nav-vertical-tab-ico2"
                    role="tab"
                    aria-controls="nav-vertical-tab-ico2"
                    aria-selected="false"
                  >
                    Tributi e Riscossioni{" "}
                    <svg className="icon icon-primary">
                      <use href={`${iconSprite}#it-calendar`} />{" "}
                    </svg>
                  </a>
                  <a
                    className="nav-link"
                    id="nav-vertical-tab-ico3-tab"
                    data-toggle="tab"
                    href="#nav-vertical-tab-ico3"
                    role="tab"
                    aria-controls="nav-vertical-tab-ico3"
                    aria-selected="false"
                  >
                    Chat{" "}
                    <svg className="icon icon-primary">
                      <use href={`${iconSprite}#it-comment`} />{" "}
                    </svg>
                  </a>
                </div>
              </div>
              <div className="col-8">
                <div className="tab-content" id="nav-vertical-tab-icoContent">
                  <div
                    className="tab-pane p-3 fade show active"
                    id="nav-vertical-tab-ico1"
                    role="tabpanel"
                    aria-labelledby="nav-vertical-tab-ico1-tab"
                  >
                    <h3>1. Anagrafe</h3>
                    L'anagrafe della popolazione residente è la registrazione di
                    tutte le persone che hanno stabilito la loro residenza in
                    Italia, con la sola esclusione dei cittadini stranieri
                    irregolari. Solo dall'adempimento scrupoloso ed immediato di
                    detti obblighi nasce la regolare tenuta dell'anagrafe, e
                    cioè di una banca dati che, in ogni momento deve
                    rispecchiare la reale situazione di fatto. L'Anagrafe
                    Nazionale della Popolazione Residente (ANPR) rappresenta la
                    realizzazione di un progetto strategico per la
                    digitalizzazione e la modernizzazione del Paese, in tutte le
                    sue componenti amministrative. L'attività anagrafica, di
                    competenza dello Stato e gestita dai comuni, oltre a
                    rappresentare uno strumento fondamentale a tutela della
                    sicurezza, costituisce la base di numerosi altri servizi
                    pubblici, quali quello elettorale, scolastico, sanitario,
                    assistenziale, tributario, che attingono alla fonte
                    anagrafica per le notizie necessarie alle loro funzioni.
                    Dalla correttezza e dalla puntualità degli adempimenti
                    anagrafici dipende tutta l'attività amministrativa dello
                    Stato, delle Regioni e dei Comuni, con le inevitabili
                    ricadute sui diritti e sui doveri delle persone. Le norme
                    vigenti, fin dall'unità d'Italia, prevedono l'obbligo per
                    ogni comune di tenere in regola l'Anagrafe. Il Sindaco è
                    l'ufficiale d'anagrafe che esercita le sue funzioni,
                    irrinunciabili, tramite dipendenti da lui stesso delegati.
                    La formazione continua, che comprende anche la conoscenza
                    delle norme che disciplinano la registrazione delle
                    dinamiche migratorie dei cittadini stranieri e comunitari e
                    il loro diritto di soggiornare e risiedere in Italia, le
                    norme sul rilascio della Carta di identità e l'applicazione
                    delle disposizioni in materia di semplificazione
                    amministrativa, è l'unico strumento in grado di garantire ai
                    comuni, il supporto necessario alla correttezza degli
                    adempimenti normativi.
                  </div>
                  <div
                    className="tab-pane p-3 fade"
                    id="nav-vertical-tab-ico2"
                    role="tabpanel"
                    aria-labelledby="nav-vertical-tab-ico2-tab"
                  >
                    <h3>2. Riscossione</h3>
                    La riscossione consiste nella raccolta dei tributi, e in
                    generale dei proventi fiscali: essa costituisce una fase
                    tipizzata, il che vuol dire che l'ente impositore ha la
                    facoltà di adottare unicamente forme di riscossione
                    stabilite dalla legge. Quello della riscossione è un momento
                    successivo rispetto all'accertamento, fase il cui culmine è
                    da identificare nell'emanazione del provvedimento
                    amministrativo attraverso il quale viene imposto il
                    pagamento di un tributo. Pagamento che corrisponde, appunto,
                    alla riscossione: esso può avvenire in seguito a una
                    iscrizione a ruolo o tramite un versamento diretto, per via
                    telematica, presso un ufficio postale, a uno sportello
                    bancario o a uno sportello dell'agente della riscossione. Si
                    parla di riscossione spontanea per riferirsi al fatto che le
                    principali imposte erariali previste dal nostro sistema
                    tributario, come l'imposta di registro, l'Iva, l'Ires e
                    l'Irpef, vengono riscosse tramite versamenti spontanei: e lo
                    stesso vale anche per tributi locali come l'Irap. I tributi
                    possono essere versati, in seguito all'entrata in vigore del
                    d. lgs. n. 241 del 9 luglio del 1997, con il modello F24,
                    che per altro permette di compensare i debiti e i crediti
                    che riguardano i vari tributi. Fonte: La riscossione dei
                    tributi
                    https://www.studiocataldi.it/guide_legali/fisco/la-riscossione.asp#ixzz6uAKugzY1
                    (www.studiocataldi.it)
                  </div>
                  <div
                    className="tab-pane p-3 fade"
                    id="nav-vertical-tab-ico3"
                    role="tabpanel"
                    aria-labelledby="nav-vertical-tab-ico3-tab"
                  >
                    <div className="text-center"></div>
                    <h3>3. CHAT</h3>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-lg-6">
              <h2>Serve aiuto?</h2>
              <br />
              <p>
                Puoi consultare <b>Semplícita Bot</b> che trovi in basso a
                destra
              </p>
              <p>
                Altrimenti contatta l'<strong>assistenza</strong> per segnalare
                eventuali problematiche riscontrate. Puoi{" "}
                <strong>scriverci una email</strong> esplicitando la tua
                richiesta ed includendo tutte le informazioni in tuo possesso
                (es. codice fiscale, Comune di residenza, screenshot, etc.) che
                possono esserci utili a capire il problema e fornirti il
                migliore supporto possibile.
              </p>
              <div className="it-helpdesk d-md-flex mb-4">
                <div className="it-btn-container">
                  <a
                    className="btn btn-primary btn-icon noloader"
                    href="mailto:segnalazioni@smartanpr.it"
                  >
                    <svg className="icon icon-white d-none d-lg-block">
                      <use href={`${iconSprite}#it-mail`} />{" "}
                    </svg>
                    <span>info@semplicitacatasto.it</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                style={{ float: "right", height: "35%" }}
                src={chatbot}
                alt="chatbotlogo"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Help;
