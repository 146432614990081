import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId, width, height, title }) => (
  <div className="video-responsive">
    <iframe
      width={width ? width : "853"}
      height={height ? height : "480"}
      src={`https://www.youtube-nocookie.com/embed/${embedId}?vq=hd720`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title ? title : "Embedded youtube"}
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
