import { createRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import Select from "../Objects/Select";
import moment from "moment";
import Repo from "../Utility/Repo";
import useLocalStorage from "../Utility/useLocalStorage";
import ListaRichiesteCoda from "../Objects/ListaRichiesteCoda";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import UploadFile from "../Objects/UploadFile";
import ImmobileForm from "../Objects/ImmobileForm";
import SoggettoForm from "../Objects/SoggettoForm";
import GiuridicaForm from "../Objects/GiuridicaForm";
import NotaForm from "../Objects/NotaForm";
import MetaTags from "react-meta-tags";
import Loader from "../Utility/Loader";

function Conservatoria(props) {
  const collapseRef = createRef();
  const [token, setToken] = useState(props.token);
  const [servizi, setServizi] = useState([]);
  const [isDelega, setIsDelega] = useState(false);
  const [validation, setValidation] = useState(false);
  const [serviziTemp, setServiziTemp] = useState([]);
  const [loading, setLoading] = useState(true);
  const [immobili, setImmobili] = useState([]);
  const [conservatoria, setConservatoria] = useState([]);
  const [conservatoriaTemp, setConservatoriaTemp] = useState();
  const [servizioSelezionato, setServizioSelezionato] = useState();
  const [pageNext, setPageNext] = useState(false);
  const [checkPrivate, setCheckPrivate] = useState(false);
  const [validationStep, setValidationStep] = useState(false);
  const [validationFatturazione, setValidationFatturazione] = useState(true);
  const [validationCF, setValidationCF] = useState(false);
  const [validationIVA, setValidationIVA] = useState(false);
  const [isOpen, toggleModal] = useState(false);
  const [notaCheck, setNotaCheck] = useState(false);
  const [ordiniNonPagati, setOrdiniNonPagati] = useState([]);
  const [checkSoggettoText, setCheckSoggettoText] = useState("IMMOBILE");
  const [listaRichiesteDescrizione, setListaRichiesteDescrizione] = useState(
    []
  );
  const [isOpenInfoSubServizio, toggleModalInfoSubServizio] = useState(false);
  const [descSubServizio, setDescSubServizio] = useState("");
  const [radioButtonRegistro, setRadioButtonRegistro] = useState(false);
  const [scroll, setScroll] = useState(false);
  var passedValidation = true;

  // Oggetto con campi
  const obb = {
    provincia: "",
    ufficioConservatoria: "",
    comune: "",
    mappale: "",
    foglio: "",
    //particella: "",
    subalterno: "",
    indirizzo: "",
    piano: "",
    cf_piva: "",
    cognome: "",
    nome: "",
    dataNascita: null,
    luogoNascita: "",
    indirizzoSede: "",
    //ImmobileForm
    motivazioneConservatoria: "",
    provinciaImmobile: "",
    comuneImmobile: "",
    //NotaForm
    numeroRegistroParticolare: "",
    annoRegistroParticolare: "",
    numeroRegistroGenerale: "",
    annoRegistroGenerale: "",
    tipoNota: "",
  };

  const [data, setData] = useState(obb);

  // switch colori
  const [check, setCheck] = useState("1");
  const [bC1, setBC1] = useState("");
  const [tC1, setTC1] = useState("");
  const [bC2, setBC2] = useState("");
  const [tC2, setTC2] = useState("");
  const [bC3, setBC3] = useState("");
  const [tC3, setTC3] = useState("");
  const [bC4, setBC4] = useState("");
  const [tC4, setTC4] = useState("");

  useEffect(() => {
    switch (check) {
      case "1":
        setBC1("#2e58a6");
        setTC1("white");
        setBC2("transparent");
        setTC2("#435a70");
        setBC3("transparent");
        setTC3("#435a70");
        setBC4("transparent");
        setTC4("#435a70");
        break;

      case "2":
        setBC1("transparent");
        setTC1("#435a70");
        setBC2("#2e58a6");
        setTC2("white");
        setBC3("transparent");
        setTC3("#435a70");
        setBC4("transparent");
        setTC4("#435a70");
        break;

      case "3":
        setBC1("transparent");
        setTC1("#435a70");
        setBC3("#2e58a6");
        setTC3("white");
        setBC2("transparent");
        setTC2("#435a70");
        setBC4("transparent");
        setTC4("#435a70");
        break;

      case "4":
        setBC1("transparent");
        setTC1("#435a70");
        setBC4("#2e58a6");
        setTC4("white");
        setBC2("transparent");
        setTC2("#435a70");
        setBC3("transparent");
        setTC3("#435a70");
        break;

      default:
        break;
    }

    // if (checkSoggetto) {
    //   setData((prevState) => ({
    //     ...prevState,
    //     provincia: "",
    //     comune: "",
    //     mappale: "",
    //     foglio: "",
    //     particella: "",
    //     subalterno: "",
    //     indirizzo: "",
    //     piano: "",
    //   }));
    //   setSelectedComune();
    //   setSelectedProvincia();
    //   setImmobileTemp();
    // } else {
    // }

    setServiziTemp([]);
  }, [check]);

  // API Servizi
  useEffect(() => {
    setLoading(false);
    Repo.getListaServizi(token, 1)
      .then((response) => {
        setServizi(response.data);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });

    Repo.getListaImmobili(token)
      .then((response) => {
        setImmobili(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    Repo.getListaConservatorie()
      .then((response) => {
        setConservatoria(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    if (token) {
      Repo.getUserRiepilogoRichieste(token)
        .then((response) => {
          setListaRichiesteDescrizione(response.data);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });

      Repo.getOrdini(token, false)
        .then((response) => {
          setOrdiniNonPagati(response.data);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  }, []);

  const delRichiestaOrdine = (idRichiesta) => {
    Repo.delRichiestaFromOrdine(token, idRichiesta)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        window.location.reload();
        console.log(error);
      });
  };

  // checkBox
  const handleChangeCheckBox = (event, subServizio) => {
    if (event) {
      let flagUpload = event.target.value;
      let name = event.target.name;
      let id = event.target.id;
      let checked = event.target.checked;

      setServizioSelezionato(id);
      switch (name) {
        case "servizi":
          if (checked) {
            setServiziTemp((prevState) => [
              ...prevState,
              {
                id: id,
                flagUpload: flagUpload,
              },
            ]);

            if (subServizio && subServizio.flagDefaultAttivoSelezionePadre) {
              setServiziTemp((prevState) => [
                ...prevState,
                {
                  id: subServizio.id,
                  flagUpload: subServizio.flagRichiestaDelega.toString(),
                },
              ]);
            }

            break;
          } else {
            if (subServizio) {
              var arr = serviziTemp.filter(
                (item) => item.id !== subServizio.id && item.id !== id
              );
            } else {
              var arr = serviziTemp.filter((item) => item.id !== id);
            }

            setServiziTemp(arr);

            break;
          }
        default:
          break;
      }
    }
  };

  const handleChange = (event, num) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case "provincia":
        setData((prevState) => ({
          ...prevState,
          provincia: value,
        }));

        break;

      case "comuneSede":
        setData((prevState) => ({
          ...prevState,
          comuneSede: value,
        }));

        break;

      case "comune":
        setData((prevState) => ({
          ...prevState,
          comune: value,
        }));

        break;

      case "indirizzo":
        setData((prevState) => ({
          ...prevState,
          indirizzo: value,
        }));
        break;

      case "indirizzoSede":
        setData((prevState) => ({
          ...prevState,
          indirizzoSede: value,
        }));
        break;

      case "cf_piva":
        setData((prevState) => ({
          ...prevState,
          cf_piva: num,
        }));
        break;

      case "cognome":
        setData((prevState) => ({
          ...prevState,
          cognome: value,
        }));
        break;

      case "nome":
        setData((prevState) => ({
          ...prevState,
          nome: value,
        }));
        break;

      case "dataNascita":
        setData((prevState) => ({
          ...prevState,
          dataNascita: value,
        }));
        break;

      case "luogoNascita":
        setData((prevState) => ({
          ...prevState,
          luogoNascita: value,
        }));
        break;

      case "motivazioneConservatoria":
        setData((prevState) => ({
          ...prevState,
          motivazioneConservatoria: value,
        }));

        break;

      default:
        break;
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  //Select

  const handleChangeSelect = (event, nameOfComponent) => {
    if (event) {
      let name = nameOfComponent.name;
      let value = event.value;
      let label = event.label;

      switch (name) {
        case "tipoImmobile":
          setImmobileTemp(label);

          setData((prevState) => ({
            ...prevState,
            tipoImmobileId: parseInt(value),
          }));
          break;

        case "conservatoria":
          setConservatoriaTemp(label);

          setData((prevState) => ({
            ...prevState,
            ufficioConservatoria: value,
          }));
          break;

        default:
          break;
      }
    } else {
      let name = nameOfComponent.name;
      switch (name) {
        case "tipoImmobile":
          setData((prevState) => ({
            ...prevState,
            tipoImmobileId: null,
          }));
          break;

        default:
          break;
      }
    }
  };

  const sendForm = async () => {
    setValidation(true);
    setValidationFatturazione(true);
    setValidationStep(true);

    let tempData = data;

    if (serviziTemp.length == 0 || !serviziTemp) {
      passedValidation = false;
    }
    if (!data.ufficioConservatoria) passedValidation = false;
    tempData["flagRichiestaDelega"] = false;
    tempData["idServizi"] = [];

    serviziTemp.map((x) => {
      tempData["idServizi"].push(x.id);
      if (x.flagUpload == true) {
        tempData["flagRichiestaDelega"] = true;
      }
    });

    if (check == "1") {
      if (!data.mappale) passedValidation = false;
      if (!data.foglio) passedValidation = false;
      if (!data.provincia) passedValidation = false;
      if (!data.comune) passedValidation = false;
      if (!data.tipoImmobileId) passedValidation = false;
      if (!data.subalterno) passedValidation = false;
    } else if (check == "2") {
      validationSoggetto();
    } else {
      if (notaCheck == true) {
        if (!data.mappale) passedValidation = false;
        if (!data.foglio) passedValidation = false;
        if (!data.subalterno) passedValidation = false;
        if (!data.provincia) passedValidation = false;
        if (!data.comune) passedValidation = false;
        if (!data.tipoImmobileId) passedValidation = false;
      } else {
        validationSoggetto();
      }
      if (radioButtonRegistro) {
        //registro generale
        if (!data.annoRegistroGenerale) passedValidation = false;
        if (!data.numeroRegistroGenerale) passedValidation = false;
      } else {
        if (!data.tipoNota) passedValidation = false;
        if (!data.numeroRegistroParticolare) passedValidation = false;
        if (!data.annoRegistroParticolare) passedValidation = false;
      }
    }

    if (passedValidation) {
      var dataJson = JSON.stringify(tempData);

      if (!token) {
        Repo.postRichiestaConservatoria(token, dataJson)
          .then((response) => {
            var query = response.data.fatturazioneClienteId;
            if (response.status == 200) {
              if (isDelega) {
                if (
                  !data.mappale ||
                  !data.foglio ||
                  !data.subalterno ||
                  !data.comune
                ) {
                  window.location.href =
                    props.subfolder + "/Delega?query=" + query;
                } else {
                  window.location.href =
                    props.subfolder +
                    "/Delega?query=" +
                    query +
                    "&comune=" +
                    selectedComune.label +
                    "&foglio=" +
                    data.foglio +
                    "&mappale=" +
                    data.mappale +
                    "&subalterno=" +
                    data.subalterno;
                }
              } else {
                window.location.href =
                  props.subfolder + "/Fatturazione?query=" + query;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        Repo.postUserRichiestaConservatoria(token, dataJson)
          .then((response) => {
            if (response.status == 200) {
              toggleModal(!isOpen);
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status == 401) {
              props.deleteCoockieExpired();
            }
          });
      }
    } else {
      setScroll(true);
    }
  };

  useEffect(() => {
    if (document.querySelector(".error_div")) {
      document.querySelector(".error_div").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollBy(0, -500);
      setScroll(false);
    }
  }, [scroll]);

  const validationSoggetto = () => {
    if (checkPrivate) {
      setValidationIVA(true);
      if (!data.cf_piva && checkPrivate) {
        if (!data.cognome || !data.indirizzoSede || !data.luogoNascita) {
          passedValidation = false;
          setValidationFatturazione(false);
        }
      } else if (data.cf_piva.length != 11) {
        setValidationIVA(false);
        passedValidation = false;
      }
    } else if (!checkPrivate) {
      setValidationCF(true);
      if (!data.cf_piva && !checkPrivate) {
        if (
          !data.cognome ||
          !data.nome ||
          !data.dataNascita ||
          !data.luogoNascita
        ) {
          passedValidation = false;
          setValidationFatturazione(false);
        }
      } else if (data.cf_piva.length != 16) {
        setValidationCF(false);
        passedValidation = false;
      }
    }
  };

  const motivazione = () => {
    return (
      <div
        className="row form-group pl-5 px-5 pt-4 pb-2"
        style={{
          borderRadius: "5px",
          border: "2px solid rgb(46, 88, 166, 0.35)",
        }}
      >
        <div className="col-lg-12">
          <Input
            id="motivazioneConservatoria"
            label="Motivazione"
            type="text"
            name="motivazioneConservatoria"
            value={data.motivazioneConservatoria || ""}
            style={{ borderBottom: "1.5px solid #5c6f82" }}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  };

  const selectForm = () => {
    switch (check) {
      case "1":
        return (
          <>
            <ImmobileForm
              token={token}
              setData={setData}
              dataObj={data}
              setValidationStep={setValidationStep}
              validationStep={validationStep}
            ></ImmobileForm>
            {motivazione()}
          </>
        );
        break;
      case "2":
        return (
          <>
            <SoggettoForm
              token={token}
              setData={setData}
              dataObj={data}
              checkPrivate={checkPrivate}
              setCheckPrivate={setCheckPrivate}
              setValidationStep={setValidationStep}
              validationStep={validationStep}
              setValidationFatturazione={setValidationFatturazione}
              validationFatturazione={validationFatturazione}
              validationCF={validationCF}
              validationIVA={validationIVA}
            ></SoggettoForm>
            {motivazione()}
          </>
        );
        break;
      case "4":
        return (
          <>
            <NotaForm
              token={token}
              setData={setData}
              dataObj={data}
              setNotaCheck={setNotaCheck}
              setValidationStep={setValidationStep}
              validationStep={validationStep}
              setRadioButtonRegistro={setRadioButtonRegistro}
            ></NotaForm>

            {notaCheck ? (
              <>
                <ImmobileForm
                  token={token}
                  setData={setData}
                  dataObj={data}
                  setValidationStep={setValidationStep}
                  validationStep={validationStep}
                ></ImmobileForm>
              </>
            ) : (
              <>
                <SoggettoForm
                  token={token}
                  setData={setData}
                  dataObj={data}
                  checkPrivate={checkPrivate}
                  setCheckPrivate={setCheckPrivate}
                  setValidationStep={setValidationStep}
                  validationStep={validationStep}
                  setValidationFatturazione={setValidationFatturazione}
                  validationFatturazione={validationFatturazione}
                  validationCF={validationCF}
                  validationIVA={validationIVA}
                ></SoggettoForm>
              </>
            )}
          </>
        );
        break;
      default:
        return "";
        break;
    }
  };

  // Servizi con o senza delega in base al flag
  const serviziDelega = (flag) => {
    let type;

    switch (check) {
      case "1":
        type = "IMMOBILE";
        break;
      case "2":
        type = "SOGGETTO";
        break;
      case "4":
        type = "NOTA";
        break;
      default:
        break;
    }

    return (
      <>
        <MetaTags>
          <title>Conservatoria</title>
          <meta
            property="og:title"
            content="Visure online dai registri Immobiliari e ipotecari della conservatoria"
          />
          <meta
            property="og:description"
            content="Conservatoria online -  richiedi online visure e ispezioni nella conservatoria dei registri immobiliari e ipotecari"
          />
          <meta
            name="description"
            content="Conservatoria online -  richiedi online visure e ispezioni nella conservatoria dei registri immobiliari e ipotecari"
          />
          <meta
            name="keywords"
            content="conservatoria, ispezione in conservatoria, visura ipocatastale, visura ipotecaria, registro immobiliare, visura ipoteche, copia atto notarile, archivio conservatoria"
          />
        </MetaTags>
        {servizi &&
          servizi.length > 0 &&
          servizi
            .filter((servizi) => servizi.tipologiaServizio == type)
            .filter((servizi) => servizi.flagRichiestaDelega == flag)
            .map((x, index) => (
              <div key={x.id} className="form-check col-lg-6 text-center">
                <div
                  className="card-wrapper card-space"
                  style={{ minHeight: "200px", height: "fit-content" }}
                >
                  <div
                    className="card card-teaser rounded shadow p-2"
                    style={{
                      border: "1px solid rgba(46, 88, 166, 0.35)",
                      backgroundColor: "#c4dcf5",
                    }}
                  >
                    <div className="card-body pb-0">
                      <h5 className="card-title mb-1">{x.nome}</h5>
                      <p
                        className="card-text mt-2"
                        style={{ minHeight: "90px" }}
                      >
                        {x.descrizione}
                      </p>
                      {x.tempoConsegna && (
                        <p>
                          <b>Consegna in {x.tempoConsegna} minuti</b>
                        </p>
                      )}
                      <div className="row card-text">
                        <div
                          className="col-lg-6"
                          style={{
                            paddingTop: "5px",
                            marginTop: "auto",
                          }}
                        >
                          Prezzo (IVA e Tributi INCLUSI):{" "}
                          <b>
                            {x.prezzoUnitario &&
                              x.prezzoDTO.totale.toFixed(2) + " € "}
                          </b>
                        </div>
                        <div className="col-lg-6">
                          <input
                            value={x.flagRichiestaDelega}
                            name="servizi"
                            id={x.id}
                            {...(serviziTemp &&
                            serviziTemp.length > 0 &&
                            serviziTemp.find((y) => y.id == x.id)
                              ? {
                                  checked: true,
                                }
                              : { checked: false })}
                            type="checkbox"
                            onChange={(e) =>
                              handleChangeCheckBox(
                                e,
                                x.subServizio && x.subServizio
                              )
                            }
                            data-toggle="collapse"
                            data-target={"#collapseSubServizi-" + x.id}
                            aria-expanded="false"
                            aria-controls={"collapseSubServizi-" + x.id}
                          />
                          <label htmlFor={x.id}>Seleziona servizio</label>
                        </div>

                        {x.subServizio && (
                          <div
                            className="collapse mb-2 px-2 pt-2"
                            id={"collapseSubServizi-" + x.id}
                          >
                            <hr className="hrCustom" />
                            <a className="mb-2 pt-2">
                              Scegli il servizio aggiuntivo:
                            </a>
                            <h6 className="card-title mb-1">
                              {x.subServizio.nome}
                              <svg
                                className="icon ml-3"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setDescSubServizio(x.subServizio.descrizione);
                                  toggleModalInfoSubServizio(true);
                                }}
                              >
                                <use
                                  href={`${iconSprite}#it-info-circle`}
                                ></use>
                              </svg>
                            </h6>
                            <p className="card-text my-2">
                              E’ possibile richiedere i TITOLI delle formalità
                              relative ad un immobile e registrate in
                              Conservatoria dei Registri Immobiliari solo se
                              iscritte dall’anno 2012.
                            </p>
                            <div className="row px-4">
                              <div
                                className="col-lg-6 
                          "
                              >
                                Prezzo (IVA e Tributi INCLUSI):{" "}
                                <b>
                                  {x.subServizio.prezzoDTO.totale.toFixed(2) +
                                    "€"}
                                </b>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  id={x.subServizio.id}
                                  value={x.subServizio.flagRichiestaDelega}
                                  name="servizi"
                                  type="checkbox"
                                  onChange={handleChangeCheckBox}
                                  {...(serviziTemp &&
                                  serviziTemp.length > 0 &&
                                  serviziTemp.find(
                                    (y) => y.id == x.subServizio.id
                                  )
                                    ? {
                                        checked: true,
                                      }
                                    : { checked: false })}
                                />
                                <label htmlFor={x.subServizio.id}>
                                  Seleziona servizio
                                </label>
                              </div>
                            </div>
                            {serviziTemp &&
                            serviziTemp.length > 0 &&
                            serviziTemp.find(
                              (y) => y.id == x.subServizio.id
                            ) ? (
                              <></>
                            ) : (
                              <a className="text-danger">
                                {x.subServizio.alertText}
                              </a>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
      </>
    );
  };

  useEffect(() => {
    let tempData = [];
    setIsDelega(false);
    if (serviziTemp && serviziTemp.length > 0) {
      serviziTemp.map((x) => {
        tempData.push(x.id);
        if (x.flagUpload == "true") {
          setIsDelega(true);
        }
      });
    }
  }, [serviziTemp]);

  const loggedDelega = () => {
    var result = listaRichiesteDescrizione.find((obj) => {
      return obj.isDelega === true;
    });

    if ((result && result.isDelega === true) || isDelega) {
      window.location.href = props.subfolder + "/Delega";
    } else {
      window.location.href = props.subfolder + "/Fatturazione";
    }
  };

  return (
    <>
      {ordiniNonPagati && ordiniNonPagati.length > 0 && (
        <>
          <Link to="/ordini_sospeso" style={{ textDecoration: "none" }}>
            <div className="container test-docs">
              <div className="row mb-4">
                <div
                  className="notification with-icon error d-block"
                  role="alert"
                  aria-labelledby="not2b-title"
                  style={{
                    position: "static",
                    transform: "none",
                    width: "100%",
                  }}
                >
                  <h5 id="not2b-title" className="mb-3">
                    <svg className="icon">
                      <use href={`${iconSprite}#it-close-circle`}></use>
                    </svg>
                    Ordini non Pagati
                  </h5>
                  <div className="ml-3">
                    <span className="pl-3">
                      Ci sono <b>{ordiniNonPagati.length}</b> ordini non ancora
                      pagati. Per procedere al pagamento, andare nel carrello.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </>
      )}

      {listaRichiesteDescrizione && listaRichiesteDescrizione.length > 0 && (
        <>
          <ListaRichiesteCoda
            subfolder={props.subfolder}
            delRichiesta={delRichiestaOrdine}
          >
            {listaRichiesteDescrizione}
          </ListaRichiesteCoda>
        </>
      )}
      <div className="card card-teaser rounded shadow mb-4">
        <div className="card-body">
          <h4 className="card-title mb-2">Conservatoria</h4>
          <div className="row">
            <div className="col-12 text-center">
              <b style={{ color: "rgb(46, 88, 166)" }}>
                Le pratiche saranno evase da lunedì a venerdì dalle ore 9.00
                alle ore 18.00
              </b>
            </div>
          </div>
          <div className="row form-group px-5 py-2 borderCard">
            <div
              className="row col-lg-6 border border-primary rounded mb-1 mt-3 text-center ml-1 p-0 align-items-center"
              style={{ height: "40px" }}
            >
              <div
                className="col-4 p-0 border-right border-primary rounded pt-1"
                style={{
                  backgroundColor: bC1,
                  color: tC1,
                  cursor: "pointer",
                  height: "40px",
                }}
                onClick={() => {
                  setCheck("1");
                  setCheckSoggettoText("IMMOBILE");
                }}
              >
                <a
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                  }}
                >
                  <b>Immobile</b>
                </a>
              </div>
              <div
                className="col-4 p-0 border-right border-primary rounded pt-1"
                style={{
                  backgroundColor: bC2,
                  color: tC2,
                  cursor: "pointer",
                  height: "40px",
                }}
                onClick={() => {
                  setCheck("2");
                  setCheckSoggettoText("SOGGETTO");
                }}
              >
                <a
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                  }}
                >
                  <b>Soggetto</b>
                </a>
              </div>
              <div
                className="col-4 p-0 rounded pt-1"
                style={{
                  backgroundColor: bC4,
                  color: tC4,
                  cursor: "pointer",
                  height: "40px",
                }}
                onClick={() => {
                  setCheck("4");
                  setCheckSoggettoText("NOTA");
                }}
              >
                <a
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                  }}
                >
                  <b> Nota</b>
                </a>
              </div>
            </div>
            <div className="col-lg-12 mb-1 text-center">
              <a style={{ fontSize: "20px" }}>
                <b>Servizi senza delega</b>
              </a>
            </div>
            <div className="row col-lg-12">{serviziDelega(false)}</div>
            {servizi &&
              servizi.length > 0 &&
              servizi
                .filter(
                  (servizi) => servizi.tipologiaServizio == checkSoggettoText
                )
                .filter((servizi) => servizi.flagRichiestaDelega == true)
                .length > 0 && (
                <>
                  <div className="col-lg-12 mb-1 text-center">
                    <a style={{ fontSize: "20px" }}>
                      <b>Servizi con delega</b>
                    </a>
                  </div>
                  <div className="row col-lg-12">
                    {servizi && serviziDelega(true)}
                  </div>
                </>
              )}
            {isDelega && (
              <>
                <b className="col-lg-12 mb-1 text-center text-primary">
                  Verrai indirizzato alla pagina successiva in cui compilerai la
                  delega
                </b>
              </>
            )}

            {validation && serviziTemp.length == 0 && (
              <div className="row">
                <div className="col-lg-12 mb-2">
                  <>
                    <small className="text-danger error_div">
                      Seleziona almeno un servizio!
                    </small>
                  </>
                </div>
              </div>
            )}
            <div className="col-lg-12 mb-4 mt-2 p-0">
              <div className="bootstrap-select-wrapper col-12 ">
                <Select
                  aria-label="Seleziona Conservatoria"
                  clearText="Annulla"
                  defaultMenuIsOpen={false}
                  required
                  icon={false}
                  name="conservatoria"
                  id="conservatoria"
                  options={conservatoria}
                  onChange={handleChangeSelect}
                  isSearchable
                  placeholder={"Seleziona conservatoria"}
                />
              </div>
              {validation && !conservatoriaTemp ? (
                <small
                  id="errorSelect"
                  className="form-text error_div text-danger"
                >
                  Seleziona un campo
                </small>
              ) : (
                <></>
              )}
            </div>
          </div>
          {selectForm()}
        </div>
      </div>
      <div className="row">
        {/* {pageNext && (
          <div className="col-lg-10 text-right">
            <a
              onClick={() => setPageNext(false)}
              className="btn btn-info text-white"
            >
              Precedente
            </a>
          </div>
        )} */}
        <div className="col-lg-12 text-right">
          <button
            onClick={() => sendForm()}
            className="btn btn-primary text-white"
          >
            Invia Richiesta
          </button>
        </div>
      </div>

      <div>
        {" "}
        <Modal
          isOpen={isOpen}
          toggle={() => toggleModal(!isOpen)}
          size={"lg"}
          labelledBy="Modale1"
        >
          <ModalHeader id="Modale1">
            La tua richiesta è andata a buon fine.
          </ModalHeader>
          <ModalBody>
            Puoi procedere inserendo una nuova richiesta o puoi inserire i dati
            di fatturazione e procedere con il pagamento.
          </ModalBody>
          <ModalFooter>
            <Button
              color="info text-white text-center"
              onClick={() => reloadPage()}
            >
              Inserisci un'altra richiesta
            </Button>
            <Button
              color="primary text-white text-center"
              onClick={() => loggedDelega()}
            >
              Procedi
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={isOpenInfoSubServizio}
          toggle={() => toggleModalInfoSubServizio(!isOpenInfoSubServizio)}
          size={"lg"}
          labelledBy="Modale1"
        >
          <ModalHeader id="Modale1">
            Descrizione SubServizio selezionato.
          </ModalHeader>
          <ModalBody>{descSubServizio}</ModalBody>
          <ModalFooter>
            <Button
              color="primary text-white text-center"
              onClick={() => {
                setDescSubServizio("");
                toggleModalInfoSubServizio(false);
              }}
            >
              Chiudi
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      {!loading && <Loader />}
    </>
  );
}

export default Conservatoria;
