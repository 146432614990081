import React from "react";

import mainLogo from "../../Img/semplicita-catasto.png";
import logo from "../../Img/logo.svg";

import GooglePlayGet from "../../Img/GooglePlayGet.png";
import AppStoreDownload from "../../Img/AppStoreDownload.png";
import { Link } from "react-router-dom";
import onlife from "../../Img/servizi-onlife.png";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
function Footer(props) {
  return (
    <footer className="it-footer">
      <div className="it-footer-main footerCustom">
        <div className="container footerText">
          <section>
            <div className="row ">
              <div className="col-sm-12">
                <div className="it-brand-wrapper">
                  <a className="">
                    <img alt="dd" height="100" src={logo} />
                    <div className="it-brand-text">
                      <h2 className="no_toc">
                        {" "}
                        <img
                          className="img-fluid"
                          src={mainLogo}
                          style={{ marginLeft: "5px", maxHeight: "80px" }}
                        />
                      </h2>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section></section>
          <section className="py-4">
            <div className="row clearfix">
              <div className="col-lg-3 col-md-4 pb-2">
                <h6>CUSTOMER CARE</h6>
                <p>
                  <strong>Semplícita Catasto</strong>
                  <br />
                  <a
                    href="https://www.servizilocalispa.it/"
                    className="small-prints text-white"
                    target="_blank"
                    title="www.SemplícitaCatasto.it"
                    rel="noopener noreferrer"
                  >
                    www.servizilocalispa.it
                  </a>{" "}
                  <br />
                  info@semplicitacatasto.it
                </p>
                <p></p>
                <p></p>
              </div>

              <div className="col-lg-3 col-md-4 pb-2">
                <h6>CONTATTI</h6>
                <p>
                  <strong>Servizi Locali SPA</strong> <br />
                  Via G. Schiaparelli, 17 <br /> 20125 Milano (MI) <br />
                  C.F./P.IVA: 03170580751 <br />
                  Numero: (+39) 02.76009067 <br />
                  <a
                    href="http://www.servizilocalispa.it"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="small-prints text-white"
                    title="www.servizilocalispa.it"
                  >
                    www.servizilocalispa.it
                  </a>
                </p>
              </div>
              <div className="col-lg-3 col-md-4 pb-2">
                <h6>SOCIAL</h6>
                <p>
                  <a
                    target="_blank"
                    href="https://it.linkedin.com/company/servizi-locali-spa"
                  >
                    <svg className="icon icon-white borderCircle">
                      <use href={`${iconSprite}#it-linkedin`} />
                    </svg>
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/servizilocalispa.it"
                  >
                    <svg className="icon icon-white borderCircle">
                      <use href={`${iconSprite}#it-facebook`} />
                    </svg>
                  </a>
                </p>
              </div>
              {/* <div className="col-lg-3 col-md-4 pb-2">
                <h6>APPS</h6>
                <p>
                  <img src={GooglePlayGet} className="storeApp" alt="" />
                </p>
                <p>
                  <img src={AppStoreDownload} className="storeApp" alt="" />
                </p>
              </div> */}
            </div>
          </section>

          <section className="py-2 border-white border-top">
            <div className="row clearfix">
              <div className="col-lg-9">
                <h3 className="sr-only">Sezione Link Utili</h3>
                <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
                  <li className="list-inline-item">
                    <img
                      className="img-fluid"
                      src={onlife}
                      style={{
                        marginLeft: "5px",
                        height: "55px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        window.open("https://www.servizilocalispa.it", "_blank")
                      }
                    />
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 py-2">
                <h3 className="sr-only">Sezione Link Utili</h3>
                <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
                  <li className="list-inline-item">
                    <Link
                      to="/termini_condizioni"
                      className="small-prints text-white"
                      title="Termini e Condizioni"
                      target="_blank"
                    >
                      Termini e Condizioni
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to="/privacy_policy"
                      className="small-prints text-white"
                      title="Privacy-Cookies"
                      target="_blank"
                    >
                      Privacy policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <div className="row">
            <div className="col-12 text-center">
              <p>
                SemplicitaCatasto.it - Copyright © 2022 • All rights reserved.
              </p>
            </div>
          </div>
          <div className="cookiebar">
            <p>
              Questo sito utilizza solo cookie tecnici. <br />
              Per maggiori informazioni consulta la nostra Privacy Policy.
            </p>
            <div className="cookiebar-buttons">
              <Link
                to="/privacy_policy"
                target="_blank"
                className="cookiebar-btn"
              >
                Privacy policy<span className="sr-only">cookies</span>
              </Link>
              <button
                data-accept="cookiebar"
                className="cookiebar-btn cookiebar-confirm"
              >
                Accetto<span className="sr-only"> i cookies</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
export const MemoizedFooter = React.memo(Footer);
