import { useEffect, useState } from "react";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import Select from "../Objects/Select";
import moment from "moment";
import Fatturazione from "../Objects/Fatturazione";
import Repo from "../Utility/Repo";
import { useLocation } from "react-router";
import useLocalStorage from "../Utility/useLocalStorage";
import { Link } from "react-router-dom";

function FatturazioneCatastale(props) {
  const [token, setToken] = useState(props.token);
  const [listaRichieste, setListaRichieste] = useState();
  const [id, setId] = useState();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("query")) {
      setId(query.get("query"));

      Repo.getRiepilogoRichieste(query.get("query"))
        .then((response) => {
          setListaRichieste(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      Repo.getUserRiepilogoRichieste(token)
        .then((response) => {
          setListaRichieste(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (listaRichieste && listaRichieste.length == 0) {
      window.location.href = props.subfolder + "/";
    }
  }, [listaRichieste]);

  const sendFatturazione = (data) => {
    if (!token) {
      Repo.postFatturazioneCatastale(token, JSON.stringify(data))
        .then((response) => {
          if (response.status == 200) {
            window.location.href =
              props.subfolder + "/riepilogo?sessionId=" + response.data.id;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      Repo.postUserFatturazioneCatastale(token, JSON.stringify(data))
        .then((response) => {
          if (response.status == 200) {
            window.location.href =
              props.subfolder + "/riepilogo?sessionId=" + response.data.id;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  return (
    <>
      <Fatturazione
        token={token}
        sendFatturazione={sendFatturazione}
        listaRichieste={listaRichieste}
        id={id}
      ></Fatturazione>
    </>
  );
}

export default FatturazioneCatastale;
