import React, { useEffect, useState } from "react";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import Repo from "../Utility/Repo";
import Loader from "../Utility/Loader";

function ListaRichiesteVisure(props) {
  const baseUrlPdf = window.__RUNTIME_CONFIG__.REACT_APP_HUB_URL;
  const [isOpen, toggleModal] = useState(false);
  const [isOpenRefuse, toggleModalRefuse] = useState(false);
  const [isOpenSend, toggleModalSend] = useState(false);
  const [token, setToken] = useState(props.token);
  const [attachment, setAttachment] = useState();
  const [fileList, setFileList] = useState([]);
  const [acceptedReq, setAcceptedReq] = useState([]);
  const [downloadFiles, setDownloadFiles] = useState([]);
  const [alertConfirm, setAlertConfirm] = useState(false);
  const [alertNoConfirm, setAlertNoConfirm] = useState(false);
  const [reason, setReason] = useState();
  const [refusedId, setRefusedId] = useState();
  const [validationReason, setValidationReason] = useState(false);
  const [saveVisible, setSaveVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [visibleButton, setVisibleButton] = useState(false);
  const [isOpenImage, toggleModalImage] = useState(false);
  const [isOpenDelega, toggleModalDelega] = useState(false);
  const [filePdf, setFilePdf] = useState();
  const [fileDoc1, setFileDoc1] = useState();
  const [fileDoc2, setFileDoc2] = useState();
  const [isRichiediFirma, setIsRichiediFirma] = useState(false);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertConfirm(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, [alertConfirm]);

  // useEffect(() => {
  //   let serviziRifiutati = props.children.richiesteServizi.filter(
  //     (x) => x.motivazione
  //   ).length;

  //   let serviziEvasi = 0;
  //   let lengthArr = 0;

  //   props.children.richiesteServizi.map((x) =>
  //     x.servizi.map((y) => {
  //       lengthArr++;
  //       if (y.allegatoId !== null) {
  //         serviziEvasi++;
  //       }
  //     })
  //   );

  //   if (serviziEvasi == 0) {
  //     setVisibleButton(false);
  //   } else if (serviziEvasi + serviziRifiutati == lengthArr) {
  //     setVisibleButton(true);
  //   }
  // }, []);

  // let temp2 = 0;

  // const checkRequestNum = (arr) => {
  //   let serviziRifiutati = props.children.richiesteServizi.filter(
  //     (x) => x.motivazione
  //   ).length;

  //   let serviziEvasi = 0;
  //   props.children.richiesteServizi.map((x) =>
  //     x.servizi.map((y) => {
  //       if (y.allegatoId !== null) {
  //         serviziEvasi++;
  //       }
  //     })
  //   );

  //   let temp = 0;

  //   props.children.richiesteServizi.map((x) => {
  //     let serviziCountTempo = x.servizi.length;

  //     x.servizi.map((y) => {
  //       if (y.allegatoId !== null) {
  //         temp++;
  //       }
  //     });
  //     if (serviziCountTempo === temp) {
  //       temp2++;
  //     }
  //     serviziCountTempo = 0;
  //   });

  //   let richiesteRifiutatePost = 0;
  //   let richiesteEvasePost = 0;
  //   arr.map((x) => {
  //     if (x.accepted == true && x.refused == false) {
  //       richiesteEvasePost++;
  //     } else if (x.accepted == false && x.refused == true) {
  //       richiesteRifiutatePost++;
  //     }
  //   });
  //   if (richiesteEvasePost == 0 && serviziEvasi == 0) {
  //     setVisibleButton(false);
  //   } else if (
  //     richiesteEvasePost + richiesteRifiutatePost + temp2 + serviziRifiutati ==
  //     props.children.richiesteServizi.length
  //   ) {
  //     setVisibleButton(true);
  //   }
  // };
  useEffect(() => {
    if (props.children.fatturazioneCliente) {
      if (props.children.fatturazioneCliente.delegaPDF) {
        var obj = document.createElement("object");
        obj.type = "application/pdf";
        obj.data =
          "data:application/pdf;base64," +
          props.children.fatturazioneCliente.delegaPDF;
        setFilePdf(obj);
      }

      if (props.children.fatturazioneCliente.fronteCarta) {
        var obj = document.createElement("object");
        obj.type = "image/jpg";
        obj.data =
          "data:image/jpg;base64," +
          props.children.fatturazioneCliente.fronteCarta;
        setFileDoc1(obj);
      }

      if (props.children.fatturazioneCliente.retroCarta) {
        var obj = document.createElement("object");
        obj.type = "image/jpg";
        obj.data =
          "data:image/jpg;base64," +
          props.children.fatturazioneCliente.retroCarta;
        setFileDoc2(obj);
      }
    }
    // if (props.children.firmata == true) {
    //   setIsRichiediFirma(true);
    // }
  }, []);

  const richiestaFirma = () => {
    Repo.getRichiestaFirma(token, props.children.id)
      .then((response) => {
        setLoading(true);
        setIsRichiediFirma(true);
        window.location.href = props.subfolder + "/richieste_corso";
      })
      .catch((error) => {
        setLoading(true);
        console.log(error);
        setIsRichiediFirma(false);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertNoConfirm(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, [alertNoConfirm]);

  function handleChange(event, ordine, servizio) {
    console.log(`Selected file - ${event.target.files[0].name}`);

    setAttachment({
      name: event.target.files[0].name,
      file: event.target.files[0],
      idRichiesta: ordine.id,
      idServizio: servizio,
    });

    const jsonData = {
      idServizio: servizio,
      idRichiesta: ordine.id,
      idAllegato: null,
      allegatoBase64: "",
    };
    let fileName = event.target.files[0].name;
    let arrayTemp = fileList;
    getBase64(event.target.files[0])
      .then((result) => {
        jsonData.allegatoBase64 = result;
        Repo.postAllegato(token, jsonData)
          .then((response) => {
            arrayTemp.push({
              idRichiesta: jsonData.idRichiesta,
              idServizio: jsonData.idServizio,
              name: fileName,
            });
            let downloadTemp = downloadFiles;
            downloadTemp.push({
              idRichiesta: jsonData.idRichiesta,
              idServizio: jsonData.idServizio,
              idDownload: response.data,
            });
            setDownloadFiles(downloadTemp);
            setFileList(arrayTemp);
            setAttachment(null);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  }

  //rimuove l'allegato dall'API e da fileList
  const removeAllegato = (richiesta, servizio) => {
    const jsonData = {
      idServizio: servizio,
      idRichiesta: richiesta,
      idAllegato: downloadFiles.find(
        (obj) => obj.idRichiesta == richiesta && obj.idServizio == servizio
      ).idDownload,
      allegatoBase64: "",
    };

    Repo.postAllegato(token, jsonData)
      .then((response) => {
        //per rimuovere da fileList si crea un array di appoggio
        let newFileList = fileList.filter(
          (obj) => !(obj.idRichiesta == richiesta && obj.idServizio == servizio)
        );
        setFileList(newFileList);
        newFileList = downloadFiles.filter(
          (obj) => !(obj.idRichiesta == richiesta && obj.idServizio == servizio)
        );
        setDownloadFiles(newFileList);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  };

  //una richiesta può essere confermata solo se il numero di servizi contenuti nella responde coincide con il numero di file inseriti in fileList
  const checkUpload = (ordine) => {
    let arrayTmp = acceptedReq;
    let checkExists = (obj) => obj.idRichiesta === ordine.id;
    let fileListOrdine = fileList.filter((obj) => obj.idRichiesta == ordine.id);

    if (fileListOrdine.length > 0) {
      let oldConfirmed = 0;

      oldConfirmed = ordine.servizi.filter(
        (elem) => elem.allegatoId != null
      ).length;

      if (ordine.servizi.length == fileListOrdine.length + oldConfirmed) {
        //se non esiste l'elemento nell'array si deve fare push, altrimenti si modifica solo la proprietà dell'elemento
        if (arrayTmp.some(checkExists)) {
          arrayTmp = arrayTmp.map((elem) =>
            elem.idRichiesta == ordine.id
              ? { ...elem, accepted: true, refused: false }
              : elem
          );
        } else {
          arrayTmp.push({
            idRichiesta: ordine.id,
            accepted: true,
            refused: false,
          });
        }

        setAcceptedReq(arrayTmp);
        // checkRequestNum(arrayTmp);
        setFileList(fileList.filter((obj) => !(obj.idRichiesta == ordine.id)));
        setAlertConfirm(false);
      } else {
        setAlertConfirm(true);
        if (arrayTmp.some(checkExists)) {
          arrayTmp = arrayTmp.map((elem) =>
            elem.idRichiesta == ordine.id
              ? { ...elem, accepted: false, refused: false }
              : elem
          );
        } else {
          arrayTmp.push({
            idRichiesta: ordine.id,
            accepted: false,
            refused: false,
          });
        }
        // checkRequestNum(arrayTmp);
        setAcceptedReq(arrayTmp);
      }
    } else {
      setAlertNoConfirm(true);
    }
  };

  const refuseRequest = (idRequest) => {
    toggleModal(true);
    setRefusedId(idRequest);
  };

  //viene richiamata col bottone "Salva". Se il # di richieste confermate coincide il numero di servizi nella response la pratica viene chiusa perchè tutti i file sono stati inseriti, altrimenti va "In Lavorazione"
  const saveRequest = () => {
    setLoading(false);
    var confirmed = acceptedReq.filter(
      (obj) => obj.accepted === true || obj.refused === true
    );

    //se la pratica è totalmente conclusa (tutti i file devono essere caricati e tutte le richieste elaborate)
    Repo.postSalvaPraticaEvasa(token, props.children.id)
      .then((response) => {
        setLoading(true);
        window.location.href =
          props.subfolder + "/richieste_visure?viewList=false";
      })
      .catch((error) => {
        setLoading(true);
        console.log(error);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  };

  const pauseRequest = (idRequest) => {
    //mette la richiesta in pausa nel caso in cui l'operatore non possa ultimare la procedura (es. dati errati)
    Repo.postSalvaPraticaPausa(token, idRequest)
      .then((response) => {
        window.location.href =
          props.subfolder + "/richieste_visure?viewList=false";
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  };

  const saveRefusedRequest = () => {
    if (refusedId && reason) {
      let temp = acceptedReq;
      //se le variabili sono state settate controllo la lunghezza di reason (deve essere almeno di 10 caratteri)
      if (reason.length > 10) {
        //memorizzo la richiesta in acceptedReq come "rifiutata"
        let exists = (obj) => obj.idRichiesta === refusedId;
        if (temp.some(exists)) {
          temp = temp.map((elem) =>
            elem.idRichiesta == refusedId
              ? { ...elem, accepted: false, refused: true }
              : elem
          );
        } else {
          temp.push({
            idRichiesta: refusedId,
            accepted: false,
            refused: true,
          });
        }
        const jsonData = {
          idRichiesta: refusedId,
          motivazione: reason,
        };
        Repo.postRifiutaPratica(token, jsonData)
          .then((response) => {})
          .catch((err) => {
            console.log(err);
            if (err.response.status == 401) {
              props.deleteCoockieExpired();
            }
          });
        setAcceptedReq(temp);
        // checkRequestNum(temp);
        setFileList(fileList.filter((obj) => !(obj.idRichiesta == refusedId)));
        toggleModal(false);
        setValidationReason(false);
      } else if (reason || reason.length > 10) {
        setValidationReason(true);
      }
    } else {
      setValidationReason(true);
    }
  };

  const refuseAllRequest = () => {
    setLoading(false);
    Repo.postSalvaPraticaRifiutata(token, props.children.id)
      .then((response) => {
        window.location.href =
          props.subfolder + "/richieste_visure?viewList=false";
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="row">
          <div className="col-12">
            <p className="float-right">
              Ordine N. {props.children && props.children.descrizione}
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <h2 className="card-title mb-3">Richiesta</h2>
            <i>Tempi di Consegna: Entro 2 ore via Email</i>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h5 className="card-title mb-3">Dati di fatturazione</h5>
          </div>
        </div>
        <div className="row">
          {props.children && props.children.fatturazioneCliente.cf_piva && (
            <div className="col-4">
              Codice Fiscale/PIva:{" "}
              <b>
                {props.children && props.children.fatturazioneCliente.cf_piva}
              </b>
            </div>
          )}
          {props.children && props.children.fatturazioneCliente.nome && (
            <div className="col-4">
              Nome:{" "}
              <b>{props.children && props.children.fatturazioneCliente.nome}</b>
            </div>
          )}
          {props.children &&
            props.children.fatturazioneCliente.cognomeDenominazione && (
              <div className="col-4">
                Cognome/Denominazione:{" "}
                <b>
                  {props.children &&
                    props.children.fatturazioneCliente.cognomeDenominazione}
                </b>
              </div>
            )}
        </div>
        <div className="row">
          {props.children && props.children.fatturazioneCliente.nazione && (
            <div className="col-4">
              Nazione:{" "}
              <b>
                {props.children && props.children.fatturazioneCliente.nazione}
              </b>
            </div>
          )}
          {props.children && props.children.fatturazioneCliente.citta && (
            <div className="col-4">
              Citta:{" "}
              <b>
                {props.children && props.children.fatturazioneCliente.citta} (
                {props.children && props.children.fatturazioneCliente.provincia}
                )
              </b>
            </div>
          )}
          {props.children && props.children.fatturazioneCliente.indirizzo && (
            <div className="col-4">
              Indirizzo:{" "}
              <b>
                {props.children && props.children.fatturazioneCliente.indirizzo}
              </b>
            </div>
          )}
        </div>
        <div className="row">
          {props.children && props.children.fatturazioneCliente.email && (
            <div className="col-4">
              Email:{" "}
              <b>
                {props.children && props.children.fatturazioneCliente.email}
              </b>
            </div>
          )}
          {props.children && props.children.fatturazioneCliente.telefono && (
            <div className="col-4">
              Telefono:{" "}
              <b>
                {props.children && props.children.fatturazioneCliente.telefono}
              </b>
            </div>
          )}
        </div>
        <div className="row">
          {props.children && props.children.fatturazioneCliente.codiceSDI && (
            <div className="col-4">
              Codice SDI:{" "}
              <b>
                {props.children && props.children.fatturazioneCliente.codiceSDI}
              </b>
            </div>
          )}
          {props.children && props.children.fatturazioneCliente.pecCessionario && (
            <div className="col-4">
              PEC:{" "}
              <b>
                {props.children &&
                  props.children.fatturazioneCliente.pecCessionario}
              </b>
            </div>
          )}
        </div>
        <hr />

        <div className="row mb-2">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <table className="customTable">
              <tfoot>
                <tr>
                  <td>Importo Totale</td>
                  <td>{props.children.importoTotale.toFixed(2)} €</td>
                </tr>
              </tfoot>
              <tbody>
                <tr>
                  <td>Imponibile</td>
                  <td>{props.children.importoPagato.toFixed(2)} €</td>
                </tr>
                <tr>
                  <td>Diritti</td>
                  <td>{props.children.costoEDiritti.toFixed(2)} €</td>
                </tr>
                <tr>
                  <td>Iva</td>
                  <td>{props.children.importoIva} €</td>
                </tr>
              </tbody>
            </table>

            <div className="col-lg-12 mt-2 text-center">
              {props.children && props.children.flagPagato ? (
                <>
                  <>
                    <svg className="icon icon-success pb-1">
                      <use href={`${iconSprite}#it-check-circle`}></use>
                    </svg>
                    <b className="text-success pl-1">Pagamento Effettuato</b>
                  </>
                </>
              ) : (
                <>
                  <svg className="icon icon-danger pb-1">
                    <use href={`${iconSprite}#it-warning-circle`}></use>
                  </svg>
                  <b className="text-danger pl-1">Da Pagare</b>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>

        <hr />

        {filePdf && props.isSuperDelegate && (
          <>
            <div className="row mt-2">
              <div className="col-12">
                <h5 className="card-title mb-2">Documentazione Delega</h5>
              </div>
            </div>
            <div className="row mt-2 mb-2">
              <div className="col-lg-2">
                <button
                  onClick={() => toggleModalImage(!isOpenImage)}
                  className="btn btn-xs btn-outline-primary"
                >
                  Visualizza Documenti
                </button>
              </div>
              <div className="col-lg-2">
                <button
                  onClick={() => toggleModalDelega(!isOpenDelega)}
                  className="btn btn-xs btn-outline-primary"
                >
                  Visualizza Delega
                </button>
              </div>

              {!isRichiediFirma && props.children.firmata == false ? (
                <div className="col-lg-8">
                  <span className="pr-4">
                    <svg className="icon icon-warning pb-1">
                      <use href={`${iconSprite}#it-warning-circle`}></use>
                    </svg>
                    <b className="text-warning">
                      Per poter evadere le pratiche devi prima richiedere la
                      firma della delega!
                    </b>
                  </span>
                </div>
              ) : (
                <>
                  {props.children.firmata == true ? (
                    <div className="col-lg-2">
                      <svg className="icon icon-success pb-1">
                        <use href={`${iconSprite}#it-check-circle`}></use>
                      </svg>
                      <b className="text-success">Delega Firmata!</b>
                    </div>
                  ) : (
                    <div className="col-lg-2">
                      <svg className="icon icon-success pb-1">
                        <use href={`${iconSprite}#it-check-circle`}></use>
                      </svg>
                      <b className="text-success">Firma In Attesa!</b>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}
        {props.children &&
          props.children.richiesteServizi.length > 0 &&
          props.children.richiesteServizi.map((x, index) => (
            <React.Fragment key={index}>
              {x.servizi.length != 0 && (
                <div>
                  <div className="card card-teaser rounded shadow mb-3">
                    <div className="card-body">
                      <div
                        className="row mb-1"
                        style={
                          x.ufficioConservatoria &&
                          x.tipoImmobile && { borderBottom: "inset" }
                        }
                      >
                        {x.ufficioConservatoria && (
                          <>
                            <div
                              className="col-lg-6 p-0"
                              style={{ borderRight: "inset" }}
                            >
                              <div className="row mb-1">
                                <h6>
                                  {" "}
                                  Dati richiesta conservatoria{" "}
                                  {(x.tipoNota || x.numeroRegistroGenerale) && (
                                    <>per nota:</>
                                  )}{" "}
                                </h6>
                                <div className="col-lg-12">
                                  Ufficio: <b>{x.ufficioConservatoria}</b>
                                </div>
                                <div className="col-lg-6">
                                  Registro:{" "}
                                  <b>
                                    {x.numeroRegistroParticolare ? (
                                      <>Particolare</>
                                    ) : (
                                      <>Generale</>
                                    )}
                                  </b>
                                </div>
                                {x.tipoNota && (
                                  <div className="col-lg-6">
                                    Tipo Nota:{" "}
                                    <b>
                                      {x.tipoNota == "0" ? (
                                        <>Trascrizioni</>
                                      ) : x.tipoNota == "" ? (
                                        <>Iscrizioni</>
                                      ) : (
                                        <>Annotazioni</>
                                      )}
                                    </b>
                                  </div>
                                )}
                                {x.numeroRegistroParticolare && (
                                  <div className="col-lg-6">
                                    N. Registro:{" "}
                                    <b>{x.numeroRegistroParticolare}</b>
                                  </div>
                                )}
                                {x.numeroRegistroGenerale && (
                                  <div className="col-lg-6">
                                    N. Registro:{" "}
                                    <b>{x.numeroRegistroGenerale}</b>
                                  </div>
                                )}
                                {x.annoRegistroGenerale && (
                                  <div className="col-lg-6">
                                    Anno Registro:{" "}
                                    <b>{x.annoRegistroGenerale}</b>
                                  </div>
                                )}
                                {x.annoRegistroParticolare && (
                                  <div className="col-lg-6">
                                    Anno Registro:{" "}
                                    <b>{x.annoRegistroParticolare}</b>
                                  </div>
                                )}
                                {x.motivazioneConservatoria && (
                                  <div className="col-lg-12">
                                    Motivazione:{" "}
                                    <b>{x.motivazioneConservatoria}</b>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}

                        {!x.cf_piva && !x.cognome && (
                          <>
                            <div
                              className="col-lg-6"
                              style={{ borderRight: "inset" }}
                            >
                              <div className="row mb-1">
                                <h6> Dati Immobile:</h6>

                                {x.tipoImmobile && (
                                  <div className="col-lg-12">
                                    Tipo Immobile:{" "}
                                    <b>
                                      {x.tipoImmobile &&
                                      x.tipoImmobile.descrizione
                                        ? x.tipoImmobile.descrizione
                                        : "-"}
                                    </b>
                                  </div>
                                )}

                                {x.mappale && x.foglio && (
                                  <div className="col-lg-12">
                                    Mappale: <b>{x.mappale} </b> Foglio:{" "}
                                    <b>{x.foglio}</b>
                                    {x.subalterno && (
                                      <>
                                        {" "}
                                        Subalterno: <b> {x.subalterno}</b>
                                      </>
                                    )}
                                  </div>
                                )}
                                {x.indirizzo && (
                                  <div className="col-lg-12">
                                    Indirizzo (Piano):{" "}
                                    <b>
                                      {x.indirizzo}
                                      {x.piano ? (
                                        <>{" (" + x.piano + ")"}</>
                                      ) : (
                                        <> </>
                                      )}
                                    </b>
                                  </div>
                                )}
                                {x.comune && (
                                  <div className="col-lg-12">
                                    Comune:{" "}
                                    <b>
                                      {x.comune ? x.comune : "-"}
                                      {x.provincia
                                        ? " (" + x.provincia + ")"
                                        : ""}
                                    </b>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}

                        {x.tipoImmobile == null && (
                          <>
                            <div className="col-lg-6 pl-2">
                              <h6> Dati del Proprietario:</h6>

                              <div className="row">
                                {(x.nome || x.cognome) && (
                                  <div className="col-lg-12">
                                    Nominativo:{" "}
                                    <b>
                                      {x.nome ? x.nome : " "}{" "}
                                      {x.cognome ? x.cognome : " "}
                                    </b>
                                  </div>
                                )}
                                {x.cf_piva && (
                                  <div className="col-lg-12">
                                    Codice Fiscale/PIva: <b>{x.cf_piva}</b>
                                  </div>
                                )}
                                {x.luogoNascita && (
                                  <div className="col-lg-6">
                                    Luogo di Nascita: <b>{x.luogoNascita}</b>
                                  </div>
                                )}
                                {x.dataNascita && x.cf_piva !== "" && (
                                  <div className="col-lg-6">
                                    Data di Nascita:{" "}
                                    <b>
                                      {x.dataNascita &&
                                      x.dataNascita != "01/01/0001"
                                        ? x.dataNascita
                                        : "-"}
                                    </b>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      {/* {x.ufficioConservatoria && (
                    <>
                      <div className="col-lg-12 pl-2 mt-4">
                        <div className="row">
                          <h6> Dati Richiesta Conservatoria:</h6>
                        </div>{" "}
                        <div className="row mb-2">
                          <div className="col-lg-6">
                            Ufficio Conservatoria:{" "}
                            <b>
                              {x.ufficioConservatoria
                                ? x.ufficioConservatoria
                                : " - "}
                            </b>
                          </div>
                          {x.tipoNota && (
                            <div className="col-lg-6">
                              Tipo Nota: <b>{x.tipoNota}</b>
                            </div>
                          )}
                          {x.numeroRegistroParticolare && (
                            <div className="col-lg-4">
                              N. Registro: <b>{x.numeroRegistroParticolare}</b>
                            </div>
                          )}
                          {x.numeroRegistro && (
                            <div className="col-lg-4">
                              N. Registro: <b>{x.numeroRegistro}</b>
                            </div>
                          )}
                          {x.annoRegistroGenerale && (
                            <div className="col-lg-4">
                              Anno Registro: <b>{x.annoRegistroGenerale}</b>
                            </div>
                          )}
                          {x.annoRegistroParticolare && (
                            <div className="col-lg-4">
                              Anno Registro: <b>{x.annoRegistroParticolare}</b>
                            </div>
                          )}
                          {x.motivazioneConservatoria && (
                            <div className="col-lg-4">
                              Motivazione: <b>{x.motivazioneConservatoria}</b>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )} */}

                      <div className="row mb-2 mt-4">
                        <div className="card-title pl-2">
                          <b>Servizi</b>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div
                          className="it-list-wrapper rounded border border-primary"
                          style={{ width: "100%" }}
                        >
                          <ul className="it-list">
                            {x.servizi &&
                              x.servizi.length > 0 &&
                              x.servizi.map((z, ind) => (
                                <div key={ind}>
                                  <li>
                                    <a>
                                      <div className="row it-right-zone">
                                        <div className="col-12">
                                          <div className=" py-3">
                                            <div className="row">
                                              <div className="text col-lg-3">
                                                <b>{z.nome}</b>
                                              </div>
                                              <div className="text col-lg-2">
                                                <div className="col-lg-12">
                                                  Imponibile:{" "}
                                                  <b>
                                                    {z.prezzoDTO.imponibile} €
                                                  </b>
                                                </div>
                                                <div className="col-lg-12">
                                                  Diritti:{" "}
                                                  <b>
                                                    {
                                                      z.prezzoDTO
                                                        .costoDirittiETributi
                                                    }{" "}
                                                    €
                                                  </b>
                                                </div>
                                                <div className="col-lg-12">
                                                  Iva:{" "}
                                                  <b>{z.prezzoDTO.imposta} €</b>
                                                </div>
                                              </div>
                                              {acceptedReq.find(
                                                (obj) => obj.idRichiesta == x.id
                                              ) &&
                                              acceptedReq.find(
                                                (obj) => obj.idRichiesta == x.id
                                              ).accepted ? (
                                                <>
                                                  <div className="text row col-lg-7">
                                                    <button
                                                      type="button"
                                                      className="btn btn-outline-primary py-2"
                                                      onClick={() => {
                                                        window.location.href =
                                                          baseUrlPdf +
                                                          "/api/SmartCatastoOperator/GetPdf?IdAllegato=" +
                                                          downloadFiles.find(
                                                            (obj) =>
                                                              obj.idRichiesta ==
                                                                x.id &&
                                                              obj.idServizio ==
                                                                z.id
                                                          ).idDownload;
                                                      }}
                                                      style={{ height: "50px" }}
                                                    >
                                                      <svg
                                                        className="icon icon-sm icon-primary"
                                                        aria-hidden="true"
                                                      >
                                                        <use
                                                          href={`${iconSprite}#it-download`}
                                                        ></use>
                                                      </svg>
                                                      Download allegato
                                                    </button>
                                                  </div>
                                                </>
                                              ) : (acceptedReq.find(
                                                  (obj) =>
                                                    obj.idRichiesta == x.id
                                                ) &&
                                                  acceptedReq.find(
                                                    (obj) =>
                                                      obj.idRichiesta == x.id
                                                  ).refused) ||
                                                x.motivazione ? (
                                                <></>
                                              ) : z.allegatoId ? (
                                                <>
                                                  <div className="text row col-lg-7">
                                                    <button
                                                      type="button"
                                                      className="btn btn-outline-primary py-2"
                                                      onClick={() => {
                                                        window.location.href =
                                                          baseUrlPdf +
                                                          "/api/SmartCatastoOperator/GetPdf?IdAllegato=" +
                                                          z.allegatoId;
                                                      }}
                                                      style={{ height: "50px" }}
                                                    >
                                                      <svg
                                                        className="icon icon-sm icon-primary"
                                                        aria-hidden="true"
                                                      >
                                                        <use
                                                          href={`${iconSprite}#it-download`}
                                                        ></use>
                                                      </svg>
                                                      Download allegato
                                                    </button>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="text row col-lg-7 justify-content-center align-items-center">
                                                    <div className="col-lg p-0">
                                                      {!(
                                                        (attachment &&
                                                          attachment.idServizio ==
                                                            z.id &&
                                                          attachment.idServizio ==
                                                            x.id) ||
                                                        (fileList &&
                                                          fileList.find(
                                                            (obj) =>
                                                              obj.idRichiesta ==
                                                                x.id &&
                                                              obj.idServizio ==
                                                                z.id
                                                          ))
                                                      ) ? (
                                                        <>
                                                          <div className="d-inline p-0">
                                                            <input
                                                              type="file"
                                                              accept="application/pdf"
                                                              name={
                                                                "upload|" +
                                                                x.id +
                                                                "|" +
                                                                z.id
                                                              }
                                                              id={
                                                                "upload|" +
                                                                x.id +
                                                                "|" +
                                                                z.id
                                                              }
                                                              className="upload"
                                                              onChange={(e) =>
                                                                handleChange(
                                                                  e,
                                                                  x,
                                                                  z.id
                                                                )
                                                              }
                                                            />
                                                            <label
                                                              htmlFor={
                                                                "upload|" +
                                                                x.id +
                                                                "|" +
                                                                z.id
                                                              }
                                                              className="btn btn-outline-primary active mb-0"
                                                              style={{
                                                                marginTop:
                                                                  "3px",
                                                              }}
                                                            >
                                                              <svg
                                                                className="icon icon-sm"
                                                                aria-hidden="true"
                                                              >
                                                                <use
                                                                  href={`${iconSprite}#it-upload`}
                                                                ></use>
                                                              </svg>
                                                              <span>
                                                                Upload
                                                              </span>
                                                            </label>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {!z.flagRichiestaDelega &&
                                                            !filePdf && (
                                                              <>
                                                                <button
                                                                  type="button"
                                                                  className="btn btn-outline-danger py-2"
                                                                  onClick={() =>
                                                                    removeAllegato(
                                                                      x.id,
                                                                      z.id
                                                                    )
                                                                  }
                                                                >
                                                                  <svg
                                                                    className="icon icon-sm icon-danger"
                                                                    aria-hidden="true"
                                                                  >
                                                                    <use
                                                                      href={`${iconSprite}#it-delete`}
                                                                    ></use>
                                                                  </svg>
                                                                  Elimina
                                                                </button>
                                                              </>
                                                            )}
                                                        </>
                                                      )}
                                                    </div>

                                                    {(attachment &&
                                                      attachment.idServizio ==
                                                        z.id &&
                                                      attachment.idServizio ==
                                                        x.id) ||
                                                    (fileList &&
                                                      fileList.find(
                                                        (obj) =>
                                                          obj.idRichiesta ==
                                                            x.id &&
                                                          obj.idServizio == z.id
                                                      )) ? (
                                                      <>
                                                        <div className="col-lg-8 d-inline">
                                                          <p className="d-inline">
                                                            <span className="sr-only">
                                                              Caricamento
                                                              immagine:
                                                            </span>

                                                            <b>
                                                              {fileList.find(
                                                                (obj) =>
                                                                  obj.idRichiesta ==
                                                                    x.id &&
                                                                  obj.idServizio ==
                                                                    z.id
                                                              )
                                                                ? fileList.find(
                                                                    (obj) =>
                                                                      obj.idRichiesta ==
                                                                        x.id &&
                                                                      obj.idServizio ==
                                                                        z.id
                                                                  ).name
                                                                : attachment.name}
                                                            </b>
                                                            <span className="upload-file-weight"></span>
                                                          </p>

                                                          {fileList.find(
                                                            (obj) =>
                                                              obj.idRichiesta ==
                                                                x.id &&
                                                              obj.idServizio ==
                                                                z.id
                                                          ) ? (
                                                            <>
                                                              <div className="progress mt-1">
                                                                <div
                                                                  className="progress-bar"
                                                                  role="progressbar"
                                                                  aria-valuenow="50"
                                                                  aria-valuemin="0"
                                                                  aria-valuemax="100"
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                ></div>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <div className="progress progress-indeterminate mt-1">
                                                                <div
                                                                  className="progress-bar"
                                                                  role="progressbar"
                                                                ></div>
                                                              </div>
                                                            </>
                                                          )}
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div className="col-lg-8 d-inline">
                                                          <svg
                                                            className="icon icon-sm text-danger mr-0"
                                                            aria-hidden="true"
                                                          >
                                                            <use
                                                              href={`${iconSprite}#it-file`}
                                                            ></use>
                                                          </svg>
                                                          <p className="d-inline">
                                                            <strong>
                                                              {" "}
                                                              Nessun file
                                                              selezionato
                                                            </strong>{" "}
                                                            <span className="upload-file-weight"></span>
                                                          </p>
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          {z.subServizio && (
                                            <div
                                              className="row col-lg-12 "
                                              style={{ paddingInline: "2%" }}
                                            >
                                              <div className="col-lg-12">
                                                <b>Servizi Aggiuntivi:</b>
                                              </div>
                                              <div className="col-lg-6">
                                                {z.subServizio.nome}
                                              </div>

                                              <div className="col-lg-6">
                                                <div className="row">
                                                  <div className="col-lg-4">
                                                    Imponibile:{" "}
                                                    <b>
                                                      {
                                                        z.subServizio.prezzoDTO
                                                          .imponibile
                                                      }{" "}
                                                      €
                                                    </b>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    Diritti:{" "}
                                                    <b>
                                                      {
                                                        z.subServizio.prezzoDTO
                                                          .costoDirittiETributi
                                                      }{" "}
                                                      €
                                                    </b>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    Iva:{" "}
                                                    <b>
                                                      {
                                                        z.subServizio.prezzoDTO
                                                          .imposta
                                                      }{" "}
                                                      €
                                                    </b>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </a>
                                  </li>
                                </div>
                              ))}
                            {!x.servizi && !x.servizi.length > 0 && (
                              <h5>Non sono presenti servizi!</h5>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="row text-right mt-2">
                        <div className="col-lg-6"></div>
                        {(acceptedReq.find((obj) => obj.idRichiesta == x.id) &&
                          acceptedReq.find((obj) => obj.idRichiesta == x.id)
                            .accepted) ||
                        (x.servizi.filter((elem) => elem.allegatoId == null)
                          .length == 0 &&
                          x.motivazione == null) ? (
                          <>
                            <div className="col-lg-6 text-success">
                              <b>Richiesta Elaborata</b>{" "}
                              <svg
                                className="icon icon-success"
                                aria-hidden="true"
                              >
                                <use href={`${iconSprite}#it-check`}></use>
                              </svg>
                            </div>
                          </>
                        ) : (acceptedReq.find(
                            (obj) => obj.idRichiesta == x.id
                          ) &&
                            acceptedReq.find((obj) => obj.idRichiesta == x.id)
                              .refused) ||
                          x.motivazione ? (
                          <>
                            <div className="col-lg-6 text-danger">
                              <b>Richiesta Rifiutata</b>{" "}
                              <svg
                                className="icon icon-danger"
                                aria-hidden="true"
                              >
                                <use href={`${iconSprite}#it-check`}></use>
                              </svg>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-lg-6">
                              <button
                                className="btn btn-success"
                                title="Conferma la richiesta dopo aver caricato tutti gli allegati."
                                onClick={() => checkUpload(x)}
                              >
                                Conferma
                              </button>
                              <button
                                className="btn btn-danger ml-2"
                                title="Segnala un errore nella richiesta fornendo una motivazione."
                                onClick={() => refuseRequest(x.id)}
                              >
                                Inserisci motivazione diniego
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
      </div>
      <div className="row col-lg-12 mt-4">
        <div className="col-lg-6"></div>
        {/* <div className="col-lg-6 text-right">
          {visibleButton == true ? (
            <button
              className="btn btn-primary mr-2"
              title="Completa la pratica."
              onClick={() => saveRequest(props.children.id)}
            >
              Salva
            </button>
          ) : (
            <button
              className="btn btn-primary mr-2 disabled  "
              style={{ cursor: "not-allowed" }}
              title="Il Pulsante è disabilitato finchè non completi tutti i servizi."
            >
              Salva
            </button>
          )}

          <button
            className="btn btn-info mr-2 ml-2"
            title="Salva le modifiche apportate e metti In Pausa la pratica."
            style={{ color: "#fff" }}
            onClick={() => pauseRequest(props.children.id)}
          >
            Richiesta in Pausa
          </button>
          {visibleButton == false ? (
            <button
              className="btn btn-danger ml-2"
              title="Rifiuta l'intera pratica."
              onClick={() => toggleModalRefuse(true)}
            >
              Rifiuta Pratica
            </button>
          ) : (
            <button
              className="btn btn-danger ml-2 disabled"
              title="Il pulsante si disattiva quando le richieste sono tutte state elaborate"
              style={{ cursor: "not-allowed" }}
            >
              Rifiuta Pratica
            </button>
          )}
        </div> */}
        <div className="col-lg-6 text-right">
          <button
            className="btn btn-primary mr-2"
            title={
              filePdf && !isRichiediFirma
                ? "Richiedi la firma della delega e completa la pratica"
                : "Completa la pratica."
            }
            // onClick={() => saveRequest(props.children.id)}
            onClick={() => toggleModalSend(true)}
            disabled={
              props.isSuperDelegate
                ? filePdf && props.children.firmata
                  ? isRichiediFirma
                    ? true
                    : false
                  : filePdf
                  ? true
                  : false
                : false
            }
          >
            Evadi pratica
          </button>
          <button
            className="btn btn-info mr-2 ml-2"
            title="Salva le modifiche apportate e metti In Pausa la pratica."
            style={{ color: "#fff" }}
            onClick={() => pauseRequest(props.children.id)}
          >
            Richiesta in Pausa
          </button>
          <button
            className="btn btn-danger ml-2"
            title="Rifiuta l'intera pratica."
            onClick={() => toggleModalRefuse(true)}
          >
            Rifiuta Pratica
          </button>
        </div>
      </div>
      {alertConfirm && (
        <>
          <div
            className="alert alert-danger"
            role="alert"
            style={{
              position: "fixed",
              bottom: 0,
              right: "15px",
              backgroundColor: "#fff",
              width: "22%",
              zIndex: 1000,
            }}
          >
            <b>
              E' necessario caricare un allegato per ogni servizio della
              richiesta.
            </b>
          </div>
        </>
      )}

      {alertNoConfirm && (
        <>
          <div
            className="alert alert-danger"
            role="alert"
            style={{
              position: "fixed",
              bottom: 0,
              right: "15px",
              backgroundColor: "#fff",
              width: "22%",
              zIndex: 1000,
            }}
          >
            <b>
              Non è possibile confermare la richiesta. Caricare tutti i file!
            </b>
          </div>
        </>
      )}

      <div>
        {" "}
        <Modal
          isOpen={isOpen}
          toggle={() => toggleModal(!isOpen)}
          size={"lg"}
          labelledBy="Modale1"
        >
          <ModalHeader id="Modale1">Fallimento Richiesta.</ModalHeader>
          <ModalBody>
            Specifica la motivazione per cui la richiesta viene rifiutata.
            <div className="mt-4">
              <textarea
                label="Motivazione..."
                rows="3"
                id="reason"
                name="reason"
                onChange={(e) => setReason(e.target.value.trim())}
              />
            </div>
            {validationReason && (
              <>
                <span className="text-danger mt-0">
                  La motivazione non può essere vuota e deve contenere almeno 10
                  caratteri.
                </span>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="info text-white text-center"
              onClick={() => {
                saveRefusedRequest();
              }}
            >
              Invia
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        {" "}
        <Modal
          isOpen={isOpenRefuse}
          toggle={() => toggleModalRefuse(!isOpenRefuse)}
          size={"lg"}
          labelledBy="Modale2"
        >
          <ModalHeader id="Modale2">Rifiuta Pratica</ModalHeader>
          <ModalBody>
            Sei sicuro di voler rifiutare l'intera pratica? <br />
            Confermando questa azione, le richieste incluse nelle pratica
            saranno automaticamente rifiutate e non sarà più possibile
            modificarle. <br /> <br />{" "}
            <strong> L'utente riceverà una mail </strong>contente le motivazioni
            di diniego se presenti.
            <br /> <strong>IL PROCESSO È IRREVERSIBILE.</strong>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success text-white text-center"
              onClick={() => refuseAllRequest()}
            >
              Si
            </Button>
            <Button
              color="danger text-white text-center"
              onClick={() => toggleModalRefuse(false)}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={isOpenSend}
          toggle={() => toggleModalSend(!isOpenSend)}
          size={"lg"}
          labelledBy="Modale2"
        >
          <ModalHeader id="Modale2">Evadi Pratica</ModalHeader>
          <ModalBody>
            Confermando questa azione, se non sono stati confermati o rifiutati
            tutti i servizi presenti nella richiesta, la pratica risulterà{" "}
            <strong> IN PAUSA </strong> e sarà possibile recuperarla
            dall'apposita sezione. <br />
            <br />
            Se invece tutti i servizi sono stati completati o rifiutati,
            <strong> l'utente riceverà una mail </strong>con i file caricati e
            le motivazioni di diniego se presenti.
            <br />{" "}
            <strong>Sei sicuro di voler evadere l'intera pratica?</strong>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success text-white text-center"
              onClick={() => saveRequest()}
            >
              Si
            </Button>
            <Button
              color="danger text-white text-center"
              onClick={() => toggleModalSend(!isOpenSend)}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <Modal
        isOpen={isOpenDelega}
        toggle={() => toggleModalDelega(!isOpenDelega)}
        size={"lg"}
        labelledBy="esempio1"
      >
        <ModalHeader id="esempio1">DELEGA</ModalHeader>
        <ModalBody>
          <object
            width="100%"
            height="900"
            data={filePdf && filePdf.data}
            type="application/pdf"
          ></object>
        </ModalBody>
        <ModalFooter>
          {!isRichiediFirma ||
          (!isRichiediFirma && props.children.firmata == true) ? (
            <Button
              onClick={() => {
                richiestaFirma(), setLoading(false);
              }}
              className="btn btn-success"
            >
              Richiedi Firma
            </Button>
          ) : (
            <>
              <svg className="icon icon-success pb-1">
                <use href={`${iconSprite}#it-check-circle`}></use>
              </svg>
              <b className="text-success">Firma In Attesa!</b>
            </>
          )}
          <Button
            color="primary"
            onClick={() => toggleModalDelega(!isOpenDelega)}
          >
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={isOpenImage}
        toggle={() => toggleModalImage(!isOpenImage)}
        size={"lg"}
        labelledBy="esempio1"
      >
        <ModalHeader id="esempio1">DOCUMENTI</ModalHeader>
        <ModalBody>
          <object
            width="50%"
            height="50%"
            data={fileDoc1 && fileDoc1.data}
            type={fileDoc1 && fileDoc1.type}
          ></object>
          <object
            width="50%"
            height="50%"
            data={fileDoc2 && fileDoc2.data}
            type={fileDoc2 && fileDoc2.type}
          ></object>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => toggleModalImage(!isOpenImage)}
          >
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
      {!loading && <Loader />}
    </>
  );
}
export default ListaRichiesteVisure;
