import React, { useEffect, useState } from "react";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Repo from "../Utility/Repo";
import { useLocation } from "react-router";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import jwt_decode from "jwt-decode";

function RichiesteOperatoreElaborate(props) {
  const baseUrlPdf = window.__RUNTIME_CONFIG__.REACT_APP_HUB_URL;
  let { search } = useLocation();
  const queryP = new URLSearchParams(search);
  const [token, setToken] = useState(props.token);
  const [listaRichieste, setListaRichieste] = useState();
  const [status, setStatus] = useState();
  const [acceptedReq, setAcceptedReq] = useState([]);
  const [downloadFiles, setDownloadFiles] = useState([]);

  const [isOpenImage, toggleModalImage] = useState(false);
  const [filePdf, setFilePdf] = useState();
  const [fileDoc1, setFileDoc1] = useState();
  const [fileDoc2, setFileDoc2] = useState();
  const [isOpenDelega, toggleModalDelega] = useState(false);

  useEffect(() => {
    var idPagamento = "";
    if (queryP.get("pagamentoId")) {
      idPagamento = queryP.get("pagamentoId");
    } else if (props.idPagamento) {
      idPagamento = props.idPagamento;
    }
    if (idPagamento) {
      var decoded = jwt_decode(token);

      if (decoded.role && decoded.role == "STATSVIEWER") {
        Repo.getRichiestaFromPagamentoStats(token, idPagamento)
          .then((response) => {
            setListaRichieste(response.data);
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status == 401) {
              props.deleteCoockieExpired();
            }
          });
      } else {
        Repo.getRichiestaFromPagamento(token, idPagamento)
          .then((response) => {
            setListaRichieste(response.data);
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status == 401) {
              props.deleteCoockieExpired();
            }
          });
      }
    }

    if (queryP.get("stato")) {
      setStatus(queryP.get("stato"));
    } else if (props.status) {
      setStatus(props.status);
    }
  }, []);

  useEffect(() => {
    if (listaRichieste && listaRichieste.fatturazioneCliente) {
      if (listaRichieste.fatturazioneCliente.delegaPDF) {
        var obj = document.createElement("object");
        obj.type = "application/pdf";
        obj.data =
          "data:application/pdf;base64," +
          listaRichieste.fatturazioneCliente.delegaPDF;
        setFilePdf(obj);
      }

      if (listaRichieste.fatturazioneCliente.fronteCarta) {
        var obj = document.createElement("object");
        obj.type = "image/jpg";
        obj.data =
          "data:image/jpg;base64," +
          listaRichieste.fatturazioneCliente.fronteCarta;
        setFileDoc1(obj);
      }

      if (listaRichieste.fatturazioneCliente.retroCarta) {
        var obj = document.createElement("object");
        obj.type = "image/jpg";
        obj.data =
          "data:image/jpg;base64," +
          listaRichieste.fatturazioneCliente.retroCarta;
        setFileDoc2(obj);
      }
    }
  }, [listaRichieste]);

  return (
    <>
      <div className="card card-teaser rounded shadow mb-3">
        <div className="card-body">
          {listaRichieste && (
            <div className="col-lg-12">
              <div className="row">
                <div className="col-12">
                  <p className="float-right">
                    Ordine N. {listaRichieste && listaRichieste.descrizione}
                  </p>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12">
                  <h2 className="card-title mb-3">Richiesta</h2>
                  <i>Tempi di Consegna: Entro 2 ore via Email</i>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h5 className="card-title mb-3">Dati di fatturazione</h5>
                </div>
              </div>
              <div className="row">
                {listaRichieste && listaRichieste.fatturazioneCliente.cf_piva && (
                  <div className="col-4">
                    Codice Fiscale/PIva:{" "}
                    <b>
                      {listaRichieste &&
                        listaRichieste.fatturazioneCliente.cf_piva}
                    </b>
                  </div>
                )}
                {listaRichieste && listaRichieste.fatturazioneCliente.nome && (
                  <div className="col-4">
                    Nome:{" "}
                    <b>
                      {listaRichieste &&
                        listaRichieste.fatturazioneCliente.nome}
                    </b>
                  </div>
                )}
                {listaRichieste &&
                  listaRichieste.fatturazioneCliente.cognomeDenominazione && (
                    <div className="col-4">
                      Cognome/Denominazione:{" "}
                      <b>
                        {listaRichieste &&
                          listaRichieste.fatturazioneCliente
                            .cognomeDenominazione}
                      </b>
                    </div>
                  )}
              </div>
              <div className="row">
                {listaRichieste && listaRichieste.fatturazioneCliente.nazione && (
                  <div className="col-4">
                    Nazione:{" "}
                    <b>
                      {listaRichieste &&
                        listaRichieste.fatturazioneCliente.nazione}
                    </b>
                  </div>
                )}
                {listaRichieste && listaRichieste.fatturazioneCliente.citta && (
                  <div className="col-4">
                    Citta:{" "}
                    <b>
                      {listaRichieste &&
                        listaRichieste.fatturazioneCliente.citta}{" "}
                      (
                      {listaRichieste &&
                        listaRichieste.fatturazioneCliente.provincia}
                      )
                    </b>
                  </div>
                )}
                {listaRichieste &&
                  listaRichieste.fatturazioneCliente.indirizzo && (
                    <div className="col-4">
                      Indirizzo:{" "}
                      <b>
                        {listaRichieste &&
                          listaRichieste.fatturazioneCliente.indirizzo}
                      </b>
                    </div>
                  )}
              </div>
              <div className="row">
                {listaRichieste && listaRichieste.fatturazioneCliente.email && (
                  <div className="col-4">
                    Email:{" "}
                    <b>
                      {listaRichieste &&
                        listaRichieste.fatturazioneCliente.email}
                    </b>
                  </div>
                )}
                {listaRichieste && listaRichieste.fatturazioneCliente.telefono && (
                  <div className="col-4">
                    Telefono:{" "}
                    <b>
                      {listaRichieste &&
                        listaRichieste.fatturazioneCliente.telefono}
                    </b>
                  </div>
                )}
              </div>
              <div className="row">
                {listaRichieste &&
                  listaRichieste.fatturazioneCliente.codiceSDI && (
                    <div className="col-4">
                      Codice SDI:{" "}
                      <b>
                        {listaRichieste &&
                          listaRichieste.fatturazioneCliente.codiceSDI}
                      </b>
                    </div>
                  )}
                {listaRichieste &&
                  listaRichieste.fatturazioneCliente.pecCessionario && (
                    <div className="col-4">
                      PEC:{" "}
                      <b>
                        {listaRichieste &&
                          listaRichieste.fatturazioneCliente.pecCessionario}
                      </b>
                    </div>
                  )}
              </div>
              <hr />

              <div className="row mb-2">
                <div className="col-lg-3"></div>
                <div className="col-lg-6">
                  <table className="customTable">
                    <tfoot>
                      <tr>
                        <td>Importo Totale</td>
                        <td>{listaRichieste.importoTotale.toFixed(2)} €</td>
                      </tr>
                    </tfoot>
                    <tbody>
                      <tr>
                        <td>Imponibile</td>
                        <td>{listaRichieste.importoPagato.toFixed(2)} €</td>
                      </tr>
                      <tr>
                        <td>Diritti</td>
                        <td>{listaRichieste.costoEDiritti.toFixed(2)} €</td>
                      </tr>
                      <tr>
                        <td>Iva</td>
                        <td>{listaRichieste.importoIva} €</td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="col-lg-12 mt-2 text-center">
                    {listaRichieste && listaRichieste.flagPagato ? (
                      <>
                        <>
                          <svg className="icon icon-success pb-1">
                            <use href={`${iconSprite}#it-check-circle`}></use>
                          </svg>
                          <b className="text-success pl-1">
                            Pagamento Effettuato
                          </b>
                        </>
                      </>
                    ) : (
                      <>
                        <svg className="icon icon-danger pb-1">
                          <use href={`${iconSprite}#it-warning-circle`}></use>
                        </svg>
                        <b className="text-danger pl-1">Da Pagare</b>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-lg-3"></div>
              </div>

              <hr />

              {props.isSuperDelegate && filePdf && (
                <>
                  <div className="row mt-2">
                    <div className="col-12">
                      <h5 className="card-title mb-2">Documentazione Delega</h5>
                    </div>
                  </div>
                  <div className="row mt-2 mb-2">
                    <div className="col-lg-2">
                      <button
                        onClick={() => toggleModalImage(!isOpenImage)}
                        className="btn btn-xs btn-outline-primary"
                      >
                        Visualizza Documenti
                      </button>
                    </div>
                    <div className="col-lg-2">
                      <button
                        onClick={() => toggleModalDelega(!isOpenDelega)}
                        className="btn btn-xs btn-outline-primary"
                      >
                        Visualizza Delega
                      </button>
                    </div>
                  </div>
                </>
              )}

              {listaRichieste &&
                listaRichieste.richiesteServizi.length > 0 &&
                listaRichieste.richiesteServizi.map((x, index) => (
                  <React.Fragment key={index}>
                    {x.servizi.length != 0 && (
                      <div>
                        <div className="card card-teaser rounded shadow mb-3">
                          <div className="card-body">
                            <div
                              className="row mb-1"
                              style={
                                x.ufficioConservatoria &&
                                x.tipoImmobile && { borderBottom: "inset" }
                              }
                            >
                              {x.ufficioConservatoria && (
                                <>
                                  <div
                                    className="col-lg-6 p-0"
                                    style={{ borderRight: "inset" }}
                                  >
                                    <div className="row mb-1">
                                      <h6>
                                        {" "}
                                        Dati richiesta conservatoria{" "}
                                        {(x.tipoNota ||
                                          x.numeroRegistroGenerale) && (
                                          <>per nota:</>
                                        )}{" "}
                                      </h6>
                                      <div className="col-lg-12">
                                        Ufficio: <b>{x.ufficioConservatoria}</b>
                                      </div>
                                      <div className="col-lg-6">
                                        Registro:{" "}
                                        <b>
                                          {x.numeroRegistroParticolare ? (
                                            <>Particolare</>
                                          ) : (
                                            <>Generale</>
                                          )}
                                        </b>
                                      </div>
                                      {x.tipoNota && (
                                        <div className="col-lg-6">
                                          Tipo Nota:{" "}
                                          <b>
                                            {x.tipoNota == "0" ? (
                                              <>Trascrizioni</>
                                            ) : x.tipoNota == "" ? (
                                              <>Iscrizioni</>
                                            ) : (
                                              <>Annotazioni</>
                                            )}
                                          </b>
                                        </div>
                                      )}
                                      {x.numeroRegistroParticolare && (
                                        <div className="col-lg-6">
                                          N. Registro:{" "}
                                          <b>{x.numeroRegistroParticolare}</b>
                                        </div>
                                      )}
                                      {x.numeroRegistroGenerale && (
                                        <div className="col-lg-6">
                                          N. Registro:{" "}
                                          <b>{x.numeroRegistroGenerale}</b>
                                        </div>
                                      )}
                                      {x.annoRegistroGenerale && (
                                        <div className="col-lg-6">
                                          Anno Registro:{" "}
                                          <b>{x.annoRegistroGenerale}</b>
                                        </div>
                                      )}
                                      {x.annoRegistroParticolare && (
                                        <div className="col-lg-6">
                                          Anno Registro:{" "}
                                          <b>{x.annoRegistroParticolare}</b>
                                        </div>
                                      )}
                                      {x.motivazioneConservatoria && (
                                        <div className="col-lg-12">
                                          Motivazione:{" "}
                                          <b>{x.motivazioneConservatoria}</b>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}

                              {!x.cf_piva && !x.cognome && (
                                <>
                                  <div
                                    className="col-lg-6"
                                    style={{ borderRight: "inset" }}
                                  >
                                    <div className="row mb-1">
                                      <h6> Dati Immobile:</h6>

                                      {x.tipoImmobile && (
                                        <div className="col-lg-12">
                                          Tipo Immobile:{" "}
                                          <b>
                                            {x.tipoImmobile &&
                                            x.tipoImmobile.descrizione
                                              ? x.tipoImmobile.descrizione
                                              : "-"}
                                          </b>
                                        </div>
                                      )}

                                      {x.mappale && x.foglio && (
                                        <div className="col-lg-12">
                                          Mappale: <b>{x.mappale} </b> Foglio:{" "}
                                          <b>{x.foglio}</b>
                                          {x.subalterno && (
                                            <>
                                              {" "}
                                              Subalterno: <b> {x.subalterno}</b>
                                            </>
                                          )}
                                        </div>
                                      )}
                                      {x.indirizzo && (
                                        <div className="col-lg-12">
                                          Indirizzo (Piano):{" "}
                                          <b>
                                            {x.indirizzo}
                                            {x.piano ? (
                                              <>{" (" + x.piano + ")"}</>
                                            ) : (
                                              <> </>
                                            )}
                                          </b>
                                        </div>
                                      )}
                                      {x.comune && (
                                        <div className="col-lg-12">
                                          Comune:{" "}
                                          <b>
                                            {x.comune ? x.comune : "-"}
                                            {x.provincia
                                              ? " (" + x.provincia + ")"
                                              : ""}
                                          </b>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}

                              {x.tipoImmobile == null && (
                                <>
                                  <div className="col-lg-6 pl-2">
                                    <h6> Dati del Proprietario:</h6>

                                    <div className="row">
                                      {(x.nome || x.cognome) && (
                                        <div className="col-lg-12">
                                          Nominativo:{" "}
                                          <b>
                                            {x.nome ? x.nome : " "}{" "}
                                            {x.cognome ? x.cognome : " "}
                                          </b>
                                        </div>
                                      )}
                                      {x.cf_piva && (
                                        <div className="col-lg-12">
                                          Codice Fiscale/PIva:{" "}
                                          <b>{x.cf_piva}</b>
                                        </div>
                                      )}
                                      {x.luogoNascita && (
                                        <div className="col-lg-6">
                                          Luogo di Nascita:{" "}
                                          <b>{x.luogoNascita}</b>
                                        </div>
                                      )}
                                      {x.dataNascita && x.cf_piva !== "" && (
                                        <div className="col-lg-6">
                                          Data di Nascita:{" "}
                                          <b>
                                            {x.dataNascita &&
                                            x.dataNascita != "01/01/0001"
                                              ? x.dataNascita
                                              : "-"}
                                          </b>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            <div className="row mb-2 mt-4">
                              <div className="card-title pl-2">
                                <b>Servizi</b>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div
                                className="it-list-wrapper rounded border border-primary"
                                style={{ width: "100%" }}
                              >
                                <ul className="it-list">
                                  {x.servizi &&
                                    x.servizi.length > 0 &&
                                    x.servizi.map((z, ind) => (
                                      <div key={ind}>
                                        <li>
                                          <a>
                                            <div className="row it-right-zone">
                                              <div className="col-12">
                                                <div className=" py-3">
                                                  <div className="row">
                                                    <div className="text col-lg-3">
                                                      <b>{z.nome}</b>
                                                    </div>
                                                    <div className="text col-lg-2">
                                                      <div className="col-lg-12">
                                                        Imponibile:{" "}
                                                        <b>
                                                          {
                                                            z.prezzoDTO
                                                              .imponibile
                                                          }{" "}
                                                          €
                                                        </b>
                                                      </div>
                                                      <div className="col-lg-12">
                                                        Diritti:{" "}
                                                        <b>
                                                          {
                                                            z.prezzoDTO
                                                              .costoDirittiETributi
                                                          }{" "}
                                                          €
                                                        </b>
                                                      </div>
                                                      <div className="col-lg-12">
                                                        Iva:{" "}
                                                        <b>
                                                          {z.prezzoDTO.imposta}{" "}
                                                          €
                                                        </b>
                                                      </div>
                                                    </div>
                                                    {acceptedReq.find(
                                                      (obj) =>
                                                        obj.idRichiesta == x.id
                                                    ) &&
                                                    acceptedReq.find(
                                                      (obj) =>
                                                        obj.idRichiesta == x.id
                                                    ).accepted ? (
                                                      <>
                                                        <div className="text row col-lg-7">
                                                          <button
                                                            type="button"
                                                            className="btn btn-outline-primary py-2"
                                                            onClick={() => {
                                                              window.location.href =
                                                                baseUrlPdf +
                                                                "/api/SmartCatastoOperator/GetPdf?IdAllegato=" +
                                                                downloadFiles.find(
                                                                  (obj) =>
                                                                    obj.idRichiesta ==
                                                                      x.id &&
                                                                    obj.idServizio ==
                                                                      z.id
                                                                ).idDownload;
                                                            }}
                                                            style={{
                                                              height: "50px",
                                                            }}
                                                          >
                                                            <svg
                                                              className="icon icon-sm icon-primary"
                                                              aria-hidden="true"
                                                            >
                                                              <use
                                                                href={`${iconSprite}#it-download`}
                                                              ></use>
                                                            </svg>
                                                            Download allegato
                                                          </button>
                                                        </div>
                                                      </>
                                                    ) : (acceptedReq.find(
                                                        (obj) =>
                                                          obj.idRichiesta ==
                                                          x.id
                                                      ) &&
                                                        acceptedReq.find(
                                                          (obj) =>
                                                            obj.idRichiesta ==
                                                            x.id
                                                        ).refused) ||
                                                      x.motivazione ? (
                                                      <></>
                                                    ) : (
                                                      z.allegatoId && (
                                                        <>
                                                          <div className="text row col-lg-7">
                                                            <button
                                                              type="button"
                                                              className="btn btn-outline-primary py-2"
                                                              onClick={() => {
                                                                window.location.href =
                                                                  baseUrlPdf +
                                                                  "/api/SmartCatastoOperator/GetPdf?IdAllegato=" +
                                                                  z.allegatoId;
                                                              }}
                                                              style={{
                                                                height: "50px",
                                                              }}
                                                            >
                                                              <svg
                                                                className="icon icon-sm icon-primary"
                                                                aria-hidden="true"
                                                              >
                                                                <use
                                                                  href={`${iconSprite}#it-download`}
                                                                ></use>
                                                              </svg>
                                                              Download allegato
                                                            </button>
                                                          </div>
                                                        </>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-12">
                                                {z.subServizio && (
                                                  <div
                                                    className="row col-lg-12 "
                                                    style={{
                                                      paddingInline: "2%",
                                                    }}
                                                  >
                                                    <div className="col-lg-12">
                                                      <b>Servizi Aggiuntivi:</b>
                                                    </div>
                                                    <div className="col-lg-6">
                                                      {z.subServizio.nome}
                                                    </div>

                                                    <div className="col-lg-6">
                                                      <div className="row">
                                                        <div className="col-lg-4">
                                                          Imponibile:{" "}
                                                          <b>
                                                            {
                                                              z.subServizio
                                                                .prezzoDTO
                                                                .imponibile
                                                            }{" "}
                                                            €
                                                          </b>
                                                        </div>
                                                        <div className="col-lg-4">
                                                          Diritti:{" "}
                                                          <b>
                                                            {
                                                              z.subServizio
                                                                .prezzoDTO
                                                                .costoDirittiETributi
                                                            }{" "}
                                                            €
                                                          </b>
                                                        </div>
                                                        <div className="col-lg-4">
                                                          Iva:{" "}
                                                          <b>
                                                            {
                                                              z.subServizio
                                                                .prezzoDTO
                                                                .imposta
                                                            }{" "}
                                                            €
                                                          </b>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </a>
                                        </li>
                                      </div>
                                    ))}
                                  {!x.servizi && !x.servizi.length > 0 && (
                                    <h5>Non sono presenti servizi!</h5>
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div className="row text-right mt-2">
                              <div className="col-lg-8"></div>
                              {(acceptedReq.find(
                                (obj) => obj.idRichiesta == x.id
                              ) &&
                                acceptedReq.find(
                                  (obj) => obj.idRichiesta == x.id
                                ).accepted) ||
                              (x.servizi.filter(
                                (elem) => elem.allegatoId == null
                              ).length == 0 &&
                                x.motivazione == null) ? (
                                <>
                                  <div className="col-lg-4 text-success">
                                    <b>Richiesta Elaborata</b>{" "}
                                    <svg
                                      className="icon icon-success"
                                      aria-hidden="true"
                                    >
                                      <use
                                        href={`${iconSprite}#it-check`}
                                      ></use>
                                    </svg>
                                  </div>
                                </>
                              ) : (
                                (acceptedReq.find(
                                  (obj) => obj.idRichiesta == x.id
                                ) &&
                                  acceptedReq.find(
                                    (obj) => obj.idRichiesta == x.id
                                  ).refused) ||
                                (x.motivazione && (
                                  <>
                                    <div className="col-lg-4 text-danger">
                                      <b>Richiesta Rifiutata</b>{" "}
                                      <svg
                                        className="icon icon-danger"
                                        aria-hidden="true"
                                      >
                                        <use
                                          href={`${iconSprite}#it-check`}
                                        ></use>
                                      </svg>
                                    </div>
                                  </>
                                ))
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </div>
          )}

          {status == "RIFIUTATO" ? (
            <div className="row">
              <div className="col-lg-12 text-right text-danger">
                <svg className="icon icon-danger pb-1">
                  <use href={`${iconSprite}#it-close-circle`}></use>
                </svg>
                <span className="text-danger">
                  <b>RIFIUTATO</b>
                </span>
              </div>
            </div>
          ) : (
            status == "EVASA" && (
              <div className="row">
                <div className="col-lg-12 text-right text-danger">
                  <svg className="icon icon-success pb-1">
                    <use href={`${iconSprite}#it-check-circle`}></use>
                  </svg>
                  <span className="text-success">
                    <b>EVASO</b>
                  </span>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <Modal
        isOpen={isOpenImage}
        toggle={() => toggleModalImage(!isOpenImage)}
        size={"lg"}
        labelledBy="esempio1"
      >
        <ModalHeader id="esempio1">DOCUMENTI</ModalHeader>
        <ModalBody>
          <object
            width="50%"
            height="50%"
            data={fileDoc1 && fileDoc1.data}
            type={fileDoc1 && fileDoc1.type}
          ></object>
          <object
            width="50%"
            height="50%"
            data={fileDoc2 && fileDoc2.data}
            type={fileDoc2 && fileDoc2.type}
          ></object>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => toggleModalImage(!isOpenImage)}
          >
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={isOpenDelega}
        toggle={() => toggleModalDelega(!isOpenDelega)}
        size={"lg"}
        labelledBy="esempio1"
      >
        <ModalHeader id="esempio1">DELEGA</ModalHeader>
        <ModalBody>
          <object
            width="100%"
            height="900"
            data={filePdf && filePdf.data}
            type="application/pdf"
          ></object>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => toggleModalDelega(!isOpenDelega)}
          >
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
export default RichiesteOperatoreElaborate;
