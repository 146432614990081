import { createRef, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import Select from "../Objects/Select";
import moment from "moment";
import Repo from "../Utility/Repo";
import useLocalStorage from "../Utility/useLocalStorage";
import ListaRichiesteCoda from "../Objects/ListaRichiesteCoda";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import UploadFile from "../Objects/UploadFile";
import SoggettoForm from "../Objects/SoggettoForm";
import MetaTags from "react-meta-tags";
import Loader from "../Utility/Loader";

function VisuraCatastale(props) {
  const collapseRef = createRef();
  const [token, setToken] = useState(props.token);
  const [pagato, setPagato] = useState(props.pagato);
  const [validation, setValidation] = useState(false);
  const [validationStep, setValidationStep] = useState(false);
  const [validationCF, setValidationCF] = useState(false);
  const [validationIVA, setValidationIVA] = useState(false);
  const [validationImmobile, setValidationImmobile] = useState(true);
  const [validationFatturazione, setValidationFatturazione] = useState(true);
  const [validationDelega, setValidationDelega] = useState(true);
  const [isOpen, toggleModal] = useState(false);
  const [isOpenInfoSubServizio, toggleModalInfoSubServizio] = useState(false);
  const [pageNext, setPageNext] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [immobileTemp, setImmobileTemp] = useState();
  const [serviziTemp, setServiziTemp] = useState([]);
  const [ordiniNonPagati, setOrdiniNonPagati] = useState([]);
  const [isDelega, setIsDelega] = useState(false);
  const [linkDelega, setLinkDelega] = useState();
  const [uploadDelega, setUploadDelega] = useState(false);
  const [listaProvince, setListaProvince] = useState([]);
  const [selectedProvincia, setSelectedProvincia] = useState();
  const [listaComuni, setListaComuni] = useState([]);
  const [selectedComune, setSelectedComune] = useState();
  const [checkPrivate, setCheckPrivate] = useState(false);
  const [checkSoggetto, setCheckSoggetto] = useState(false);
  const [checkSoggettoText, setCheckSoggettoText] = useState("IMMOBILE");
  const [backgroundColorF, setBackgroundColorF] = useState("transparent");
  const [textColorF, setTextColorF] = useState("#435a70");
  const [backgroundColorG, setBackgroundColorG] = useState("transparent");
  const [textColorG, setTextColorG] = useState("#435a70");
  const [backgroundColorFSoggetto, setBackgroundColorFSoggetto] =
    useState("transparent");
  const [textColorFSoggetto, setTextColorFSoggetto] = useState("#435a70");
  const [backgroundColorGSoggetto, setBackgroundColorGSoggetto] =
    useState("transparent");
  const [textColorGSoggetto, setTextColorGSoggetto] = useState("#435a70");

  const [loading, setLoading] = useState(true);

  const [listaRichiesteDescrizione, setListaRichiesteDescrizione] = useState(
    []
  );

  const [descSubServizio, setDescSubServizio] = useState("");

  const [scroll, setScroll] = useState(false);
  const obb = {
    provincia: "",
    comune: "",
    mappale: "",
    foglio: "",
    //particella: "",
    subalterno: "",
    indirizzo: "",
    piano: "",
    cf_piva: "",
    cognome: "",
    nome: "",
    dataNascita: "",
    luogoNascita: "",
    indirizzoSede: "",
  };

  const [data, setData] = useState(obb);

  const [servizi, setServizi] = useState([]);

  const [immobili, setImmobili] = useState([]);

  const delRichiestaOrdine = (idRichiesta) => {
    Repo.delRichiestaFromOrdine(token, idRichiesta)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        window.location.reload();
        console.log(error);
      });
  };

  const getPdf = async () => {
    Repo.getLinkDelega(token)
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "delega.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(false);
    Repo.getListaServizi(token, 0)
      .then((response) => {
        setServizi(response.data);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });

    Repo.getListaImmobili(token)
      .then((response) => {
        setImmobili(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    if (token) {
      Repo.getUserRiepilogoRichieste(token)
        .then((response) => {
          setListaRichiesteDescrizione(response.data);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });

      Repo.getOrdini(token, pagato)
        .then((response) => {
          setOrdiniNonPagati(response.data);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  }, []);

  useEffect(() => {
    let tempData = [];
    setIsDelega(false);
    if (serviziTemp && serviziTemp.length > 0) {
      serviziTemp.map((x) => {
        tempData.push(x.id);
        if (x.flagUpload == "true") {
          setIsDelega(true);
        }
      });
    }
  }, [serviziTemp]);

  const handleChangeCheckBox = (event, subServizio) => {
    if (event) {
      let flagUpload = event.target.value;
      let name = event.target.name;
      let id = event.target.id;
      let checked = event.target.checked;

      switch (name) {
        case "servizi":
          if (checked) {
            setServiziTemp((prevState) => [
              ...prevState,
              {
                id: id,
                flagUpload: flagUpload,
              },
            ]);

            if (subServizio && subServizio.flagDefaultAttivoSelezionePadre) {
              setServiziTemp((prevState) => [
                ...prevState,
                {
                  id: subServizio.id,
                  flagUpload: subServizio.flagRichiestaDelega.toString(),
                },
              ]);
            }

            break;
          } else {
            if (subServizio) {
              var arr = serviziTemp.filter(
                (item) => item.id !== subServizio.id && item.id !== id
              );
            } else {
              var arr = serviziTemp.filter((item) => item.id !== id);
            }

            setServiziTemp(arr);

            break;
          }
        default:
          break;
      }
    }
  };

  useEffect(() => {
    setSelectedProvincia(null);
    Repo.getProvince(token)
      .then((response) => {
        setListaProvince(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const reloadPage = () => {
    window.location.reload();
  };

  const loggedDelega = () => {
    var result = listaRichiesteDescrizione.find((obj) => {
      return obj.isDelega === true;
    });

    if ((result && result.isDelega === true) || isDelega) {
      window.location.href = props.subfolder + "/Delega";
    } else {
      window.location.href = props.subfolder + "/Fatturazione";
    }
  };

  const handleChangeSelect = (event, nameOfComponent) => {
    if (event) {
      let name = nameOfComponent.name;
      let value = event.value;
      let label = event.label;

      switch (name) {
        case "tipoImmobile":
          setImmobileTemp(label);

          setData((prevState) => ({
            ...prevState,
            tipoImmobileId: parseInt(value),
          }));
          break;

        default:
          break;
      }
    } else {
      let name = nameOfComponent.name;
      switch (name) {
        case "tipoImmobile":
          setData((prevState) => ({
            ...prevState,
            tipoImmobileId: null,
          }));
          break;

        default:
          break;
      }
    }
  };

  const handleChange = (event, num) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case "provincia":
        setData((prevState) => ({
          ...prevState,
          provincia: value,
        }));

        break;

      case "comuneSede":
        setData((prevState) => ({
          ...prevState,
          comuneSede: value,
        }));

        break;

      case "comune":
        setData((prevState) => ({
          ...prevState,
          comune: value,
        }));

        break;

      case "mappale":
        setData((prevState) => ({
          ...prevState,
          mappale: value,
        }));

        break;

      case "foglio":
        setData((prevState) => ({
          ...prevState,
          foglio: value,
        }));

        break;

      // case "particella":
      //   setData((prevState) => ({
      //     ...prevState,
      //     particella: value,
      //   }));

      //   break;

      case "subalterno":
        setData((prevState) => ({
          ...prevState,
          subalterno: value,
        }));
        break;

      case "indirizzo":
        setData((prevState) => ({
          ...prevState,
          indirizzo: value,
        }));
        break;

      case "indirizzoSede":
        setData((prevState) => ({
          ...prevState,
          indirizzoSede: value,
        }));
        break;

      case "piano":
        setData((prevState) => ({
          ...prevState,
          piano: value,
        }));
        break;

      case "cf_piva":
        setData((prevState) => ({
          ...prevState,
          cf_piva: num,
        }));
        break;

      case "cognome":
        setData((prevState) => ({
          ...prevState,
          cognome: value,
        }));
        break;

      case "nome":
        setData((prevState) => ({
          ...prevState,
          nome: value,
        }));
        break;

      case "dataNascita":
        setData((prevState) => ({
          ...prevState,
          dataNascita: value,
        }));
        break;

      case "luogoNascita":
        setData((prevState) => ({
          ...prevState,
          luogoNascita: value,
        }));
        break;

      default:
        break;
    }
  };

  const changeProvincia = (event) => {
    setSelectedProvincia({
      value: event.value,
      label: event.label,
    });
    Repo.getComuni(token, event.value)
      .then((response) => {
        setListaComuni(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeComune = (event) => {
    setSelectedComune({
      value: event.value,
      label: event.label,
    });
  };

  useEffect(() => {
    if (!checkPrivate) {
      setBackgroundColorF("#2e58a6");
      setTextColorF("white");
      setBackgroundColorG("transparent");
      setTextColorG("#435a70");
    } else {
      setBackgroundColorF("transparent");
      setTextColorF("#435a70");
      setBackgroundColorG("#2e58a6");
      setTextColorG("white");
    }

    if (!checkPrivate) {
      setData((prevState) => ({
        ...prevState,
        cf_piva: "",
        cognome: "",
        nome: "",
        luogoNascita: "",
        dataNascita: null,
      }));
    } else {
      setData((prevState) => ({
        ...prevState,
        cf_piva: "",
        cognome: "",
        indirizzoSede: "",
        luogoNascita: "",
        dataNascita: null,
      }));
    }

    setValidationFatturazione(false);
    setValidationStep(false);
  }, [checkPrivate]);

  useEffect(() => {
    if (!checkSoggetto) {
      setBackgroundColorFSoggetto("#2e58a6");
      setTextColorFSoggetto("white");
      setBackgroundColorGSoggetto("transparent");
      setTextColorGSoggetto("#435a70");
    } else {
      setBackgroundColorFSoggetto("transparent");
      setTextColorFSoggetto("#435a70");
      setBackgroundColorGSoggetto("#2e58a6");
      setTextColorGSoggetto("white");
    }

    if (checkSoggetto) {
      setData((prevState) => ({
        ...prevState,
        provincia: "",
        comune: "",
        mappale: "",
        foglio: "",
        //particella: "",
        subalterno: "",
        indirizzo: "",
        piano: "",
      }));
      setSelectedComune();
      setSelectedProvincia();
      setImmobileTemp();
    } else {
    }

    setServiziTemp([]);
  }, [checkSoggetto]);

  function getBase64(file) {
    return new Promise(function (resolve) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log(error);
      };
    });
  }

  const sendForm = async () => {
    setValidation(true);
    setValidationFatturazione(true);
    setValidationStep(true);

    let passedValidation = true;
    let tempData = data;

    if (serviziTemp.length == 0 || !serviziTemp) {
      passedValidation = false;
    }

    tempData["flagRichiestaDelega"] = false;
    tempData["idServizi"] = [];

    serviziTemp.map((x) => {
      tempData["idServizi"].push(x.id);
      if (x.flagUpload == true) {
        tempData["flagRichiestaDelega"] = true;
      }
    });

    // if (uploadDelega) {
    //   var base64File = await getBase64(uploadDelega);
    //   var splitBase64 = base64File.split(",");
    //   tempData["file"] = splitBase64[1];
    // }

    if (checkSoggetto) {
      if (checkPrivate) {
        setValidationIVA(true);
        if (!data.cf_piva && checkPrivate) {
          if (!data.cognome || !data.indirizzoSede || !data.luogoNascita) {
            passedValidation = false;
            setValidationFatturazione(false);
          }
        } else if (data.cf_piva.length != 11) {
          setValidationIVA(false);
          passedValidation = false;
        }
      } else if (!checkPrivate) {
        setValidationCF(true);
        if (!data.cf_piva && !checkPrivate) {
          if (
            !data.cognome ||
            !data.nome ||
            !data.dataNascita ||
            !data.luogoNascita
          ) {
            passedValidation = false;
            setValidationFatturazione(false);
          }
        } else if (data.cf_piva.length != 16) {
          setValidationCF(false);
          passedValidation = false;
        }
      }
    } else {
      setValidationImmobile(true);
      setValidationDelega(true);

      if (
        selectedProvincia &&
        selectedProvincia.value &&
        selectedComune &&
        selectedComune.label
      ) {
        data.provincia = selectedProvincia.value;
        data.comune = selectedComune.label;
      }

      // if (!uploadDelega && isDelega) {
      //   checkTemp = false;
      //   setValidationDelega(false);
      // }

      if (!data.provincia) passedValidation = false;
      if (!data.comune) passedValidation = false;

      // Identificazione dell'immobile tramite i dati catastali o tramite l'indirizzo
      if (!data.indirizzo) {
        if (!data.mappale || !data.foglio || !data.subalterno) {
          passedValidation = false;
          setValidationImmobile(false);
        }
      }

      if (!immobileTemp) passedValidation = false;
    }

    if (passedValidation) {
      var dataJson = JSON.stringify(tempData);

      if (!token) {
        Repo.postRichiesta(token, dataJson)
          .then((response) => {
            var query = response.data.fatturazioneClienteId;
            if (response.status == 200) {
              if (isDelega) {
                window.location.href =
                  props.subfolder + "/Delega?query=" + query;
              } else {
                window.location.href =
                  props.subfolder + "/Fatturazione?query=" + query;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        Repo.postUserRichiesta(token, dataJson)
          .then((response) => {
            if (response.status == 200) {
              toggleModal(!isOpen);
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status == 401) {
              props.deleteCoockieExpired();
            }
          });
      }
    } else {
      setScroll(true);
    }
  };

  useEffect(() => {
    if (document.querySelector(".error_div")) {
      document.querySelector(".error_div").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollBy(0, -500);
      setScroll(false);
    }
  }, [scroll]);

  const selectForm = () => {
    switch (checkSoggetto) {
      case true:
        return (
          <>
            <SoggettoForm
              token={token}
              setData={setData}
              dataObj={data}
              checkPrivate={checkPrivate}
              setCheckPrivate={setCheckPrivate}
              setValidationStep={setValidationStep}
              validationStep={validationStep}
              setValidationFatturazione={setValidationFatturazione}
              validationFatturazione={validationFatturazione}
              validationCF={validationCF}
              validationIVA={validationIVA}
            ></SoggettoForm>
          </>
        );
        break;

      default:
        return "";
        break;
    }
  };

  const serviziDelega = (flag) => {
    let type;

    switch (checkSoggetto) {
      case false:
        type = "IMMOBILE";
        break;
      case true:
        type = "SOGGETTO";
        break;
      default:
        break;
    }

    return (
      <>
        <MetaTags>
          <title>Catasto</title>
          <meta property="og:title" content="La tua visura catastale online" />
          <meta
            property="og:description"
            content="Acquisto visura catastale per privati e imprese online in maniera semplice e veloce. Documenti disponibili in pochi minuti online."
          />
          <meta
            name="description"
            content="Acquisto visura catastale per privati e imprese online in maniera semplice e veloce. Documenti disponibili in pochi minuti online."
          />
          <meta
            name="keywords"
            content="richiesta, visura,catastale, richiesta visura catastale, richiesta, visure, catastali, richiesta visure catastali, acquisto, acquistare, visura, catastale, catasto"
          />
        </MetaTags>
        {servizi &&
          servizi.length > 0 &&
          servizi
            .filter((servizi) => servizi.tipologiaServizio == type)
            .filter((servizi) => servizi.flagRichiestaDelega == flag)
            .map((x, index) => (
              <div key={x.id} className="form-check col-lg-6 text-center">
                <div
                  className="card-wrapper card-space"
                  style={{ minHeight: "200px", height: "fit-content" }}
                >
                  <div
                    className="card card-teaser rounded shadow p-2"
                    style={{
                      border: "1px solid rgba(46, 88, 166, 0.35)",
                      backgroundColor: "#c4dcf5",
                    }}
                  >
                    <div className="card-body pb-0">
                      <h5 className="card-title mb-1">{x.nome}</h5>
                      <p
                        className="card-text mt-2"
                        style={{ minHeight: "90px" }}
                      >
                        {x.descrizione}
                      </p>
                      {x.tempoConsegna && (
                        <p>
                          <b>Consegna in {x.tempoConsegna} minuti</b>
                        </p>
                      )}
                      <div className="row card-text">
                        <div
                          className="col-lg-6"
                          style={{
                            paddingTop: "5px",
                            marginTop: "auto",
                          }}
                        >
                          Prezzo (IVA e Tributi INCLUSI):{" "}
                          <b>
                            {x.prezzoUnitario &&
                              x.prezzoDTO.totale.toFixed(2) + " € "}
                          </b>
                        </div>
                        <div className="col-lg-6">
                          <input
                            value={x.flagRichiestaDelega}
                            name="servizi"
                            id={x.id}
                            {...(serviziTemp &&
                            serviziTemp.length > 0 &&
                            serviziTemp.find((y) => y.id == x.id)
                              ? {
                                  checked: true,
                                }
                              : { checked: false })}
                            type="checkbox"
                            onChange={(e) =>
                              handleChangeCheckBox(
                                e,
                                x.subServizio && x.subServizio
                              )
                            }
                            data-toggle="collapse"
                            data-target={"#collapseSubServizi-" + x.id}
                            aria-expanded="false"
                            aria-controls={"collapseSubServizi-" + x.id}
                          />
                          <label htmlFor={x.id}>Seleziona servizio</label>
                        </div>

                        {x.subServizio && (
                          <div
                            className="collapse mb-2 px-2 pt-2"
                            id={"collapseSubServizi-" + x.id}
                          >
                            <hr className="hrCustom" />
                            <a className="mb-2 pt-2">
                              Scegli il servizio aggiuntivo:
                            </a>
                            <h6 className="card-title mb-1">
                              {x.subServizio.nome}
                              <svg
                                className="icon ml-3"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setDescSubServizio(x.subServizio.descrizione);
                                  toggleModalInfoSubServizio(true);
                                }}
                              >
                                <use
                                  href={`${iconSprite}#it-info-circle`}
                                ></use>
                              </svg>
                            </h6>
                            <div className="row px-4">
                              <div className="col-lg-6">
                                Prezzo (IVA e Tributi INCLUSI):{" "}
                                <b>
                                  {x.subServizio.prezzoDTO.totale.toFixed(2) +
                                    "€"}
                                </b>
                              </div>
                              <div className="col-lg-6">
                                <input
                                  id={x.subServizio.id}
                                  value={x.subServizio.flagRichiestaDelega}
                                  name="servizi"
                                  type="checkbox"
                                  onChange={handleChangeCheckBox}
                                  {...(serviziTemp &&
                                  serviziTemp.length > 0 &&
                                  serviziTemp.find(
                                    (y) => y.id == x.subServizio.id
                                  )
                                    ? {
                                        checked: true,
                                      }
                                    : { checked: false })}
                                />
                                <label htmlFor={x.subServizio.id}>
                                  Seleziona servizio
                                </label>
                              </div>
                            </div>
                            {serviziTemp &&
                            serviziTemp.length > 0 &&
                            serviziTemp.find(
                              (y) => y.id == x.subServizio.id
                            ) ? (
                              <></>
                            ) : (
                              <a className="text-danger">
                                {x.subServizio.alertText}
                              </a>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
      </>
    );
  };

  return (
    <>
      {ordiniNonPagati && ordiniNonPagati.length > 0 && (
        <>
          <Link to="/ordini_sospeso" style={{ textDecoration: "none" }}>
            <div className="container test-docs">
              <div className="row mb-4">
                <div
                  className="notification with-icon error d-block"
                  role="alert"
                  aria-labelledby="not2b-title"
                  style={{
                    position: "static",
                    transform: "none",
                    width: "100%",
                  }}
                >
                  <h5 id="not2b-title" className="mb-3">
                    <svg className="icon">
                      <use href={`${iconSprite}#it-close-circle`}></use>
                    </svg>
                    Ordini non Pagati
                  </h5>
                  <div className="ml-3">
                    <span className="pl-3">
                      Ci sono <b>{ordiniNonPagati.length}</b> ordini non ancora
                      pagati. Per procedere al pagamento, andare nel carrello.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </>
      )}
      {listaRichiesteDescrizione && listaRichiesteDescrizione.length > 0 && (
        <>
          <ListaRichiesteCoda
            subfolder={props.subfolder}
            delRichiesta={delRichiestaOrdine}
          >
            {listaRichiesteDescrizione}
          </ListaRichiesteCoda>
        </>
      )}

      <div className="card card-teaser rounded shadow mb-4">
        <div className="card-body">
          <h4 className="card-title mb-2">Catasto</h4>
          <div className="row">
            <div className="col-12 text-center">
              <b style={{ color: "rgb(46, 88, 166)" }}>
                Le pratiche saranno evase da lunedì a venerdì dalle ore 9.00
                alle ore 18.00
              </b>
            </div>
          </div>
          <div className="col-lg-12 mb-5 mt-2">
            <div className="row form-group px-5 py-2 borderCard">
              <div
                className="row col-lg-4 border border-primary rounded mb-1 mt-3 text-center ml-1 p-0 align-items-center"
                style={{ height: "40px" }}
              >
                <div
                  className="col-6 p-0 border-right border-primary rounded pt-1"
                  style={{
                    backgroundColor: backgroundColorFSoggetto,
                    color: textColorFSoggetto,
                    cursor: "pointer",
                    height: "40px",
                  }}
                  onClick={() => {
                    setCheckSoggetto(false);
                    setCheckSoggettoText("IMMOBILE");
                  }}
                >
                  <a>
                    <b>Immobile</b>
                  </a>
                </div>
                <div
                  className="col-6 p-0 rounded pt-1"
                  style={{
                    backgroundColor: backgroundColorGSoggetto,
                    color: textColorGSoggetto,
                    cursor: "pointer",
                    height: "40px",
                  }}
                  onClick={() => {
                    setCheckSoggetto(true);
                    setCheckSoggettoText("SOGGETTO");
                  }}
                >
                  <a>
                    <b>Soggetto</b>
                  </a>
                </div>
              </div>
              <div className="col-lg-12 mb-1 text-center">
                <a style={{ fontSize: "20px" }}>
                  <b>Servizi senza delega</b>
                </a>
              </div>
              <div className="row col-lg-12 d-flex">
                {servizi && serviziDelega(false)}
              </div>
              {servizi &&
                servizi.length > 0 &&
                servizi
                  .filter(
                    (servizi) => servizi.tipologiaServizio == checkSoggettoText
                  )
                  .filter((servizi) => servizi.flagRichiestaDelega == true)
                  .length > 0 && (
                  <>
                    <div className="col-lg-12 mb-1 text-center">
                      <a style={{ fontSize: "20px" }}>
                        <b>Servizi con delega</b>
                      </a>
                    </div>
                    <div className="row col-lg-12">
                      {servizi && serviziDelega(true)}
                    </div>
                  </>
                )}
              {/* {isDelega && (
                    <>
                      <div className="col-lg-12 mb-1 text-center">
                        <a style={{ fontSize: "20px" }}>
                          <b>Delega</b>
                        </a>
                        <p className="mt-3 text-left">
                          <u
                            className="text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => getPdf()}
                          >
                            Scarica il modulo per la delega qui, compilalo e
                            caricalo selezionando il pulsante sotto.
                          </u>
                        </p>
                      </div>
                      <div className="col-12 mb-4">
                        <UploadFile
                          handleFile={setUploadDelega}
                          position={"text-left"}
                        ></UploadFile>
                      </div>
                      {validation && !validationDelega && (
                        <div className="col-lg-12 mb-2 text-center">
                          <a
                            className="text-danger"
                            style={{ fontSize: "20px" }}
                          >
                            <b>
                              E' necessario caricare il modulo per la delega!
                            </b>
                          </a>
                        </div>
                      )}
                    </>
                  )} */}

              {isDelega && (
                <>
                  <b className="col-lg-12 mb-1 text-center text-primary">
                    Verrai indirizzato alla pagina successiva in cui compilerai
                    la delega
                  </b>
                </>
              )}

              {validation && serviziTemp.length == 0 && (
                <small className="text-danger error_div">
                  Seleziona almeno un servizio!
                </small>
              )}

              {!checkSoggetto && (
                <>
                  <div className="col-lg-12 mb-4 mt-2">
                    <div className="bootstrap-select-wrapper col-12 ">
                      <Select
                        aria-label="Tipo Immobile"
                        clearText="Annulla"
                        defaultMenuIsOpen={false}
                        required
                        icon={false}
                        name="tipoImmobile"
                        id="tipoImmobile"
                        options={immobili}
                        onChange={handleChangeSelect}
                        isSearchable
                        placeholder={
                          immobileTemp && immobileTemp
                            ? immobileTemp
                            : "Seleziona tipo immobile"
                        }
                      />
                    </div>
                    {validation && !immobileTemp ? (
                      <small
                        id="errorSelect"
                        className="form-text text-danger error_div"
                      >
                        Seleziona un campo
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-lg-8 mb-4">
                    <div className="bootstrap-select-wrapper col-12">
                      <Select
                        aria-label="Scegli una provincia"
                        clearText="Annulla"
                        defaultMenuIsOpen={false}
                        value={selectedProvincia ? selectedProvincia : ""}
                        icon={false}
                        id="selectExampleClassic"
                        onChange={changeProvincia}
                        options={listaProvince}
                        isSearchable
                        placeholder="Scegli una provincia"
                      />
                    </div>
                    {validation && !selectedProvincia ? (
                      <small
                        id="errorSelect"
                        className="form-text error_div text-danger"
                      >
                        Seleziona un campo
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-lg-4">
                    <div className="bootstrap-select-wrapper col-12">
                      <Select
                        aria-label="Scegli un comune"
                        clearText="Annulla"
                        defaultMenuIsOpen={false}
                        value={selectedComune ? selectedComune : ""}
                        icon={false}
                        id="selectExampleClassic"
                        onChange={changeComune}
                        options={listaComuni}
                        isSearchable
                        placeholder="Scegli un comune"
                      />
                    </div>
                    {validation && !selectedComune ? (
                      <small
                        id="errorSelect"
                        className="form-text error_div text-danger"
                      >
                        Seleziona un campo
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div>
            {!checkSoggetto && (
              <>
                <div className="row">
                  <div
                    className="col-lg-12 mb-2 text-center"
                    style={{ fontSize: "21px" }}
                  >
                    <b>
                      <u>
                        Compila uno dei seguenti gruppi di dati per identificare
                        l'immobile.
                      </u>
                    </b>
                  </div>
                </div>
                <div className="row form-group px-5 py-2 borderCard">
                  <div className="col-lg-12 mb-2 text-center">
                    <a>
                      <b>
                        Identificazione dell'immobile tramite i dati catastali
                      </b>
                    </a>
                  </div>

                  <div className="col-lg-6">
                    <Input
                      id="foglio"
                      required
                      label="Foglio*"
                      type="text"
                      value={data.foglio || ""}
                      name="foglio"
                      {...(validation &&
                        !data.foglio &&
                        !validationImmobile && {
                          infoText: "Il campo non può essere vuoto!",
                        })}
                      {...(validation &&
                        !data.foglio &&
                        !validationImmobile && {
                          className: "text-danger error_div",
                        })}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Input
                      id="mappale"
                      label="Particella*"
                      type="text"
                      value={data.mappale || ""}
                      name="mappale"
                      {...(validation &&
                        !data.mappale &&
                        !validationImmobile && {
                          infoText: "Il campo non può essere vuoto!",
                        })}
                      {...(validation &&
                        !data.mappale &&
                        !validationImmobile && {
                          className: "text-danger error_div",
                        })}
                      onChange={handleChange}
                    />
                  </div>

                  {/* <div className="col-lg-6">
                    <Input
                      id="particella"
                      required
                      label="Particella*"
                      type="text"
                      value={data.particella || ""}
                      name="particella"
                      {...(validation &&
                        !data.particella &&
                        !validationImmobile && {
                        infoText: "Il campo non può essere vuoto!",
                      })}
                      {...(validation &&
                        !data.particella &&
                        !validationImmobile && {
                        className: "text-danger",
                      })}
                      onChange={handleChange}
                    />
                  </div> */}
                  <div className="col-lg-6">
                    <Input
                      id="subalterno"
                      type="text"
                      label="Subalterno*"
                      value={data.subalterno || ""}
                      name="subalterno"
                      {...(validation &&
                        !data.subalterno &&
                        !validationImmobile && {
                          infoText: "Il campo non può essere vuoto!",
                        })}
                      {...(validation &&
                        !data.subalterno &&
                        !validationImmobile && {
                          className: "text-danger",
                        })}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row form-group px-5 py-2 borderCard">
                  <div className="col-lg-12 mb-2 text-center">
                    <a style={{ fontSize: "20px" }}>
                      <b>Identificazione dell'immobile tramite l'indirizzo</b>
                    </a>
                  </div>

                  <div className="col-lg-8">
                    <Input
                      id="indirizzo"
                      required
                      label="Indirizzo*"
                      type="text"
                      name="indirizzo"
                      value={data.indirizzo || ""}
                      {...(validation &&
                        !data.indirizzo &&
                        !validationImmobile && {
                          infoText: "Il campo non può essere vuoto!",
                        })}
                      {...(validation &&
                        !data.indirizzo &&
                        !validationImmobile && {
                          className: "text-danger error_div",
                        })}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Input
                      id="piano"
                      label="Piano"
                      type="text"
                      name="piano"
                      value={data.piano || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            )}

            {validation && !validationImmobile && (
              <div className="col-lg-12 mb-2 text-center">
                <a
                  className="text-danger error_div"
                  style={{ fontSize: "20px" }}
                >
                  <b>
                    È necessario compilare i dati catastali o inserire
                    l'indirizzo dell'immobile
                  </b>
                </a>
              </div>
            )}
            {selectForm()}

            <div className="row">
              <div className="col-lg-12 text-right">
                <button
                  onClick={() => sendForm()}
                  className="btn btn-primary text-white"
                >
                  Invia Richiesta
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {" "}
        <Modal
          isOpen={isOpen}
          toggle={() => toggleModal(!isOpen)}
          size={"lg"}
          labelledBy="Modale1"
        >
          <ModalHeader id="Modale1">
            La tua richiesta è andata a buon fine.
          </ModalHeader>
          <ModalBody>
            Puoi procedere inserendo una nuova richiesta o puoi inserire i dati
            di fatturazione e procedere con il pagamento.
          </ModalBody>
          <ModalFooter>
            <Button
              color="info text-white text-center"
              onClick={() => reloadPage()}
            >
              Inserisci un'altra richiesta
            </Button>
            <Button
              color="primary text-white text-center"
              onClick={() => loggedDelega()}
            >
              Procedi
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={isOpenInfoSubServizio}
          toggle={() => toggleModalInfoSubServizio(!isOpenInfoSubServizio)}
          size={"lg"}
          labelledBy="Modale1"
        >
          <ModalHeader id="Modale1">
            Descrizione SubServizio selezionato.
          </ModalHeader>
          <ModalBody>{descSubServizio}</ModalBody>
          <ModalFooter>
            <Button
              color="primary text-white text-center"
              onClick={() => {
                setDescSubServizio("");
                toggleModalInfoSubServizio(false);
              }}
            >
              Chiudi
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      {!loading && <Loader />}
    </>
  );
}

export default VisuraCatastale;
