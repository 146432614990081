import axios from "axios";
import configuration from "./config.json";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const hub = process.env.REACT_APP_VALUTAZIONE_URL;

const Repo = {
  getSelectComuni: () => {
    var config = {
      method: "get",
      url: hub + configuration.URL_GET_SELECT_COMUNE,
    };

    return axios(config);
  },

  getSelectDestinazioni: () => {
    var config = {
      method: "get",
      url: hub + configuration.URL_GET_SELECT_DESTINAZIONI,
    };

    return axios(config);
  },

  getSelectZona: (id, CodTipoPrev) => {
    var config = {
      method: "get",
      url:
        hub +
        configuration.URL_GET_SELECT_ZONA +
        "?id=" +
        id +
        " &CodTipoPrev=" +
        CodTipoPrev,
    };

    return axios(config);
  },

  getSelectCaratteristiche: () => {
    var config = {
      method: "get",
      url: hub + configuration.URL_GET_SELECT_CARATTERISTICHE,
    };

    return axios(config);
  },

  getSelectPiano: () => {
    var config = {
      method: "get",
      url: hub + configuration.URL_GET_SELECT_PIANO,
    };

    return axios(config);
  },

  getSelectClasse: () => {
    var config = {
      method: "get",
      url: hub + configuration.URL_GET_SELECT_CLASSE,
    };

    return axios(config);
  },

  getSelectStato: () => {
    var config = {
      method: "get",
      url: hub + configuration.URL_GET_SELECT_STATO,
    };

    return axios(config);
  },

  postCalcolo: (obb) => {
    var header = {
      "Content-Type": "application/json",
    };

    var config = {
      method: "post",
      url: hub + configuration.URL_POST_CALCOLO_VALORE,
      headers: header,
      data: JSON.stringify(obb),
    };
    return axios(config);
  },
};

export default Repo;
