import { useEffect, useState } from "react";
import ListaStorico from "../Objects/ListaStorico";
import Repo from "../Utility/Repo";
import Loader from "../Utility/Loader";

function StoricoRichiesteCompletate(props) {
  const [listaStorico, setListaStorico] = useState();
  const [token, setToken] = useState(props.token);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    Repo.getRichiesteEvaseOperatore(token)
      .then((response) => {
        setLoading(true);
        setListaStorico(response.data);
      })
      .catch((error) => {
        setLoading(true);
        console.log(error);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  }, []);

  return (
    <>
      <div className="col-lg-12 p-0">
        <h2 className="mb-3">Richieste Completate</h2>
        {listaStorico && listaStorico.length > 0 ? (
          <>
            <ListaStorico subfolder={props.subfolder} token={token}>
              {listaStorico}
            </ListaStorico>
          </>
        ) : (
          <h5>Non ci sono richieste elaborate!</h5>
        )}
      </div>
      {!loading && <Loader />}
    </>
  );
}

export default StoricoRichiesteCompletate;
