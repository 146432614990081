import { useState } from "react";
import Select from "../Objects/Select";
import { Input, Label, FormGroup } from "design-react-kit";

function NotaForm(props) {
  const [listaTipiNota, setListaTipiNota] = useState([
    { value: "0", label: "Trascrizioni" },
    { value: "1", label: "Iscrizioni" },
    { value: "2", label: "Annotazioni" },
  ]);

  const [radioButtonRegistro, setRadioButtonRegistro] = useState(false);

  const handleChange = (event, num) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case "motivazioneConservatoria":
        props.setData((prevState) => ({
          ...prevState,
          motivazioneConservatoria: value,
        }));
        break;

      case "numeroRegistroParticolare":
        props.setData((prevState) => ({
          ...prevState,
          numeroRegistroParticolare: value,
        }));
        break;

      case "annoRegistroParticolare":
        props.setData((prevState) => ({
          ...prevState,
          annoRegistroParticolare: value,
        }));
        break;

      case "numeroRegistroGenerale":
        props.setData((prevState) => ({
          ...prevState,
          numeroRegistroGenerale: value,
        }));
        break;

      case "annoRegistroGenerale":
        props.setData((prevState) => ({
          ...prevState,
          annoRegistroGenerale: value,
        }));
        break;

      default:
        break;
    }
  };

  const handleChangeRadio = (event) => {
    let id = event.target.id;

    switch (id) {
      case "particolare":
        setRadioButtonRegistro(false);
        props.setRadioButtonRegistro(false);
        break;
      case "generale":
        setRadioButtonRegistro(true);
        props.setRadioButtonRegistro(true);
        break;
    }
  };

  const handleChangeNota = (event) => {
    let id = event.target.id;

    props.setValidationStep(false);

    switch (id) {
      case "soggetto":
        props.setNotaCheck(false);
        break;
      case "immobile":
        props.setNotaCheck(true);
        break;
    }
  };

  const changeTipoNota = (event) => {
    props.setData((prevState) => ({
      ...prevState,
      tipoNota: event.value,
    }));
  };

  return (
    <>
      <div
        className="row form-group pl-5 px-5 pt-2 pb-2"
        style={{
          borderRadius: "5px",
          border: "2px solid rgb(46, 88, 166, 0.35)",
        }}
      >
        <div className="col-lg-12 mb-2 text-center">
          <a>
            <div className="col-lg-12 mt-3">
              <div className="row">
                <div className="col-lg-3 text-left">
                  <FormGroup check>
                    <Input
                      name="gruppo1"
                      type="radio"
                      id="particolare"
                      defaultChecked
                      onChange={handleChangeRadio}
                    />
                    <Label check htmlFor="particolare">
                      Registro Particolare
                    </Label>
                  </FormGroup>
                </div>
                <div className="col-lg-3">
                  <Select
                    aria-label="Tipo Nota"
                    clearText="Annulla"
                    defaultMenuIsOpen={false}
                    //value={selectedProvincia ? selectedProvincia : ""}
                    icon={false}
                    id="selectExampleClassic"
                    onChange={changeTipoNota}
                    options={listaTipiNota}
                    isDisabled={radioButtonRegistro}
                    isSearchable
                    placeholder="Tipo Nota"
                  />
                  {props.validationStep &&
                  !radioButtonRegistro &&
                  !props.dataObj.tipoNota ? (
                    <small
                      id="errorSelect"
                      className="form-text error_div text-danger"
                    >
                      Seleziona un campo
                    </small>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-lg-3">
                  <Input
                    id="numeroRegistroParticolare"
                    required
                    label="N. Registro"
                    type="text"
                    name="numeroRegistroParticolare"
                    disabled={radioButtonRegistro}
                    value={props.dataObj.numeroRegistroParticolare || ""}
                    onChange={handleChange}
                    maxLength={6}
                    style={{ borderBottom: "1.5px solid #5c6f82" }}
                    {...(props.validationStep &&
                      !radioButtonRegistro &&
                      !props.dataObj.numeroRegistroParticolare && {
                        infoText: "Il campo non può essere vuoto!",
                        className: "text-danger error_div",
                      })}
                  />
                </div>
                <div className="col-lg-3">
                  <Input
                    id="annoRegistroParticolare"
                    required
                    label="Anno"
                    type="text"
                    name="annoRegistroParticolare"
                    disabled={radioButtonRegistro}
                    value={props.dataObj.annoRegistroParticolare || ""}
                    onChange={handleChange}
                    onInput={(e) =>
                      (e.target.value = e.target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*?)\..*/g, "$1"))
                    }
                    maxLength={4}
                    style={{ borderBottom: "1.5px solid #5c6f82" }}
                    {...(props.validationStep &&
                      !radioButtonRegistro &&
                      !props.dataObj.annoRegistroParticolare && {
                        infoText: "Il campo non può essere vuoto!",
                        className: "text-danger error_div",
                      })}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-3 text-left">
                  <FormGroup check>
                    <Input
                      name="gruppo1"
                      type="radio"
                      id="generale"
                      onChange={handleChangeRadio}
                    />
                    <Label check htmlFor="generale">
                      Registro Generale
                    </Label>
                  </FormGroup>
                </div>
                <div className="col-lg-3">
                  <Input
                    id="numeroRegistroGenerale"
                    required
                    label="N. Registro"
                    type="text"
                    name="numeroRegistroGenerale"
                    disabled={!radioButtonRegistro}
                    value={props.dataObj.numeroRegistroGenerale || ""}
                    onChange={handleChange}
                    maxLength={6}
                    style={{ borderBottom: "1.5px solid #5c6f82" }}
                    {...(props.validationStep &&
                      radioButtonRegistro &&
                      !props.dataObj.numeroRegistroGenerale && {
                        infoText: "Il campo non può essere vuoto!",
                        className: "text-danger error_div",
                      })}
                  />
                </div>
                <div className="col-lg-3">
                  <Input
                    id="annoRegistroGenerale"
                    required
                    label="Anno"
                    type="text"
                    name="annoRegistroGenerale"
                    value={props.dataObj.annoRegistroGenerale || ""}
                    disabled={!radioButtonRegistro}
                    onChange={handleChange}
                    onInput={(e) =>
                      (e.target.value = e.target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*?)\..*/g, "$1"))
                    }
                    maxLength={4}
                    style={{ borderBottom: "1.5px solid #5c6f82" }}
                    {...(props.validationStep &&
                      radioButtonRegistro &&
                      !props.dataObj.annoRegistroGenerale && {
                        infoText: "Il campo non può essere vuoto!",
                        className: "text-danger error_div",
                      })}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-12 text-left">
                  <b
                    style={{
                      float: "left",
                      paddingTop: "10px",
                      paddingRight: "80px",
                      paddingLeft: "10px",
                    }}
                  >
                    Restrizioni per:{" "}
                  </b>
                  <FormGroup check style={{ float: "left" }}>
                    <Input
                      name="gruppo2"
                      type="radio"
                      id="soggetto"
                      defaultChecked
                      onChange={handleChangeNota}
                    />
                    <Label check htmlFor="soggetto">
                      Soggetto
                    </Label>
                  </FormGroup>
                  <FormGroup check style={{ float: "left" }}>
                    <Input
                      name="gruppo2"
                      type="radio"
                      id="immobile"
                      onChange={handleChangeNota}
                    />
                    <Label check htmlFor="immobile">
                      Immobile
                    </Label>
                  </FormGroup>
                </div>
              </div>
              <div className="col-lg-12 mt-3">
                <Input
                  id="motivazioneConservatoria"
                  required
                  label="Motivazione"
                  type="text"
                  name="motivazioneConservatoria"
                  value={props.dataObj.motivazioneConservatoria || ""}
                  onChange={handleChange}
                  style={{ borderBottom: "1.5px solid #5c6f82" }}
                />
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
}

export default NotaForm;
