import { useEffect, useState } from "react";
import Select from "../Objects/Select";
import Repo from "../Utility/Repo";
import Pagination from "../Utility/Pagination";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Chart from "react-apexcharts";
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import StatsIntermediariInt from "./StatsIntermediariInt";

function StatsInterne(props) {
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage] = useState(5);
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [countRichieste, setCountRichieste] = useState();
  const [items, setItems] = useState([]);
  const [series, setSeries] = useState([]);
  const [monthGain, setMonthGain] = useState(0);
  const [monthPiva, setMonthPiva] = useState(0);
  const [monthCF, setMonthCF] = useState(0);
  const [monthbool, setMonthBool] = useState(false);
  const [totalPiva, setTotalPiva] = useState(0);
  const [totalCF, setTotalCF] = useState(0);
  const d = new Date();

  const [mese, setMese] = useState(d.getMonth() + 1);
  const [anno, setAnno] = useState(d.getFullYear());

  const [refArr, setRefArr] = useState();
  const [listaEsercenti, setListaEsercenti] = useState([
    { value: 1, label: "Banca Popolare Pugliese" },
    { value: 2, label: "Buffetti" },
    { value: 3, label: "Anusca" },
  ]);
  const [selectedEsercente, setSelectedEsercente] = useState();
  const [richiesteEsercente, setRichiesteEsercente] = useState(null);
  const [isOpen, toggleModal] = useState(false);

  const [mesi] = useState([
    { label: "Gennaio", value: 1 },
    { label: "Febbraio", value: 2 },
    { label: "Marzo", value: 3 },
    { label: "Aprile", value: 4 },
    { label: "Maggio", value: 5 },
    { label: "Giugno", value: 6 },
    { label: "Luglio", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Settembre", value: 9 },
    { label: "Ottobre", value: 10 },
    { label: "Novembre", value: 11 },
    { label: "Dicembre", value: 12 },
  ]);

  const [anni] = useState([
    { label: "2022", value: 2022 },
    { label: "2023", value: 2023 },
    { label: "2024", value: 2024 },
    { label: "2025", value: 2025 },
    { label: "2026", value: 2026 },
    { label: "2027", value: 2027 },
    { label: "2028", value: 2028 },
    { label: "2029", value: 2029 },
  ]);

  const [chartOptions, SetChartOptions] = useState({
    labels: [
      "IN CARICO",
      "EVASO",
      "IN PAUSA",
      "RIFIUTATO",
      "NON PRESO IN CARICO",
    ],
    fill: {
      colors: ["#06c", "#008758", "#5c6f82", "#d9364f", "#d96a00"],
    },
    colors: ["#06c", "#008758", "#5c6f82", "#d9364f", "#d96a00"],
  });

  const [series2, setSeries2] = useState([
    {
      name: "Richieste",
      data: [],
    },
  ]);

  const [chartOptions2, SetChartOptions2] = useState({
    xaxis: {
      categories: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
    },
  });

  useEffect(() => {
    Repo.getCountRichieste(props.token)
      .then((response) => {
        setCountRichieste(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    Repo.getRichiesteStats(props.token)
      .then((response) => {
        setItems(response.data.filter((x) => x.flagPagato == true));
        setCurrentItems(
          response.data
            .filter((x) => x.flagPagato == true)
            .slice(0, itemsPerPage)
        );
        createChart(
          response.data.filter((x) => x.flagPagato == true),
          d.getFullYear()
        );
        totalStats(response.data.filter((x) => x.flagPagato == true));
        monthStats(
          null,
          response.data.filter((x) => x.flagPagato == true),
          null
        );
        refStats(
          null,
          response.data.filter((x) => x.flagPagato == true)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const totalStats = (arr) => {
    let sumPrivato = 0;
    let sumAzienda = 0;
    arr.forEach((element) => {
      if (element.isPrivato) {
        sumPrivato = sumPrivato + element.importoPagato;
      } else if (!element.IsPrivato) {
        sumAzienda = sumAzienda + element.importoPagato;
      }
    });
    setTotalCF(sumPrivato);
    setTotalPiva(sumAzienda);
  };

  const createChart = (item, anno) => {
    setSeries([
      item
        .filter((x) => x.flagPagato == true)
        .filter((x) => x.status == "IN_CARICO").length,
      item
        .filter((x) => x.flagPagato == true)
        .filter((x) => x.status == "EVASO").length,
      item
        .filter((x) => x.flagPagato == true)
        .filter((x) => x.status == "IN_PAUSA").length,
      item
        .filter((x) => x.flagPagato == true)
        .filter((x) => x.status == "RIFIUTATO").length,
      item.filter((x) => x.flagPagato == true).filter((x) => x.status == null)
        .length,
    ]);

    let tempData = [];

    for (let i = 1; i < 13; i++) {
      tempData[i - 1] = item
        .filter((x) => x.flagPagato == true)
        .filter((x) => x.dataModifica && x.dataModifica.split("/")[2] == anno)
        .filter(
          (x) => x.dataModifica && x.dataModifica.split("/")[1] == i
        ).length;
    }

    setSeries2([
      {
        name: "Richieste",
        data: tempData,
      },
    ]);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const monthStats = (event, arr, bool) => {
    let sum = 0;
    let sumPrivato = 0;
    let sumAzienda = 0;

    if (monthbool == true) {
      if (bool == true) {
        setMese(event.value);
        items.forEach((element) => {
          if (
            element.dataModifica &&
            element.dataModifica.split("/")[1] == event.value &&
            element.dataModifica.split("/")[2] == anno
          ) {
            sum = sum + element.importoPagato;
            if (element.isPrivato) {
              sumPrivato = sumPrivato + element.importoPagato;
            } else if (!element.IsPrivato) {
              sumAzienda = sumAzienda + element.importoPagato;
            }
          }
        });
      } else if (bool == false) {
        setAnno(event.value);
        items.forEach((element) => {
          if (
            element.dataModifica &&
            element.dataModifica.split("/")[1] == mese &&
            element.dataModifica.split("/")[2] == event.value
          ) {
            sum = sum + element.importoPagato;
            if (element.isPrivato) {
              sumPrivato = sumPrivato + element.importoPagato;
            } else if (!element.IsPrivato) {
              sumAzienda = sumAzienda + element.importoPagato;
            }
          }
        });
      }
    } else {
      arr.forEach((element) => {
        if (
          element.dataModifica &&
          element.dataModifica.split("/")[1] == d.getMonth() + 1 &&
          element.dataModifica.split("/")[2] == d.getFullYear()
        ) {
          sum = sum + element.importoPagato;
          if (element.isPrivato) {
            sumPrivato = sumPrivato + element.importoPagato;
          } else if (!element.IsPrivato) {
            sumAzienda = sumAzienda + element.importoPagato;
          }
        }
      });
      setMonthBool(true);
    }
    setMonthGain(sum);
    setMonthPiva(sumAzienda);
    setMonthCF(sumPrivato);
  };

  const refStats = (event, arrTemp) => {
    let tempData = [];
    let arr = [];
    if (event !== null) {
      arr = arrTemp.filter(
        (x) => x.dataModifica && x.dataModifica.split("/")[1] == event.value
      );
    } else {
      arr = arrTemp;
    }
    for (let i = 0; i < arr.length; i++) {
      if (tempData.length == 0) {
        tempData.push({
          label: arr[i].refUrl,
          value: 1,
          price: arr[i].importoPagato,
        });
      } else {
        for (let z = 0; z < tempData.length; z++) {
          if (tempData[z].label == arr[i].refUrl) {
            tempData[z].value += 1;
            tempData[z].price += arr[i].importoPagato;
          }
        }
        tempData.push({
          label: arr[i].refUrl,
          value: 1,
          price: arr[i].importoPagato,
        });
      }
    }

    tempData = tempData.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.label === value.label)
    );
    setRefArr(tempData);
  };

  let nameMounth = chartOptions2.xaxis.categories[d.getMonth()];

  const changeEsercente = (event) => {
    setSelectedEsercente({
      value: event.value,
      label: event.label,
    });
  };

  return (
    <>
      <h3 className="card-title mb-2">Statistiche</h3>
      <div className="row">
        <div className="col-lg-4 d-flex align-items-stretch">
          <div className="card card-teaser rounded shadow rounded mb-4 p-5">
            <div className="card-body">
              <div className="row">
                <div className="col-10">
                  <h6 className="card-title mb-2" style={{ fontSize: 25 }}>
                    Numero di richieste effettuate (Pagate):
                  </h6>
                </div>
                <div className="col-2 text-right">
                  <svg className="icon icon-success">
                    <use href={`${iconSprite}#it-note`}></use>
                  </svg>
                </div>
              </div>
              <strong style={{ fontSize: 40 }}>
                {countRichieste && countRichieste[0].pagamento}
              </strong>
            </div>
          </div>
        </div>
        <div className="col-lg-4 d-flex align-items-stretch">
          <div className="card card-teaser rounded rounded shadow d-flex flex-column mb-4 p-5">
            <div className="card-body d-flex flex-column">
              <div
                className="row"
                // style={{ minHeight: "110px", height: "fit-content" }}
              >
                <div className="col-10">
                  <h6 className="card-title mb-2 " style={{ fontSize: 25 }}>
                    Totale Incassato:
                  </h6>
                </div>
                <div className="col-2 text-right">
                  <svg className="icon icon-success">
                    <use href={`${iconSprite}#it-chart-line`}></use>
                  </svg>
                </div>
              </div>
              <div style={{ marginTop: "auto" }}>
                <div className="col-12">
                  <a>Totale: </a>
                </div>
                <strong style={{ fontSize: 40 }}>
                  € {countRichieste && countRichieste[1].totPagato}
                </strong>
                <div className="col-12">
                  <a>Privati: </a>
                </div>
                <strong style={{ fontSize: 40 }}>
                  € {totalCF && totalCF.toFixed(2)}
                </strong>
                <div className="col-12">
                  <a>Aziende: </a>
                </div>
                <strong style={{ fontSize: 40 }}>
                  € {totalPiva && totalPiva.toFixed(2)}
                </strong>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card card-teaser rounded rounded shadow mb-4">
            <div className="card-body p-4">
              <div className="row">
                <div className="col-12">
                  <h6 className="card-title mb-2">
                    <strong style={{ fontSize: 25 }}>
                      Incassi per mese/anno:
                    </strong>
                  </h6>
                </div>
              </div>
              <div className="row">
                <div className="bootstrap-select-wrapper col-6 mb-4">
                  <Select
                    clearText="Annulla"
                    defaultMenuIsOpen={false}
                    defaultValue={d.getMonth() + 1}
                    icon={false}
                    id="selectExampleClassic"
                    onChange={(e) => {
                      monthStats(e, null, true);
                    }}
                    options={mesi}
                    isSearchable
                    placeholder={nameMounth}
                  />
                </div>
                <div className="bootstrap-select-wrapper col-6 mb-4">
                  <Select
                    clearText="Annulla"
                    defaultMenuIsOpen={false}
                    defaultValue={d.getFullYear()}
                    icon={false}
                    id="selectExampleClassic"
                    onChange={(e) => {
                      monthStats(e, null, false);
                    }}
                    options={anni}
                    isSearchable
                    placeholder={d.getFullYear()}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <a>Totale: </a>
                </div>
                <strong style={{ fontSize: 40 }}>
                  € {monthGain && monthGain.toFixed(2)}
                </strong>
              </div>
              <div className="row">
                <div className="col-12">
                  <a>Privati: </a>
                </div>
                <strong style={{ fontSize: 40 }}>
                  € {monthCF && monthCF.toFixed(2)}
                </strong>
              </div>
              <div className="row">
                <div className="col-12">
                  <a>Aziende: </a>
                </div>
                <strong style={{ fontSize: 40 }}>
                  € {monthPiva && monthPiva.toFixed(2)}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-lg-12">
          <div className="card card-teaser rounded  rounded shadow mb-4">
            <div className="card-body">
              <div className="row">
                <h6 className="ml-4 mt-3">Seleziona Anno: </h6>
                <div className="bootstrap-select-wrapper col-3">
                  <Select
                    clearText="Annulla"
                    defaultMenuIsOpen={false}
                    defaultValue={d.getFullYear()}
                    icon={false}
                    id="selectExampleClassic"
                    onChange={(e) => createChart(items, e.value)}
                    options={anni}
                    isSearchable
                    placeholder={d.getFullYear()}
                  />
                </div>
              </div>
              <Chart
                options={chartOptions2}
                series={series2}
                type="bar"
                width={"100%"}
                height={292}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 d-flex align-items-stretch">
          <div className="card card-teaser rounded rounded shadow mb-4">
            <div className="card-body">
              <Chart
                options={chartOptions}
                series={series}
                type="donut"
                width={"100%"}
                height={600}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-flex align-items-stretch">
          <div className="card card-teaser rounded  rounded shadow mb-4">
            <div className="card-body">
              <div className="row">
                <div className="col-10">
                  <h6 className="card-title mb-2">
                    <strong style={{ fontSize: 25 }}>
                      Dettaglio richieste pagate:
                    </strong>
                  </h6>
                </div>
                <div className="col-2 text-right">
                  <svg className="icon icon-success">
                    <use href={`${iconSprite}#it-bookmark`}></use>
                  </svg>
                </div>
              </div>
              {currentItems &&
                currentItems.map((x) => (
                  <div key={x.id}>
                    <div className="row mb-1">
                      <div className="col-12">
                        Ordine:
                        <strong> {x.descrizione && x.descrizione}</strong>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        Status:{" "}
                        {x.status == "IN_CARICO" ? (
                          <span className="badge badge-primary badgeCustom">
                            IN CARICO
                          </span>
                        ) : x.status == "EVASO" ? (
                          <span className="badge badge-success badgeCustom">
                            EVASO
                          </span>
                        ) : x.status == "IN_PAUSA" ? (
                          <span className="badge badge-secondary badgeCustom">
                            IN PAUSA
                          </span>
                        ) : x.status == "RIFIUTATO" ? (
                          <span className="badge badge-danger badgeCustom">
                            RIFIUTATO
                          </span>
                        ) : (
                          <span className="badge badgeYellow badgeCustom">
                            NON PRESO IN CARICO
                          </span>
                        )}
                      </div>
                      <div className="col-6">
                        Importo Pagato:{" "}
                        <strong>{x.importoPagato && x.importoPagato} €</strong>
                      </div>
                    </div>
                    <hr></hr>
                  </div>
                ))}
              {currentItems && (
                <div className="mt-3">
                  <Pagination
                    items={items}
                    setCurrentItems={setCurrentItems}
                    itemsPerPage={5}
                  ></Pagination>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StatsInterne;
