export const MESI = [
  { label: "Gennaio", value: 1 },
  { label: "Febbraio", value: 2 },
  { label: "Marzo", value: 3 },
  { label: "Aprile", value: 4 },
  { label: "Maggio", value: 5 },
  { label: "Giugno", value: 6 },
  { label: "Luglio", value: 7 },
  { label: "Agosto", value: 8 },
  { label: "Settembre", value: 9 },
  { label: "Ottobre", value: 10 },
  { label: "Novembre", value: 11 },
  { label: "Dicembre", value: 12 },
];
