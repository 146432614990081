import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Link } from "react-router-dom";
import aiIcon from "../../Img/chatbot-icon.svg";
import globalIcon from "../../Img/global-network.png";
import flashIcon from "../../Img/flash.png";
// import NotifyPanel from "../Objects/NotifyPanel.js";
import MetaTags from "react-meta-tags";
import YoutubeEmbed from "../Objects/YoutubeEmbed";

function ServiziCittadino(props) {
  return (
    <>
      <MetaTags>
        <title>Home</title>
        <meta
          property="og:title"
          content="Semplicita Catasto: visure catastali e conservatoria online"
        />
        <meta
          property="og:description"
          content="Semplícita Catasto il servizio online facile, economico e veloce per richiedere visure catastali e visure conservatoria"
        />
        <meta
          name="description"
          content="Semplícita Catasto il servizio online facile, economico e veloce per richiedere visure catastali e visure conservatoria"
        />
        <meta
          name="keywords"
          content="visure, catastali, conservatoria, visure catastali, visura,catastale, visura catastale, on-line, catasto, visure catasto, visure catastali on-line, visura,catastale, visura catastale, visura catastale on-line, dati, dati catastali, visure catasto on-line, dati catastali on-line, catasto, rete, catasto in rete"
        />
      </MetaTags>
      {/* <div className="col-12 col-lg-12 mb-4"><NotifyPanel></NotifyPanel></div> */}
      <div className="row text-center mt-5">
        <div className="col-12 mb-2">
          <h2>FACILE E VELOCE</h2>
        </div>
        <div className="col-12">
          <h5>
            Servizi catastali ovunque ti trovi e tramite qualsiasi dispositivo.
          </h5>
        </div>
        <div className="col-12 mb-5">
          <hr className="hrCustom" />
        </div>
      </div>
      <div className="row text-center mb-5">
        <div className="col-12 col-lg-6">
          <Link to="/catasto" className="text-decoration-none">
            <div className="card-wrapper card-space cardCustom">
              <div className="card card-bg card-big">
                <div className="card-body">
                  <div className="top-icon">
                    <svg className="icon">
                      <use href={`${iconSprite}#it-map-marker-circle`} />
                    </svg>
                  </div>
                  <h5 className="card-title">CATASTO</h5>
                  <p className="card-text">
                    La visura catastale contiene le informazioni tecniche
                    registrate nell'archivio del Catasto relative ai dati
                    catastali di immobili fabbricati, di un terreno o di
                    soggetti proprietari
                  </p>
                  <button type="button" className="btn btn-outline-primary">
                    Richiedi
                  </button>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-12 col-lg-6">
          <Link to="/Conservatoria" className="text-decoration-none">
            <div className="card-wrapper card-space cardCustom">
              <div className="card card-bg card-big">
                <div className="card-body">
                  <div className="top-icon">
                    <svg className="icon">
                      <use href={`${iconSprite}#it-file`} />
                    </svg>
                  </div>
                  <h5 className="card-title">CONSERVATORIA</h5>
                  <p className="card-text">
                    Presso la Conservatoria dei Registri Immobiliari sono
                    archiviati tutti i documenti che attestano i passaggi di
                    proprietà degli immobili, comprese le ipoteche, i privilegi
                    e i diritti su di essi.{" "}
                  </p>
                  <button type="button" className="btn btn-outline-primary">
                    Richiedi
                  </button>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-12 col-lg-12">
          <Link to="/Valutazione_immobile" className="text-decoration-none">
            <div className="card-wrapper card-space cardCustom">
              <div className="card card-bg card-big">
                <div className="card-body">
                  <div className="top-icon">
                    <svg className="icon">
                      <use href={`${iconSprite}#it-pa`} />
                    </svg>
                  </div>
                  <h5 className="card-title">VALUTAZIONE IMMOBILE</h5>
                  <p className="card-text">
                    Il primo passo per intraprendere una compravendita è una
                    corretta valutazione dell’immobile. L’algoritmo di
                    valutazione permette di ottenere in pochi secondi una stima
                    del valore di mercato dell’immobile, in modo
                    <strong> rapido e completamente gratuito.</strong>
                  </p>
                  <button type="button" className="btn btn-outline-primary">
                    Richiedi
                  </button>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="col-12 mb-5">
        <hr className="hrCustom"></hr>
      </div>
      <div className="text-center">
        <h2>SCOPRI IL MONDO SEMPLÍCITA CATASTO</h2>
        <YoutubeEmbed embedId="rQ8cOZFeeo8" height="315" width="560" />
      </div>
      <div className="col-12 mb-5">
        <hr className="hrCustom"></hr>
      </div>
      <div className="card-wrapper">
        <div className="card card-bg">
          <div className="card-body">
            <div className="row text-center">
              <div className="col-lg-12" style={{ paddingInline: "20%" }}>
                <img
                  src={aiIcon}
                  title="globalIcon"
                  alt="imagealt"
                  style={{ width: "22%" }}
                ></img>
                <h5 className="mt-3 p-2">
                  Hai dubbi o domande? Utilizza il nostro chatbot
                </h5>
                <p>
                  Tramite algoritmi di intelligenza artificiale, è in grado di
                  comprendere e rispondere alle tue domande.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiziCittadino;
