import { useEffect, useState } from "react";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import Select from "../Objects/Select";
import moment from "moment";
import ListaAttivita from "../Objects/ListaAttivita";
import Repo from "../Utility/Repo";
import { useLocation } from "react-router";
import useLocalStorage from "../Utility/useLocalStorage";
import { Link } from "react-router-dom";

function AttivitaSvolte(props) {
  const [token, setToken] = useState(props.token);

  const [listaAtt, setListaAtt] = useState([
    {
      id: "30864",
      data: "01/01/2021",
      richieste: "4",
      richiesteEvase: "3",
      importoSpettante: "50",
      flagPagato: true,
    },
    {
      id: "85293",
      data: "05/02/2021",
      richieste: "2",
      richiesteEvase: "2",
      importoSpettante: "100",
      flagPagato: false,
    },
    {
      id: "78941",
      data: "11/12/2021",
      richieste: "7",
      richiesteEvase: "5",
      importoSpettante: "200",
      flagPagato: true,
    },
  ]);

  return (
    <>
      <ListaAttivita token={token}>{listaAtt}</ListaAttivita>
    </>
  );
}

export default AttivitaSvolte;
