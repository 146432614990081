import { React, useState, useEffect } from "react";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

function RatingImmobile(props) {
    const [fidelity, setFidelity] = useState("Prezzo Ottimo");

    useEffect(() => {
        var subMax = props.children.prezzoMassimo - props.children.prezzoMedio;
        var subMin = props.children.prezzoMedio - props.children.prezzoMinimo;
        var average = (props.children.prezzoMinimo + props.children.prezzoMassimo) / 2;
        var subAvg = props.children.prezzoMedio - average;
        if (Math.abs(subAvg) < subMax && Math.abs(subAvg) < subMin && subAvg > 0) {
          setFidelity("Prezzo Medio");
        } else if (subMin < subMax && subMin < Math.abs(subAvg)) {
          setFidelity("Prezzo Ottimo");
        } else {
          setFidelity("Prezzo Alto");
        }
    });

    return (
        <>
            <div className="col mt-1">
                {fidelity == "Prezzo Ottimo" ? (
                    <>
                        <span className="pl-2 text-success" style={{fontSize: "24px"}}><b>Prezzo Ottimo!</b></span>
                        <div className="col">
                            <svg className="icon icon-sm icon-success" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                            <svg className="icon icon-sm icon-success" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                            <svg className="icon icon-sm icon-success" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                            <svg className="icon icon-sm icon-success" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                            <svg className="icon icon-sm icon-success" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                        </div>
                    </>
                ) : fidelity == "Prezzo Alto" ? (
                    <>
                        <span className="pl-2 text-danger" style={{fontSize: "24px"}}><b>Prezzo Alto!</b></span>
                        <div className="col">
                            <svg className="icon icon-sm icon-danger" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                            <svg className="icon icon-sm icon-danger" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                            <svg className="icon icon-sm icon-danger" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                            <svg className="icon icon-sm icon-danger" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                            <svg className="icon icon-sm icon-danger" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                        </div>
                    </>
                ) : (
                    <>
                        <span className="pl-2 text-warning" style={{fontSize: "24px"}}><b>Prezzo Medio!</b></span>
                        <div className="col">
                            <svg className="icon icon-sm icon-warning" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                            <svg className="icon icon-sm icon-warning" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                            <svg className="icon icon-sm icon-warning" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                            <svg className="icon icon-sm icon-secondary" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                            <svg className="icon icon-sm icon-secondary" style={{ minWidth: "0px" }}>
                                <use href={`${iconSprite}#it-star-full`} />
                            </svg>{" "}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default RatingImmobile;