function Faq(props) {
  return (
    <div className="px-lg-5 px-2">
      <div className="row pt-md-4">
        <div className="col-lg-10  col-12">
          <h2>Domande frequenti (FAQ)</h2>
          <h5>Domande frequenti Su Semplícita Catasto</h5>
        </div>
      </div>

      <div className="pb-5 mt-4">
        <div className="row">
          <div className="col-lg-10 col-12">
            <div
              id="accordionDivFAQ"
              className="collapse-faq collapse-div"
              role="tablist"
            >
              <div className="collapse-header" id="heading1">
                <button
                  data-toggle="collapse"
                  data-nfaq="n1"
                  data-target="#accordion1"
                  aria-expanded="false"
                  aria-controls="accordion1"
                  className="collapsed"
                >
                  Come si effettuano i pagamenti?
                </button>
              </div>
              <div
                id="accordion1"
                className="collapse"
                role="tabpanel"
                aria-labelledby="heading1"
                data-parent="#accordionDivFAQ"
              >
                <div className="collapse-body">
                  I pagamenti possono essere effettuati tramite PagoPA andando
                  nella sezione dei Pagamenti Attesi o nella cartella del
                  relativo fascicolo documentale.
                </div>
              </div>

              <div className="collapse-header" id="heading2">
                <button
                  data-toggle="collapse"
                  data-nfaq="n2"
                  data-target="#accordion2"
                  aria-expanded="false"
                  aria-controls="accordion2"
                  className="collapsed"
                >
                  E' possibile usufruire di PagoPA per i pagamenti?
                </button>
              </div>
              <div
                id="accordion2"
                className="collapse"
                role="tabpanel"
                aria-labelledby="heading2"
                data-parent="#accordionDivFAQ"
              >
                <div className="collapse-body">
                  SI, su SmartCityPA è possibile effettuare il pagamento di una
                  posizione debitoria tramite PagoPA.
                </div>
              </div>
              <div className="collapse-header" id="heading2">
                <button
                  data-toggle="collapse"
                  data-nfaq="n2"
                  data-target="#accordion2"
                  aria-expanded="false"
                  aria-controls="accordion2"
                  className="collapsed"
                >
                  A cosa serve SmartCityPA?
                </button>
              </div>
              <div
                id="accordion2"
                className="collapse"
                role="tabpanel"
                aria-labelledby="heading2"
                data-parent="#accordionDivFAQ"
              >
                <div className="collapse-body">
                  Smart City PA permette di effettuare pagamenti di posizioni
                  debitorie con il proprio comune di residenza o dove ha altre
                  posizioni aperte, attraverso i sistemi di PagoPA
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
