import { useEffect, useState } from "react";
import Repo from "../Utility/Repo";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Loader from "../Utility/Loader";

function RegistrationConfirm(props) {
  const [apiToken, setApiToken] = useState(props.apiToken);
  const queryP = new URLSearchParams(window.location.search);
  const [bool, setBool] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    if (queryP.get("userid") && queryP.get("code")) {
      Repo.postConfirmRegistration(queryP.get("userid"), queryP.get("code"))
        .then((response) => {
          if (response.status == 200) {
            setLoading(true);
            setBool(true);
          }
        })
        .catch((error) => {
          if (error.response.status == 400) {
            setErrorApi(error.response.data.Message);
            toggleModalError(!isOpenError);
          }
        });
    }
  }, [queryP.get("userid") && queryP.get("code")]);

  return (
    <>
      <div className="card card-teaser text-center">
        <div className="card-body p-3">
          {bool && (
            <>
              <br />
              <svg className="icon-success icon-xl">
                <use href={`${iconSprite}#it-check-circle`} />
              </svg>
              <br />
              <br />
              <h3>Grazie per esserti registrato</h3>
              <h4>Stai per essere reindirizzato alla pagina di login</h4>
              <br />
              <button
                className="btn btn-primary mr-1"
                onClick={() =>
                  (window.location.href = props.subfolder + "/login")
                }
              >
                Login
              </button>
            </>
          )}
        </div>
      </div>
      {!loading && <Loader />}
    </>
  );
}

export default RegistrationConfirm;
