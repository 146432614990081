import "./App.css";
import Repo from "./Components/Utility/Repo";
import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { MemoizedHeader } from "./Components/Layout/Header";
import { MemoizedFooter } from "./Components/Layout/Footer";
import Body from "./Components/Layout/Body";
import useLocalStorage from "./Components/Utility/useLocalStorage";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";

function App() {
  const cookies = new Cookies();
  const [user, setUser] = useState("");
  const [apiToken] = useState(cookies.get("token_key"));
  const [checkDelegate, setCheckDelegate] = useState(false);
  const [operator, setOperator] = useState(false);
  const [isDelegate, setIsDelegate] = useState(false);
  const [isStatsViewer, setIsStatsViewer] = useState(false);
  const [isSuperDelegate, setIsSuperDelegate] = useState(false);

  const [subfolder, setSubFolder] = useState(
    window.__RUNTIME_CONFIG__.PUBLIC_URL
  );
  const ref = new URLSearchParams(window.location.search);
  const [refUrl, setRefUrl, removeLocal] = useLocalStorage("refUrl");
  const [dateRefUrl, setDateRefUrl, removeLocalDate] =
    useLocalStorage("dateRefUrl");

  useEffect(() => {
    if (ref.get("refUrl")) {
      setRefUrl(ref.get("refUrl"));
      var date = new Date();
      setDateRefUrl(date);
    } else {
      var nowDate = new Date();
      var diff = Math.abs(nowDate - Date.parse(dateRefUrl)) / 36e5;

      if (diff > 24) {
        localStorage.clear();
      }
    }
  }, [ref.get("refUrl")]);

  useEffect(() => {
    if (apiToken) {
      var decoded = jwt_decode(apiToken);

      setUser(decoded.FullName);

      if (decoded.role && typeof decoded.role === "object") {
        if (decoded.role.includes("STATSVIEWER")) {
          setIsStatsViewer(true);
        } else if (decoded.role.includes("OPERATOR")) {
          setOperator(true);
        } else if (decoded.role.includes("DELEGATE")) {
          setIsDelegate(true);
          insertDelegate(decoded.FullName);
        } else if (decoded.role.includes("SUPER_DELEGATE")) {
          setIsSuperDelegate(true);
          insertSuperDelegate(decoded.FullName);
        }
      } else if (decoded.role && typeof decoded.role === "string") {
        if (decoded.role == "STATSVIEWER") {
          setIsStatsViewer(true);
        } else if (decoded.role == "OPERATOR") {
          setOperator(true);
        } else if (decoded.role == "DELEGATE") {
          setIsDelegate(true);
          insertDelegate();
        } else if (decoded.role == "SUPER_DELEGATE") {
          setIsSuperDelegate(true);
          insertSuperDelegate(decoded.FullName);
        }
      }
    }
  }, [cookies]);

  const insertDelegate = (fullName) => {
    if (!checkDelegate) {
      Repo.postInsertDelegate(apiToken, fullName)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });

      setCheckDelegate(true);
    }
  };

  const insertSuperDelegate = (fullName) => {
    if (!checkDelegate) {
      Repo.postInsertSuperDelegate(apiToken, fullName)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });

      setCheckDelegate(true);
    }
  };

  let interval;
  useEffect(() => {
    interval = setInterval(() => {
      localStorage.clear();
      window.location.href = subfolder + "/";
    }, 32400000);
  }, [interval]);

  const listaLinkUser = [
    { testo: "Home", url: "/" },
    { testo: "Catasto", url: "/catasto" },
    { testo: "Conservatoria", url: "/conservatoria" },
    { testo: "Ordini Completati", url: "/ordini_completati" },
    { testo: "Valutazione Immobile", url: "/Valutazione_immobile" },
    { testo: "Serve Aiuto?", url: "/help" },
  ];
  const listaLinkDelegate = [
    { testo: "Home", url: "/" },
    { testo: "Catasto", url: "/catasto" },
    { testo: "Conservatoria", url: "/conservatoria" },
    { testo: "Ordini Completati", url: "/ordini_completati" },
    { testo: "Dashboard", url: "/dashboard" },
    { testo: "Serve Aiuto?", url: "/help" },
  ];

  const listaLinkSuperDelegate = [
    { testo: "Home", url: "/" },
    { testo: "Presa in Carico", url: "/richieste_delegato" },
    { testo: "Richieste in Corso", url: "/richieste_corso" },
    { testo: "Richieste Completate", url: "/richieste_completate" },
    { testo: "Dashboard", url: "/dashboard_professionista" },
    { testo: "Serve Aiuto?", url: "/help" },
  ];

  const listaLinkOperator = [
    { testo: "Home", url: "/" },
    { testo: "Presa in Carico", url: "/richieste_visure" },
    { testo: "Richieste in Corso", url: "/richieste_corso" },
    { testo: "Richieste Completate", url: "/richieste_completate" },
    { testo: "Serve Aiuto?", url: "/help" },
  ];
  const listaLink = [
    { testo: "Home", url: "/" },
    { testo: "Catasto", url: "/catasto" },
    { testo: "Conservatoria", url: "/conservatoria" },
    { testo: "Valutazione Immobile", url: "/Valutazione_immobile" },
    { testo: "Serve Aiuto?", url: "/help" },
  ];
  const listaLinkStatsViewer = [
    { testo: "Home", url: "/" },
    { testo: "Statistiche esercenti", url: "/statistiche_esercenti" },
    { testo: "Statistiche professionisti", url: "/statistiche_professionisti" },
    { testo: "Serve Aiuto?", url: "/help" },
  ];

  const returnlink = () => {
    if (operator) {
      return listaLinkOperator;
    } else if (isStatsViewer) {
      return listaLinkStatsViewer;
    } else if (isDelegate) {
      return listaLinkDelegate;
    } else if (isSuperDelegate) {
      return listaLinkSuperDelegate;
    } else if (apiToken && !operator) {
      return listaLinkUser;
    } else {
      return listaLink;
    }
  };

  return (
    <div className="App">
      <Router {...(subfolder && { basename: subfolder })}>
        <div className="headerCustom">
          <MemoizedHeader
            name={user}
            url="http://www.servizilocalispa.com/"
            Title="Servizi Locali Spa"
            links={returnlink()}
            subfolder={subfolder ? subfolder : ""}
          />
        </div>
        <Body subfolder={subfolder ? subfolder : ""}></Body>
        <MemoizedFooter> </MemoizedFooter>
      </Router>
    </div>
  );
}

export default App;
