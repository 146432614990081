import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import useLocalStorage from "../Utility/useLocalStorage";
import { Link } from "react-router-dom";
// import NotifyPanel from "../Objects/NotifyPanel.js";

function HomeOperator(props) {
  return (
    <>
      {/* <div className="col-12 col-lg-12 mb-4"><NotifyPanel></NotifyPanel></div> */}
      <div className="row text-center mt-5">
        {props.isSuperDelegate ? (
          <>
            <h2 className="pl-2 mb-3">Pannello Delegato</h2>
          </>
        ) : (
          <>
            <h2 className="pl-2 mb-3">Pannello Operatore</h2>
          </>
        )}

        <div className="col-12 col-lg-12">
          <Link
            to={
              props.isSuperDelegate
                ? "/richieste_delegato"
                : "/richieste_visure"
            }
            className="text-decoration-none"
          >
            <div className="card-wrapper card-space cardCustom">
              <div className="card card-bg card-big">
                <div className="card-body">
                  <div className="top-icon">
                    <svg className="icon">
                      <use href={`${iconSprite}#it-plus-circle`} />
                    </svg>
                  </div>
                  <h5 className="card-title">Prendi in carico</h5>
                  <p className="card-text">
                    Accedendo a questa sezione potrai prendere in carico una
                    nuova richiesta
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-6 col-lg-6 mt-4">
          <Link to="/richieste_corso" className="text-decoration-none">
            <div className="card-wrapper card-space cardCustom">
              <div className="card card-bg card-big">
                <div className="card-body">
                  <div className="top-icon">
                    <svg
                      className="icon bi bi-hourglass"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2h-7z" />
                    </svg>
                  </div>
                  <h5 className="card-title">Richieste in Corso</h5>
                  <p className="card-text">
                    Accedendo a questa sezione potrai visualizzare l’elenco
                    delle richieste tenute in sospeso o in carico
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-6 col-lg-6 mt-4">
          <Link to="/richieste_completate" className="text-decoration-none">
            <div className="card-wrapper card-space cardCustom">
              <div className="card card-bg card-big">
                <div className="card-body">
                  <div className="top-icon">
                    <svg className="icon">
                      <use href={`${iconSprite}#it-check-circle`} />
                    </svg>
                  </div>
                  <h5 className="card-title">Richieste Completate</h5>
                  <p className="card-text">
                    Accedendo a questa sezione potrai visualizzare l’elenco
                    delle richieste completate
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>

        {props.isSuperDelegate && (
          <div className="col-12 col-lg-12 mt-4">
            <Link
              to="/dashboard_professionista"
              className="text-decoration-none"
            >
              <div className="card-wrapper card-space cardCustom">
                <div className="card card-bg card-big">
                  <div className="card-body">
                    <div className="top-icon">
                      <svg className="icon">
                        <use href={`${iconSprite}#it-files`} />
                      </svg>
                    </div>
                    <h5 className="card-title">Dashboard</h5>
                    <p className="card-text">
                      Accedendo a questa sezione potrai visualizzare le pratiche
                      evase e il totale delle provvigioni
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        )}
      </div>
    </>
  );
}

export default HomeOperator;
