import { useEffect, useState } from "react";
import Select from "../Objects/Select";
import Repo from "../Utility/Repo";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Pagination from "../Utility/Pagination";
import Loader from "../Utility/Loader";

function StatsIntermediari(props) {
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage] = useState(5);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const d = new Date();

  const [selectedMonth, setSelectedMonth] = useState(d.getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(d.getFullYear());
  const [total, setTotal] = useState();
  const [percentage, setPercentage] = useState();

  const [mesi] = useState([
    { label: "Gennaio", value: 1 },
    { label: "Febbraio", value: 2 },
    { label: "Marzo", value: 3 },
    { label: "Aprile", value: 4 },
    { label: "Maggio", value: 5 },
    { label: "Giugno", value: 6 },
    { label: "Luglio", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Settembre", value: 9 },
    { label: "Ottobre", value: 10 },
    { label: "Novembre", value: 11 },
    { label: "Dicembre", value: 12 },
  ]);

  const [anni] = useState([
    { label: "2022", value: 2022 },
    { label: "2023", value: 2023 },
    { label: "2024", value: 2024 },
    { label: "2025", value: 2025 },
    { label: "2026", value: 2026 },
    { label: "2027", value: 2027 },
    { label: "2028", value: 2028 },
    { label: "2029", value: 2029 },
  ]);

  const [chartOptions2, SetChartOptions2] = useState({
    xaxis: {
      categories: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
    },
  });

  let nameMounth = chartOptions2.xaxis.categories[d.getMonth()];

  useEffect(() => {
    const obb = {
      anno: d.getFullYear(),
      mese: d.getMonth() + 1,
    };

    setLoading(false);
    Repo.postRichiesteDelegato(props.token, JSON.stringify(obb))
      .then((response) => {
        setLoading(true);
        setItems(response.data.statisticheDelegato);
        setCurrentItems(
          response.data.statisticheDelegato.slice(0, itemsPerPage)
        );
        setTotal(response.data.totaleProvigioni);
        setPercentage(response.data.percentualeProvigioni);
      })
      .catch((error) => {
        setLoading(true);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const monthFilter = (event, month) => {
    const obb = {
      anno: selectedYear,
      mese: selectedMonth,
    };

    if (month) {
      setSelectedMonth(event.value);
      obb["mese"] = event.value;
    } else {
      setSelectedYear(event.value);
      obb["anno"] = event.value;
    }

    setLoading(false);
    Repo.postRichiesteDelegato(props.token, JSON.stringify(obb))
      .then((response) => {
        setItems(response.data.statisticheDelegato);
        setCurrentItems(
          response.data.statisticheDelegato.slice(0, itemsPerPage)
        );
        setTotal(response.data.totaleProvigioni);
        setPercentage(response.data.percentualeProvigioni);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  };

  return (
    <>
      <div className="col-12">
        <div className="row">
          <div className="col-10">
            <h3 className="card-title mb-2">Dashboard Visure</h3>
          </div>
        </div>
        <div className="col-lg-12 mt-3 mb-2 d-flex flex-row">
          <div className="col-1">
            <svg className="icon icon" style={{ marginTop: "11px" }}>
              <use href={`${iconSprite}#it-funnel`}></use>
            </svg>
          </div>
          <div className="bootstrap-select-wrapper col-4">
            <Select
              clearText="Annulla"
              defaultMenuIsOpen={false}
              defaultValue={d.getMonth() + 1}
              icon={false}
              id="selectExampleClassic"
              onChange={(e) => monthFilter(e, true)}
              options={mesi}
              isSearchable
              placeholder={nameMounth}
            />
          </div>
          <div className="bootstrap-select-wrapper col-4">
            <Select
              clearText="Annulla"
              defaultMenuIsOpen={false}
              defaultValue={d.getFullYear()}
              icon={false}
              id="selectExampleClassic"
              onChange={(e) => monthFilter(e, false)}
              options={anni}
              isSearchable
              placeholder={d.getFullYear()}
            />
          </div>
        </div>

        {items && items.length > 0 ? (
          <div className="row mt-4">
            <div className="col-lg-12 d-flex align-items-stretch">
              <div className="card card-teaser rounded  rounded shadow mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-10">
                      <h6 className="card-title mb-2">
                        <strong style={{ fontSize: 25 }}>Riepilogo</strong>
                      </h6>
                    </div>
                    <div className="col-2 text-right">
                      <svg className="icon icon-success">
                        <use href={`${iconSprite}#it-chart-line`}></use>
                      </svg>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-3 d-flex flex-column">
                      <span>Numero Totale di Richieste:</span>
                      <strong className="mt-2" style={{ fontSize: 28 }}>
                        {items.length}
                      </strong>
                    </div>
                    <div className="col-3 d-flex flex-column">
                      <span>Totale provvigioni:</span>
                      <strong className="mt-2" style={{ fontSize: 28 }}>
                        {total} €
                      </strong>
                    </div>
                    <div className="col-3 d-flex flex-column">
                      <span>Percentuale Attuale:</span>
                      <div className="row d-flex flex-row mt-2">
                        <div className="col">
                          <strong className="mt-2" style={{ fontSize: 28 }}>
                            {percentage} %
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 d-flex align-items-stretch">
              <div className="card card-teaser rounded  rounded shadow mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-10">
                      <h6 className="card-title mb-2">
                        <strong style={{ fontSize: 25 }}>
                          Dettaglio richieste pagate:
                        </strong>
                      </h6>
                    </div>
                    <div className="col-2 text-right">
                      <svg className="icon icon-success">
                        <use href={`${iconSprite}#it-bookmark`}></use>
                      </svg>
                    </div>
                  </div>
                  {/* 
            {richiesteEsercente &&
              richiesteEsercente.map((x) => ( */}
                  {currentItems &&
                    currentItems.map((x) => (
                      <div key={x.id}>
                        <div className="row mb-1">
                          <div className="col-9">
                            Ordine:
                            <strong>
                              {x.riepilogo.descrizione &&
                                x.riepilogo.descrizione}
                            </strong>
                          </div>
                          <div className="col-3">
                            Status:{" "}
                            {x.riepilogo.status == "IN_CARICO" ? (
                              <span className="badge badge-primary badgeCustom">
                                IN CARICO
                              </span>
                            ) : x.riepilogo.status == "EVASO" ? (
                              <span className="badge badge-success badgeCustom">
                                EVASO
                              </span>
                            ) : x.riepilogo.status == "IN_PAUSA" ? (
                              <span className="badge badge-secondary badgeCustom">
                                IN PAUSA
                              </span>
                            ) : x.riepilogo.status == "RIFIUTATO" ? (
                              <span className="badge badge-danger badgeCustom">
                                RIFIUTATO
                              </span>
                            ) : (
                              <span className="badge badgeYellow badgeCustom">
                                NON PRESO IN CARICO
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div
                            className="col-lg-6"
                            style={{ borderRight: "inset" }}
                          >
                            <div className="col-lg-12">
                              <h5 className="card-title mb-2">
                                Dati Richiesta
                              </h5>
                            </div>
                            <div className="row mt-1 pl-1">
                              <div className="col-5">
                                <u>Importo provvigione:</u>{" "}
                              </div>
                              <div className="col-7">
                                <b>
                                  {x.totaleProvvigione && x.totaleProvvigione} €
                                </b>
                              </div>
                            </div>
                            <div
                              className="card-text"
                              style={{ fontSize: "18px", color: "black" }}
                            >
                              <div className="row mt-1 pl-1">
                                <div className="col-5">Importo Totale: </div>
                                <div className="col-7">
                                  <b>{x.totalePagato && x.totalePagato} €</b>
                                </div>
                              </div>
                              <div className="row mt-1 pl-1">
                                <div className="col-5">
                                  Importo imponibile:{" "}
                                </div>
                                <div className="col-7">
                                  <b>
                                    {x.totaleGuadagno && x.totaleGuadagno} €
                                  </b>
                                </div>
                              </div>
                              <div className="row mt-1 pl-1">
                                <div className="col-5">Percentuale: </div>
                                <div className="col-7">
                                  <b>{x.percentuale && x.percentuale} %</b>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="col-lg-12">
                              <h5 className="card-title mb-2">Servizi</h5>
                            </div>

                            <div className="it-list-wrapper">
                              <ul className="it-list">
                                {x &&
                                  x.riepilogo.richiesteServizi.map((y) =>
                                    y.servizi.map((z) => (
                                      <li key={z.id} className="pl-1">
                                        {z.nome}
                                      </li>
                                    ))
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <hr></hr>
                      </div>
                    ))}
                  {!currentItems && (
                    <>
                      <span className="text">Non sono presenti richieste.</span>
                    </>
                  )}
                  {/* {richiesteEsercente && richiesteEsercente.length !== 0 && ( */}
                  {currentItems && currentItems.length !== 0 && (
                    <div className="mt-3">
                      <Pagination
                        items={items}
                        setCurrentItems={setCurrentItems}
                        itemsPerPage={5}
                      ></Pagination>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-lg-12 d-flex align-items-stretch">
            <div className="card card-teaser rounded  rounded shadow mb-4">
              <div className="card-body">
                <div className="row">
                  <h6>
                    Non sono presenti visure effettuate per il mese/anno
                    selezionati
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {!loading && <Loader />}
    </>
  );
}
export default StatsIntermediari;
