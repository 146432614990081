import { useState, useEffect } from "react";
import Repo from "../Utility/Repo";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Input } from "design-react-kit";
import Select from "../Objects/Select";
import moment from "moment";
import { MESI } from "../Utility/Month";
import { ANNI } from "../Utility/Year";
import ModalComponent from "../Utility/ModalComponent";
import Pagination from "../Utility/Pagination";
import Loader from "../Utility/Loader";

function DashboardProfessionisti(props) {
  const [idConfirm, setIdConfirm] = useState();
  const [currentItems, setCurrentItems] = useState();
  const [items, setItems] = useState();
  const [prezzoServizi, setPrezzoServizi] = useState();
  const [showPrezzi, setShowPrezzi] = useState(false);
  const [showPrezziPrev, setShowPrezziPrev] = useState(false);
  const [prezzoServiziPrev, setPrezzoServiziPrev] = useState();
  const [loading, setLoading] = useState(true);
  const [statusOptions, setStatusOptions] = useState([]);
  const [isOpen, toggleModal] = useState(false);
  const [listaProfessionisti, setListaProfessionisti] = useState();
  const [selectedProfessionista, setSelectedProfessionista] = useState();
  const [total, setTotal] = useState();
  const d = new Date();
  const [selectedMonth, setSelectedMonth] = useState(d.getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(d.getFullYear());
  const [value, setValue] = useState();
  const [listaMesi] = useState(MESI);
  const [listaAnni] = useState(ANNI);
  const [profDefault, setShowProfDefault] = useState(false);
  const [defaultDeleghe, setDefaultDeleghe] = useState();

  const number = new RegExp("^[+]?([0-9]{0,})*[.]?([0-9]{0,2})?$", "g");

  let nameMounth = listaMesi[d.getMonth()].label;

  useEffect(() => {
    setLoading(false);
    Repo.getOperatoriOptions(props.token)
      .then((response) => {
        setListaProfessionisti(response.data);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });

    Repo.getStatusOptions(props.token)
      .then((response) => {
        setStatusOptions(response.data);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  }, []);

  const apiProfessionista = (obb, value) => {
    setLoading(false);
    Repo.postRichiesteProfessionistaById(
      props.token,
      JSON.stringify(obb),
      value
    )
      .then((response) => {
        setDefaultDeleghe(response.data.defaultDeleghe);
        setTotal(response.data.totaleGuadagnato);
        setItems(response.data.statisticheDelegato);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });

    Repo.getPrezzoServizi(props.token, value)
      .then((response) => {
        setPrezzoServizi(response.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });

    Repo.getPrezzoServiziPrev(props.token, value)
      .then((response) => {
        setPrezzoServiziPrev(response.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  };

  const monthFilter = (event, month) => {
    const obb = {
      anno: selectedYear,
      mese: selectedMonth,
    };

    if (month == true) {
      setSelectedMonth(event.value);
      obb["mese"] = event.value;
    } else if (month == false) {
      setSelectedYear(event.value);
      obb["anno"] = event.value;
    }

    apiProfessionista(obb, selectedProfessionista.value);
  };

  const changeProfessionista = (event) => {
    setSelectedProfessionista({
      value: event.value,
      label: event.label,
    });

    const obb = {
      anno: d.getFullYear(),
      mese: d.getMonth() + 1,
    };

    apiProfessionista(obb, event.value);
  };

  const changePrezzo = (obb) => {
    delete obb.dataPrezzo;
    delete obb.id;
    delete obb.servizioLabel;

    setLoading(false);
    Repo.setPrezzoServizi(props.token, obb)
      .then((response) => {
        setLoading(true);
        const obj = {
          anno: selectedYear,
          mese: selectedMonth,
        };

        apiProfessionista(obj, obb.idProfiloOperatore);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  };

  const changeListaPrezzi = (value, obb, bool) => {
    const indexToUpdate = prezzoServizi.findIndex((obj) => obj.id === obb.id);
    const updated = [...prezzoServizi];

    if (bool && value.match(number)) {
      updated[indexToUpdate].compenso = value;
    } else if (!bool && value.match(number)) {
      updated[indexToUpdate].rimborso = value;
    }
    setPrezzoServizi(updated);
  };

  const handleClick = (id) => {
    setIdConfirm(id);
  };

  const bodyProf = () => {
    return (
      <div className="col-lg-12 d-flex align-items-stretch">
        <div className="card card-teaser rounded  rounded shadow mb-4">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <svg className="icon icon-warning">
                  <use href={`${iconSprite}#it-warning-circle`}></use>
                </svg>
                <b className="text-warning" style={{ fontSize: "15px" }}>
                  Seleziona un input per mostrare il tasto conferma
                </b>
              </div>
              <div className="col-6">
                <h6 className="card-title mb-2">
                  <strong style={{ fontSize: 25 }}>Nome Servizio</strong>
                </h6>
              </div>
              <div className="col-2">
                <h6 className="card-title mb-2">
                  <strong style={{ fontSize: 25 }}>Compensi</strong>
                </h6>
              </div>
              <div className="col-2">
                <h6 className="card-title mb-2">
                  <strong style={{ fontSize: 25 }}>Rimborso</strong>
                </h6>
              </div>
              <div className="col-2"></div>
            </div>

            {prezzoServizi &&
              prezzoServizi.map((x) => (
                <div className="row mb-2" key={x.id}>
                  <div className="col-lg-6"> - {x.servizioLabel}</div>
                  <div className="col-lg-2">
                    <div className="wrapper" style={{ position: "relative" }}>
                      <strong
                        style={{
                          zIndex: 1,
                          position: "absolute",
                          right: 0,
                          top: 7,
                        }}
                      >
                        €
                      </strong>

                      <input
                        id={x.id}
                        label="Valore"
                        value={x.compenso}
                        type="text"
                        name="Valore"
                        placeholder="Inserisci prezzo"
                        onClick={() => handleClick(x.id)}
                        onChange={(e) =>
                          changeListaPrezzi(e.target.value, x, true)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="wrapper" style={{ position: "relative" }}>
                      <strong
                        style={{
                          zIndex: 1,
                          position: "absolute",
                          right: 0,
                          top: 7,
                        }}
                      >
                        €
                      </strong>
                      <input
                        id={x.id}
                        label="Valore"
                        value={x.rimborso}
                        onClick={() => handleClick(x.id)}
                        type="text"
                        name="Valore"
                        placeholder="Inserisci prezzo rimborso"
                        onChange={(e) =>
                          changeListaPrezzi(e.target.value, x, false)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 text-center" key={idConfirm}>
                    {idConfirm && idConfirm == x.id && (
                      <button
                        onClick={() => changePrezzo(x)}
                        className="btn btn-primary"
                      >
                        Conferma
                      </button>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const bodyProfDef = () => {
    return (
      <>
        <div className="row">
          <div className="p-2">
            <div className="text-paragraph-small">VALORE DELEGA:</div>
            <div className="border-light">
              <strong>
                {defaultDeleghe
                  ? "DELEGATO DI DEFAULT"
                  : "NON È DELEGATO DI DEFAULT"}
              </strong>
            </div>
          </div>
        </div>
        <div className="row">
          {defaultDeleghe == false ? (
            <>
              <svg className="icon icon-warning mr-2">
                <use href={`${iconSprite}#it-warning-circle`}></use>
              </svg>
              <b className="text-warning">
                Premendo salva si rende il professionista <u>SELEZIONATO</u> di
                default.
                <br></br>
                Cosi facendo le deleghe verranno autocompilate con i suoi dati.
              </b>
            </>
          ) : (
            <>
              <svg className="icon icon-warning mr-2">
                <use href={`${iconSprite}#it-warning-circle`}></use>
              </svg>
              <b className="text-warning">
                Premendo salva si rende il professionista <u>NON</u> selezionato
                di default.
                <br></br>
                Cosi facendo le deleghe <u>NON</u> verranno autocompilate.
              </b>
            </>
          )}
        </div>
        <div className="row mt-3">
          <div className="col-lg-12 text-right">
            <button
              type="button"
              onClick={() => changeDefault()}
              className="btn btn-primary mr-3"
            >
              Cambia
            </button>
            <button
              type="button"
              className="btn btn-danger "
              onClick={() => setShowProfDefault(!profDefault)}
            >
              Indietro
            </button>
          </div>
        </div>
      </>
    );
  };

  const changeDefault = () => {
    Repo.postChangeDefaultProf(
      props.token,
      selectedProfessionista.value,
      !defaultDeleghe
    )
      .then((response) => {
        window.location.reload();
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  };

  const bodyProfPrev = () => {
    return (
      <div className="col-lg-12 d-flex align-items-stretch">
        <div className="card card-teaser rounded  rounded shadow mb-4">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <h6 className="card-title mb-2">
                  <strong style={{ fontSize: 25 }}>Nome servizio</strong>
                </h6>
              </div>
              <div className="col-2">
                <h6 className="card-title mb-2">
                  <strong style={{ fontSize: 25 }}>Compensi</strong>
                </h6>
              </div>
              <div className="col-2">
                <h6 className="card-title mb-2">
                  <strong style={{ fontSize: 25 }}>Rimborso</strong>
                </h6>
              </div>
              <div className="col-2"></div>
            </div>
            {prezzoServiziPrev &&
              prezzoServiziPrev.map((x) => (
                <div className="row mb-2" key={x.id}>
                  <div className="col-lg-6"> - {x.servizioLabel}</div>
                  <div className="col-lg-2">
                    <div className="wrapper" style={{ position: "relative" }}>
                      <strong
                        style={{
                          zIndex: 1,
                          position: "absolute",
                          right: 0,
                          top: 7,
                        }}
                      >
                        €
                      </strong>
                      <input
                        id={x.id}
                        label="Valore"
                        value={x.compenso}
                        type="text"
                        name="Valore"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="wrapper" style={{ position: "relative" }}>
                      <strong
                        style={{
                          zIndex: 1,
                          position: "absolute",
                          right: 0,
                          top: 7,
                        }}
                      >
                        €
                      </strong>
                      <input
                        id={x.id}
                        label="Valore"
                        value={x.rimborso}
                        type="text"
                        name="Valore"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="col-lg-12 p-0">
        <h3 className="mb-2">Statistiche Professionisti</h3>
      </div>
      <div className="row">
        <div className="col-lg-8 mt-3 mb-2 d-flex flex-row">
          <div className="bootstrap-select-wrapper col-4">
            <Select
              clearText="Annulla"
              defaultMenuIsOpen={false}
              icon={false}
              id="selectExampleClassic"
              onChange={(e) => changeProfessionista(e)}
              aria-label="Scegli un professionista"
              value={selectedProfessionista ? selectedProfessionista : ""}
              options={listaProfessionisti}
              isSearchable
              placeholder={"Seleziona Delegato"}
            />
          </div>
        </div>

        {selectedProfessionista && (
          <div className="col-lg-4 mt-3 mb-2 text-right">
            <button
              onClick={() => {
                setShowProfDefault(!profDefault);
              }}
              className="btn btn-outline-primary btn-xs mb-2"
            >
              PROFESSIONISTA DELEGA DEFAULT
              <svg className="icon icon-primary">
                <use href={`${iconSprite}#it-arrow-right`}></use>
              </svg>
            </button>
          </div>
        )}
      </div>

      {selectedProfessionista && (
        <>
          <div className="flex-row mt-3 mb-2 d-flex">
            <div className="bootstrap-select-wrapper col-12 col-lg-4">
              <Select
                clearText="Annulla"
                defaultMenuIsOpen={false}
                defaultValue={d.getMonth() + 1}
                icon={false}
                id="selectExampleClassic"
                onChange={(e) => monthFilter(e, true)}
                options={listaMesi}
                isSearchable
                placeholder={nameMounth}
              />
            </div>
            <div className="bootstrap-select-wrapper col-12 col-lg-4">
              <Select
                clearText="Annulla"
                defaultMenuIsOpen={false}
                defaultValue={d.getFullYear()}
                icon={false}
                id="selectExampleClassic"
                onChange={(e) => monthFilter(e, false)}
                options={listaAnni}
                isSearchable
                placeholder={d.getFullYear()}
              />
            </div>
            <div className="col-12 col-lg-2">
              <button
                onClick={() => {
                  setShowPrezzi(!showPrezzi);
                  setShowPrezziPrev(false);
                }}
                className="btn btn-outline-primary btn-xs mb-2"
              >
                <svg className="icon icon-primary">
                  <use href={`${iconSprite}#it-pencil`}></use>
                </svg>
                MODIFICA E VISUALIZZA PREZZI
              </button>
            </div>
            <div className="col-12 col-lg-2">
              <button
                onClick={() => {
                  setShowPrezziPrev(!showPrezziPrev);
                  setShowPrezzi(false);
                }}
                className="btn btn-outline-primary btn-xs mb-2"
              >
                <svg className="icon icon-primary">
                  <use href={`${iconSprite}#it-pencil`}></use>
                </svg>
                VISUALIZZA PREZZI PRECEDENTI
              </button>
            </div>
          </div>

          {showPrezzi && <div className="row">{bodyProf()}</div>}
          {showPrezziPrev && <div className="row">{bodyProfPrev()}</div>}

          <div className="col-lg-12 d-flex align-items-stretch">
            <div className="card card-teaser rounded  rounded shadow mb-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h6 className="card-title mb-2">
                      <strong style={{ fontSize: 25 }}>Riepilogo</strong>
                    </h6>
                  </div>
                </div>
                <div className="row">
                  {items && items.length > 0 ? (
                    <div className="col-6">
                      <div className="row mt-3">
                        <div className="col-6 d-flex flex-column">
                          <span>Numero Totale di Richieste:</span>
                          <strong className="mt-2" style={{ fontSize: 28 }}>
                            {items.length}
                          </strong>
                        </div>
                        <div className="col-6 d-flex flex-column">
                          <span>Totale provvigioni:</span>
                          <strong className="mt-2" style={{ fontSize: 28 }}>
                            {total} €
                          </strong>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12">
                      Non sono presenti visure per la data selezionata
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {items && items.length > 0 && (
            <div className="row mt-4">
              <div className="col-lg-12 d-flex align-items-stretch">
                <div className="card card-teaser rounded  rounded shadow mb-4">
                  <div className="card-body">
                    {currentItems &&
                      currentItems.map((x) => (
                        <div key={x.id}>
                          <div className="row mb-1">
                            <div className="col-9">
                              Ordine:
                              <strong>
                                {x.riepilogo.descrizione &&
                                  x.riepilogo.descrizione}
                              </strong>
                            </div>
                            <div className="col-3 text-right">
                              Status:{" "}
                              {x.riepilogo.status == "IN_CARICO" ? (
                                <span className="badge badge-primary badgeCustom">
                                  IN CARICO
                                </span>
                              ) : x.riepilogo.status == "EVASO" ? (
                                <span className="badge badge-success badgeCustom">
                                  EVASO
                                </span>
                              ) : x.riepilogo.status == "IN_PAUSA" ? (
                                <span className="badge badge-secondary badgeCustom">
                                  IN PAUSA
                                </span>
                              ) : x.riepilogo.status == "RIFIUTATO" ? (
                                <span className="badge badge-danger badgeCustom">
                                  RIFIUTATO
                                </span>
                              ) : (
                                <span className="badge badgeYellow badgeCustom">
                                  NON PRESO IN CARICO
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div
                              className="col-lg-6"
                              style={{ borderRight: "inset" }}
                            >
                              <div className="col-lg-12">
                                <h5 className="card-title mb-2">
                                  Dati Richiesta
                                </h5>
                              </div>
                              <div
                                className="card-text"
                                style={{ fontSize: "18px", color: "black" }}
                              >
                                <div className="row mt-1 pl-1">
                                  <div className="col-5">
                                    Totale Guadagnato Richiesta:{" "}
                                  </div>
                                  <div className="col-7">
                                    <b>{x.totaleGuadagnato} €</b>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-8">
                                  <h5 className="card-title mb-2">Servizi</h5>
                                </div>
                                <div className="col-lg-2">
                                  <h5 className="card-title mb-2">Compensi</h5>
                                </div>
                                <div className="col-lg-2">
                                  <h5 className="card-title mb-2">Rimborso</h5>
                                </div>
                              </div>
                              <div className="it-list-wrapper">
                                <ul className="it-list">
                                  {x &&
                                    x.riepilogo.richiesteServizi.map((y) =>
                                      y.servizi.map((z) => (
                                        <li key={z.id} className="pl-1">
                                          <div className="row">
                                            <div className="col-8">
                                              {z.nome}
                                            </div>
                                            <div className="col-2">
                                              <strong>
                                                {z.compensoProfessionista} €
                                              </strong>
                                            </div>
                                            <div className="col-2">
                                              <strong>
                                                {z.rimborsoProfessionista} €
                                              </strong>
                                            </div>
                                          </div>{" "}
                                        </li>
                                      ))
                                    )}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                        </div>
                      ))}
                    {!currentItems && (
                      <>
                        <span className="text">
                          Non sono presenti richieste.
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {items && (
            <div className="mt-3">
              <Pagination
                items={items}
                setCurrentItems={setCurrentItems}
                itemsPerPage={5}
              ></Pagination>
            </div>
          )}
        </>
      )}

      <ModalComponent
        toggleModal={toggleModal}
        isOpen={isOpen}
        header="Modifica provvigioni"
        body={bodyProf()}
        scrollable={false}
      ></ModalComponent>

      <ModalComponent
        toggleModal={setShowProfDefault}
        isOpen={profDefault}
        header="Professionista di defualt"
        body={bodyProfDef()}
        scrollable={false}
      ></ModalComponent>

      {!loading && <Loader />}
    </>
  );
}

export default DashboardProfessionisti;
