import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import { useEffect, useState } from "react";

const ModalComponent = (props) => {
  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={() => props.toggleModal(!props.isOpen)}
        size={"lg"}
        scrollable={props.scrollable}
        labelledBy="Modale1"
      >
        <ModalHeader style={{ fontSize: "1.1rem" }} id="Modale1">
          {props.header}
        </ModalHeader>
        <ModalBody>{props.body}</ModalBody>
        <ModalFooter>
          {props.buttonConfirm && (
            <Button
              color="primary text-white text-center"
              onClick={() => props.function()}
            >
              {props.buttonConfirm}
            </Button>
          )}
          {props.buttonDeny && (
            <Button
              color="danger text-white text-center"
              onClick={() => props.toggleModal(!props.isOpen)}
            >
              {props.buttonDeny}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default ModalComponent;
