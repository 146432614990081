import { useEffect, useState, useRef } from "react";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Link } from "react-router-dom";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import Select from "../Objects/Select";
import moment from "moment";
import useLocalStorage from "../Utility/useLocalStorage";
import Repo from "../Utility/Repo";
import jwt_decode from "jwt-decode";

function Fatturazione(props) {
  const [validation, setValidation] = useState();
  const [validationEmail, setValidationEmail] = useState(true);
  const [validationPec, setValidationPec] = useState(true);
  const [editForce, setEditForce] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [errorCfPiva, setErrorCfPiva] = useState(false);
  const [checkReceipt, setCheckReceipt] = useState(true);
  const [listaNazioni, setListaNazioni] = useState([]);
  const [selectedComune, setSelectedComune] = useState();
  const [selectedProvincia, setSelectedProvincia] = useState();
  const [selectedNazione, setSelectedNazione] = useState({
    value: "IT",
    label: "ITALIA",
  });
  const [token, setToken] = useState(props.token);
  var decoded = props.token ? jwt_decode(props.token) : "";
  const [nazioneItalia, setNazioneItalia] = useState(false);
  const [listaComuni, setListaComuni] = useState([]);
  const [listaProvince, setListaProvince] = useState([]);
  const [backgroundColorF, setBackgroundColorF] = useState("transparent");
  const [textColorF, setTextColorF] = useState("#435a70");
  const [backgroundColorG, setBackgroundColorG] = useState("transparent");
  const [textColorG, setTextColorG] = useState("#435a70");
  const [refUrl, setRefUrl, removeLocal] = useLocalStorage("refUrl");
  const [isOpenAlertEmail, toggleModalAlertEmail] = useState(false);
  const regCap = /^[0-9]*$/m;

  const [data, setData] = useState({
    cf_piva: "",
    cognomeDenominazione: "",
    nome: "",
    indirizzo: "",
    cap: "",
    citta: "",
    provincia: "",
    nazione: "",
    telefono: "",
    email: decoded ? decoded.unique_name : "",
    pecCessionario: "",
    codiceSDI: "",
    isRicevuta: false,
  });

  useEffect(() => {
    if (!checkReceipt) {
      setBackgroundColorF("#2e58a6");
      setTextColorF("white");
      setBackgroundColorG("transparent");
      setTextColorG("#435a70");
    } else {
      setBackgroundColorF("transparent");
      setTextColorF("#435a70");
      setBackgroundColorG("#2e58a6");
      setTextColorG("white");
    }

    setData({
      cf_piva: "",
      cognomeDenominazione: "",
      nome: "",
      indirizzo: "",
      cap: "",
      citta: "",
      provincia: "",
      nazione: "",
      telefono: "",
      email: decoded ? decoded.unique_name : "",
      pecCessionario: "",
      codiceSDI: "",
      isRicevuta: false,
    });

    setValidation(false);
  }, [checkReceipt]);

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case "cf_piva":
        setData((prevState) => ({
          ...prevState,
          cf_piva: value,
        }));
        break;

      case "cognomeDenominazione":
        setData((prevState) => ({
          ...prevState,
          cognomeDenominazione: value,
        }));
        break;

      case "nome":
        setData((prevState) => ({
          ...prevState,
          nome: value,
        }));
        break;

      case "indirizzo":
        setData((prevState) => ({
          ...prevState,
          indirizzo: value,
        }));
        break;

      case "cap":
        if (value.match(regCap)) {
          setData((prevState) => ({
            ...prevState,
            cap: value,
          }));
        }
        break;

      case "telefono":
        setData((prevState) => ({
          ...prevState,
          telefono: value,
        }));
        break;

      case "email":
        setData((prevState) => ({
          ...prevState,
          email: value,
        }));
        break;

      case "pecCessionario":
        setData((prevState) => ({
          ...prevState,
          pecCessionario: value,
        }));
        break;

      case "codiceSDI":
        setData((prevState) => ({
          ...prevState,
          codiceSDI: value,
        }));
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    Repo.getNazioni(token)
      .then((response) => {
        setListaNazioni(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!data.email.match(regexEmail)) {
      setValidationEmail(false);
    } else {
      setValidationEmail(true);
    }

    if (data.pecCessionario && !data.pecCessionario.match(regexEmail)) {
      setValidationPec(false);
    } else {
      setValidationPec(true);
    }
  }, [data.email, data.pecCessionario]);

  useEffect(() => {
    setSelectedProvincia(null);
    setSelectedComune(null);

    setNazioneItalia(true);
    if (listaProvince.length == 0) {
      Repo.getProvince(token)
        .then((response) => {
          setListaProvince(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const changeNazione = (event) => {
    setSelectedNazione({
      value: event.value,
      label: event.label,
    });
    setSelectedProvincia(null);
    setSelectedComune(null);
    setListaComuni([]);
    if (event.value == "IT") {
      setNazioneItalia(true);
      if (listaProvince.length == 0) {
        Repo.getProvince(token)
          .then((response) => {
            setListaProvince(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      setNazioneItalia(false);
    }
  };

  const changeComune = (event) => {
    data.cap = "";
    setSelectedComune({
      value: event.value,
      label: event.label,
    });
  };

  const changeProvincia = (event) => {
    setSelectedProvincia({
      label: event.label,
      value: event.value,
    });
    setSelectedComune(null);
    data.cap = "";
    Repo.getComuni(token, event.value)
      .then((response) => {
        setListaComuni(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendForm = () => {
    setValidation(true);
    setErrorCfPiva(false);
    let passedValidation = true;

    let tempData = data;

    if (selectedNazione) {
      tempData.nazione = selectedNazione.label;
    }
    if (selectedProvincia) {
      tempData.provincia = selectedProvincia.value;
    }
    if (selectedComune) {
      tempData.citta = selectedComune.label;
    }

    if (!tempData.cf_piva) passedValidation = false;
    if (!tempData.cognomeDenominazione) passedValidation = false;
    if (checkReceipt) {
      if (!tempData.nome) passedValidation = false;
    }

    if (checkReceipt) {
      if (tempData.cf_piva.length != 16) {
        setErrorCfPiva(true);
        passedValidation = false;
      }
    } else {
      if (tempData.cf_piva.length != 11) {
        setErrorCfPiva(true);
        passedValidation = false;
      }
    }

    if (!tempData.nazione) passedValidation = false;
    if (!tempData.provincia) passedValidation = false;

    if (!tempData.cap) passedValidation = false;
    if (!tempData.indirizzo) passedValidation = false;
    if (!tempData.email) passedValidation = false;

    if (!checkReceipt) {
      if (!tempData.pecCessionario && !tempData.codiceSDI) {
        passedValidation = false;
      } else if (tempData.pecCessionario) {
        if (!validationPec) passedValidation = false;
      }
    }

    var listRichiesteId = [];

    props.listaRichieste &&
      props.listaRichieste.map((obbj) => listRichiesteId.push(obbj.id));

    tempData["idRichieste"] = listRichiesteId;
    tempData["id"] = props.id;
    tempData["refUrl"] = refUrl;
    tempData["isPrivato"] = checkReceipt;

    if (passedValidation && validationEmail) {
      props.sendFatturazione(tempData);
    }
  };

  const sendFormOnlyReceipt = () => {
    setValidation(true);
    let passedValidation = true;

    let tempData = data;

    if (!tempData.email) passedValidation = false;

    var listRichiesteId = [];

    props.listaRichieste &&
      props.listaRichieste.map((obbj) => listRichiesteId.push(obbj.id));

    tempData["idRichieste"] = listRichiesteId;
    tempData["id"] = props.id;

    if (checkReceipt && passedValidation && validationEmail) {
      props.sendFatturazione(tempData);
    }
  };

  const fatturazionForm = () => {
    return (
      <div className="col-lg-12 mb-5">
        <div className="row form-group">
          {!checkReceipt ? (
            <>
              <div className="col-lg-4">
                <Input
                  id="cf_piva"
                  label="Partita Iva*"
                  required
                  type="text"
                  name="cf_piva"
                  value={data.cf_piva}
                  {...(validation &&
                    !data.cf_piva && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  {...(validation &&
                    errorCfPiva &&
                    !checkReceipt && {
                      infoText: "La Partita IVA deve essere di 11 caratteri!",
                    })}
                  {...(errorCfPiva && { className: "text-danger" })}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-8">
                <Input
                  id="cognomeDenominazione"
                  label="Ragione Sociale*"
                  required
                  type="text"
                  name="cognomeDenominazione"
                  value={data.cognomeDenominazione}
                  {...(validation &&
                    !data.cognomeDenominazione && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  onChange={handleChange}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-4">
                <Input
                  id="cf_piva"
                  label="Codice Fiscale*"
                  required
                  type="text"
                  name="cf_piva"
                  value={data.cf_piva}
                  {...(validation &&
                    !data.cf_piva && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  {...(validation &&
                    errorCfPiva &&
                    checkReceipt && {
                      infoText:
                        "Il Codice Fiscale deve essere di 16 caratteri!",
                    })}
                  {...(errorCfPiva && { className: "text-danger" })}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  id="cognomeDenominazione"
                  label="Cognome*"
                  required
                  type="text"
                  name="cognomeDenominazione"
                  value={data.cognomeDenominazione}
                  {...(validation &&
                    !data.cognomeDenominazione && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  id="nome"
                  label="Nome*"
                  required
                  type="text"
                  name="nome"
                  value={data.nome}
                  {...(validation &&
                    !data.nome && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
        </div>
        <div className="row form-group">
          {nazioneItalia ? (
            <>
              <div className="col-lg-3">
                <div className="bootstrap-select-wrapper col-12">
                  <Select
                    aria-label="Scegli una nazione"
                    clearText="Annulla"
                    defaultMenuIsOpen={false}
                    defaultValue="ITALIA"
                    icon={false}
                    id="selectExampleClassic"
                    onChange={changeNazione}
                    options={listaNazioni}
                    isSearchable
                    placeholder="ITALIA"
                  />
                </div>
              </div>

              {validation && !selectedNazione ? (
                <small id="errorSelect" className="form-text  text-danger">
                  Seleziona un campo
                </small>
              ) : (
                <></>
              )}

              <div className="col-lg-3">
                <div className="bootstrap-select-wrapper col-12">
                  <Select
                    aria-label="Scegli una provincia*"
                    clearText="Annulla"
                    defaultMenuIsOpen={false}
                    value={selectedProvincia ? selectedProvincia : ""}
                    icon={false}
                    id="selectExampleClassic"
                    onChange={changeProvincia}
                    options={listaProvince}
                    isSearchable
                    placeholder="Scegli una provincia"
                  />
                </div>
                {validation && !selectedProvincia ? (
                  <small id="errorSelect" className="form-text  text-danger">
                    Seleziona un campo
                  </small>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-lg-4">
                <div className="bootstrap-select-wrapper col-12">
                  <Select
                    aria-label="Scegli un comune*"
                    clearText="Annulla"
                    defaultMenuIsOpen={false}
                    value={selectedComune ? selectedComune : ""}
                    icon={false}
                    id="selectExampleClassic"
                    onChange={changeComune}
                    options={listaComuni}
                    isSearchable
                    placeholder="Scegli un comune"
                  />
                </div>
                {validation && !selectedComune ? (
                  <small id="errorSelect" className="form-text  text-danger">
                    Seleziona un campo
                  </small>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-lg-2">
                <Input
                  id="cap"
                  label="CAP*"
                  type="text"
                  required
                  name="cap"
                  value={data.cap}
                  {...(validation &&
                    !data.cap && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  {...((validation && data.cap.length < 5) ||
                    (data.cap.length > 5 && {
                      infoText: "Il campo deve contenere 5 caratteri!",
                      className: "text-danger",
                    }))}
                  onChange={handleChange}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-4">
                <div className="bootstrap-select-wrapper col-12">
                  <Select
                    aria-label="Scegli una nazione"
                    clearText="Annulla"
                    defaultMenuIsOpen={false}
                    defaultValue="ITALIA"
                    icon={false}
                    id="selectExampleClassic"
                    onChange={changeNazione}
                    options={listaNazioni}
                    isSearchable
                    placeholder="ITALIA"
                  />
                </div>
                {validation && !selectedNazione ? (
                  <small id="errorSelect" className="form-text  text-danger">
                    Seleziona un campo
                  </small>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-lg-2">
                <Input
                  id="provincia"
                  required
                  label="Provincia*"
                  type="text"
                  value={selectedProvincia}
                  name="provincia"
                  {...(validation &&
                    !selectedProvincia && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  id="citta"
                  label="Città*"
                  type="text"
                  required
                  name="citta"
                  value={selectedComune}
                  {...(validation &&
                    !selectedComune && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  onChange={handleChange}
                />
              </div>

              <div className="col-lg-2">
                <Input
                  id="cap"
                  label="CAP*"
                  type="text"
                  required
                  name="cap"
                  value={data.cap}
                  {...(validation &&
                    !data.cap && {
                      infoText: "Il campo non può essere vuoto!",
                      className: "text-danger",
                    })}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
        </div>
        <div className="row form-group">
          <div className="col-lg-4">
            <Input
              id="indirizzo"
              label="Indirizzo*"
              type="text"
              required
              name="indirizzo"
              value={data.indirizzo}
              {...(validation &&
                !data.indirizzo && {
                  infoText: "Il campo non può essere vuoto!",
                  className: "text-danger",
                })}
              onChange={handleChange}
            />
          </div>

          <div className="col-lg-4">
            <Input
              id="telefono"
              label="Telefono"
              type="text"
              required
              name="telefono"
              value={data.telefono}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-4">
            <div className="wrapper" style={{ position: "relative" }}>
              <svg
                className="icon icon-warning ml-3"
                style={{
                  cursor: "pointer",
                  zIndex: 1,
                  position: "absolute",
                  right: 0,
                  top: 7,
                }}
                onClick={() => {
                  toggleModalAlertEmail(true);
                }}
              >
                <use href={`${iconSprite}#it-warning-circle`}></use>
              </svg>
              <Input
                id="email"
                label="Email*"
                type="email"
                required
                name="email"
                style={{ paddingRight: "10%" }}
                value={data.email}
                {...(validation &&
                  !data.email && {
                    infoText: "Il campo non può essere vuoto!",
                    className: "text-danger",
                  })}
                {...(data.email &&
                  !validationEmail && {
                    infoText: "Il formato della mail non è corretto!",
                    className: "text-danger",
                  })}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        {!checkReceipt && (
          <>
            <div className="row">
              <div
                className="col-lg-12 mb-4 pl-2 mt-3"
                style={{ fontSize: "21px" }}
              >
                <b>
                  <u>Compila almeno uno dei seguenti campi.</u>
                </b>
              </div>
            </div>
            <div className="row form-group mb-0">
              <div className="col-lg-4">
                <Input
                  id="pecCessionario"
                  label="PEC"
                  type="text"
                  name="pecCessionario"
                  value={data.pecCessionario}
                  {...(data.pecCessionario &&
                    !validationPec && {
                      infoText: "Il formato della PEC non è corretto!",
                      className: "text-danger",
                    })}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  id="codiceSDI"
                  label="Codice SDI"
                  type="text"
                  name="codiceSDI"
                  value={data.codiceSDI}
                  onChange={handleChange}
                />
              </div>
            </div>
            {!data.pecCessionario && !data.codiceSDI && validation && (
              <small id="errorSelect" className="form-text text-danger">
                Specificare il codice SDI oppure la PEC!
              </small>
            )}
          </>
        )}

        <div className="row">
          <div className="col-lg-12 text-right">
            <button
              onClick={() => sendForm()}
              className="btn btn-primary text-white"
            >
              Invia Dati
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="card card-teaser rounded shadow mb-5">
        <div className="card-body">
          <h4 className="card-title mb-4">Dati di Fatturazione</h4>
          <div
            className="row col-lg-4 border border-primary rounded mb-4 mt-5 text-center ml-1 p-0 align-items-center"
            style={{ height: "40px" }}
          >
            <div
              className="col-6 p-0 border-right border-primary rounded pt-1"
              style={{
                backgroundColor: backgroundColorF,
                color: textColorF,
                cursor: "pointer",
                height: "40px",
              }}
              onClick={() => setCheckReceipt(false)}
            >
              <a>
                <b>Azienda</b>
              </a>
            </div>
            <div
              className="col-6 p-0 rounded pt-1"
              style={{
                backgroundColor: backgroundColorG,
                color: textColorG,
                cursor: "pointer",
                height: "40px",
              }}
              onClick={() => setCheckReceipt(true)}
            >
              <a>
                <b>Privato</b>
              </a>
            </div>
          </div>

          {fatturazionForm()}
        </div>
      </div>
      <Modal
        isOpen={isOpenAlertEmail}
        toggle={() => toggleModalAlertEmail(!isOpenAlertEmail)}
        size={"lg"}
        labelledBy="Modale1"
      >
        <ModalHeader id="Modale1">Attenzione!</ModalHeader>
        <ModalBody>
          Prestare particolarmente attenzione alla mail poichè sarà la medesima
          a cui verrà inviata la Fatturazione ed il documento di Delega da
          firmare, ove fosse necessario.
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary text-white text-center"
            onClick={() => {
              toggleModalAlertEmail(false);
            }}
          >
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Fatturazione;
