import { useEffect, useState } from "react";
import Select from "../Objects/Select";
import Repo from "../Utility/Repo";
import { Input } from "design-react-kit";

function ImmobileForm(props) {
  const [listaComuni, setListaComuni] = useState([]);
  const [listaProvince, setListaProvince] = useState([]);
  const [selectedProvincia, setSelectedProvincia] = useState();
  const [selectedComune, setSelectedComune] = useState();
  const [selectedCatasto, setSelectedCatasto] = useState();
  const [immobili, setImmobili] = useState([]);
  const [token, setToken] = useState(props.token);
  const [scroll, setScroll] = useState(false);

  //TO-DO:
  //- gestione select di sezione censuaria per quando si avrà l'api con le options
  //- capire se le select sono dipendenti e gestirle
  //- implementazione sendForm con validazione

  useEffect(() => {
    setSelectedProvincia(null);
    Repo.getProvince(token)
      .then((response) => {
        setListaProvince(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    Repo.getListaImmobili(token)
      .then((response) => {
        setImmobili(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const changeComune = (event) => {
    setSelectedComune({
      value: event.value,
      label: event.label,
    });

    props.setData((prevState) => ({
      ...prevState,
      comune: event.label,
    }));
  };

  const changeProvincia = (event) => {
    setSelectedProvincia({
      value: event.value,
      label: event.label,
    });
    Repo.getComuni(token, event.value)
      .then((response) => {
        setListaComuni(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    props.setData((prevState) => ({
      ...prevState,
      provincia: event.value,
    }));
  };

  const changeCatasto = (event) => {
    setSelectedCatasto({
      value: event.value,
      label: event.label,
    });

    props.setData((prevState) => ({
      ...prevState,
      tipoImmobileId: parseInt(event.value),
    }));
  };

  const handleChange = (event, num) => {
    let value = event.target.value;
    let name = event.target.name;

    switch (name) {
      case "mappale":
        props.setData((prevState) => ({
          ...prevState,
          mappale: value,
        }));

        break;

      case "foglio":
        props.setData((prevState) => ({
          ...prevState,
          foglio: value,
        }));

        break;

      case "subalterno":
        props.setData((prevState) => ({
          ...prevState,
          subalterno: value,
        }));
        break;
    }
  };

  return (
    <>
      <div
        className="row form-group pl-5 px-5 pt-2 pb-2"
        style={{
          borderRadius: "5px",
          border: "2px solid rgb(46, 88, 166, 0.35)",
        }}
      >
        <div className="col-lg-12 mb-2">
          <div className="col-lg-12 mb-3 text-center">
            <a style={{ fontSize: "20px" }}>
              <b>Dati dell'immobile</b>
            </a>
          </div>
          <a>
            <div className="row">
              <div className="col-lg-12">
                <Select
                  aria-label="Tipo Immobile"
                  clearText="Annulla"
                  defaultMenuIsOpen={false}
                  value={selectedCatasto ? selectedCatasto : ""}
                  icon={false}
                  id="selectExampleClassic"
                  onChange={changeCatasto}
                  options={immobili}
                  isSearchable
                  placeholder="Seleziona tipo Immobile"
                />
                {props.validationStep && !selectedCatasto ? (
                  <small
                    id="errorSelect"
                    className="form-text error_div text-danger"
                  >
                    Seleziona un campo
                  </small>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-8 mb-4 p-0">
                <div className="bootstrap-select-wrapper col-12">
                  <Select
                    aria-label="Scegli una provincia"
                    clearText="Annulla"
                    defaultMenuIsOpen={false}
                    value={selectedProvincia ? selectedProvincia : ""}
                    icon={false}
                    id="selectExampleClassic"
                    onChange={changeProvincia}
                    options={listaProvince}
                    isSearchable
                    placeholder="Scegli una provincia"
                  />
                </div>
                {props.validationStep && !selectedProvincia ? (
                  <small
                    id="errorSelect"
                    className="form-text error_div text-danger"
                  >
                    Seleziona un campo
                  </small>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-lg-4 p-0">
                <div className="bootstrap-select-wrapper col-12">
                  <Select
                    aria-label="Scegli un comune"
                    clearText="Annulla"
                    defaultMenuIsOpen={false}
                    value={selectedComune ? selectedComune : ""}
                    icon={false}
                    id="selectExampleClassic"
                    onChange={changeComune}
                    options={listaComuni}
                    isSearchable
                    placeholder="Scegli un comune"
                  />
                </div>
                {props.validationStep && !selectedComune ? (
                  <small
                    id="errorSelect"
                    className="form-text error_div text-danger"
                  >
                    Seleziona un campo
                  </small>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </a>
        </div>
      </div>
      <div
        className="row form-group pl-5 px-5 pt-4 pb-2"
        style={{
          borderRadius: "5px",
          border: "2px solid rgb(46, 88, 166, 0.35)",
        }}
      >
        <div className="col-lg-6">
          <Input
            id="foglio"
            required
            label="Foglio*"
            type="text"
            value={props.dataObj.foglio || ""}
            name="foglio"
            {...(props.validationStep &&
              !props.dataObj.foglio && {
                infoText: "Il campo non può essere vuoto!",
                className: "text-danger error_div",
              })}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6">
          <Input
            id="mappale"
            label="Particella*"
            type="text"
            value={props.dataObj.mappale || ""}
            name="mappale"
            {...(props.validationStep &&
              !props.dataObj.mappale && {
                infoText: "Il campo non può essere vuoto!",
                className: "text-danger error_div",
              })}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6">
          <Input
            id="subalterno"
            type="text"
            label="Subalterno*"
            value={props.dataObj.subalterno || ""}
            name="subalterno"
            {...(props.validationStep &&
              !props.dataObj.subalterno && {
                infoText: "Il campo non può essere vuoto!",
                className: "text-danger",
              })}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
}

export default ImmobileForm;
