import { useEffect, useState } from "react";
import Select from "../Objects/Select";
import Repo from "../Utility/Repo";
import { Link } from "react-router-dom";
import moment from "moment";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";

function CreaUtenze(props) {
  const [servizi, setServizi] = useState([]);
  const [validatePasswordRegex, setValidatePasswordRegex] = useState();
  const [validatePasswordConfirmRegex, setValidatePasswordConfirmRegex] =
    useState();
  const [validation, setValidation] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [cf, setCf] = useState();
  const [cfValidation, setCfValidation] = useState(true);
  const [dataNascita, setDataNascita] = useState("");
  const [isOpen, toggleModal] = useState(false);
  const [isOpenError, toggleModalError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errorApi, setErrorApi] = useState();

  const reg = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(true);
  // Gestione validazione mail
  const [email, setEmail] = useState();
  const [emailReg, setEmailReg] = useState("");
  const [validationEmail, setValidationEmail] = useState(true);

  const [role, setRole] = useState();

  const [roleOperator, setRoleOperator] = useState([
    { label: "OPERATORE", value: "OPERATOR" },
    { label: "PROFESSIONISTA", value: "SUPER_DELEGATE" },
  ]);

  const validatePassword = (event) => {
    let value = event.value;

    if (event.name == "password") {
      setPassword(value.toString());
      validateInput(event.name, value.toString());
    } else {
      setConfirmPassword(value.toString(), value.toString());
      validateInput(event.name, value.toString());
    }
  };

  const validateInput = (name, value) => {
    if (name == "password") {
      const regexPW =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/m;

      if (!value.match(regexPW)) {
        setValidatePasswordRegex(false);
      } else {
        setValidatePasswordRegex(true);
      }
      if (confirmPassword == value) {
        setValidatePasswordConfirmRegex(true);
      } else {
        setValidatePasswordConfirmRegex(false);
      }
    } else {
      if (value == password) {
        setValidatePasswordConfirmRegex(true);
      } else {
        setValidatePasswordConfirmRegex(false);
      }
    }
  };

  const sendForm = (event) => {
    event.preventDefault();
    setLoading(false);
    setValidation(true);

    const regexCF =
      /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/gm;

    let passedValidation = true;

    if (!firstName) passedValidation = false;
    if (!lastName) passedValidation = false;
    if (!cf) passedValidation = false;
    else {
      if (!cf.match(regexCF)) {
        setCfValidation(false);
        passedValidation = false;
      } else setCfValidation(true);
    }
    // if (!street) passedValidation = false;
    // if (!houseNumber) passedValidation = false;
    // if (!city) passedValidation = false;
    // if (!cap) passedValidation = false;
    // if (!phone) passedValidation = false;

    if (!emailReg) passedValidation = false;

    if (validatePasswordConfirmRegex && validatePasswordRegex) {
      if (confirmPassword == password) {
        if (passedValidation && validationEmail) {
          var request = {
            Password: password,
            ConfirmPassword: confirmPassword,
            Email: emailReg,
            LastName: lastName,
            FirstName: firstName,
            FiscalCode: cf,
            DocumentType: null,
            DocumentNumber: null,
            DocumentDate: null,
            Birthdate: moment(dataNascita).format("MM/DD/YYYY"),
            PrivacyConfirmed: 1,
            PrivacyCommercialConfirmed: null,
          };

          Repo.postRegistration(JSON.stringify(request))

            .then((response) => {
              setLoading(true);
              if (response.status == 200) {
                setDisableButton(true);
                toggleModal(!isOpen);
              }
            })
            .catch((error) => {
              setLoading(true);
              if (error.response.status == 400) {
                setErrorApi(error.response.data.Message);
                toggleModalError(!isOpenError);
              }
            });
        }
      }
    } else {
      setDisableButton(false);
    }
  };

  useEffect(() => {
    setLoading(false);
    Repo.getListaServizi(props.token)
      .then((response) => {
        setServizi(response.data);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  }, []);

  return (
    <form className="needs-validation" onSubmit={sendForm}>
      <h5 className="card-title">
        <h3>Registra Utenza</h3>
      </h5>
      <br />
      <div className="row">
        <div className="col-12 mb-2">
          <h6>Dati</h6>
        </div>

        <div className="col-lg-3">
          <Input
            onChange={(e) => setFirstName(e.target.value)}
            id="firstName "
            label="Nome"
            name="firstName"
            {...(validation &&
              !firstName && {
                infoText: "Il campo non può essere vuoto!",
                className: "text-danger",
              })}
          />
        </div>

        <div className="col-lg-2">
          <Input
            label="Cognome"
            id="lastName"
            name="lastName"
            {...(validation &&
              !lastName && {
                infoText: "Il campo non può essere vuoto!",
                className: "text-danger",
              })}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        <div className="col-lg-2">
          <Input
            label="Codice Fiscale"
            id="cf"
            name="cf"
            {...(validation &&
              !cf && {
                infoText: "Il campo non può essere vuoto!",
                className: "text-danger",
              })}
            {...(validation &&
              !cfValidation && {
                infoText: "Codice Fiscale non coretto",
                className: "text-danger",
              })}
            onChange={(e) => setCf(e.target.value)}
          />
        </div>
        <div className="col-lg-2">
          <Input
            id="dataNascita"
            required
            label="Data di Nascita"
            type="date"
            value={moment(dataNascita).format("YYYY-MM-DD")}
            name="dataNascita"
            max={moment().subtract(16, "year").format("YYYY-MM-DD")}
            placeholder="Seleziona una data"
            onChange={(e) => setDataNascita(e.target.value)}
          />
        </div>
        <div className="col-lg-3">
          <Input
            onChange={(e) => setEmailReg(e.target.value)}
            label="Email"
            name="email"
            id="email"
            {...(validation &&
              !emailReg && {
                infoText: "Il campo non può essere vuoto!",
                className: "text-danger",
              })}
            {...(validation && !emailReg && {})}
            {...(emailReg &&
              !validationEmail && {
                infoText: "Il formato della mail non è corretto!",
                className: "text-danger",
              })}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-12 mb-3">
          <h6>Credenziali</h6>
        </div>

        <div className="col-lg-4">
          <Input
            type="password"
            onChange={(e) => validatePassword(e.target)}
            id="password"
            label="Password"
            name="password"
            infoText="Inserisci almeno 8 caratteri, una lettera maiuscola e un simbolo"
            required
            {...(validatePasswordRegex == false && {
              infoText: "Inserisci una password valida!",
              className: "text-danger",
            })}
            {...(validatePasswordRegex && {
              infoText: "Password Valida!",
              className: "text-success",
            })}
          />
        </div>

        <div className="col-lg-4">
          <Input
            type="password"
            onChange={(e) => validatePassword(e.target)}
            id="confirmPassword"
            label="Conferma Password"
            name="confirmPassword"
            infoText="Ripeti la password inserita nel campo in alto"
            required
            {...(validatePasswordConfirmRegex == false && {
              infoText: "La password non corrisponde!",
              className: "text-danger",
            })}
            {...(validatePasswordConfirmRegex &&
              password !== "" && {
                infoText: "La password corrisponde!",
                className: "text-success",
              })}
          />
        </div>

        <div className="bootstrap-select-wrapper col-4 mb-5 ">
          <Select
            aria-label="Scegli ruolo"
            clearText="Annulla"
            defaultMenuIsOpen={false}
            value={role ? role : ""}
            icon={false}
            id="ruolo"
            onChange={(e) => setRole(e)}
            options={roleOperator}
            placeholder="Scegli ruolo"
          />
        </div>
      </div>

      {role && role.value == "SUPER_DELEGATE" && (
        <>
          <div className="row">
            <div className="col-12 mb-3">
              <h6>Prezzi Servizi</h6>
            </div>
          </div>

          {servizi &&
            servizi.map((x) => (
              <div className="row">
                <div className="col-lg-6">ciao</div>
                <div className="col-lg-2">ciao</div>
              </div>
            ))}
        </>
      )}
      <div className="row mt-4">
        <div className="col-lg-12 text-right ">
          <button
            type="submit"
            disabled={disableButton}
            className="btn btn-primary mt-4"
          >
            Conferma
          </button>
        </div>
      </div>
    </form>
  );
}
export default CreaUtenze;
