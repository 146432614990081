import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
} from "design-react-kit";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function ListaRichiesteCoda(props) {
  const [idDel1, setIdDel1] = useState();
  const [isOpen, toggleModal] = useState(false);

  const delRichiestaOrdine = (x) => {
    props.delRichiesta(x);
  };

  const reloadPageRichieste = () => {
    var result = props.children.find((obj) => {
      return obj.isDelega === true;
    });

    if (result && result.isDelega === true) {
      window.location.href = props.subfolder + "/Delega";
    } else {
      window.location.href = props.subfolder + "/Fatturazione";
    }
  };

  return (
    <>
      <div className="row d-flex align-items-center">
        <div className="col-lg-12">
          <div className="card card-teaser rounded shadow mb-3 p-0">
            <div className="card-body ">
              <div
                id="accordionDiv2"
                className="collapse-div"
                role="tablist"
                style={{ borderBottom: "none" }}
              >
                <div className="collapse-header" id="headingA2">
                  <button
                    data-toggle="collapse"
                    data-target="#accordion2"
                    aria-expanded="true"
                    aria-controls="accordion2"
                    className="collapseCustom card-title text-dark"
                  >
                    RICHIESTE IN CODA
                  </button>
                </div>

                <div className="it-list-wrapper">
                  <ul className="it-list">
                    {props.children &&
                      props.children.length > 0 &&
                      props.children.map((x, index) => (
                        <li
                          key={index}
                          id="accordion2"
                          className="collapse"
                          role="tabpanel"
                          aria-labelledby="headingA2"
                          data-parent="#accordionDiv2"
                        >
                          {index == props.children.length - 1 ? (
                            <>
                              <a>
                                <div className="it-right-zone border-bottom-0">
                                  <span className="text">
                                    - {x.descrizione}
                                  </span>
                                  <svg
                                    className="icon icon-sm icon-danger"
                                    aria-hidden="true"
                                    onClick={() => {
                                      setIdDel1(x.id);
                                      toggleModal(!isOpen);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <use href={`${iconSprite}#it-delete`} />
                                  </svg>
                                </div>
                              </a>
                            </>
                          ) : (
                            <>
                              <a>
                                <div className="it-right-zone">
                                  <span className="text">
                                    - {x.descrizione}
                                  </span>
                                  <svg
                                    className="icon icon-sm icon-danger"
                                    aria-hidden="true"
                                    onClick={() => {
                                      setIdDel1(x.id);
                                      toggleModal(!isOpen);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <use href={`${iconSprite}#it-delete`} />
                                  </svg>
                                </div>
                              </a>
                            </>
                          )}
                        </li>
                      ))}
                  </ul>
                  <div
                    className="collapse row"
                    id="accordion2"
                    role="tabpanel"
                    aria-labelledby="headingA2"
                    data-parent="#accordionDiv2"
                  >
                    <div className="col-12 text-right p-4">
                      <button
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Procedi senza inserire nuovi servizi"
                        className="btn btn-primary"
                        onClick={() => reloadPageRichieste()}
                      >
                        Completa l'ordine
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal
          isOpen={isOpen}
          toggle={() => toggleModal(!isOpen)}
          size={"lg"}
          labelledBy="Modale1"
        >
          <ModalHeader id="Modale1">ELIMINAZIONE RICHIESTA IN CODA</ModalHeader>
          <ModalBody>
            {" "}
            Stai per eliminare una richiesta in coda. Sei sicuro di voler
            procedere ?
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger text-white text-center"
              onClick={() => delRichiestaOrdine(idDel1)}
            >
              Elimina
            </Button>
            <Button
              color="primary text-white text-center"
              onClick={() => toggleModal(!isOpen)}
            >
              Annulla
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default ListaRichiesteCoda;
