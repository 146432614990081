import { useEffect, useState } from "react";
import iconCheckOk from "bootstrap-italia/dist/svg/sprite.svg";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import Loader from "../Utility/Loader";
import Select from "../Objects/Select";
import moment from "moment";
import Fatturazione from "../Objects/Fatturazione";
import Repo from "../Utility/Repo";
import ListaVisureCatastali from "../Objects/ListaVisureCatastali";
import { useLocation } from "react-router";
import useLocalStorage from "../Utility/useLocalStorage";
import { Link } from "react-router-dom";
import UploadFile from "../Objects/UploadFile";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Riepilogo(props) {
  const [token, setToken] = useState(props.token);
  const [riepilogoOrdine, setRiepilogoOrdine] = useState({});
  const [filePdf, setFilePdf] = useState();
  const [datiFatturazione, setDatiFatturazione] = useState();
  const [listaVisure, setListaVisure] = useState([]);
  let { search } = useLocation();
  const queryP = new URLSearchParams(search);
  const idPagamento = queryP.get("sessionId");
  const stato = queryP.get("status");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sendData, setSendData] = useState({});
  const [isVerified, setIsVerified] = useState();
  const [isPayed, setIsPayed] = useState();
  const [disableButton, setDisableButton] = useState(true);
  const [privacyOne, setPrivacyOne] = useState(false);
  const [loadingPayement, setLoadingPayement] = useState(true);
  const [fileDoc1, setFileDoc1] = useState();
  const [fileDoc2, setFileDoc2] = useState();
  const [isOpen, toggleModal] = useState(false);
  const [isOpenImage, toggleModalImage] = useState(false);
  const [delegaFirmata, setDelegaFirmata] = useState();

  let query = useQuery();

  useEffect(() => {
    setLoading(false);
    Repo.getRiepilogoOrdine(token, idPagamento)
      .then((response) => {
        if ((response.status = 200)) {
          if (response.data.fatturazioneCliente.delegaPDF) {
            var obj = document.createElement("object");
            obj.type = "application/pdf";
            obj.data =
              "data:application/pdf;base64," +
              response.data.fatturazioneCliente.delegaPDF;
            setFilePdf(obj);
          }

          if (response.data.fatturazioneCliente.fronteCarta) {
            var obj = document.createElement("object");
            obj.type = "image/jpg";
            obj.data =
              "data:image/jpg;base64," +
              response.data.fatturazioneCliente.fronteCarta;
            setFileDoc1(obj);
          }

          if (response.data.fatturazioneCliente.retroCarta) {
            var obj = document.createElement("object");
            obj.type = "image/jpg";
            obj.data =
              "data:image/jpg;base64," +
              response.data.fatturazioneCliente.retroCarta;
            setFileDoc2(obj);
          }

          setRiepilogoOrdine(response.data);
          setDatiFatturazione(response.data.fatturazioneCliente);
          setListaVisure(response.data.richiesteServizi);
          setLoading(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
        // if (isPayed == undefined) {
        //   window.location.href = props.subfolder + "/";
        // }
      });
    delegaFirmata;
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleChangeDelegaFirmata = async (event) => {
    let fileData = event.target.files[0];
    if (event.target.files[0]) {
      var base64FileF = await getBase64(event.target.files[0]);
      var splitBase64F = base64FileF.split(",");
      setDelegaFirmata({
        file: splitBase64F[1],
        nome: fileData.name,
      });
    }
  };

  useEffect(() => {
    if (delegaFirmata != null) {
      let dataDelegaFirmata = {
        delegaPDF: delegaFirmata.file,
      };
      Repo.postDelegaFirmata(
        riepilogoOrdine.fatturazioneCliente.id,
        JSON.stringify(dataDelegaFirmata)
      )
        .then((response) => {
          window.location.href =
            props.subfolder + "/riepilogo?sessionId=" + idPagamento;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [delegaFirmata]);

  useEffect(() => {
    setSendData({
      id: riepilogoOrdine.id,
      price: riepilogoOrdine.importoTotale,
      description: riepilogoOrdine.descrizione,
    });
  }, [riepilogoOrdine]);

  useEffect(() => {
    if (privacyOne) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [privacyOne]);

  const sendPayment = () => {
    if (privacyOne) {
      var dataJson = JSON.stringify(sendData);
      setLoadingPayement(false);
      Repo.postPagamento(props.token, dataJson)
        .then((response) => {
          window.location.href = response.data;
          setLoadingPayement(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (query.get("success")) {
      setIsPayed(true);
      if (query.get("success") == "False") {
        setIsVerified(false);
      }
      if (query.get("success") == "True") {
        setIsVerified(true);
      }
    }
  }, []);

  return isPayed ? (
    <div className="main-body mt-2 mb-0 text-center">
      <div className="col-lg-12">
        <div className="card card-teaser rounded shadow">
          <div className="card-body p-3">
            {isVerified ? (
              <>
                <svg className="icon-success icon-xl">
                  <use href={`${iconCheckOk}#it-check-circle`} />
                </svg>
                <br />
                <br />
                <h3>Il pagamento è stato effettuato con successo</h3>
                <h4>
                  Riceverai una mail di conferma dell'ordine, potrebbe essere
                  necessario verificare all'interno dello spam
                </h4>
              </>
            ) : (
              <>
                <svg
                  className="icon-danger icon-xl"
                  style={{ paddingTop: "10%" }}
                >
                  <use href={`${iconCheckOk}#it-close-circle`} />
                </svg>
                <br />
                <br />
                <h3>Il pagamento non è andato a buon fine</h3>
              </>
            )}
            <br />
            <br />
            <button
              className="btn btn-primary mr-1"
              onClick={() =>
                (window.location.href =
                  props.subfolder + "/riepilogo?sessionId=" + idPagamento)
              }
            >
              Torna al riepilogo
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="row">
        <div className="col-12 ">
          <div className="card-wrapper card-space mb-0">
            <div className="card card-bg rounded">
              <div className="card-body">
                {riepilogoOrdine.richiesteServizi &&
                  riepilogoOrdine.richiesteServizi.length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <p className="float-right">
                          Ordine N. {riepilogoOrdine.descrizione}
                        </p>
                      </div>
                    </div>
                  )}

                <div className="row">
                  <div className="col-12">
                    <h3 className="card-title mb-2">Riepilogo</h3>
                    <i>Tempi di Consegna: Entro 2 ore via Email</i>
                  </div>
                </div>
                {riepilogoOrdine.richiesteServizi &&
                riepilogoOrdine.richiesteServizi.length > 0 ? (
                  <>
                    <div className="row">
                      <div className="col-lg-8 pt-3">
                        <a>Visure Totali: </a>
                        <b>
                          {riepilogoOrdine.richiesteServizi &&
                            riepilogoOrdine.richiesteServizi.length > 0 &&
                            riepilogoOrdine.richiesteServizi.length}
                        </b>

                        {stato == "EVASO" && riepilogoOrdine.flagPagato && (
                          <>
                            <div className="row mt-5 pl-2">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                  window.location.href =
                                    "https://dev2smartanpr.servizilocalispa.it/SmartCatasto.WebAPI/api/SmartCatasto/download/" +
                                    idPagamento;
                                }}
                              >
                                Scarica Certificati
                              </button>
                            </div>
                          </>
                        )}

                        {!riepilogoOrdine.flagPagato && (
                          <div className="row mt-4">
                            <div className="col-lg-10">
                              <FormGroup check style={{ marginTop: "0%" }}>
                                <Input
                                  id="disabled-checkbox1"
                                  type="checkbox"
                                  name="privacyOne"
                                  onChange={() => setPrivacyOne(!privacyOne)}
                                />
                                <Label
                                  for="disabled-checkbox1"
                                  check
                                  style={{ lineHeight: "1.5" }}
                                >
                                  <i style={{ fontSize: "0.8rem" }}>
                                    Dichiaro di aver letto e di accettare i{" "}
                                    <Link
                                      to="/termini_condizioni"
                                      target="_blank"
                                    >
                                      <b>Termini e le Condizioni d'Uso</b>
                                    </Link>{" "}
                                    e di aver preso visione della
                                    <Link to="/privacy_policy" target="_blank">
                                      {" "}
                                      <b>Privacy Policy</b>
                                    </Link>
                                  </i>
                                </Label>
                              </FormGroup>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-lg-4 pt-3 float-right">
                        <table className="customTable">
                          <tfoot>
                            <tr>
                              <td>Importo Totale</td>
                              <td>
                                {riepilogoOrdine.importoTotale.toFixed(2)} €
                              </td>
                            </tr>
                          </tfoot>
                          <tbody>
                            <tr>
                              <td>Imponibile</td>
                              <td>
                                {riepilogoOrdine.importoPagato.toFixed(2)} €
                              </td>
                            </tr>
                            <tr>
                              <td>Diritti</td>
                              <td>
                                {riepilogoOrdine.costoEDiritti.toFixed(2)} €
                              </td>
                            </tr>
                            <tr>
                              <td>Iva</td>
                              <td>{riepilogoOrdine.importoIva} €</td>
                            </tr>
                          </tbody>
                        </table>
                        {!riepilogoOrdine.flagPagato ? (
                          <button
                            type="button"
                            style={{ width: "100%", marginTop: "2%" }}
                            className="btn btn-primary"
                            disabled={disableButton}
                            onClick={() => sendPayment()}
                          >
                            Paga Ora
                          </button>
                        ) : (
                          <div
                            className="col-lg-12 pt-3"
                            style={{ textAlign: "center" }}
                          >
                            <svg className="icon icon-success pb-1">
                              <use href={`${iconSprite}#it-check-circle`}></use>
                            </svg>
                            <b className="text-success">Pagamento effettuato</b>
                          </div>
                        )}
                      </div>
                    </div>
                    <hr className="mb-2" />
                    <div className="row">
                      <div className="col-12">
                        <h5 className="card-title mb-2">
                          Dati di fatturazione
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      {riepilogoOrdine.fatturazioneCliente.cf_piva && (
                        <div className="col-4">
                          Codice Fiscale/PIva:{" "}
                          <b>{riepilogoOrdine.fatturazioneCliente.cf_piva}</b>
                        </div>
                      )}
                      {riepilogoOrdine.fatturazioneCliente.nome && (
                        <div className="col-4">
                          Nome:{" "}
                          <b>{riepilogoOrdine.fatturazioneCliente.nome}</b>
                        </div>
                      )}
                      {riepilogoOrdine.fatturazioneCliente
                        .cognomeDenominazione && (
                        <div className="col-4">
                          Cognome/Denominazione:{" "}
                          <b>
                            {
                              riepilogoOrdine.fatturazioneCliente
                                .cognomeDenominazione
                            }
                          </b>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {riepilogoOrdine.fatturazioneCliente.nazione && (
                        <div className="col-4">
                          Nazione:{" "}
                          <b>{riepilogoOrdine.fatturazioneCliente.nazione}</b>
                        </div>
                      )}
                      {riepilogoOrdine.fatturazioneCliente.citta && (
                        <div className="col-4">
                          Citta:{" "}
                          <b>
                            {riepilogoOrdine.fatturazioneCliente.citta} (
                            {riepilogoOrdine.fatturazioneCliente.provincia})
                          </b>
                        </div>
                      )}
                      {riepilogoOrdine.fatturazioneCliente.indirizzo && (
                        <div className="col-4">
                          Indirizzo:{" "}
                          <b>{riepilogoOrdine.fatturazioneCliente.indirizzo}</b>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {riepilogoOrdine.fatturazioneCliente.email && (
                        <div className="col-4">
                          Email:{" "}
                          <b>{riepilogoOrdine.fatturazioneCliente.email}</b>
                        </div>
                      )}
                      {riepilogoOrdine.fatturazioneCliente.telefono && (
                        <div className="col-4">
                          Telefono:{" "}
                          <b>{riepilogoOrdine.fatturazioneCliente.telefono}</b>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {riepilogoOrdine.fatturazioneCliente.codiceSDI && (
                        <div className="col-4">
                          Codice SDI:{" "}
                          <b>{riepilogoOrdine.fatturazioneCliente.codiceSDI}</b>
                        </div>
                      )}
                      {riepilogoOrdine.fatturazioneCliente.pecCessionario && (
                        <div className="col-4">
                          PEC:{" "}
                          <b>
                            {riepilogoOrdine.fatturazioneCliente.pecCessionario}
                          </b>
                        </div>
                      )}
                    </div>

                    {filePdf && (
                      <>
                        <hr className="mb-2" />
                        <div className="row mt-2">
                          <div className="col-12">
                            <h5 className="card-title mb-2">
                              Documentazione Delega
                            </h5>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-lg-2 d-flex align-items-center">
                            <button
                              onClick={() => toggleModalImage(!isOpenImage)}
                              className="btn btn-xs btn-outline-primary"
                            >
                              Visualizza Documenti
                            </button>
                          </div>
                          <div className="col-lg-2 d-flex align-items-center">
                            <button
                              onClick={() => toggleModal(!isOpen)}
                              className="btn btn-xs btn-outline-primary"
                            >
                              {riepilogoOrdine.status == "DELEGA_FIRMATA"
                                ? "Visualizza Delega Firmata"
                                : "Visualizza Delega"}
                            </button>
                          </div>

                          {riepilogoOrdine.status == "RICHIESTA_FIRMA" ||
                          riepilogoOrdine.status == "DELEGA_FIRMATA" ? (
                            <>
                              <div className="col-lg-8 d-flex flex-row-reverse align-items-center pr-5">
                                <input
                                  type="file"
                                  name="uploadDelegaFirmata"
                                  id="uploadDelegaFirmata"
                                  onChange={handleChangeDelegaFirmata}
                                  className="upload"
                                  multiple="multiple"
                                />
                                <label htmlFor="uploadDelegaFirmata">
                                  <svg
                                    className="icon icon-sm"
                                    aria-hidden="true"
                                  >
                                    <use href={`${iconSprite}#it-upload`}></use>
                                  </svg>
                                  <span>Upload</span>
                                </label>
                                {riepilogoOrdine.status == "DELEGA_FIRMATA" ? (
                                  <>
                                    <span className="pr-4">
                                      <svg className="icon icon-success pb-1">
                                        <use
                                          href={`${iconSprite}#it-check-circle`}
                                        ></use>
                                      </svg>
                                      <b className="text-success">
                                        Delega Firmata e Caricata!
                                      </b>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span className="pr-4">
                                      <svg className="icon icon-warning pb-1">
                                        <use
                                          href={`${iconSprite}#it-warning-circle`}
                                        ></use>
                                      </svg>
                                      <b className="text-warning">
                                        E' necessario scaricare la delega,
                                        firmarla e caricarla nuovamente!
                                      </b>
                                    </span>
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            riepilogoOrdine.status == null && (
                              <>
                                <span className="pr-4">
                                  <svg className="icon pb-1">
                                    <use
                                      href={`${iconSprite}#it-warning-circle`}
                                    ></use>
                                  </svg>
                                  <b>La delega verrà firmata in seguito</b>
                                </span>
                              </>
                            )
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>Non sono presenti visure.</>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {riepilogoOrdine.richiesteServizi &&
        riepilogoOrdine.richiesteServizi.length > 0 && (
          <ListaVisureCatastali token={token} riepilogo={riepilogoOrdine} />
        )}

      <Modal
        isOpen={isOpen}
        toggle={() => toggleModal(!isOpen)}
        size={"lg"}
        labelledBy="esempio1"
      >
        <ModalHeader id="esempio1">DELEGA</ModalHeader>
        <ModalBody>
          <object
            width="100%"
            height="900"
            data={filePdf && filePdf.data}
            type="application/pdf"
          ></object>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggleModal(!isOpen)}>
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isOpenImage}
        toggle={() => toggleModalImage(!isOpenImage)}
        size={"lg"}
        labelledBy="esempio1"
      >
        <ModalHeader id="esempio1">DOCUMENTI</ModalHeader>
        <ModalBody>
          <object
            width="50%"
            height="50%"
            data={fileDoc1 && fileDoc1.data}
            type={fileDoc1 && fileDoc1.type}
          ></object>
          <object
            width="50%"
            height="50%"
            data={fileDoc2 && fileDoc2.data}
            type={fileDoc2 && fileDoc2.type}
          ></object>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => toggleModalImage(!isOpenImage)}
          >
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>

      {!loading && (
        <div className="col-12">
          <div className="trasparente">
            <div className="progress-spinner progress-spinner-active spinner"></div>
            <span className="customTextSpinner">Caricamento...</span>
          </div>
        </div>
      )}

      {!loadingPayement && <Loader />}
    </>
  );
}

export default Riepilogo;
