import { React, useState, useEffect } from "react";
import { Markup } from "interweave";

function TerminiCondizioni(props) {
  const [dataHtml, setData] = useState(null);

  useEffect(() => {
    fetch("./terminiCondizioni.html")
      .then((res) => res.text())
      .then((data) => {
        setData(data);
      })
      .catch((err) => console.log(err));
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="card-wrapper card-space shadow ">
        <div className="card card-bg card-big">
          <div className="card-body">
            <Markup content={dataHtml} />
          </div>
        </div>
      </div>
    </>
  );
}

export default TerminiCondizioni;
