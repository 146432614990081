import Repo from "../Utility/Repo";
import { useEffect, useState } from "react";
import Loader from "../Utility/Loader";

function PresaInCarico(props) {
  const [richiesta, setRichiesta] = useState();
  const [loading, setLoading] = useState(true);

  const getRequest = () => {
    setLoading(false);
    Repo.getRichiesta(props.token)
      .then((response) => {
        setLoading(true);
        setRichiesta(response.data);
        props.setViewListaRichieste(true);
      })
      .catch((error) => {
        setLoading(true);
        console.log(error);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  };

  useEffect(() => {
    props.setListaRichieste(richiesta);
  }, [richiesta]);

  return (
    <>
      <h4 className="card-title mb-4">
        Numero di richieste in coda: {props.richiesteN}
      </h4>
      <div className="row">
        <div className="col-lg-10 pt-2">
          Per elaborare una richiesta, selezionare il pulsante accanto.
        </div>

        {props.richiesteN != 0 && (
          <>
            <div className="col-lg-2">
              <button
                className="btn btn-primary"
                onClick={() => getRequest()}
                disabled={
                  props.isSuperDelegate
                    ? props.isDatiDocumento
                      ? false
                      : true
                    : false
                }
              >
                Presa in carico
              </button>
            </div>
          </>
        )}
      </div>

      {!loading && <Loader />}
    </>
  );
}
export default PresaInCarico;
