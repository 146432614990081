import { useEffect, useState } from "react";
import ListaStorico from "../Objects/ListaStorico";
import Repo from "../Utility/Repo";

function StoricoRichieste(props) {
  const [listaStorico, setListaStorico] = useState();
  const [token, setToken] = useState(props.token);

  useEffect(() => {
    Repo.getStoricoRichieste(token)
      .then((response) => {
        setListaStorico(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  }, []);

  return (
    <>
      <div className="col-lg-12 p-0">
        <h2 className="mb-3">Richieste in Corso</h2>

        {listaStorico && listaStorico.length > 0 ? (
          <>
            <ListaStorico
              subfolder={props.subfolder}
              token={token}
              isSuperDelegate={props.isSuperDelegate}
            >
              {listaStorico}
            </ListaStorico>
          </>
        ) : (
          <h5>Non ci sono richieste elaborate!</h5>
        )}
      </div>
    </>
  );
}

export default StoricoRichieste;
