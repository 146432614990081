import { useEffect, useState } from "react";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import ListaRichiesteVisure from "../Objects/ListaRichiesteVisure";
import PresaInCarico from "../Objects/PresaInCarico";
import Repo from "../Utility/Repo";
import { useLocation } from "react-router";
import Select from "../Objects/Select";
import InfiniteScroll from "react-infinite-scroll-component";
import Pagination from "../Utility/Pagination";
import Loader from "../Utility/Loader";

function RichiesteSuperDelegato(props) {
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage] = useState(5);
  const [items, setItems] = useState([]);
  const [viewListaRichieste, setViewListaRichieste] = useState(false);
  const [token, setToken] = useState(props.token);
  const [richiesteN, setRichiesteN] = useState();
  const [listaRichieste, setListaRichieste] = useState([]);
  const [richiesteNonFiltrate, setRichiesteNonFiltrate] = useState([]);
  const [isDatiDocumento, setIsDatiDocumento] = useState(true);
  const [loading, setLoading] = useState(true);
  const [statiPagamento, setStatiPagamento] = useState([
    { label: "Ordini in Coda", value: 0 },
    { label: "Ordini Non Completati", value: 1 },
    { label: "Tutti gli Ordini", value: 2 },
  ]);

  let { search } = useLocation();
  const queryP = new URLSearchParams(search);

  useEffect(() => {
    setLoading(false);
    if (queryP.get("viewList") === "true") {
      setViewListaRichieste(true);
      if (queryP.get("pagamentoId")) {
        const idPagamento = queryP.get("pagamentoId");
        Repo.getRichiestaFromPagamento(token, idPagamento)
          .then((response) => {
            setListaRichieste(response.data);
            setLoading(true);
          })
          .catch((error) => {
            console.log(error);
            setLoading(true);
            if (error.response.status == 401) {
              props.deleteCoockieExpired();
            }
          });
      }
    } else {
      setViewListaRichieste(false);
      Repo.getRichiesteN(token)
        .then((response) => {
          setLoading(true);
          setRichiesteN(response.data);
        })
        .catch((error) => {
          setLoading(true);
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
    Repo.getRichiesteInCoda(token)
      .then((response) => {
        var richiestePagate = response.data.filter(
          (obj) => obj.flagPagato == true
        );

        setItems(richiestePagate);
        setCurrentItems(richiestePagate.slice(0, itemsPerPage));
        setRichiesteNonFiltrate(response.data);
      })

      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });

    Repo.getDatiDocumento(props.token)
      .then((response) => {
        if (response.data.numeroDocumento != null) {
          setIsDatiDocumento(true);
        } else {
          setIsDatiDocumento(false);
        }
      })
      .catch((error) => {
        setIsDatiDocumento(false);
        console.log(error);
      });
  }, []);

  const changePagamento = (event) => {
    var richiesteFiltrate = [];

    if (event.value == 0) {
      richiesteFiltrate = richiesteNonFiltrate.filter(
        (obj) => obj.flagPagato == true
      );
    } else if (event.value == 1) {
      richiesteFiltrate = richiesteNonFiltrate.filter(
        (obj) => obj.flagPagato == false
      );
    } else if (event.value == 2) {
      richiesteFiltrate = richiesteNonFiltrate;
    }

    setItems(richiesteFiltrate);
    setCurrentItems(richiesteFiltrate.slice(0, itemsPerPage));
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {viewListaRichieste ? (
        <>
          <div className="card card-teaser rounded shadow mb-3">
            <div className="card-body">
              {listaRichieste && listaRichieste.length != 0 ? (
                <ListaRichiesteVisure
                  token={token}
                  subfolder={props.subfolder}
                  isSuperDelegate={true}
                >
                  {listaRichieste}
                </ListaRichiesteVisure>
              ) : (
                <>
                  <h5>Non sono presenti richieste!</h5>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          {!isDatiDocumento && (
            <>
              <div
                className="callout callout-highlight warning mb-3"
                style={{ maxWidth: "108ch" }}
              >
                <div className="callout-title mb-0">
                  <svg className="icon icon-warning pb-1">
                    <use href={`${iconSprite}#it-help-circle`}></use>
                  </svg>
                  Attenzione
                </div>
                <div className="row">
                  <div className="col-10 d-flex align-items-center">
                    <p>
                      Per poter elaborare le pratiche è necessario compilare
                      tutti i <b>Dati del Documento di Riconoscimento</b>.{" "}
                    </p>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <button
                      className="btn btn-success"
                      onClick={() =>
                        (window.location.href = props.subfolder + "/profilo")
                      }
                    >
                      Vai al Profilo
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="card card-teaser rounded shadow mb-3">
            <div className="card-body">
              {richiesteN && richiesteN.length != 0 ? (
                <>
                  <PresaInCarico
                    setViewListaRichieste={setViewListaRichieste}
                    richiesteN={richiesteN}
                    token={token}
                    setListaRichieste={setListaRichieste}
                    isDatiDocumento={isDatiDocumento}
                    isSuperDelegate={true}
                  ></PresaInCarico>
                </>
              ) : (
                <>
                  <h5>Non sono presenti richieste!</h5>
                </>
              )}
            </div>
          </div>
          <div className="row mt-5 pl-3">
            <div className="col-lg-4">
              <div className="bootstrap-select-wrapper col-12">
                <Select
                  clearText="Annulla"
                  defaultMenuIsOpen={false}
                  defaultValue="Ordini in Coda"
                  icon={false}
                  id="selectExampleClassic"
                  onChange={changePagamento}
                  options={statiPagamento}
                  isSearchable
                  placeholder="Ordini in Coda"
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            {currentItems && currentItems.length > 0 ? (
              <>
                <div className="it-list-wrapper" style={{ width: "100%" }}>
                  <ul className="it-list">
                    {currentItems.map((x, index) => (
                      <div key={index} className="mt-3">
                        <li className="border-bottom">
                          <div className="p-3">
                            <div className="row">
                              <div className="card-title col-lg-10">
                                <b>{x.descrizione ? x.descrizione : ""}</b>
                              </div>
                              <div className="col-lg-2">
                                {x.isDelega && (
                                  <div
                                    className="chip chip-primary chip-lg"
                                    style={{
                                      backgroundColor: "#2e58a6",
                                    }}
                                  >
                                    <span
                                      className="chip-label"
                                      style={{ color: "white" }}
                                    >
                                      Pratica con Delega
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-5">
                                Importo:{" "}
                                <b>
                                  {x.importoPagato
                                    ? x.importoPagato + " €"
                                    : " - "}
                                </b>
                              </div>
                              <div className="col-lg-4">
                                {x.flagPagato ? (
                                  <>
                                    <svg className="icon icon-success pb-1">
                                      <use
                                        href={`${iconSprite}#it-check-circle`}
                                      ></use>
                                    </svg>
                                    <b className="text-success pl-1">
                                      Pagamento Effettuato
                                    </b>
                                  </>
                                ) : (
                                  <>
                                    <svg className="icon icon-danger pb-1">
                                      <use
                                        href={`${iconSprite}#it-warning-circle`}
                                      ></use>
                                    </svg>
                                    <b className="text-danger pl-1">
                                      Da Pagare
                                    </b>
                                  </>
                                )}
                              </div>
                              <div className="col-lg-3">
                                {x.flagEmail ? (
                                  <>
                                    <svg className="icon icon-success pb-1">
                                      <use
                                        href={`${iconSprite}#it-check-circle`}
                                      ></use>
                                    </svg>
                                    <b className="text-success pl-1">
                                      Email Inviata
                                    </b>
                                  </>
                                ) : (
                                  <>
                                    <svg className="icon icon-danger pb-1">
                                      <use
                                        href={`${iconSprite}#it-warning-circle`}
                                      ></use>
                                    </svg>
                                    <b className="text-danger pl-1">
                                      Email Non Inviata!
                                    </b>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      </div>
                    ))}
                    {items && (
                      <div className="mt-3">
                        <Pagination
                          items={items}
                          setCurrentItems={setCurrentItems}
                          itemsPerPage={5}
                        ></Pagination>
                      </div>
                    )}
                  </ul>
                </div>
              </>
            ) : (
              <>Non sono presenti richieste!</>
            )}
          </div>
          {!loading && <Loader />}
        </>
      )}
    </>
  );
}
export default RichiesteSuperDelegato;
