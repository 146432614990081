const Loader = (props) => {
  return (
    <div className="col-12">
      <div className="trasparente">
        <div className="progress-spinner progress-spinner-active spinner"></div>
        <span className="customTextSpinner">
          {props.customText ? props.customText : "Caricamento..."}
        </span>
      </div>
    </div>
  );
};

export default Loader;
