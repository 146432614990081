import axios from "axios";
import configuration from "./config.json";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const hub_url = window.__RUNTIME_CONFIG__.REACT_APP_HUB_URL;

const auth_url = window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL;

var clientId = "semplicita_catasto";

const Repo = {
  getListaServizi: (token, flag) =>
    axios({
      method: "GET",
      url:
        hub_url +
        configuration.HUB_PATH_GET_LISTA_SERVIZI +
        "?idSettore=" +
        flag,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      params: {},
    }),

  getLinkDelega: (token) =>
    axios({
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_LINK_DELEGA,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      responseType: "blob",
      params: {},
    }),

  getListaImmobili: (token) =>
    axios({
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_LISTA_IMMOBILI,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {},
    }),

  getListaConservatorie: () =>
    axios({
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_LISTA_CONSERVATORIE,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {},
    }),

  postRichiesta: (token, obb) => {
    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_POST_RICHIESTA,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postRichiestaConservatoria: (token, obb) => {
    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_POST_RICHIESTA_CONSERVATORIA,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postUserRichiestaConservatoria: (token, obb) => {
    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_POST_USER_RICHIESTA_CONSERVATORIA,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postUserRichiesta: (token, obb) => {
    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_POST_USER_RICHIESTA,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postPagamento: (token, obb) => {
    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_POST_PAGAMENTO,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postFatturazioneCatastale: (token, obb) => {
    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_POST_FATTURAZIONE_CATASTALE,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postUserFatturazioneCatastale: (token, obb) => {
    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_POST_FATTURAZIONE_USER_CATASTALE,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  getRiepilogoOrdine: (token, idPagamento) =>
    axios({
      method: "GET",
      url:
        hub_url +
        configuration.HUB_PATH_GET_RIEPILOGO_ORDINE +
        "/" +
        idPagamento,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: {},
    }),

  delRichiestaFromOrdine: (token, idRichiesta) => {
    var config = {
      method: "POST",
      url:
        hub_url + configuration.HUB_PATH_DELETE_RICHIESTA + "/" + idRichiesta,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    return axios(config);
  },

  delOrdine: (id) => {
    var config = {
      method: "POST",
      url:
        hub_url + configuration.HUB_PATH_DELETE_ORDINE + "?pagamentoId=" + id,
    };
    return axios(config);
  },

  delServizioFromRichiesta: (token, idServizio, idRichiesta) => {
    var config = {
      method: "POST",
      url:
        hub_url +
        configuration.HUB_PATH_DELETE_SERVIZIO +
        "/" +
        idRichiesta +
        "/" +
        idServizio,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    return axios(config);
  },

  postRegistration: (obb) => {
    var config = {
      method: "post",
      url:
        auth_url +
        configuration.AUTH_PATH_POST_REGISTER +
        "?ClientId=" +
        clientId,
      headers: {
        "Content-Type": "application/json",
      },
      data: obb,
    };

    return axios(config);
  },

  postConfirmRegistration: (userid, code) => {
    var config = {
      method: "post",
      url:
        auth_url +
        configuration.AUTH_PATH_POST_CONFIRM_REGISTER +
        "?userid=" +
        userid +
        "&code=" +
        code,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  postResetPassword: (userid, code, password) => {
    var config = {
      method: "post",
      url:
        auth_url +
        configuration.AUTH_PATH_POST_RESET_PASSWORD +
        "?userid=" +
        userid +
        "&code=" +
        code +
        "&password=" +
        password,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  postLogin: (username, password, role) => {
    let roleSend = "";
    if (role) {
      roleSend = role;
    }

    var config = {
      method: "post",
      url:
        auth_url +
        configuration.AUTH_PATH_POST_LOGIN +
        "?username=" +
        username +
        "&password=" +
        password +
        "&role=" +
        roleSend +
        "&clientId=" +
        clientId,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  postLoginOld: (obb) => {
    var config = {
      method: "post",
      url: auth_url + configuration.AUTH_PATH_POST_LOGIN_OLD,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: obb,
    };
    return axios(config);
  },

  postForgotPassword: (email) => {
    var config = {
      method: "post",
      url:
        auth_url +
        configuration.AUTH_PATH_POST_FORGOT_PASSWORD +
        "?email=" +
        email +
        "&clientId=" +
        clientId,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  getNazioni: (token) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_NAZIONI,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    return axios(config);
  },

  getProvince: (token) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_PROVINCE,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    return axios(config);
  },

  getComuni: (token, provincia) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_COMUNI + "/" + provincia,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    return axios(config);
  },

  getRiepilogoRichieste: (id) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_RIEPILOGO_RICHIESTE + "/" + id,
    };

    return axios(config);
  },

  getUserRiepilogoRichieste: (token) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_USER_RIEPILOGO_RICHIESTE,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    return axios(config);
  },

  getOrdini: (token, params) => {
    let endUrl = "";
    if (params != null) {
      endUrl = "?pagato=" + params;
    }
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_ORDINI + endUrl,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    return axios(config);
  },

  getRichiesteN: (token) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_RICHIESTE_N,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    return axios(config);
  },

  getRichiesta: (token) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_RICHIESTA,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    return axios(config);
  },

  postAllegato: (token, obb) => {
    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_POST_ALLEGATO,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postInsertDelegate: (token, fullName) => {
    var config = {
      method: "post",
      url:
        hub_url +
        configuration.HUB_PATH_POST_DELEGATO +
        "?userName=" +
        fullName,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  postInsertSuperDelegate: (token, fullName) => {
    var config = {
      method: "post",
      url:
        hub_url +
        configuration.HUB_PATH_POST_SUPER_DELEGATO +
        "?userName=" +
        fullName,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  postSalvaPraticaEvasa: (token, idRequest) => {
    var config = {
      method: "post",
      url:
        hub_url +
        configuration.HUB_PATH_POST_SALVA_PRATICA_EVASA +
        "?IdPagamento=" +
        idRequest,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  getStoricoRichieste: (token) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_STORICO_RICHIESTE,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    return axios(config);
  },

  getRichiesteEvaseOperatore: (token) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_RICHIESTE_EVASE,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    return axios(config);
  },

  getRichiesteDeleghe: (token, obj) => {
    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_GET_RICHIESTE_DELEGA,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obj,
    };
    return axios(config);
  },

  postSalvaPraticaLavorazione: (token, idRequest) => {
    var config = {
      method: "post",
      url:
        hub_url +
        configuration.HUB_PATH_POST_SALVA_PRATICA_LAVORAZIONE +
        "?IdPagamento=" +
        idRequest,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  postSalvaPraticaPausa: (token, idRequest) => {
    var config = {
      method: "post",
      url:
        hub_url +
        configuration.HUB_PATH_POST_PAUSA_RICHIESTA +
        "?IdPagamento=" +
        idRequest,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  postRifiutaPratica: (token, obb) => {
    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_POST_RICHIESTA_MESSAGGIO,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postSalvaPraticaRifiutata: (token, idPagamento) => {
    var config = {
      method: "post",
      url:
        hub_url +
        configuration.HUB_PATH_POST_SALVA_PRATICA_RIFIUTATA +
        "?IdPagamento=" +
        idPagamento,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  getRichiestaFromPagamento: (token, idPagamento) => {
    var config = {
      method: "GET",
      url:
        hub_url +
        configuration.HUB_PATH_GET_RICHIESTA_PAGAMENTO +
        "/" +
        idPagamento,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    return axios(config);
  },

  getRichiestaFromPagamentoStats: (token, idPagamento) => {
    var config = {
      method: "GET",
      url:
        hub_url +
        configuration.HUB_PATH_GET_RICHIESTA_PAGAMENTO_STATS +
        "/" +
        idPagamento,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    return axios(config);
  },

  getRichiesteInCoda: (token) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_RICHIESTE_IN_CODA,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    return axios(config);
  },

  getCountRichieste: (token) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_COUNT_RICHIESTE,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  },

  postRichiesteDelegato: (token, obb) => {
    var config = {
      method: "POST",
      url: hub_url + configuration.HUB_PATH_GET_DELEGATO_STATS,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postRichiesteDelegatoById: (token, obb, id) => {
    var config = {
      method: "POST",
      url:
        hub_url +
        configuration.HUB_PATH_GET_DELEGATO_STATS_BYID +
        "?userId=" +
        id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  setPrezzoServizi: (token, obb) => {
    var config = {
      method: "POST",
      url: hub_url + configuration.HUB_PATH_SET_PREZZO_SERVIZI,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  getPrezzoServizi: (token, id) => {
    var config = {
      method: "GET",
      url:
        hub_url +
        configuration.HUB_PATH_GET_PREZZO_SERVIZI +
        "?idOperatore=" +
        id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  getPrezzoServiziPrev: (token, id) => {
    var config = {
      method: "GET",
      url:
        hub_url +
        configuration.HUB_PATH_GET_PREZZO_SERVIZI_PREV +
        "?idOperatore=" +
        id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  postRichiesteProfessionistaById: (token, obb, id) => {
    var config = {
      method: "POST",
      url:
        hub_url +
        configuration.HUB_PATH_GET_PROFESSIONISTA_STATS_BYID +
        "?idOperatore=" +
        id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postChangeDefaultProf: (token, id, flag) => {
    var config = {
      method: "POST",
      url:
        hub_url +
        configuration.HUB_PATH_POST_CHANGE_DEFAULT +
        "?idOp=" +
        id +
        "&defaultDelega=" +
        flag,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  putPercentageDelegato: (token, percentuale, id) => {
    var config = {
      method: "PUT",
      url:
        hub_url +
        configuration.HUB_PATH_PUT_PERCENTUALE_DELEGATO +
        "?percentuale=" +
        percentuale +
        "&userId=" +
        id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  putRielaboraPercentageDelegato: (token, percentuale, id, obb) => {
    var config = {
      method: "PUT",
      url:
        hub_url +
        configuration.HUB_PATH_PUT_RIELABORA_PERCENTUALE_DELEGATO +
        "?percentuale=" +
        percentuale +
        "&userId=" +
        id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  getListaDelegati: (token) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_LISTA_DELEGATI,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  getRichiesteStats: (token) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_PAGAMENTI_STATS,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  postDatiDelega: (token, obb, id) => {
    var config = {
      method: "POST",
      url: hub_url + configuration.HUB_PATH_POST_DATI_DELEGA + "?Id=" + id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postUserDatiDelega: (token, obb) => {
    var config = {
      method: "POST",
      url: hub_url + configuration.HUB_PATH_POST_USER_DATI_DELEGA,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },

      data: obb,
    };
    return axios(config);
  },

  postDatiDocumento: (token, obb) => {
    var config = {
      method: "POST",
      url: hub_url + configuration.HUB_PATH_POST_DATI_DOCUMENTO,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },

      data: obb,
    };
    return axios(config);
  },

  getDatiDocumento: (token) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_DATI_DOCUMENTO,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  getRichiestaFirma: (token, pagamentoId) => {
    var config = {
      method: "GET",
      url:
        hub_url +
        configuration.HUB_PATH_RICHIESTA_FIRMA +
        "?pagamentoId=" +
        pagamentoId,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  postDelegaFirmata: (fatturazioneId, obj) => {
    var config = {
      method: "POST",
      url:
        hub_url +
        configuration.HUB_PATH_POST_DELEGA_FIRMATA +
        "?fatturazioneId=" +
        fatturazioneId,
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };
    return axios(config);
  },

  getDatiImmobili: (token, fatturazioneId) => {
    var urlDatiImmobili = "";
    if (fatturazioneId != null) {
      urlDatiImmobili = "?fatturazioneClienteId=" + fatturazioneId;
    } else {
      urlDatiImmobili = "";
    }
    var config = {
      method: "GET",
      url:
        hub_url +
        configuration.HUB_PATH_GET_DATI_IMMOBILI_DELEGA +
        urlDatiImmobili,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  getOperatoriOptions: (token) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_OPERATORI_LISTA,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  getStatusOptions: (token) => {
    var config = {
      method: "GET",
      url: hub_url + configuration.HUB_PATH_GET_STATUS_LISTA,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },
};

export default Repo;
