import { useEffect, useState } from "react";
import Repo from "../Utility/Repo";
import { Input, FormGroup, Label } from "design-react-kit";
import useLocalStorage from "../Utility/useLocalStorage";
import VisualizzaProfilo from "../Objects/VisualizzaProfilo";

function Profilo(props) {
  const [apiToken, setApiToken] = useState(props.apiToken);
  const [loginLocalStorage, setLoginLocalStorage] =
    useLocalStorage("UserLoggedIn");

  return (
    <>
      <VisualizzaProfilo
        user={!props.operator}
        displayMode={true}
        profile={props.children}
        sendMail={false}
        subfolder={props.subfolder}
      ></VisualizzaProfilo>
    </>
  );
}

export default Profilo;
