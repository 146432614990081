import { useEffect, useState } from "react";
import { Input } from "design-react-kit";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import moment from "moment";
import Repo from "../Utility/Repo";

function VisualizzaProfilo(props) {
  const [validation, setValidation] = useState();
  const [utenteID, setUtenteID] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [cf, setCf] = useState();
  const [cfValidation, setCfValidation] = useState();
  const [passwordValidation, setPasswordValidation] = useState(true);
  const [institution, setInstitution] = useState();
  const [institutionId, setInstitutionId] = useState();
  const [street, setStreet] = useState();
  const [crediti, setCrediti] = useState();
  const [houseNumber, setHouseNumber] = useState();
  const [city, setCity] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [qualification, setQualification] = useState();
  const [subscribers, setSubscribers] = useState(false);
  const [verifySubscribers, setVerifiySubscribers] = useState();
  const [cap, setCap] = useState();
  const [phone, setPhone] = useState();
  const [user, setUser] = useState(props.user);
  const [editForce, setEditForce] = useState(props.displayMode);
  const [isSuperDelegate, setIsSuperDelegate] = useState(false);
  const [dataEmissioneDocumento, setDataEmissioneDocumento] = useState();

  const cookies = new Cookies();
  const [apiToken] = useState(cookies.get("token_key"));

  const [dataDocumento, setDataDocumento] = useState({
    nominativo: "",
    numeroDocumento: "",
    tipoDocumento: "",
    dataEmissioneDocumento: "",
    enteRilascio: "",
    email: "",
  });
  const [documentValidation, setDocumentValidation] = useState();

  function stringToDate(_date, _format, _delimiter) {
    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(
      dateItems[yearIndex],
      month,
      dateItems[dayIndex]
    );
    return formatedDate;
  }

  useEffect(() => {
    if (isSuperDelegate == true) {
      Repo.getDatiDocumento(apiToken)
        .then((response) => {
          setDataDocumento(response.data);
          const d = stringToDate(
            response.data.dataEmissioneDocumento,
            "dd/MM/yyyy",
            "/"
          );

          setDataEmissioneDocumento(moment(d).format("YYYY/MM/DD"));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isSuperDelegate]);

  useEffect(() => {
    if (apiToken) {
      var decoded = jwt_decode(apiToken);
      setFirstName(decoded.FullName.split(" ")[0]);
      setLastName(decoded.FullName.split(" ")[1]);
      setEmail(decoded.unique_name);
      setCf(decoded.FiscalCode);

      if (decoded.role == "SUPER_DELEGATE" && dataDocumento) {
        setIsSuperDelegate(true);
        let tempData = dataDocumento;
        tempData["nominativo"] =
          decoded.FullName.split(" ")[0] + " " + decoded.FullName.split(" ")[1];
        tempData["email"] = decoded.unique_name;
        setDataDocumento(tempData);
      }
    }
  }, [cookies]);

  const sendDataDocumento = () => {
    setDocumentValidation(true);
    let passedValidation = true;

    Object.entries(dataDocumento).map(([key, value]) => {
      if ((value == "" || value == null) && key !== "defaultDeleghe") {
        passedValidation = false;
      }
    });

    if (passedValidation) {
      Repo.postDatiDocumento(apiToken, JSON.stringify(dataDocumento))
        .then((response) => {
          window.location.href = props.subfolder + "/profilo";
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const sendForm = () => {
    setValidation(true);
    const regexCF =
      /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/gm;
    const regexPW = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/m;
    let passedValidation = true;

    if (!firstName) passedValidation = false;
    if (!lastName) passedValidation = false;
    if (!cf) passedValidation = false;
    else {
      if (!cf.match(regexCF)) {
        setCfValidation(false);
        passedValidation = false;
      } else setCfValidation(true);
    }
    if (!city) passedValidation = false;
    if (!email) passedValidation = false;
    if (!qualification) passedValidation = false;
    if (!phone) passedValidation = false;
    if (!houseNumber) passedValidation = false;
    if (!street) passedValidation = false;
    if (!cap) passedValidation = false;
    if (!institution) passedValidation = false;

    if (passedValidation) {
      var request = {
        nome: firstName,
        cognome: lastName,
        codiceFiscale: cf,
        luogoResidenza: city,
        email: email,
        qualifica: qualification,
        telefono: phone,
        nCivico: houseNumber,
        via: street,
        cap: cap,
        enteID: institutionId,
        isTesserato: subscribers,
        isVerificaTesserato: verifySubscribers,
      };
      if (props.sendMail) {
        props.editMethod(request, utenteID);
      } else {
        props.editMethod(request);
      }
    }
  };
  useEffect(() => {
    if (props.profile) {
      var data = props.profile;
      setEditForce(props.displayMode);
      if (data) {
        //utente
        setUtenteID(data.utenteID);
        setFirstName(data.nome);
        setLastName(data.cognome);
        setCrediti(data.crediti && data.crediti);
        setCf(data.codiceFiscale);
        setCity(data.city);
        setEmail(data.email);
        setPassword(data.password);
        setQualification(data.qualifica);
        setPhone(data.telefono);
        setHouseNumber(data.nCivico);
        setStreet(data.via);
        setCap(data.cap);
        setCity(data.luogoResidenza);
        setPassword(data.password);
        setSubscribers(data.isTesserato);
        setVerifiySubscribers(data.isVerificaTesserato);
        setInstitution(data.ente && data.ente.nomeEnte);
        setInstitutionId(data.ente && data.ente.enteID);
      }
    }
  }, [props]);

  const changeInstitution = (event) => {
    setInstitution(event.label);
    setInstitutionId(event.value);
  };

  const obbjTesserato = [
    { label: "SI", value: true },
    { label: "NO", value: false },
  ];

  const changeTesserato = (event) => {
    setSubscribers(event.value);
  };

  const changeQualification = (event) => {
    setQualification(event.value);
  };
  return (
    <div className="card-wrapper card-space mb-0">
      <div className="card card-bg">
        <div className="card-body">
          <div className="d-flex justify-content-center">
            <div className="card-body">
              <div className="col-8">
                <h3>Dati Utente</h3>
              </div>
              <br />
              <div className="row ">
                <div className="col-12 mb-4">
                  <h6>Dati Anagrafici ed Ente</h6>
                </div>
                <div className="col-lg-3">
                  <Input
                    onChange={(e) => setFirstName(e.target.value)}
                    id="firstName "
                    label="Nome"
                    name="firstName"
                    value={firstName ? firstName : ""}
                    {...(validation &&
                      !firstName && {
                        infoText: "Il campo non può essere vuoto!",
                        className: "text-danger",
                      })}
                    disabled={!user ? editForce : true}
                  />
                </div>
                <div className="col-lg-3">
                  <Input
                    label="Cognome"
                    id="lastName"
                    name="lastName"
                    value={lastName ? lastName : ""}
                    {...(validation &&
                      !lastName && {
                        infoText: "Il campo non può essere vuoto!",
                        className: "text-danger",
                      })}
                    onChange={(e) => setLastName(e.target.value)}
                    disabled={!user ? editForce : true}
                  />
                </div>

                <div className="col-lg-6">
                  <Input
                    label="Codice Fiscale"
                    id="cf"
                    name="cf"
                    value={cf ? cf : ""}
                    {...(validation &&
                      !cf && {
                        infoText: "Il campo non può essere vuoto!",
                        className: "text-danger",
                      })}
                    {...(validation &&
                      !cfValidation && {
                        infoText: "Codice Fiscale non coretto",
                        className: "text-danger",
                      })}
                    disabled={!user ? editForce : true}
                    onChange={(e) => setCf(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 mb-4">
                  <h6>Recapiti</h6>
                </div>

                <div className="col-lg-4">
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    name="email"
                    id="email"
                    value={email ? email : ""}
                    {...(validation &&
                      !email && {
                        infoText: "Il campo non può essere vuoto!",
                        className: "text-danger",
                      })}
                    disabled={!user ? editForce : true}
                  />
                </div>
              </div>

              {isSuperDelegate && (
                <>
                  <div className="row mt-2">
                    <div className="col-12 mb-4">
                      <h6>Dati del Documento di Riconoscimento</h6>
                    </div>
                    <div className="col-lg-4">
                      <Input
                        label="Numero Documento"
                        name="numDocumento"
                        type="text"
                        id="numDocumento"
                        maxLength={9}
                        value={dataDocumento.numeroDocumento || " "}
                        onChange={(e) =>
                          setDataDocumento({
                            ...dataDocumento,
                            numeroDocumento: e.target.value,
                          })
                        }
                        {...(documentValidation &&
                          !dataDocumento.numeroDocumento && {
                            infoText: "Il campo non può essere vuoto!",
                            className: "text-danger",
                          })}
                        disabled={!editForce ? editForce : true}
                      />
                    </div>
                    <div className="col-lg-4">
                      <Input
                        label="Tipo Documento"
                        name="tipoDocumento"
                        id="tipoDocumento"
                        type="text"
                        maxLength={30}
                        value={dataDocumento.tipoDocumento || " "}
                        onChange={(e) =>
                          setDataDocumento({
                            ...dataDocumento,
                            tipoDocumento: e.target.value,
                          })
                        }
                        {...(documentValidation &&
                          !dataDocumento.tipoDocumento && {
                            infoText: "Il campo non può essere vuoto!",
                            className: "text-danger",
                          })}
                        disabled={!editForce ? editForce : true}
                      />
                    </div>
                    <div className="col-lg-4">
                      <Input
                        label="Data Emissione"
                        name="dataEmissioneDocumento"
                        id="dataEmissioneDocumento"
                        type="date"
                        value={
                          dataEmissioneDocumento
                            ? moment(Date.parse(dataEmissioneDocumento)).format(
                                "YYYY-MM-DD"
                              )
                            : moment(
                                Date.parse(dataDocumento.dataEmissioneDocumento)
                              ).format("YYYY-MM-DD")
                        }
                        placeholder="sad"
                        onChange={(e) => {
                          setDataDocumento({
                            ...dataDocumento,
                            dataEmissioneDocumento: moment(
                              e.target.value
                            ).format("YYYY-MM-DD"),
                          });
                          setDataEmissioneDocumento();
                        }}
                        {...(documentValidation &&
                          !dataDocumento.dataEmissioneDocumento && {
                            infoText: "Il campo non può essere vuoto!",
                            className: "text-danger",
                          })}
                        disabled={!editForce ? editForce : true}
                      />
                    </div>
                    <div className="col-lg-4">
                      <Input
                        label="Ente di Rilascio"
                        name="enteRilascio"
                        id="enteRilascio"
                        value={dataDocumento.enteRilascio || " "}
                        onChange={(e) =>
                          setDataDocumento({
                            ...dataDocumento,
                            enteRilascio: e.target.value,
                          })
                        }
                        {...(documentValidation &&
                          !dataDocumento.enteRilascio && {
                            infoText: "Il campo non può essere vuoto!",
                            className: "text-danger",
                          })}
                        disabled={!editForce ? editForce : true}
                      />
                    </div>
                  </div>
                </>
              )}

              {isSuperDelegate && (
                <h5>
                  <div className="row">
                    <>
                      {editForce ? (
                        <>
                          {" "}
                          <div className="col-12 text-right">
                            <button
                              type="button"
                              className="btn btn-primary "
                              onClick={() => setEditForce(!editForce)}
                            >
                              Modifica
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="col-10 text-right">
                            <a
                              onClick={() => sendDataDocumento()}
                              className="btn btn-primary text-white"
                            >
                              Salva
                            </a>
                          </div>
                          <div className="col-2 text-right">
                            <button
                              type="submit"
                              className="btn btn-danger "
                              onClick={() => setEditForce(!editForce)}
                            >
                              Indietro
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  </div>
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisualizzaProfilo;
