import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Link } from "react-router-dom";
import heroBackground from "../../Img/hero-background.jpg";
import hero2 from "../../Img/hero2.jpeg";
import hero3 from "../../Img/hero3.jpg";
import Img from "react-cool-img";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Hero(props) {
  var settings = {
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <>
      <Slider {...settings}>
        <div className="it-hero-wrapper it-hero-small-size it-dark it-overlay">
          <div className="img-responsive-wrapper">
            <div className="img-responsive">
              <div className="img-wrapper">
                <Img
                  src="https://semplicitacatasto.it/img/hero3.jpg"
                  debounce={1000}
                  alt="imagealt"
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  style={{ paddingLeft: "0px" }}
                  className="it-hero-text-wrapper bg-dark"
                >
                  <h1 className="customHeroText">
                    Vuoi vendere o acquistare casa?
                  </h1>
                  <p className="customHeroText" style={{ fontSize: "5px" }}>
                    Scopri GRATUITAMENTE il reale valore del tuo immobile.{" "}
                    <br></br>
                    Non trovi la documentazione catastale?
                    <Link to={"/catasto"} style={{ color: "white" }}>
                      RICHIEDILA QUI
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="it-hero-wrapper it-hero-small-size it-dark it-overlay"
          style={{ height: "450px" }}
        >
          <div className="img-responsive-wrapper">
            <div className="img-responsive">
              <div className="img-wrapper">
                <Img
                  src="https://semplicitacatasto.it/img/hero-background.jpg"
                  debounce={1000}
                  alt="imagealt"
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  style={{ paddingLeft: "0px" }}
                  className="it-hero-text-wrapper bg-dark"
                >
                  <h1 className="customHeroText">
                    CATASTO E CONSERVATORIA ONLINE
                  </h1>
                  <p className="customHeroText">
                    Ottieni documenti ed i certificati catastali in modo
                    semplice e veloce. In caso di servizio con delega i nostri
                    professionisti gestiranno le richieste.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="it-hero-wrapper it-hero-small-size it-dark it-overlay">
          <div className="img-responsive-wrapper">
            <div className="img-responsive">
              <div className="img-wrapper">
                <Img
                  src="https://semplicitacatasto.it/img/hero2.jpeg"
                  debounce={1000}
                  alt="imagealt"
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  style={{ paddingLeft: "0px" }}
                  className="it-hero-text-wrapper bg-dark"
                >
                  <h1 className="customHeroText">REGISTRATI</h1>
                  <p className="customHeroText" style={{ fontSize: "5px" }}>
                    Effettua più richieste in un solo pacchetto, crea un
                    carrello e paga in seguito, visualizza lo storico ordini
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
}

export default Hero;
