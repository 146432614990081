import { useEffect, useState } from "react";
import Select from "../Objects/Select";
import Repo from "../Utility/Repo";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Pagination from "../Utility/Pagination";
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import Loader from "../Utility/Loader";

function StatsIntermediariInt(props) {
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage] = useState(5);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedDelegato, setSelectedDelegato] = useState();
  const [listaDelegati, setListaDelegati] = useState();
  const [isOpen, toggleModal] = useState(false);
  const [isOpenMonthPercentage, toggleModalMonthPercentage] = useState(false);

  const d = new Date();

  const [selectedMonth, setSelectedMonth] = useState(d.getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(d.getFullYear());
  const [total, setTotal] = useState();
  const [percentage, setPercentage] = useState();
  const [editedPercentage, setEditedPercentage] = useState();
  const [validationPercentage, setValidationPercentage] = useState(false);
  const [validationMonthPercentage, setValidationMonthPercentage] =
    useState(false);
  const [editedMonthPercentage, setEditedMonthPercentage] = useState(null);

  const [mesi] = useState([
    { label: "Gennaio", value: 1 },
    { label: "Febbraio", value: 2 },
    { label: "Marzo", value: 3 },
    { label: "Aprile", value: 4 },
    { label: "Maggio", value: 5 },
    { label: "Giugno", value: 6 },
    { label: "Luglio", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Settembre", value: 9 },
    { label: "Ottobre", value: 10 },
    { label: "Novembre", value: 11 },
    { label: "Dicembre", value: 12 },
  ]);

  const [anni] = useState([
    { label: "2022", value: 2022 },
    { label: "2023", value: 2023 },
    { label: "2024", value: 2024 },
    { label: "2025", value: 2025 },
    { label: "2026", value: 2026 },
    { label: "2027", value: 2027 },
    { label: "2028", value: 2028 },
    { label: "2029", value: 2029 },
  ]);

  const [chartOptions2, SetChartOptions2] = useState({
    xaxis: {
      categories: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
    },
  });

  let nameMounth = chartOptions2.xaxis.categories[d.getMonth()];

  useEffect(() => {
    setLoading(false);
    Repo.getListaDelegati(props.token)
      .then((response) => {
        setLoading(true);
        setListaDelegati(response.data);
      })
      .catch((error) => {
        setLoading(true);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const monthFilter = (event, month) => {
    const obb = {
      anno: selectedYear,
      mese: selectedMonth,
    };

    if (month == true) {
      setSelectedMonth(event.value);
      obb["mese"] = event.value;
    } else if (month == false) {
      setSelectedYear(event.value);
      obb["anno"] = event.value;
    }

    setLoading(false);
    Repo.postRichiesteDelegatoById(
      props.token,
      JSON.stringify(obb),
      selectedDelegato.value
    )
      .then((response) => {
        setItems(response.data.statisticheDelegato);
        setCurrentItems(
          response.data.statisticheDelegato.slice(0, itemsPerPage)
        );
        setTotal(response.data.totaleProvigioni);
        setPercentage(response.data.percentualeProvigioni);
        setEditedPercentage(response.data.percentualeProvigioni);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  };

  const changePercentage = () => {
    setValidationPercentage(true);
    let passedValidation = true;

    if (!editedPercentage || editedPercentage == 0) {
      passedValidation = false;
    }

    if (passedValidation) {
      Repo.putPercentageDelegato(
        props.token,
        editedPercentage,
        selectedDelegato.value
      )
        .then((response) => {
          if (response) {
            monthFilter(null, null);
          }
          toggleModal(!isOpen);
          setLoading(true);
        })
        .catch((error) => {
          console.log(error);
          setLoading(true);
        });
    }
  };

  const changeMonthPercentage = () => {
    setValidationMonthPercentage(true);
    let passedValidation = true;

    if (!editedMonthPercentage || editedMonthPercentage == 0) {
      passedValidation = false;
    }

    if (passedValidation) {
      const obb = {
        anno: selectedYear,
        mese: selectedMonth,
      };
      Repo.putRielaboraPercentageDelegato(
        props.token,
        editedMonthPercentage,
        selectedDelegato.value,
        JSON.stringify(obb)
      )
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const changeDelegato = (event) => {
    setSelectedDelegato({
      value: event.value,
      label: event.label,
    });

    const obb = {
      anno: d.getFullYear(),
      mese: d.getMonth() + 1,
    };

    Repo.postRichiesteDelegatoById(
      props.token,
      JSON.stringify(obb),
      event.value
    )
      .then((response) => {
        setItems(response.data.statisticheDelegato);
        setCurrentItems(
          response.data.statisticheDelegato.slice(0, itemsPerPage)
        );
        setTotal(response.data.totaleProvigioni);
        setPercentage(response.data.percentualeProvigioni);
        setEditedPercentage(response.data.percentualeProvigioni);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-10">
          <h3 className="card-title mb-2">Statistiche Esercenti</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mt-3 mb-2 d-flex flex-row">
          <div className="bootstrap-select-wrapper col-4">
            <Select
              clearText="Annulla"
              defaultMenuIsOpen={false}
              icon={false}
              id="selectExampleClassic"
              onChange={(e) => changeDelegato(e)}
              aria-label="Scegli un delegato"
              value={selectedDelegato ? selectedDelegato : ""}
              options={listaDelegati}
              isSearchable
              placeholder={"Seleziona Delegato"}
            />
          </div>
        </div>
      </div>
      {selectedDelegato && (
        <>
          <div className="flex-row mt-3 mb-2 d-flex">
            <div className="bootstrap-select-wrapper col-12 col-lg-4">
              <Select
                clearText="Annulla"
                defaultMenuIsOpen={false}
                defaultValue={d.getMonth() + 1}
                icon={false}
                id="selectExampleClassic"
                onChange={(e) => monthFilter(e, true)}
                options={mesi}
                isSearchable
                placeholder={nameMounth}
              />
            </div>
            <div className="bootstrap-select-wrapper col-12 col-lg-4">
              <Select
                clearText="Annulla"
                defaultMenuIsOpen={false}
                defaultValue={d.getFullYear()}
                icon={false}
                id="selectExampleClassic"
                onChange={(e) => monthFilter(e, false)}
                options={anni}
                isSearchable
                placeholder={d.getFullYear()}
              />
            </div>
            <div className=" col-12 col-lg-4">
              <a>Percentuale Corrente: </a>
              <strong className="mt-2 mr-3" style={{ fontSize: 25 }}>
                {percentage}%
              </strong>
              <button
                onClick={() => toggleModal(!isOpen)}
                className="btn btn-outline-primary btn-xs mb-2"
              >
                <svg className="icon icon-primary">
                  <use href={`${iconSprite}#it-pencil`}></use>
                </svg>
                MODIFICA
              </button>
            </div>
          </div>

          <div className="col-lg-12 d-flex align-items-stretch">
            <div className="card card-teaser rounded  rounded shadow mb-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <h6 className="card-title mb-2">
                      <strong style={{ fontSize: 25 }}>Riepilogo</strong>
                    </h6>
                  </div>
                </div>
                <div className="row">
                  {items && items.length > 0 && (
                    <div className="col-6">
                      <div className="row mt-3">
                        <div className="col-6 d-flex flex-column">
                          <span>Numero Totale di Richieste:</span>
                          <strong className="mt-2" style={{ fontSize: 28 }}>
                            {items.length}
                          </strong>
                        </div>
                        <div className="col-6 d-flex flex-column">
                          <span>Totale provvigioni:</span>
                          <strong className="mt-2" style={{ fontSize: 28 }}>
                            {total} €
                          </strong>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-6">
                    <div className="row mt-3">
                      <div className="col-6 d-flex flex-column">
                        <span>
                          Percentuale di{" "}
                          <b>
                            {chartOptions2.xaxis.categories[selectedMonth - 1]}{" "}
                            {selectedYear}
                          </b>
                          :
                        </span>
                        <div className="row d-flex flex-row mt-2">
                          <div className="col">
                            <button
                              onClick={() =>
                                toggleModalMonthPercentage(
                                  !isOpenMonthPercentage
                                )
                              }
                              className="btn btn-outline-primary btn-xs mb-2"
                            >
                              <svg className="icon icon-primary mr-2">
                                <use
                                  href={`${iconSprite}#it-exchange-circle`}
                                ></use>
                              </svg>
                              RETTIFICA
                            </button>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {items && items.length > 0 ? (
            <div className="row mt-4">
              <div className="col-lg-12 d-flex align-items-stretch">
                <div className="card card-teaser rounded  rounded shadow mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-10">
                        <h6 className="card-title mb-2">
                          <strong style={{ fontSize: 25 }}>
                            Dettaglio richieste pagate:
                          </strong>
                        </h6>
                      </div>

                      <div className="col-2 text-right">
                        <svg className="icon icon-success">
                          <use href={`${iconSprite}#it-chart-line`}></use>
                        </svg>
                      </div>
                    </div>

                    {currentItems &&
                      currentItems.map((x) => (
                        <div key={x.id}>
                          <div className="row mb-1">
                            <div className="col-9">
                              Ordine:
                              <strong>
                                {x.riepilogo.descrizione &&
                                  x.riepilogo.descrizione}
                              </strong>
                            </div>
                            <div className="col-3">
                              Status:{" "}
                              {x.riepilogo.status == "IN_CARICO" ? (
                                <span className="badge badge-primary badgeCustom">
                                  IN CARICO
                                </span>
                              ) : x.riepilogo.status == "EVASO" ? (
                                <span className="badge badge-success badgeCustom">
                                  EVASO
                                </span>
                              ) : x.riepilogo.status == "IN_PAUSA" ? (
                                <span className="badge badge-secondary badgeCustom">
                                  IN PAUSA
                                </span>
                              ) : x.riepilogo.status == "RIFIUTATO" ? (
                                <span className="badge badge-danger badgeCustom">
                                  RIFIUTATO
                                </span>
                              ) : (
                                <span className="badge badgeYellow badgeCustom">
                                  NON PRESO IN CARICO
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div
                              className="col-lg-6"
                              style={{ borderRight: "inset" }}
                            >
                              <div className="col-lg-12">
                                <h5 className="card-title mb-2">
                                  Dati Richiesta
                                </h5>
                              </div>
                              <div className="row mt-1 pl-1">
                                <div className="col-5">
                                  <u>Importo provvigione:</u>{" "}
                                </div>
                                <div className="col-7">
                                  <b>
                                    {x.totaleProvvigione && x.totaleProvvigione}{" "}
                                    €
                                  </b>
                                </div>
                              </div>
                              <div
                                className="card-text"
                                style={{ fontSize: "18px", color: "black" }}
                              >
                                <div className="row mt-1 pl-1">
                                  <div className="col-5">Importo Totale: </div>
                                  <div className="col-7">
                                    <b>{x.totalePagato && x.totalePagato} €</b>
                                  </div>
                                </div>
                                <div className="row mt-1 pl-1">
                                  <div className="col-5">
                                    Importo imponibile:{" "}
                                  </div>
                                  <div className="col-7">
                                    <b>
                                      {x.totaleGuadagno && x.totaleGuadagno} €
                                    </b>
                                  </div>
                                </div>
                                <div className="row mt-1 pl-1">
                                  <div className="col-5">Percentuale: </div>
                                  <div className="col-7">
                                    <b>{x.percentuale && x.percentuale} %</b>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="col-lg-12">
                                <h5 className="card-title mb-2">Servizi</h5>
                              </div>

                              <div className="it-list-wrapper">
                                <ul className="it-list">
                                  {x &&
                                    x.riepilogo.richiesteServizi.map((y) =>
                                      y.servizi.map((z) => (
                                        <li key={z.id} className="pl-1">
                                          {z.nome}
                                        </li>
                                      ))
                                    )}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                        </div>
                      ))}
                    {!currentItems && (
                      <>
                        <span className="text">
                          Non sono presenti richieste.
                        </span>
                      </>
                    )}
                    {/* {richiesteEsercente && richiesteEsercente.length !== 0 && ( */}
                    {currentItems && (
                      <div className="mt-3">
                        <Pagination
                          items={items}
                          setCurrentItems={setCurrentItems}
                          itemsPerPage={5}
                        ></Pagination>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-lg-12 d-flex align-items-stretch">
              <div className="card card-teaser rounded  rounded shadow mb-4">
                <div className="card-body">
                  <div className="row">
                    <h6>
                      Non sono presenti visure effettuate per il mese/anno
                      selezionati
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <Modal
        isOpen={isOpen}
        toggle={() => toggleModal(!isOpen)}
        labelledBy="editPercent"
      >
        <ModalHeader id="editPercent">
          Modifica Percentuale Esercente
        </ModalHeader>
        <ModalBody>
          <div className="col mt-3">
            <span className="input-number input-number-percentage">
              <Input
                id="percentage"
                required
                label="Percentuale*"
                type="number"
                name="percentage"
                min="0"
                max="100"
                step="any"
                value={editedPercentage ? editedPercentage.toString() : ""}
                placeholder=" "
                {...(validationPercentage &&
                  (!editedPercentage || editedPercentage == 0) && {
                    infoText: "Il campo non può essere vuoto o uguale a 0!",
                    className: "text-danger",
                  })}
                onChange={(e) => {
                  setEditedPercentage(e.target.value);
                }}
              />
            </span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary text-white text-center"
            onClick={() => changePercentage()}
          >
            Conferma
          </Button>
          <Button
            color="danger text-white text-center"
            onClick={() => toggleModal(!isOpen)}
          >
            Annulla
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isOpenMonthPercentage}
        toggle={() => toggleModalMonthPercentage(!isOpenMonthPercentage)}
        labelledBy="editMonthPercent"
      >
        <ModalHeader id="editMonthPercent">
          Modifica Percentuale Esercente
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <span className="text pl-2 mb-4">
              Modifica la percentuale di{" "}
              <b>
                {chartOptions2.xaxis.categories[selectedMonth - 1]}{" "}
                {selectedYear}
              </b>
              :
            </span>
          </div>
          <div className="row">
            <div className="col mt-3">
              <span className="input-number input-number-percentage">
                <Input
                  id="percentage"
                  required
                  label="Percentuale*"
                  type="number"
                  name="percentage"
                  min="0"
                  max="100"
                  step="any"
                  placeholder=" "
                  {...(validationMonthPercentage &&
                    (!editedMonthPercentage || editedMonthPercentage == 0) && {
                      infoText: "Il campo non può essere vuoto o uguale a 0!",
                      className: "text-danger",
                    })}
                  onChange={(e) => {
                    setEditedMonthPercentage(e.target.value);
                  }}
                />
              </span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary text-white text-center"
            onClick={() => changeMonthPercentage()}
          >
            Conferma
          </Button>
          <Button
            color="danger text-white text-center"
            onClick={() => toggleModalMonthPercentage(!isOpenMonthPercentage)}
          >
            Annulla
          </Button>
        </ModalFooter>
      </Modal>

      {!loading && <Loader />}
    </>
  );
}
export default StatsIntermediariInt;
