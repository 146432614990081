import { FixedSizeList as List } from "react-window";
import { React, useState, useEffect, Component } from "react";

class MenuList extends Component {
  getHeight = (props) => {
    var ret = props.maxHeight;
    if (!props.options.length == 0 && props.options.length < 9)
      ret = props.options.length * 41;
    else if (props.options.length == 0) ret = 41;
    return ret;
  };

  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * 35;

    return (
      <>
        {children.length == undefined ? (
          <List height={40} itemCount={1} itemSize={35}>
            {() => <div className="text-center mt-1"> No Options</div>}
          </List>
        ) : (
          <List
            height={this.getHeight(this.props)}
            itemCount={children.length}
            itemSize={35}
            initialScrollOffset={initialOffset}
          >
            {({ index, style }) => <div style={style}>{children[index]}</div>}
          </List>
        )}
      </>
    );
  }
}

export default MenuList;
