import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Link } from "react-router-dom";
import thumbsup from "../../Img/thumbs-up.png";

function Vantaggi(props) {
  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <h2>Registrati per ottenere tanti vantaggi</h2>
          <br />
          <p>
            Registrandoti a Semplícita Catasto otterrai uno sconto del % sui
            servizi offerti
          </p>
          <p>
            <strong>Inoltre:</strong>
          </p>
          <div class="it-list-wrapper">
            <ul class="it-list">
              <li>
                <a>
                  <div class="it-right-zone">
                    <span class="text">
                      - Effettua più richieste in un solo pacchetto
                    </span>
                  </div>
                </a>
              </li>
              <li>
                <a>
                  <div class="it-right-zone">
                    <span class="text">
                      - Crea un carrello per pagare i tuoi ordini in seguito
                    </span>
                  </div>
                </a>
              </li>
              <li>
                <a>
                  <div class="it-right-zone">
                    <span class="text">- Visualizza lo storico ordini</span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="it-helpdesk d-md-flex mt-4 mb-4">
            <div className="it-btn-container">
              <button className="btn btn-primary">Registrati</button>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <img
            style={{ float: "right", width: "80%" }}
            src={thumbsup}
            alt="chatbotlogo"
          />
        </div>
      </div>
    </>
  );
}

export default Vantaggi;
