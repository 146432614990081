import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Link } from "react-router-dom";
import moment from "moment";

function ListaAttivita(props) {
  return (
    <>
      {props.children && props.children.length !== 0 ? (
        <>
          <div className="row">
            <div className="col-12">
              <div
                className="card card-teaser rounded text-primary font-weight-bold"
                style={{ fontSize: "32px" }}
              >
                Lista Attività
              </div>

              <div className="text-right">
                <h5>Totale Spettante: 0 €</h5>
              </div>

              {Array.isArray(props.children) &&
                props.children.map((obbj, index) => (
                  <div
                    className="card card-teaser rounded shadow mb-2"
                    key={index}
                  >
                    <div className="card-body">
                      <div className="text-left">
                        <div className="row mt-3">
                          <div className="col-sm-6 col-lg-3">
                            N. Ticket: <b>{obbj.id}</b>
                          </div>
                          <div className="col-sm-6 col-lg-3">
                            Data e Ora:{" "}
                            <b>
                              {moment(new Date(obbj.data)).format("DD-MM-YYYY")}{" "}
                            </b>
                          </div>
                          {obbj.flagPagabile == 1 && (
                            <div className="col-sm-6 col-lg-3">
                              <svg className="icon icon-sm mb-1">
                                <use href={`${iconSprite}#it-warning-circle`} />
                              </svg>
                              <b>
                                <span>Da Pagare</span>
                              </b>
                            </div>
                          )}
                        </div>

                        <div className="row mt-3">
                          <div className="col-sm-6 col-lg-3 ">
                            N. richieste dell'attività: <b>{obbj.richieste} </b>
                          </div>
                          <div className="col-sm-6 col-lg-3 ">
                            N. richieste evase: <b>{obbj.richiesteEvase} </b>
                          </div>
                          <div className="col-sm-6 col-lg-3">
                            Importo spettante: <b>{obbj.importoSpettante} € </b>
                          </div>
                          {obbj.flagPagato == 1 ? (
                            <div className="col-sm-6 col-lg-3">
                              <svg className="icon  icon-danger icon-sm mb-1">
                                <use href={`${iconSprite}#it-warning-circle`} />
                              </svg>
                              <b>
                                <span>Da Pagare</span>
                              </b>
                            </div>
                          ) : (
                            <div className="col-sm-6 col-lg-3">
                              <svg className="icon icon-success icon-sm mb-1 ">
                                <use href={`${iconSprite}#it-check-circle`} />
                              </svg>
                              <b>
                                <span>Pagato</span>
                              </b>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12 mt-2 d-flex flex-row-reverse">
                        <Link
                          to={
                            "/fascicolo/dettaglio_fascicolo?id=" +
                            obbj.idCartella
                          }
                        >
                          <button
                            type="button"
                            className="btn btn-primary btn-sm mb-2 btn-icon"
                            style={{ marginRight: "5px" }}
                          >
                            <svg className="icon icon-sm icon-white">
                              <use href={`${iconSprite}#it-file`} />
                            </svg>{" "}
                            <span>Dettaglio</span>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      ) : (
        <b>Nessun Risultato da Mostrare</b>
      )}
    </>
  );
}

export default ListaAttivita;
