import { React, useState, useEffect } from "react";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Repo from "../Utility/RepoValutazioneImmobile";
import Select from "../Objects/Select";
import { Input, FormGroup, Label } from "design-react-kit";
import RatingImmobile from "../ValutazioneImmobile/RatingImmobile";
import MenuList from "../Objects/MenuList";
import Loader from "../Utility/Loader";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

// function numberWithCommas(x) {
//   return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
// }

function ValutazioneImmobile(props) {
  const [steppers, setSteppers] = useState(1);
  const [comuni, setComuni] = useState();
  const [comuneSelect, setComuneSelect] = useState("");
  const [destinazioni, setDestinazioni] = useState();
  const [destinazioneSelect, setDestinazioneSelect] = useState("");
  const [listaZone, setListaZone] = useState();
  const [zonaSelect, setZonaSelect] = useState("");
  const [via, setVia] = useState("");
  const [superficie, setSuperficie] = useState(0);
  const [numvani, setNumvani] = useState(0);
  const [annoDiCostruzione, setAnnoDiCostruzione] = useState(1899);
  const [ascensore, setAscensore] = useState(false);
  const [showGarage, setShowGarage] = useState(false);
  const [garage, setGarage] = useState(0);
  const [terrazzoBalcone, setTerrazzoBalcone] = useState(0);
  const [giardino, setGiardino] = useState(0);
  const [piano, setPiano] = useState();
  const [pianoSelect, setPianoSelect] = useState("");
  const [classeEnergetica, setClasseEnergetica] = useState();
  const [classeEnergeticaeSelect, setClasseEnergeticaSelect] = useState("");
  const [statoImmobile, setStatoImmobile] = useState();
  const [statoImmobileSelect, setStatoImmobileSelect] = useState("");
  const [insData, setInsData] = useState();
  const [calcoli, setCalcoli] = useState();
  const [loading, setLoading] = useState(false);
  const [validationOne, setValidationOne] = useState(true);
  const [validationTwo, setValidationTwo] = useState(true);

  const checkIcon = () => {
    return (
      <>
        <svg className="icon steppers-success">
          <use href={`${iconSprite}#it-check`} />
        </svg>
        <span className="sr-only">Confermato</span>
      </>
    );
  };

  const changeComune = (event) => {
    var idEvent = event.value;
    var nameEvent = event.label;
    setComuneSelect({ label: nameEvent, id: idEvent });
  };

  const changeDestinazione = (event) => {
    var idEvent = event.value;
    var nameEvent = event.label;
    setDestinazioneSelect({ label: nameEvent, id: idEvent });
  };

  const changeZona = (event) => {
    var idEvent = event.value;
    var nameEvent = event.label;
    setZonaSelect({ label: nameEvent, id: idEvent });
  };

  const changePiano = (event) => {
    var idEvent = event.Value;
    var nameEvent = event.label;
    setPianoSelect({ label: nameEvent, id: idEvent });
  };

  const changeClasse = (event) => {
    var idEvent = event.Value;
    var nameEvent = event.label;
    setClasseEnergeticaSelect({ label: nameEvent, id: idEvent });
  };

  const changeStato = (event) => {
    var idEvent = event.Value;
    var nameEvent = event.label;
    setStatoImmobileSelect({ label: nameEvent, id: idEvent });
  };

  const getComuniDestinazioni = () => {
    if (!comuni) {
      setLoading(true);
      Repo.getSelectComuni()
        .then((response) => {
          setComuni(response.data);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    }

    if (!destinazioni) {
      Repo.getSelectDestinazioni()
        .then((response) => {
          setDestinazioni(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case "superficie":
        setSuperficie(value);

        break;
      case "numvani":
        setNumvani(value);
        break;
      case "annoDiCostruzione":
        setAnnoDiCostruzione(value);
        break;
      case "terrazzoBalcone":
        setTerrazzoBalcone(value);
        break;
      case "garage":
        setGarage(value);
        break;
      case "giardino":
        setGiardino(value);
        break;
      case "via":
        setVia(value);
        break;
      default:
        break;
    }
  };

  const FirstValidation = () => {
    if (
      comuneSelect == "" ||
      destinazioneSelect == "" ||
      zonaSelect == "" ||
      via == ""
    ) {
      setValidationOne(false);
    } else {
      setSteppers(steppers + 1);
      getPianoClasseStato();
    }
  };

  const SecondValidation = () => {
    if (
      superficie == 0 ||
      numvani == 0 ||
      statoImmobileSelect == "" ||
      classeEnergeticaeSelect == "" ||
      pianoSelect == "" ||
      annoDiCostruzione < 1899 ||
      annoDiCostruzione > new Date().getFullYear()
    ) {
      setValidationTwo(false);
    } else {
      calculateValue();
    }
  };

  const getPianoClasseStato = () => {
    if (!piano) {
      Repo.getSelectPiano()
        .then((response) => {
          setPiano(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    if (!classeEnergetica) {
      Repo.getSelectClasse()
        .then((response) => {
          setClasseEnergetica(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    if (!statoImmobile) {
      Repo.getSelectStato()
        .then((response) => {
          setStatoImmobile(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const calculateValue = () => {
    const reqSend = {
      superficie: superficie ? parseInt(superficie) : 0,
      piano: pianoSelect ? pianoSelect.label : "",
      numVani: numvani,
      statoImmobile: statoImmobileSelect ? statoImmobileSelect.label : "",
      annoDiCostruzione: annoDiCostruzione ? parseInt(annoDiCostruzione) : 0,
      classeEnergetica: classeEnergeticaeSelect
        ? classeEnergeticaeSelect.label
        : "",
      ascensore: ascensore ? ascensore : false,
      terrazzoBalcone: terrazzoBalcone ? parseInt(terrazzoBalcone) : 0,
      giardino: giardino ? parseInt(giardino) : 0,
      garage: garage ? parseInt(garage) : 0,
      linkZona: zonaSelect ? zonaSelect.id : "",
      codTip: destinazioneSelect ? destinazioneSelect.id : "",
    };

    Repo.postCalcolo(reqSend)
      .then((response) => {
        setCalcoli(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    setInsData(reqSend);
  };

  useEffect(() => {
    if (comuneSelect && destinazioneSelect) {
      Repo.getSelectZona(comuneSelect.id, destinazioneSelect.id)
        .then((response) => {
          setListaZone(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [comuneSelect, destinazioneSelect]);

  useEffect(() => {
    getComuniDestinazioni();
  }, []);

  const returnSteppers = () => {
    switch (steppers) {
      case 1:
        return (
          <>
            {steppers == 1 && (
              <div className="row">
                <div className="col-6">
                  <h6>Comune*: </h6>
                  <Select
                    components={{ MenuList }}
                    aria-label="Scegli una opzione"
                    clearText="Annulla"
                    icon={true}
                    id="comune"
                    onChange={changeComune}
                    options={comuni}
                    isSearchable
                    placeholder={
                      comuneSelect ? comuneSelect.label : "Seleziona comune"
                    }
                  />
                  {!validationOne && comuneSelect == "" && (
                    <>
                      <span className="text-danger mt-0">
                        Il campo non può essere vuoto
                      </span>
                    </>
                  )}
                </div>

                <div className="col-6">
                  <h6>Destinazione*: </h6>
                  <Select
                    components={{ MenuList }}
                    aria-label="Scegli una opzione"
                    clearText="Annulla"
                    icon={true}
                    id="destinazione"
                    onChange={changeDestinazione}
                    options={destinazioni}
                    isSearchable
                    placeholder={
                      destinazioneSelect
                        ? destinazioneSelect.label
                        : "Seleziona destinazione"
                    }
                  />
                  {!validationOne && destinazioneSelect == "" && (
                    <>
                      <span className="text-danger mt-0">
                        Il campo non può essere vuoto
                      </span>
                    </>
                  )}
                </div>
                <div className="col-12 mt-4">
                  <h6>Quartiere*: </h6>
                  <Select
                    components={{ MenuList }}
                    aria-label="Scegli una opzione"
                    clearText="Annulla"
                    icon={true}
                    id="zone"
                    onChange={changeZona}
                    options={listaZone}
                    isSearchable
                    placeholder={
                      zonaSelect ? zonaSelect.label : "Seleziona quartiere"
                    }
                  />
                  {!validationOne && zonaSelect == "" && (
                    <>
                      <span className="text-danger mt-0">
                        Il campo non può essere vuoto
                      </span>
                    </>
                  )}
                </div>
                <div className="col-12 mt-4">
                  <h6>Via*: </h6>
                  <Input
                    placeholder={via ? via : "Inserisci via"}
                    type="text"
                    name="via"
                    value={via ? via.toString() : ""}
                    onChange={handleChange}
                  />
                </div>
                {!validationOne && via == "" && (
                  <>
                    <span className="text-danger mt-0">
                      Il campo non può essere vuoto
                    </span>
                  </>
                )}
              </div>
            )}
          </>
        );
        break;
      case 2:
        return (
          <>
            <div className="row">
              <div className="col-6">
                <h6>Superficie in mq: </h6>
                <Input
                  type="number"
                  min="0"
                  max="2000"
                  id="superficie"
                  name="superficie"
                  value={superficie.toString()}
                  onChange={handleChange}
                />
                {!validationTwo && superficie == 0 && (
                  <>
                    <span className="text-danger mt-0">
                      Il campo non può essere vuoto
                    </span>
                  </>
                )}
              </div>
              <div className="col-6">
                <h6>Numero vani: </h6>
                <Input
                  type="number"
                  min="0"
                  max="50"
                  id="numvani"
                  name="numvani"
                  value={numvani.toString()}
                  onChange={handleChange}
                />
                {!validationTwo && numvani == 0 && (
                  <>
                    <span className="text-danger mt-0">
                      Il campo non può essere vuoto
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-6">
                <h6>Anno di costruzione: </h6>
                <Input
                  type="number"
                  min="1899"
                  max={new Date().getFullYear()}
                  id="annoDiCostruzione"
                  name="annoDiCostruzione"
                  value={annoDiCostruzione.toString()}
                  onChange={handleChange}
                />
                {!validationTwo && annoDiCostruzione == 0 && (
                  <>
                    <span className="text-danger mt-0">
                      Il campo non può essere vuoto
                    </span>
                  </>
                )}
                {!validationTwo &&
                  annoDiCostruzione < 1899 &&
                  annoDiCostruzione != 0 && (
                    <>
                      <span className="text-danger mt-0">
                        L'anno di costruzione non può essere precedente al 1899
                      </span>
                    </>
                  )}
                {!validationTwo &&
                  annoDiCostruzione > new Date().getFullYear() && (
                    <>
                      <span className="text-danger mt-0">
                        L'anno di costruzione non può essere futuro
                      </span>
                    </>
                  )}
              </div>
              <div className="col-6">
                <h6>Mq terrazzo/balcone (se presente): </h6>
                <Input
                  type="number"
                  min="0"
                  max="200"
                  id="terrazzoBalcone"
                  name="terrazzoBalcone"
                  value={terrazzoBalcone.toString()}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-3">
                <h6>Ascensore: </h6>
                <FormGroup check inline>
                  <Input
                    name="gruppo1"
                    type="radio"
                    id="radio1"
                    onChange={() => setAscensore(true)}
                  />
                  <Label check htmlFor="radio1">
                    Si
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    name="gruppo1"
                    type="radio"
                    id="radio2"
                    defaultChecked
                    onChange={() => setAscensore(false)}
                  />
                  <Label check htmlFor="radio2">
                    No
                  </Label>
                </FormGroup>
              </div>
              <div className="col-3">
                <h6>Garage: </h6>
                <FormGroup check inline>
                  <Input
                    name="gruppo2"
                    type="radio"
                    id="radio3"
                    onChange={() => setShowGarage(true)}
                  />
                  <Label check htmlFor="radio3">
                    Si
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    name="gruppo2"
                    type="radio"
                    id="radio4"
                    defaultChecked
                    onChange={() => setShowGarage(false)}
                  />
                  <Label check htmlFor="radio4">
                    No
                  </Label>
                </FormGroup>
              </div>
              {showGarage && (
                <>
                  <div className="col-6">
                    <h6>Garage : </h6>
                    <Input
                      type="number"
                      min="0"
                      max="200"
                      id="garage"
                      name="garage"
                      value={garage.toString()}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="row mt-4">
              <div className="col-6">
                <h6>Mq Giardino (se presente): </h6>
                <Input
                  type="number"
                  min="0"
                  max="2000"
                  id="giardino"
                  name="giardino"
                  value={giardino.toString()}
                  onChange={handleChange}
                />
              </div>
              <div className="col-6">
                <h6>Piano: </h6>
                <Select
                  components={{ MenuList }}
                  aria-label="Scegli una opzione"
                  clearText="Annulla"
                  icon={true}
                  id="piano"
                  onChange={changePiano}
                  options={piano}
                  isSearchable
                  placeholder={pianoSelect ? pianoSelect.label : "Seleziona"}
                />
                {!validationTwo && pianoSelect == "" && (
                  <>
                    <span className="text-danger mt-0">
                      Il campo non può essere vuoto
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-6">
                <h6>Seleziona Classe Energetica: </h6>
                <Select
                  components={{ MenuList }}
                  aria-label="Scegli una opzione"
                  clearText="Annulla"
                  icon={true}
                  id="Classe"
                  onChange={changeClasse}
                  options={classeEnergetica}
                  isSearchable
                  placeholder={
                    classeEnergeticaeSelect
                      ? classeEnergeticaeSelect.label
                      : "Seleziona"
                  }
                />
                {!validationTwo && classeEnergeticaeSelect == "" && (
                  <>
                    <span className="text-danger mt-0">
                      Il campo non può essere vuoto
                    </span>
                  </>
                )}
              </div>
              <div className="col-6">
                <h6>Seleziona Stato: </h6>
                <Select
                  components={{ MenuList }}
                  aria-label="Scegli una opzione"
                  clearText="Annulla"
                  icon={true}
                  id="stato"
                  onChange={changeStato}
                  options={statoImmobile}
                  isSearchable
                  placeholder={
                    statoImmobileSelect
                      ? statoImmobileSelect.label
                      : "Seleziona"
                  }
                />
                {!validationTwo && statoImmobileSelect == "" && (
                  <>
                    <span className="text-danger mt-0">
                      Il campo non può essere vuoto
                    </span>
                  </>
                )}
              </div>
            </div>
          </>
        );
        break;
    }
  };

  return (
    <>
      <div className="card card-teaser rounded shadow">
        <div className="card-body">
          {calcoli ? (
            <>
              {insData && (
                <>
                  <div className="row">
                    <div className="col-10">
                      <h3>Risultato della Valutazione </h3>
                    </div>

                    <div className="col-2">
                      <button
                        className="btn btn-primary"
                        onClick={() => setCalcoli()}
                      >
                        Indietro
                      </button>
                    </div>
                  </div>
                  <hr />
                  <h5>
                    <b>Riepilogo dei dati inseriti</b>
                  </h5>
                  <div className="row mt-4">
                    <div className="col-4">
                      <span>
                        Comune: <b>{comuneSelect.label}</b>
                      </span>
                    </div>
                    <div className="col-8">
                      <span>
                        Quartiere: <b>{zonaSelect.label}</b>
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <span>
                        Superficie: <b>{insData.superficie} mq</b>
                      </span>
                    </div>
                    <div className="col">
                      <span>
                        Stato Immobile: <b>{insData.statoImmobile}</b>
                      </span>
                    </div>
                    <div className="col">
                      <span>
                        Piano: <b>{insData.piano}</b>
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <span>
                        Anno di Costruzione: <b>{insData.annoDiCostruzione}</b>
                      </span>
                    </div>
                    <div className="col">
                      <span>
                        Numero Vani: <b>{insData.numVani}</b>
                      </span>
                    </div>
                    <div className="col">
                      <span>
                        Classe Energetica: <b>{insData.classeEnergetica}</b>
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <span>
                        Ascensore: <b>{insData.ascensore ? "Si" : "No"}</b>
                      </span>
                    </div>
                    <div className="col">
                      <span>
                        Garage:{" "}
                        <b>{insData.garage ? insData.garage + " mq" : "No"} </b>
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <span>
                        Giardino:{" "}
                        <b>
                          {insData.giardino ? insData.giardino + " mq" : "No"}
                        </b>
                      </span>
                    </div>
                    <div className="col">
                      <span>
                        Terrazzo/Balcone:{" "}
                        <b>
                          {insData.terrazzoBalcone
                            ? insData.terrazzoBalcone + " mq"
                            : "No"}
                        </b>
                      </span>
                    </div>
                  </div>
                  <hr />
                </>
              )}

              <br />
              <div className="row">
                <div className="col">
                  <h4>Stima:</h4>{" "}
                  <b>
                    {parseInt(calcoli.prezzoMedio).toLocaleString("it-IT")}€
                  </b>
                </div>
                <RatingImmobile>{calcoli}</RatingImmobile>
                <div className="col mt-3">
                  <p className="mb-0">
                    Prezzo Massimo:{" "}
                    <b>
                      {parseInt(calcoli.prezzoMassimo).toLocaleString("it-IT")}€
                    </b>
                  </p>
                  <p>
                    Prezzo Minimo:{" "}
                    <b>
                      {parseInt(calcoli.prezzoMinimo).toLocaleString("it-IT")}€
                    </b>
                  </p>
                </div>
              </div>

              <hr />
              <p style={{ fontSize: "14px" }}>
                Facendo uso del nostro servizio di valutazione online, sei
                consapevole che Semplícita Catasto non è un perito e non assume
                responsabilità in merito alle valutazioni proposte. Le stime di
                valore si basano su dati commerciali meramente orientativi di un
                determinato immobile e sono frutto della comparazione dei dati
                OMI con gli immobili aventi caratteristiche simili nella stessa
                area geografica. Il valore stimato non è vincolante e potrebbe
                non essere accurato, non tenendo conto di altri specifici
                fattori. Se desideri maggiori informazioni sulle nostre stime
                immobiliari, contattaci e saremo lieti di aiutarti.
              </p>
            </>
          ) : (
            <>
              <h3>Valutazione dell'Immobile </h3>{" "}
              <p>
                Scopri il valore dell’immobile in 2 semplici passaggi.<br></br>
                Inserisci le caratteristiche dell’immobile e il sistema
                Semplícita Catasto farà il resto. <br></br>Riceverai una
                valutazione immediata e gratuita, basata su milioni di dati
                forniti dall’
                <b>Osservatorio del Mercato Immobiliare (OMI)</b>.
              </p>
              <div className="steppers">
                <div className="steppers-content px-4" aria-live="polite">
                  {returnSteppers()}
                </div>
                <nav className="steppers-nav">
                  <button
                    disabled={steppers == 1}
                    type="button"
                    className="btn btn-outline-primary btn-sm steppers-btn-prev "
                    onClick={() => setSteppers(steppers - 1)}
                  >
                    Indietro
                  </button>
                  {steppers !== 2 ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm steppers-btn-confirm"
                        onClick={() => FirstValidation()}
                      >
                        Avanti
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm steppers-btn-confirm "
                        onClick={() => SecondValidation()}
                      >
                        Conferma
                      </button>
                    </>
                  )}
                </nav>
              </div>
            </>
          )}
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
}

export default ValutazioneImmobile;
