import React, { useEffect, useState } from "react";
import styled from "styled-components";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

const Button = styled.button`
  /* Insert your favorite CSS code to style a button */
`;
const UploadFile = (props) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    if (props.typeFile.includes(event.target.files[0].type)) {
      const fileUploaded = event.target.files[0];
      setFile(fileUploaded);
      props.handleFile(fileUploaded);
      props.setErrorMessage(false);
    } else {
      props.setErrorMessage(true);
    }
  };

  const [file, setFile] = useState();

  const [position] = useState(props.position ? props.position : "text-center");
  return (
    <div className="row">
      {!file && (
        <>
          <div className={"col-12 mt-2" + position}>
            {" "}
            <Button onClick={handleClick} className="btn btn-primary">
              {props.testoInterno ? props.testoInterno : "Carica la delega"}
            </Button>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </div>
        </>
      )}

      {file && (
        <div className="card card-teaser rounded shadow mt-2">
          <svg
            className="icon icon-danger "
            onClick={() => {
              setFile(), props.handleFile();
            }}
            style={{ cursor: "pointer" }}
          >
            <use xlinkHref={`${iconSprite}#it-close-circle`}></use>
          </svg>
          <div className="card-body">
            <h5 className="card-title">
              <a>{file.name.replace("_", " ").substring(0, 50)}</a>
            </h5>
          </div>
        </div>
      )}
    </div>
  );
};
export default UploadFile;
