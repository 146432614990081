import moment from "moment";
import { useEffect, useState } from "react";
import Pagination from "../Utility/Pagination";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
} from "design-react-kit";
import Repo from "../Utility/Repo";

function ListaRichiesteSospese(props) {
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage] = useState(5);
  const [items, setItems] = useState([]);
  const [isOpen, toggleModal] = useState(false);
  const [token, setToken] = useState(props.token);
  const [idDel1, setIdDel1] = useState();

  useEffect(() => {
    setItems(props.children);
    setCurrentItems(props.children.slice(0, itemsPerPage));
  }, []);

  const loadRiepilogo = (idRichiesta, stato) => {
    window.location.href =
      props.subfolder +
      "/riepilogo?sessionId=" +
      idRichiesta +
      "&status=" +
      stato;
  };

  const delOrdine = (id) => {
    Repo.delOrdine(id)
      .then((response) => {
        if (response.status == 200) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="col-lg-12 p-0">
        {props.pagato == true ? (
          <h2 className="mt-2 mb-3 text-center">Richieste</h2>
        ) : (
          <>
            <h2 className="mb-3 text-center">Carrello</h2>
            <div className="row mt-3 mb-4 pl-4 text-right">
              <div className="col-lg-12">
                <b style={{ fontSize: "20px" }}>
                  Numero Richieste: {items.length}
                </b>
              </div>
            </div>
          </>
        )}
        {currentItems &&
          currentItems.length > 0 &&
          currentItems.map((x, index) => (
            <div key={index}>
              <div className="card card-teaser rounded shadow mb-3 col-12">
                <div className="card-body">
                  <div className="row">
                    <h5 className="card-title mb-2 pl-1">
                      Ordine N. {x.descrizione}
                    </h5>
                  </div>
                  <div className="row mt-3 pr-5">
                    <div className="col-lg-4">
                      Importo Totale:{" "}
                      <b>{x.importoPagato ? x.importoPagato + "€" : " - "}</b>
                    </div>
                    <div className="col-lg-5">
                      Data Richiesta:{" "}
                      <b>{x.dataInserimento ? x.dataInserimento : " - "}</b>
                    </div>
                    <div className="col-lg-3 text-right">
                      <b>
                        {x.flagPagato ? (
                          <>
                            <svg className="icon icon-success pb-1">
                              <use href={`${iconSprite}#it-check-circle`}></use>
                            </svg>
                            <b className="text-success pl-1">
                              Pagamento Effettuato
                            </b>
                          </>
                        ) : (
                          <>
                            <svg className="icon icon-danger pb-1">
                              <use
                                href={`${iconSprite}#it-warning-circle`}
                              ></use>
                            </svg>
                            <b className="text-danger pl-1">Da Pagare</b>
                          </>
                        )}
                      </b>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-4">
                      Stato Richiesta:{" "}
                      {x.flagPagato ? (
                        <>
                          {
                            {
                              EVASO: (
                                <>
                                  <svg className="icon icon-success pb-1">
                                    <use
                                      href={`${iconSprite}#it-check-circle`}
                                    ></use>
                                  </svg>
                                  <span className="text-success">
                                    <b>EVASO</b>
                                  </span>
                                </>
                              ),
                              IN_PAUSA: (
                                <>
                                  <svg className="icon icon-warning pb-1">
                                    <use
                                      href={`${iconSprite}#it-bookmark`}
                                    ></use>
                                  </svg>
                                  <span className="text-warning">
                                    <b>IN PAUSA</b>
                                  </span>
                                </>
                              ),
                              IN_CARICO: (
                                <>
                                  <svg className="icon icon-success pb-1">
                                    <use
                                      href={`${iconSprite}#it-star-full`}
                                    ></use>
                                  </svg>
                                  <span className="text-success">
                                    <b>IN CARICO</b>
                                  </span>
                                </>
                              ),
                              RIFIUTATO: (
                                <>
                                  <svg className="icon icon-danger pb-1">
                                    <use
                                      href={`${iconSprite}#it-close-circle`}
                                    ></use>
                                  </svg>
                                  <span className="text-danger">
                                    <b>RIFIUTATO</b>
                                  </span>
                                </>
                              ),
                              EVASO_P: (
                                <>
                                  <svg className="icon icon-warning pb-1">
                                    <use
                                      href={`${iconSprite}#it-warning-circle`}
                                    ></use>
                                  </svg>
                                  <span className="text-warning">
                                    <b>IN ATTESA DELL'OPERATORE</b>
                                  </span>
                                </>
                              ),
                              RIFIUTATO_P: (
                                <>
                                  <svg className="icon icon-warning pb-1">
                                    <use
                                      href={`${iconSprite}#it-warning-circle`}
                                    ></use>
                                  </svg>
                                  <span className="text-warning">
                                    <b>IN ATTESA DELL'OPERATORE</b>
                                  </span>
                                </>
                              ),
                              null: <b>NON EVASO</b>,
                              default: <b>NON EVASO</b>,
                            }[x.status]
                          }
                        </>
                      ) : (
                        <b>NON PAGATO</b>
                      )}
                    </div>
                    <div className="col-lg-5 text-success">
                      {x.status == "EVASO" && (
                        <>
                          <b>Certificati Disponibili!</b>
                        </>
                      )}
                    </div>
                    {x.flagPagato ? (
                      <div className="col-lg-3 text-right">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => loadRiepilogo(x.id, x.status)}
                        >
                          Vai al Riepilogo
                        </button>
                      </div>
                    ) : (
                      <div className="col-lg-3 text-right ">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => loadRiepilogo(x.id, x.status)}
                        >
                          Procedi all'ordine
                        </button>
                      </div>
                    )}
                  </div>
                  {!x.flagPagato && (
                    <div className="row mt-3">
                      <div className="col-lg-12 text-right">
                        <button
                          type="button"
                          className="btn btn-outline-danger py-2 px-3"
                          // onClick={() => removeAllegato(x.id, z.id)}
                          onClick={() => {
                            setIdDel1(x.id);
                            toggleModal(!isOpen);
                          }}
                        >
                          <svg
                            className="icon icon-sm icon-danger"
                            aria-hidden="true"
                          >
                            <use href={`${iconSprite}#it-delete`}></use>
                          </svg>
                          Elimina ordine
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        {currentItems && (
          <div className="mt-3">
            <Pagination
              items={items}
              setCurrentItems={setCurrentItems}
              itemsPerPage={5}
            ></Pagination>
          </div>
        )}
      </div>

      <div>
        <Modal
          isOpen={isOpen}
          toggle={() => toggleModal(!isOpen)}
          size={"lg"}
          labelledBy="Modale1"
        >
          <ModalHeader id="Modale1">
            Sei sicuro di voler procedere ?
          </ModalHeader>
          <ModalBody></ModalBody>
          <ModalFooter>
            <Button
              color="danger text-white text-center"
              onClick={() => delOrdine(idDel1)}
            >
              Elimina
            </Button>
            <Button
              color="primary text-white text-center"
              onClick={() => toggleModal(!isOpen)}
            >
              Annulla
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default ListaRichiesteSospese;
