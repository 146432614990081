import moment from "moment";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { useEffect, useState } from "react";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import Repo from "../Utility/Repo";
import Pagination from "../Utility/Pagination";

function ListaStorico(props) {
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage] = useState(5);
  const [items, setItems] = useState([]);
  const [isOpenSend, toggleModalSend] = useState(false);
  const [pagamentoId, setPagamentoId] = useState();
  const [token, setToken] = useState(props.token);

  useEffect(() => {
    setItems(props.children);
    setCurrentItems(props.children.slice(0, itemsPerPage));
  }, []);

  const refuseAllRequest = (pagamentoId) => {
    Repo.postSalvaPraticaRifiutata(token, pagamentoId)
      .then((response) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  };

  const reloadRichiesta = (pagamentoId) => {
    if (props.isSuperDelegate) {
      window.location.href =
        props.subfolder +
        "/richieste_delegato?viewList=true&pagamentoId=" +
        pagamentoId;
    } else {
      window.location.href =
        props.subfolder +
        "/richieste_visure?viewList=true&pagamentoId=" +
        pagamentoId;
    }
  };

  const loadRichiestaElaborata = (pagamentoId, stato) => {
    window.location.href =
      props.subfolder +
      "/richieste_elaborate?stato=" +
      stato +
      "&pagamentoId=" +
      pagamentoId;
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {currentItems && currentItems.length > 0 ? (
        currentItems.map((x, index) => (
          <div key={index}>
            {x.pagamento.status === "IN_CARICO" ? (
              <>
                <div
                  className="card card-teaser rounded shadow mt-4 col-12 mr-1"
                  style={{
                    background:
                      "linear-gradient(144deg, rgb(0 135 88 / 38%) 0%, rgb(255, 255, 255) 25%)",
                  }}
                  key={index}
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-9 mb-3">
                        <h5 className="card-title">
                          {x.pagamento.descrizione}
                        </h5>
                      </div>
                      <div className="col-lg-3 text-right pr-3">
                        <svg className="icon icon-success pb-1">
                          <use href={`${iconSprite}#it-star-full`}></use>
                        </svg>
                        <span className="text-success">
                          <b>IN CARICO</b>
                        </span>
                      </div>
                    </div>

                    <div className="row mb-2 pl-2">
                      <div className="col-lg-3">
                        Importo: <b>{x.pagamento.importoPagato}€</b>
                      </div>
                      <div className="col-lg-3">
                        Data Richiesta: <b>{x.pagamento.dataInserimento}</b>
                      </div>
                      <div className="col-lg-4">
                        Ultimo Aggiornamento: <b>{x.pagamento.dataModifica}</b>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-12 text-right">
                        <button
                          className="btn btn-primary text-right"
                          onClick={() => reloadRichiesta(x.pagamentoId)}
                        >
                          Dettagli
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="card card-teaser rounded shadow mt-4 col-12 mr-1"
                  key={index}
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-9 mb-3">
                        <h5 className="card-title">
                          {x.pagamento.descrizione}
                        </h5>
                      </div>
                      <div className="col-lg-3 text-right pr-3">
                        {
                          {
                            EVASO: (
                              <>
                                <svg className="icon icon-success pb-1">
                                  <use
                                    href={`${iconSprite}#it-check-circle`}
                                  ></use>
                                </svg>
                                <span className="text-success">
                                  <b>EVASO</b>
                                </span>
                              </>
                            ),
                            IN_PAUSA: (
                              <>
                                <svg className="icon icon-warning pb-1">
                                  <use href={`${iconSprite}#it-bookmark`}></use>
                                </svg>
                                <span className="text-warning">
                                  <b>IN PAUSA</b>
                                </span>
                              </>
                            ),
                            IN_CARICO: (
                              <>
                                <svg className="icon icon-success pb-1">
                                  <use
                                    href={`${iconSprite}#it-star-full`}
                                  ></use>
                                </svg>
                                <span className="text-success">
                                  <b>IN CARICO</b>
                                </span>
                              </>
                            ),
                            RIFIUTATO: (
                              <>
                                <svg className="icon icon-danger pb-1">
                                  <use
                                    href={`${iconSprite}#it-close-circle`}
                                  ></use>
                                </svg>
                                <span className="text-danger">
                                  <b>RIFIUTATO</b>
                                </span>
                              </>
                            ),
                            EVASO_P: (
                              <>
                                <svg className="icon icon-warning pb-1">
                                  <use
                                    href={`${iconSprite}#it-warning-circle`}
                                  ></use>
                                </svg>
                                <span className="text-warning">
                                  <b>IN ATTESA DELL'OPERATORE</b>
                                </span>
                              </>
                            ),
                            RIFIUTATO_P: (
                              <>
                                <svg className="icon icon-warning pb-1">
                                  <use
                                    href={`${iconSprite}#it-warning-circle`}
                                  ></use>
                                </svg>
                                <span className="text-warning">
                                  <b>IN ATTESA DELL'OPERATORE</b>
                                </span>
                              </>
                            ),
                            RICHIESTA_FIRMA: (
                              <>
                                <svg className="icon icon-warning pb-1">
                                  <use
                                    href={`${iconSprite}#it-warning-circle`}
                                  ></use>
                                </svg>
                                <span className="text-warning">
                                  <b>IN ATTESA DELLA FIRMA</b>
                                </span>
                              </>
                            ),
                            DELEGA_FIRMATA: (
                              <>
                                <svg className="icon icon-warning pb-1">
                                  <use
                                    href={`${iconSprite}#it-warning-circle`}
                                  ></use>
                                </svg>
                                <span className="text-warning">
                                  <b>DELEGA FIRMATA</b>
                                </span>
                              </>
                            ),
                          }[x.pagamento.status]
                        }
                      </div>
                    </div>
                    <div className="row mb-2 pl-2">
                      <div className="col-lg-3">
                        Importo: <b>{x.pagamento.importoPagato}€</b>
                      </div>
                      <div className="col-lg-3">
                        Data Richiesta: <b>{x.pagamento.dataInserimento}</b>
                      </div>
                      <div className="col-lg-4">
                        Ultimo Aggiornamento: <b>{x.pagamento.dataModifica}</b>
                      </div>
                    </div>
                    {
                      {
                        EVASO: (
                          <>
                            <div className="row mb-1">
                              <div className="col-lg-10 text-right">
                                <button
                                  className="btn btn-primary text-right"
                                  onClick={() => {
                                    window.location.href =
                                      "https://devb.servizilocalispa.it/DevSemplicitaCatasto.Api/api/SmartCatasto/Download/" +
                                      x.pagamentoId;
                                  }}
                                >
                                  Scarica Certificati
                                </button>
                              </div>
                              <div className="col-lg-2 text-right">
                                <button
                                  className="btn btn-primary text-right"
                                  onClick={() =>
                                    loadRichiestaElaborata(
                                      x.pagamentoId,
                                      "EVASA"
                                    )
                                  }
                                >
                                  Dettagli
                                </button>
                              </div>
                            </div>
                          </>
                        ),
                        IN_PAUSA: (
                          <>
                            <div className="row mb-1">
                              <div className="col-lg-12 text-right">
                                <button
                                  className="btn btn-primary text-right"
                                  onClick={() => reloadRichiesta(x.pagamentoId)}
                                >
                                  Dettagli
                                </button>
                              </div>
                            </div>
                          </>
                        ),
                        RIFIUTATO: (
                          <>
                            <div className="row mb-1">
                              <div className="col-lg-12 text-right">
                                <button
                                  className="btn btn-primary text-right"
                                  onClick={() =>
                                    loadRichiestaElaborata(
                                      x.pagamentoId,
                                      "RIFIUTATO"
                                    )
                                  }
                                >
                                  Dettagli
                                </button>
                              </div>
                            </div>
                          </>
                        ),
                        EVASO_P: (
                          <>
                            <div className="row mb-1">
                              <div className="col-lg-12 text-right">
                                <button
                                  className="btn btn-primary text-right"
                                  onClick={() =>
                                    loadRichiestaElaborata(
                                      x.pagamentoId,
                                      "EVASO"
                                    )
                                  }
                                >
                                  Dettagli
                                </button>
                              </div>
                            </div>
                          </>
                        ),
                        RIFIUTATO_P: (
                          <>
                            <div className="row mb-1">
                              <div className="col-lg-12 text-right">
                                <button
                                  className="btn btn-primary text-right"
                                  onClick={() =>
                                    loadRichiestaElaborata(
                                      x.pagamentoId,
                                      "RIFIUTATO"
                                    )
                                  }
                                >
                                  Dettagli
                                </button>
                              </div>
                            </div>
                          </>
                        ),
                        RICHIESTA_FIRMA: (
                          <>
                            <div className="row mb-1">
                              <div className="col-lg-12 text-right">
                                <button
                                  className="btn btn-danger text-right"
                                  onClick={() => {
                                    toggleModalSend(true);
                                    setPagamentoId(x.pagamentoId);
                                  }}
                                >
                                  Rifiuta
                                </button>
                              </div>
                            </div>
                          </>
                        ),
                        DELEGA_FIRMATA: (
                          <>
                            <div className="row mb-1">
                              <div className="col-lg-12 text-right">
                                <button
                                  className="btn btn-primary text-right"
                                  onClick={() => reloadRichiesta(x.pagamentoId)}
                                >
                                  Dettagli
                                </button>
                              </div>
                            </div>
                          </>
                        ),
                      }[x.pagamento.status]
                    }
                  </div>
                </div>
              </>
            )}
          </div>
        ))
      ) : (
        <></>
      )}
      {currentItems && currentItems.length !== 0 && items.length > 5 && (
        <div className="mt-3">
          <Pagination
            items={items}
            setCurrentItems={setCurrentItems}
            itemsPerPage={5}
          ></Pagination>
        </div>
      )}

      <Modal
        isOpen={isOpenSend}
        toggle={() => toggleModalSend(!isOpenSend)}
        size={"lg"}
        labelledBy="Modale1"
      >
        <ModalHeader id="Modale1">
          Sei sicuro di voler rifiutare la richiesta?
        </ModalHeader>
        <ModalBody>
          Cliccando sul tasto <b>"Si"</b> verrà rifiutata la richiesta.
        </ModalBody>
        <ModalFooter>
          <Button
            color="info text-white text-center"
            onClick={() => toggleModalSend(!isOpenSend)}
          >
            No
          </Button>
          <Button
            color="primary text-white text-center"
            onClick={() => refuseAllRequest(pagamentoId)}
          >
            Si
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListaStorico;
