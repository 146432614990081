import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Repo from "../Utility/Repo";
import ListaRichiesteSospese from "../Objects/ListaRichiesteSospese";
import Select from "../Objects/Select";
import moment, { months } from "moment";
import Loader from "../Utility/Loader";

function OrdiniSospeso(props) {
  const [token, setToken] = useState(props.token);
  const [ordiniNonPagati, setOrdiniNonPagati] = useState([]);
  const [ordiniNonFiltrati, setOrdiniNonFiltrati] = useState([]);
  const [listaPeriodi, setListaPeriodi] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    Repo.getOrdini(token, props.pagato)
      .then((response) => {
        setOrdiniNonPagati(response.data);
        setOrdiniNonFiltrati(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    setLoading(true);
    setListaPeriodi([
      {
        label: "Ultimi 3 Mesi",
        value: moment().subtract(3, "months").format("DD/MM/YYYY"),
      },
      {
        label: "Ultimi 6 Mesi",
        value: moment().subtract(6, "months").format("DD/MM/YYYY"),
      },
      {
        label: "Ultimo anno",
        value: moment().subtract(1, "years").format("DD/MM/YYYY"),
      },
    ]);
  }, [props]);

  const changePeriodo = (event) => {
    let ordiniTmp = ordiniNonFiltrati;

    ordiniTmp = ordiniTmp.filter(
      (obj) =>
        obj.dataInserimento &&
        moment(obj.dataInserimento, "DD/MM/YYYY") >=
          moment(event.value, "DD/MM/YYYY")
    );
    setOrdiniNonPagati(ordiniTmp);
  };

  return (
    <>
      {/* <div className="row">
        {props.pagato && (
          <div
            className="col-lg-12 text-center"
            style={{
              borderRadius: "5px",
              border: "2px solid rgb(46, 88, 166, 0.35)",
            }}
          >
            <h4 className="mt-3">Ricerca Richieste</h4>
            <form className="mr-5 ml-5 mt-4">
              <div className="form-row">
                <div className="form-group col-md-12 text-center mt-4">
                  <div className="row mb-4">
                    <div className="col-lg-4">
                      <div className="bootstrap-select-wrapper col-12">
                        <Select
                          aria-label="Seleziona un periodo"
                          clearText="Annulla"
                          defaultMenuIsOpen={false}
                          // value={selectedComune ? selectedComune : ""}
                          icon={false}
                          id="selectExampleClassic"
                          onChange={changePeriodo}
                          options={listaPeriodi}
                          isSearchable
                          placeholder="Seleziona un periodo"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary mr-1"
                    type="submit"
                    onClick={() => setOrdiniNonPagati(ordiniNonFiltrati)}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div> */}
      <div className="row col-lg-12 p-0">
        {ordiniNonPagati && ordiniNonPagati.length > 0 ? (
          <ListaRichiesteSospese
            token={props.token}
            pagato={props.pagato}
            subfolder={props.subfolder}
          >
            {ordiniNonPagati}
          </ListaRichiesteSospese>
        ) : (
          <div className="card card-teaser rounded shadow mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    fill="currentColor"
                    className="bi bi-cart"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                </div>

                <div className="col-10">
                  {props.pagato == false ? (
                    <h5>Il tuo carrello è vuoto!</h5>
                  ) : (
                    <h5>Non ci sono ordini completati</h5>
                  )}
                  <br />
                  <Link to="/home">
                    Clicca qui per effettuare una richiesta
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {!loading && <Loader />}
    </>
  );
}

export default OrdiniSospeso;
