import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const Breadcrumb = (props) => {
  const [linkHistory, setLink] = useState([]);
  let location = useLocation();

  useEffect(() => {
    setLink(location.pathname.split("/"));
  }, [location.pathname]);

  function isLast(index) {
    return index === linkHistory.length - 1;
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      <div className="row">
        <nav className="breadcrumb-container pl-2" aria-label="breadcrumb">
          <ol className="breadcrumb">
            {location.pathname !== "/home" && location.pathname !== "/" && (
              <>
                {" "}
                <p>
                  {
                    <>
                      <li className={"breadcrumb-item "}>
                        <Link to={"/" + "home"}>Home</Link>
                      </li>
                    </>
                  }
                </p>
                {linkHistory.map((x, index) => (
                  <p key={index}>
                    {
                      <>
                        {isLast(index) ? (
                          <li className={"breadcrumb-item active"}>
                            <a>{capitalizeFirstLetter(x.replace("_", " "))}</a>
                          </li>
                        ) : (
                          <li className={"breadcrumb-item "}>
                            <Link to={"/" + x}>
                              {capitalizeFirstLetter(x.replace("_", " "))}
                            </Link>
                            <span className="separator">/</span>
                          </li>
                        )}
                      </>
                    }
                  </p>
                ))}
              </>
            )}
            {(location.pathname == "/" || location.pathname == "/home") &&
              props.noService && (
                <>
                  <p>
                    {
                      <li className={"breadcrumb-item "}>
                        <Link to={"/"}>Home</Link>
                      </li>
                    }
                  </p>
                  {location.pathname !== "/home" && (
                    <>
                      {linkHistory.map((x, index) => (
                        <p key={index}>
                          {
                            <li className={"breadcrumb-item "}>
                              <Link to={"/" + x}>
                                {capitalizeFirstLetter(x.replace("_", " "))}
                              </Link>
                            </li>
                          }
                        </p>
                      ))}
                    </>
                  )}
                </>
              )}
          </ol>
        </nav>
      </div>
    </>
  );
};

export default Breadcrumb;
